import { LoadingIndicator } from "../LoadingIndicator"
import { selectedTabGreen, specializedRankingsAccessCode, rdSpendAccessCode, safetyAccessCode, ecPremiumAccessCode, premiumPreviews } from "../dictionary"
import { useState, useEffect } from "react"

export const PremiumPreviewModal = ({ purchaseID }) => {

  const [carousel, setCarousel] = useState(1)

  useEffect(() => {
    setCarousel(1)
  }, [purchaseID])

  const getRightImage = (totalImgCount, carousel) => {
    if (carousel < totalImgCount) {
      setCarousel(carousel + 1)
    }
  }

  const getLeftImage = (carousel) => {
    if (carousel > 1) {
      setCarousel(carousel - 1)
    }
  }

  const returnWidth = (purchaseID) => {
    switch (true) {
      case purchaseID == specializedRankingsAccessCode:
        return '45vw'
      case purchaseID == rdSpendAccessCode:
        return '50vw'
      case purchaseID == safetyAccessCode:
        return '55vw'
      case purchaseID == ecPremiumAccessCode:
        return '65vw'
      default:
        return '55vw'
    }
  }

  const returnMaxWidth = (purchaseID) => {
    switch (true) {
      case purchaseID == specializedRankingsAccessCode:
        return '1341px'
      case purchaseID == rdSpendAccessCode:
        return '587px'
      case purchaseID == safetyAccessCode:
        return '2890'
      case purchaseID == ecPremiumAccessCode:
        return '731px'
      default:
        return '55vw'
    }
  }

  const returnMaxHeight = (purchaseID) => {
    switch (true) {
      case purchaseID == specializedRankingsAccessCode:
        return '1819px'
      case purchaseID == rdSpendAccessCode:
        return '457px'
      case purchaseID == safetyAccessCode:
        return '2998px'
      case purchaseID == ecPremiumAccessCode:
        return '565px'
      default:
        return '90vh'
    }
  }

  const template = (purchaseID) => {

    let totalImgCount = 3
    let title = ''
    let premiumName = ''

    // Set total image count for carousel component based on purchaseID. Update when number of preview images changes
    switch (true) {
      case purchaseID == specializedRankingsAccessCode:
        totalImgCount = 3
        premiumName = 'specializedrankings'
        title = 'Specialized Rankings'
        break;
      case purchaseID == rdSpendAccessCode:
        totalImgCount = 3
        premiumName = 'rdspend'
        title = 'R&D Spend'
        break;
      case purchaseID == safetyAccessCode:
        totalImgCount = 3
        premiumName = 'crime'
        title = 'Crime & Safety'
        break;
      case purchaseID == ecPremiumAccessCode:
        totalImgCount = 7
        premiumName = 'ecinsights'
        title = 'EC Activity Premium Insights'
        break;
    }

    if (premiumName == '') {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <LoadingIndicator text={"Loading Preview ... "} mt={'0px'} ml={'0px'} />
        </div>
      )
    }

    else if (premiumName != '') {
      return (
        <div className="d-flex flex-column justify-content-center" style={{ width: '100%', height: '100%' }}>

          <div className="fw-bold text-center" style={{ lineHeight: '1.75em', fontSize: '1.75em' }}>Sample {title} Report</div>

          <div
            className="d-flex flex-row justify-content-center"
            style={{ marginTop: '40px' }}>
            <div
              className="d-flex align-self-center ms-5"
              onClick={() => getLeftImage(carousel)}>
              <i
                className="ki-duotone ki-left"
                style={
                  carousel == 1 ? {
                    fontSize: '4em',
                    opacity: 0,
                    visibility: 'hidden'
                  } :
                    {
                      fontSize: '4em', cursor: 'pointer'
                    }
                }
              >
              </i>
            </div>

            <img
              // style={{ 
              //   height: '90%', 
              //   maxHeight: '75vh', 
              //   width: '90%', 
              //   maxWidth: '75vw' }}
              style={{
                width: returnWidth(purchaseID),
                maxWidth: returnMaxWidth(purchaseID),
                height: 'auto',
                maxHeight: returnMaxHeight(purchaseID),
              }}
              src={premiumPreviews(premiumName, `${carousel}`)}></img>

            <div
              className="d-flex align-self-center me-5"
              onClick={() => getRightImage(totalImgCount, carousel)}>
              <i
                className="ki-duotone ki-right"
                style={
                  carousel == totalImgCount ? {
                    fontSize: '4em',
                    opacity: 0,
                    visibility: 'hidden'
                  } :
                    {
                      fontSize: '4em', cursor: 'pointer'
                    }
                }
              >
              </i>
            </div>
          </div>
        </div>
      )
    }

  }

  const contentReturn = (
    <div
      className="modal-content"
      style={{
        // minWidth: '300px',
        // width: returnWidth(purchaseID),
        // maxWidth: returnMaxWidth(purchaseID),
        // height: 'fit-content',
        // maxHeight: returnMaxHeight(purchaseID),
        width: 'fit-content',
        height: 'auto',
        maxWidth: '90vw',
        maxHeight: '90vh',
        padding: '30px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        overflowY: 'auto'
      }}
    >

      <i
        className="bi bi-x-lg align-self-end"
        data-bs-toggle="modal"
        data-bs-target="#purchase_modal"
        style={{ cursor: 'pointer', position: 'absolute', top: '20px', right: '20px' }}></i>

      {template(purchaseID)}

      <div
        className="d-flex justify-content-center"
        data-bs-toggle="modal"
        data-bs-target="#purchase_modal">
        <span
          className="fw-bold"
          style={{ borderRadius: '8px', background: selectedTabGreen, border: 'transparent', width: 'fit-content', marginTop: '40px', padding: '15px', whiteSpace: 'nowrap', cursor: 'pointer' }}>
          Back
        </span>
      </div>

    </div>
  )

  return (
    <div className="modal fade" id='premium_preview_modal'>
      <div className="modal-dialog modal-dialog-centered">
        {contentReturn}
        {/* <div 
          className="modal-content" 
          style={{ 
            minWidth: '75vw', 
            height: 'fit-content', 
            padding: '30px', 
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)', 
            overflowY: 'auto' }}>

          <i
            className="bi bi-x-lg align-self-end"
            data-bs-toggle="modal"
            data-bs-target="#purchase_modal"
            style={{ cursor: 'pointer', position: 'absolute', top: '20px', right: '20px' }}></i>

          {template(purchaseID)}

          <div
            className="d-flex justify-content-center"
            data-bs-toggle="modal"
            data-bs-target="#purchase_modal">
            <span
              className="fw-bold"
              style={{ borderRadius: '8px', background: selectedTabGreen, border: 'transparent', width: 'fit-content', marginTop: '40px', padding: '15px', whiteSpace: 'nowrap', cursor: 'pointer' }}>
              Back
            </span>
          </div>

        </div> */}
      </div>
    </div >
  )
}