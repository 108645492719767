import LoadingIndicatorGIF from '../../custom_assets/dca-loading-indicator.gif'

export const LoadingIndicator = ({ text, ml = '300px', mt = '200px' }) => {
  return <div className="d-flex flex-column" style={{ marginLeft: ml, marginTop: mt }}>
    <img src={LoadingIndicatorGIF} alt='LoadingIndicator' className="dca-loading-indicator-image" />
    <div
      style={{
        textAlign: 'center',
        marginTop: '40px'
      }}>{text}</div>
  </div>
}