import { useState } from 'react'
import Chart from 'react-apexcharts'
import { selectedTabGreen, unselectedTabGray } from '../../dictionary'
import { GroupStackedColumnChart, GroupStackedColumnChartGender } from './Charts/GroupStackedColumnChart'
import { StackedColumnChart, StackedColumnChartGender } from './Charts/StackedColumnChart'
import { CollegeReactGAHandlers } from '../../ReactGAHandlers'
import '../../../../_metronic/assets/sass/style.react.scss'

import trendUp from '../../../../custom_assets/trend_up.png'
import trendDown from '../../../../custom_assets/trend_down.png'
import trendNoChange from '../../../../custom_assets/trend-nochange.png'

const AdmissionInfo = ({ college, cds, mobile = false }) => {

  const { handleGAEventChartTabClick } = CollegeReactGAHandlers()

  const c7 = { 0: 'Very Important', 1: 'Important', 2: 'Considered', 3: 'Not Considered' }
  const dateConverter = (date) => {
    const splitDate = date.split('-')
    const months = {
      1: 'January', 2: 'February', 3: 'March', 4: 'April', 5: 'May', 6: 'June',
      7: 'July', 8: 'August', 9: 'September', 10: 'October', 11: 'November', 12: 'December'
    };

    let month = months[parseInt(splitDate[1])]
    let day = splitDate[2]

    return `${month} ${day}`
  }
  const [selectedTab, setSelectedTab] = useState('Total')
  const handleSelectedTab = (val) => {
    setSelectedTab(val)
  }

  let admissionTabOptions = ['Total']
  let admissionTabs = (
    <div></div>
  )

  if (college?.earlyApplicationOffered === true && (cds[0]?.earlyAdmitRate != null || cds[0]?.earlyAdmitRate != undefined)) {
    admissionTabOptions.push('By Round')
  }

  if (cds[2]?.menAdmitRate != null) {
    admissionTabOptions.push('By Gender')
  }


  // If By Round or By Gender added to the admission tab charts, set admission tabs component to:
  if (admissionTabOptions.length > 1) {
    admissionTabs = (

      <div className='mt-3 mb-3 d-flex flex-row'>
        {admissionTabOptions.map((tab, index) => (
          <div
            key={tab}
            className="d-flex mt-3 me-3 rounded rounded-3 justify-content-center align-items-center"
            style={{ backgroundColor: selectedTab === admissionTabOptions[index] ? selectedTabGreen : unselectedTabGray, height: '65px', cursor: 'pointer', border: '1px rgba(219, 223, 233, 0.4) dashed', borderRadius: '50%', display: 'inline-block' }}
            onClick={() => {
              handleSelectedTab(admissionTabOptions[index]);
              handleGAEventChartTabClick(college.id, admissionTabOptions[index])
            }}
          >
            <p className='m-5'>{admissionTabOptions[index]}</p>
          </div>
        ))}
      </div>
    )
  }

  //////// "Total" Tab Components //////
  // Charts
  let CDS: any[] = []
  let mostRecentYear: any = undefined
  let xAxisArray: any[] = ['-', '-', '-']
  let admitRateArray: any[] = ['-', '-', '-']
  let totalApplicantsArray: any[] = ['-', '-', '-']
  let totalAdmittedArray: any[] = ['-', '-', '-']
  let totalEnrolledArray: any[] = ['-', '-', '-']
  let totalYieldArray: any[] = ['-', '-', '-']

  // By Round arrays
  let earlyApplicantsArray: any[] = ['-', '-', '-']
  let earlyAdmitArray: any[] = ['-', '-', '-']
  let earlyAdmitRateArray: any[] = ['-', '-', '-']

  let regularApplicantsArray: any[] = ['-', '-', '-']
  let regularAdmitArray: any[] = ['-', '-', '-']
  let regularAdmitRateArray: any[] = ['-', '-', '-']

  //// By Gender arrays

  // Chart 1
  let menAdmitRateArray: any[] = ['-', '-', '-']
  let womenAdmitRateArray: any[] = ['-', '-', '-']
  let otherAdmitRateArray: any[] = ['-', '-', '-']

  // Chart 2
  let menAppliedArray: any[] = ['-', '-', '-']
  let womenAppliedArray: any[] = ['-', '-', '-']
  let otherAppliedArray: any[] = ['-', '-', '-']

  let menAdmittedArray: any[] = ['-', '-', '-']
  let womenAdmittedArray: any[] = ['-', '-', '-']
  let otherAdmittedArray: any[] = ['-', '-', '-']

  // Chart 3
  let menAdmittedPercentArray: any[] = ['-', '-', '-']
  let womenAdmittedPercentArray: any[] = ['-', '-', '-']
  let otherAdmittedPercentArray: any[] = ['-', '-', '-']

  let menEnrolledPercentArray: any[] = ['-', '-', '-']
  let womenEnrolledPercentArray: any[] = ['-', '-', '-']
  let otherEnrolledPercentArray: any[] = ['-', '-', '-']

  let earlyRoundNotesN = ""
  let earlyRoundNotesN1 = ""
  let earlyRoundNotesN2 = ""
  let regularRoundNotesN = ""
  let regularRoundNotesN1 = ""
  let regularRoundNotesN2 = ""

  let roundNotes = (
    <span></span>
  )

  const returnToFixed1 = (val) => {
    return val ? (val * 100).toFixed(1) : null
  }

  const returnValOr0 = (val) => {
    return val ? val : 0
  }

  if (college && college.college_cds && college.college_cds.length > 0) {
    xAxisArray = []
    admitRateArray = []
    totalApplicantsArray = []
    totalAdmittedArray = []
    totalEnrolledArray = []
    totalYieldArray = []

    earlyApplicantsArray = []
    earlyAdmitArray = []
    earlyAdmitRateArray = []

    regularApplicantsArray = []
    regularAdmitArray = []
    regularAdmitRateArray = []

    menAdmitRateArray = []
    womenAdmitRateArray = []
    otherAdmitRateArray = []

    menAppliedArray = []
    menAdmittedArray = []
    womenAppliedArray = []
    womenAdmittedArray = []
    otherAppliedArray = []
    otherAdmittedArray = []

    menAdmittedPercentArray = []
    menEnrolledPercentArray = []
    womenAdmittedPercentArray = []
    womenEnrolledPercentArray = []
    otherAdmittedPercentArray = []
    otherEnrolledPercentArray = []

    cds.map((res) => {

      mostRecentYear = res.year

      mostRecentYear = res.year > mostRecentYear ? res.year : mostRecentYear

      xAxisArray.push(`${res.year} - ${res.year + 1}`);
      admitRateArray.push(returnToFixed1(res.totalAdmitRate))
      totalApplicantsArray.push(res.totalApplicants)
      totalAdmittedArray.push(res.totalAdmits)
      totalEnrolledArray.push(res.totalEnrolled)
      totalYieldArray.push(returnToFixed1(res.totalYieldRate))

      earlyApplicantsArray.push(res.earlyApplicants)
      earlyAdmitArray.push(res.earlyAdmits)
      earlyAdmitRateArray.push(returnToFixed1(res.earlyAdmitRate))

      regularApplicantsArray.push(res.regularApplicants)
      regularAdmitArray.push(res.regularAdmits)
      regularAdmitRateArray.push(returnToFixed1(res.regularAdmitRate))

      //// By Gender ////
      // first chart
      // admitRateArray from above == totalAdmitRate
      menAdmitRateArray.push(returnToFixed1(res.menAdmitRate))
      womenAdmitRateArray.push(returnToFixed1(res.womenAdmitRate))
      otherAdmitRateArray.push(returnToFixed1(res.otherAdmitRate))

      // second chart
      // applicant admit
      menAppliedArray.push(returnValOr0(res.menApplied))
      womenAppliedArray.push(returnValOr0(res.womenApplied))
      otherAppliedArray.push(returnValOr0(res.otherApplied))

      menAdmittedArray.push(returnValOr0(res.menAdmitted))
      womenAdmittedArray.push(returnValOr0(res.womenAdmitted))
      otherAdmittedArray.push(returnValOr0(res.otherAdmitted))

      // third chart
      const getAdmitPercentage = (res, group) => {
        let a = res.menAdmitted
        let b = res.womenAdmitted
        let c = res.otherAdmitted
        let total = (a + b + c)

        let target = a

        switch (true) {
          case group == 'men':
            target = a
            break;
          case group == 'women':
            target = b
            break;
          case group == 'other':
            target = c
            break;
        }

        return (target / total) * 100

      }

      const getEnrollPercentage = (res, group) => {
        let a = res.menEnrolled
        let b = res.womenEnrolled
        let c = res.otherEnrolled
        let total = a + b + c

        let target = a

        switch (true) {
          case group == 'men':
            target = a
            break;
          case group == 'women':
            target = b
            break;
          case group == 'other':
            target = c
            break;
        }

        return (target / total) * 100

      }

      menAdmittedPercentArray.push(getAdmitPercentage(res, 'men'))
      womenAdmittedPercentArray.push(getAdmitPercentage(res, 'women'))
      otherAdmittedPercentArray.push(getAdmitPercentage(res, 'other'))

      menEnrolledPercentArray.push(getEnrollPercentage(res, 'men'))
      womenEnrolledPercentArray.push(getEnrollPercentage(res, 'women'))
      otherEnrolledPercentArray.push(getEnrollPercentage(res, 'other'))
    })

    //Set early and regular round notes
    earlyRoundNotesN = college.college_cds[0].earlyRoundNotes
    earlyRoundNotesN1 = college.college_cds[1].earlyRoundNotes
    earlyRoundNotesN2 = college.college_cds[2].earlyRoundNotes
    regularRoundNotesN = college.college_cds[0].regularRoundNotes
    regularRoundNotesN1 = college.college_cds[1].regularRoundNotes
    regularRoundNotesN2 = college.college_cds[2].regularRoundNotes

    roundNotes = (
      <ul className='dca-font-R' style={{ listStyleType: 'none' }}>
        {earlyRoundNotesN && <li>Notes for {mostRecentYear}-{mostRecentYear + 1} data: {earlyRoundNotesN}</li>}

        {regularRoundNotesN && <li>Notes for {mostRecentYear}-{mostRecentYear + 1} data: {regularRoundNotesN}</li>}

        {earlyRoundNotesN1 && <li>Notes for {mostRecentYear - 1}-{mostRecentYear} data: {earlyRoundNotesN1}</li>}

        {regularRoundNotesN1 && <li>Notes for {mostRecentYear - 1}-{mostRecentYear} data:{regularRoundNotesN1}</li>}

        {earlyRoundNotesN2 && <li>Notes for {mostRecentYear - 2}-{mostRecentYear - 1} data: {earlyRoundNotesN2}</li>}

        {regularRoundNotesN2 && <li>Notes for {mostRecentYear - 2}-{mostRecentYear - 1} data: {regularRoundNotesN2}</li>}

        <li className='dca-font-R'>Early round data is inclusive of all early application rounds (i.e. Early Action, Early Decision I, Early Decision II, etc.) for colleges offering multiple early options, unless otherwise indicated.</li>
        <li className='dca-font-R'>Regular round admit figure may also include admits from the pool of early round applicants who were deferred into the regular round.</li>
      </ul>
    )

  }

  const totalCharts = (
    <div className={`d-flex flex-${mobile ? 'column' : 'row'} justify-content-between`}>
      <div className='dca-chart-title-wrapper' style={{ marginTop: mobile ? '25px' : '', width: mobile ? '' : '30%' }}>
        <div className='dca-chart-title-position'>Admit Rate</div>
        <Chart
          options={{
            chart: {
              id: "Total Admit Rate",
              toolbar: {
			          show: false,
			        },
            },
            xaxis: {
              categories: xAxisArray,
              labels: {
                show: true,
                style: {
                  colors: '#9AA2B6',
                },
              },
            },
            tooltip: {
              enabled: false,
            },
            yaxis: {
              min: 0.00,
              max: admitRateArray[0] * 1.5,
              forceNiceScale: true,
              labels: {
                style: {
                  colors: ['#9AA2B6'],
                },
                formatter: function (val) {
                  return val.toFixed(0) + "%";
                }
              }
            },
            colors: ["#90F892"],
            dataLabels: {
              enabled: true,
              background: {
                borderWidth: 0,
                borderRadius: 5,
                foreColor: '#000',
              },
              formatter: function (val, opts) {
                return val + "%";
              },
            },
            fill: {
              colors: ['#DAF8B9'],
              type: "gradient",
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.6,
                opacityTo: 0.95,
                stops: [0, 95, 100]
              }
            },
            stroke: {
              curve: 'smooth'
            }
          }}
          series={[
            {
              name: "Total Admit Rate %",
              data: admitRateArray
            }
          ]}
          type='area'
          height={'300'}
        />
      </div>

      <div
        className='dca-chart-title-wrapper' style={{ marginTop: mobile ? '25px' : '', width: mobile ? '' : '40%' }}>
        <div className='dca-chart-title-position'>Applied / Admitted / Enrolled</div>
        <Chart
          series={[
            {
              name: 'Applied',
              type: 'line',
              data: totalApplicantsArray
            },
            {
              name: 'Admitted',
              type: 'column',
              data: totalAdmittedArray
            },
            {
              name: 'Enrolled',
              type: 'column',
              data: totalEnrolledArray
            }
          ]}
          options={{
            chart: {
              id: "Applied / Admitted / Enrolled",
              type: 'area',
              stacked: false,
              toolbar: {
			          show: false,
			        },
            },
            legend: {
              show: true,
              position: 'top',
              horizontalAlign: 'left',
              offsetX: 10,
              onItemClick: {
                toggleDataSeries: false
              },
              onItemHover: {
                highlightDataSeries: false
              },
            },
            xaxis: {
              categories: xAxisArray,
              labels: {
                show: true,
                style: {
                  colors: '#9AA2B6',
                },
              }
            },
            tooltip: {
              enabled: false,
            },
            yaxis: [{
              seriesName: 'Applied',
              title: {
                text: 'Applied'
              },
              labels: {
                style: {
                  colors: ['#9AA2B6'],
                },
                formatter: function (val, opts) {
                  return val.toLocaleString("en-US");
                },
              },
              min: 0,
            }, {
              opposite: true,
              seriesName: 'Admitted',
              title: {
                text: 'Admitted / Enrolled'
              },
              labels: {
                style: {
                  colors: ['#9AA2B6'],
                },
                formatter: function (val, opts) {
                  return val.toLocaleString("en-US");
                },
              },
              // max: totalAdmittedArray[0] * 2,
              min: 0,
              forceNiceScale: true,
            },
            {
              seriesName: 'Admitted',
              show: false
            }],
            colors: ["#90F892", "#FFEF68", "#97E6FF"],
            dataLabels: {
              enabled: true,
              background: {
                enabled: true,
                borderWidth: 0,
                foreColor: '#000',
              },
              formatter: function (val, opts) {
                return val.toLocaleString("en-US");
              },
            },
            stroke: {
              curve: 'smooth',
            },
            plotOptions: {
              bar: {
                columnWidth: '40%',
                borderRadius: totalAdmittedArray.some(admit => typeof admit === 'number') || totalEnrolledArray.some(enroll => typeof enroll === 'number') ? 5 : undefined,
                borderRadiusApplication: 'end',
                dataLabels: {
                  position: 'top',
                }
              }
            }
          }}
          //width={'450'}
          height={'300'}
        />
      </div>

      <div className='dca-chart-title-wrapper' style={{ marginTop: mobile ? '25px' : '', width: mobile ? '' : '30%' }}>
        <div className='dca-chart-title-position'>Yield</div>
        <Chart
          options={{
            chart: {
              id: "Total Admit Rate",
              toolbar: {
			          show: false,
			        },
            },
            xaxis: {
              categories: xAxisArray,
              labels: {
                show: true,
                style: {
                  colors: '#9AA2B6',
                },
              }
            },
            tooltip: {
              enabled: false,
            },
            yaxis: {
              min: 0.00,
              max: 100.00,
              labels: {
                style: {
                  colors: ['#9AA2B6'],
                },
                formatter: function (val) {
                  return val.toFixed(0) + "%"
                }
              }
            },
            colors: ["#90F892"],
            dataLabels: {
              enabled: true,
              background: {
                borderWidth: 0,
                borderRadius: 5,
                foreColor: '#000',
              },
              formatter: function (val, opts) {
                return val + "%"
              },
            },
            fill: {
              colors: ['#DAF8B9'],
              type: "gradient",
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.6,
                opacityTo: 0.95,
                stops: [0, 95, 100]
              }
            },
            stroke: {
              curve: 'smooth'
            }
          }}
          series={[
            {
              name: "Total Yield Rate %",
              data: totalYieldArray
            }
          ]}
          type='area'
          height={'300'}
        />
      </div>
    </div>
  )

  const byRoundCharts = (
    <div className={`d-flex flex-${mobile ? 'column' : 'row'} justify-content-between`}>
      <div
        className='dca-chart-title-wrapper'
        style={{
          '--chart-wrapper-width': mobile ? '100%' : '33%', marginTop: mobile ? '25px' : ''
        } as any}>
        <div className='dca-chart-title-position'>Admit Rate Breakdown</div>
        <Chart
          options={{
            chart: {
              id: "Admit Rate Breakdown",
              toolbar: {
			          show: false,
			        },
            },
            xaxis: {
              categories: xAxisArray,
              labels: {
                show: true,
                style: {
                  colors: '#9AA2B6',
                },
              }
            },
            tooltip: {
              enabled: false,
            },
            yaxis: {
              // min: 0.00,
              min: Math.max(0, (Math.min(...earlyAdmitRateArray, ...admitRateArray, ...regularAdmitRateArray) - 2)),
              max: Math.max(...earlyAdmitRateArray, ...admitRateArray, ...regularAdmitRateArray) + 2 < 100 ? Math.max(...earlyAdmitRateArray, ...admitRateArray, ...regularAdmitRateArray) + 2 : 100,
              forceNiceScale: true,
              labels: {
                style: {
                  colors: ['#9AA2B6'],
                },
                formatter: function (val) {
                  return val.toFixed(0) + "%";
                }
              }
            },
            colors: ["#90F892", "#FFEF68", "#97E6FF"],
            dataLabels: {
              enabled: true,
              background: {
                borderWidth: 0,
                borderRadius: 5,
                foreColor: '#000',
              },
              formatter: function (val, opts) {
                return val + "%";
              },
            },
            fill: {
              colors: ['#DAF8B9'],
              opacity: 0,
              type: "solid",
              /*gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.6,
                opacityTo: 0.95,
                stops: [0, 95, 100]
              }*/
            },
            legend: {
              show: true,
              onItemClick: {
                toggleDataSeries: false
              },
              onItemHover: {
                highlightDataSeries: true
              },
            },
            stroke: {
              curve: 'smooth'
            }
          }}
          series={[
            {
              name: "Total Admit Rate %",
              type: 'area',
              data: admitRateArray
            },
            {
              name: "Early Round Admit Rate %",
              type: 'area',
              data: earlyAdmitRateArray
            },
            {
              name: "Regular Round Admit Rate %",
              type: 'area',
              data: regularAdmitRateArray
            }
          ]}
          //type='area'
          height={'300'}
        />
      </div>

      <div
        className='dca-chart-title-wrapper'
        style={{
          '--chart-wrapper-width': mobile ? '100%' : '33%', marginTop: mobile ? '25px' : ''
        } as any}>
        <div className='dca-chart-title-position'>Breakdown Of Applicant and Admit Numbers</div>
        <GroupStackedColumnChart
          chartId={'Number of Applicants Applied/Admitted'}
          xaxis={xAxisArray}
          earlyApplicantsArray={earlyApplicantsArray}
          earlyAdmitArray={earlyAdmitArray}
          regularApplicantsArray={regularApplicantsArray}
          regularAdmitArray={regularAdmitArray}
        /></div>

      <div
        className='dca-chart-title-wrapper'
        style={{
          '--chart-wrapper-width': mobile ? '100%' : '33%', marginTop: mobile ? '25px' : ''
        } as any}>
        <div
          className='dca-chart-title-position'>Breakdown of Admits By Round</div>
        <StackedColumnChart
          chartId={'Number of Applicants Applied/Admitted'}
          xaxis={xAxisArray}
          earlyAdmitArray={earlyAdmitArray}
          regularAdmitArray={regularAdmitArray}
        /></div>
    </div>
  )

  const byGenderCharts = (
    <div className={`d-flex flex-${mobile ? 'column' : 'row'} justify-content-between`}>
      <div
        className='dca-chart-title-wrapper'
        style={{
          '--chart-wrapper-width': mobile ? '100%' : '33%', marginTop: mobile ? '25px' : ''
        } as any}>
        <div className='dca-chart-title-position'>Admit Rate Breakdown</div>
        <Chart
          options={{
            chart: {
              id: "Admit Rate Breakdown",
              toolbar: {
			          show: false,
			        },
            },
            xaxis: {
              categories: xAxisArray,
              labels: {
                show: true,
                style: {
                  colors: '#9AA2B6',
                },
              }
            },
            tooltip: {
              enabled: false,
            },
            yaxis: {
              // min: 0.00,
              min: Math.max(0, (Math.min(...admitRateArray, ...menAdmitRateArray, ...womenAdmitRateArray) - 2)),
              max: Math.max(...admitRateArray, ...menAdmitRateArray, ...womenAdmitRateArray) + 2 < 100 ? Math.max(...admitRateArray, ...menAdmitRateArray, ...womenAdmitRateArray) + 2 : 100,
              forceNiceScale: true,
              labels: {
                style: {
                  colors: ['#9AA2B6'],
                },
                formatter: function (val) {
                  return val.toFixed(0) + "%";
                }
              }
            },
            colors: ["#90F892", "#FFEF68", "#97E6FF", "#C5DEF9"],
            dataLabels: {
              enabled: true,
              background: {
                borderWidth: 0,
                borderRadius: 5,
                foreColor: '#000',
              },
              formatter: function (val, opts) {
                return val ? val + "%" : ''
              },
            },
            fill: {
              colors: ['#DAF8B9'],
              opacity: 0,
              type: "solid",
              /*gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.6,
                opacityTo: 0.95,
                stops: [0, 95, 100]
              }*/
            },
            legend: {
              show: true,
              onItemClick: {
                toggleDataSeries: false
              },
              onItemHover: {
                highlightDataSeries: true
              },
            },
            stroke: {
              curve: 'smooth'
            }
          }}
          series={otherAdmitRateArray.some(item => item !== null) ? [
            {
              name: "Total Admit Rate %",
              type: 'area',
              data: admitRateArray
            },
            {
              name: "Men Admit Rate %",
              type: 'area',
              data: menAdmitRateArray
            },
            {
              name: "Women Admit Rate %",
              type: 'area',
              data: womenAdmitRateArray
            },
            {
              name: "Other Admit Rate",
              type: 'area',
              data: otherAdmitRateArray
            }
          ] : [
            {
              name: "Total Admit Rate %",
              type: 'area',
              data: admitRateArray
            },
            {
              name: "Men Admit Rate %",
              type: 'area',
              data: menAdmitRateArray
            },
            {
              name: "Women Admit Rate %",
              type: 'area',
              data: womenAdmitRateArray
            }
          ]}
          //type='area'
          height={'300'}
        />
      </div>

      <div
        className='dca-chart-title-wrapper'
        style={{
          '--chart-wrapper-width': mobile ? '100%' : '33%', marginTop: mobile ? '25px' : ''
        } as any}>
        <div className='dca-chart-title-position'>Breakdown Of Applicant and Admit Numbers</div>
        <GroupStackedColumnChartGender
          chartId={'Number of Applicants Applied/Admitted'}
          xaxis={xAxisArray}
          menAppliedArray={menAppliedArray}
          menAdmittedArray={menAdmittedArray}
          womenAppliedArray={womenAppliedArray}
          womenAdmittedArray={womenAdmittedArray}
          otherAppliedArray={otherAppliedArray}
          otherAdmittedArray={otherAdmittedArray}
        />
      </div>

      <div
        className='dca-chart-title-wrapper'
        style={{
          '--chart-wrapper-width': mobile ? '100%' : '33%', marginTop: mobile ? '25px' : ''
        } as any}>
        <div
          className='dca-chart-title-position'>Breakdown of Admits and Enrollees By Gender</div>
        <StackedColumnChartGender
          chartId={'Number of Applicants Applied/Admitted'}
          xaxis={xAxisArray}
          menAdmittedArray={menAdmittedPercentArray}
          menEnrolledArray={menEnrolledPercentArray}
          womenAdmittedArray={womenAdmittedPercentArray}
          womenEnrolledArray={womenEnrolledPercentArray}
          otherAdmittedArray={otherAdmittedPercentArray}
          otherEnrolledArray={otherEnrolledPercentArray}
        />
      </div>

    </div>
  )

  // Admission Considerations
  let admissionConsiderationsFieldsOne: any[] = [];
  let admissionConsiderationsFieldsTwo: any[] = [];

  const compareACFields = (field) => {

    let collegeCDS = [...college?.college_cds]
    if (collegeCDS?.length > 1) {
      // college?.college_cds is already sorted by year, if length is greater than 1 we can take the obj in position [1] as the comparison year
      let year1 = collegeCDS[0]?.year ?? undefined
      let year2 = collegeCDS[1]?.year ?? undefined

      if (year1 === undefined || year2 === undefined || !(year1 == year2 + 1)) {
        return undefined
      }

      let year1data = collegeCDS[0]
      let year2data = collegeCDS[1]

      // recall considerations are desc, meaning the lower the number the greater the consideration
      if (year1data[field] !== null && year1data[field] !== undefined && year2data[field] !== null && year2data[field] !== undefined) {
        if (year1data[field] === year2data[field]) {
          return 0
        }
        else {
          return year1data[field] > year2data[field] ? -1 : 1
        }
      }
    }

    return undefined
  }

  const returnTrend = (trendVal) => {
    if (trendVal === undefined) {
      return <div></div>
    }

    else {
      if (trendVal === 0) {
        return <img
          src={trendNoChange}
          alt={'trend_up'}
          className='dca-college-details-admission-considerations-trend-icon-B'></img>
      }

      else {
        return <img
          src={trendVal === 1 ? trendUp : trendDown}
          alt={'trend_up'}
          className='dca-college-details-admission-considerations-trend-icon'></img>
      }
    }
  }

  if (college?.college_cds?.length > 0) {

    const collegeCDS = college?.college_cds[0];

    admissionConsiderationsFieldsOne.push(
      {
        name: 'Rigor of secondary school record',
        consideration: c7[collegeCDS.rigorSchoolRecord],
        trend: compareACFields('rigorSchoolRecord')
      },
      {
        name: 'Class rank',
        consideration: c7[collegeCDS.classRank],
        trend: compareACFields('classRank')
      },
      {
        name: 'Academic GPA',
        consideration: c7[collegeCDS.academicGPA],
        trend: compareACFields('academicGPA')
      },
      {
        name: 'Standardized test scores',
        consideration: c7[collegeCDS.testScores],
        trend: compareACFields('testScores')
      },
      {
        name: 'Application Essay',
        consideration: c7[collegeCDS.applicationEssay],
        trend: compareACFields('applicationEssay')
      },
      {
        name: 'Recommendation(s)',
        consideration: c7[collegeCDS.recommendation],
        trend: compareACFields('recommendation')
      },
      {
        name: 'Interview',
        consideration: c7[collegeCDS.interview],
        trend: compareACFields('interview')
      },
      {
        name: 'Extracurricular Activities',
        consideration: c7[collegeCDS.extracurriculars],
        trend: compareACFields('extracurriculars')
      },
      {
        name: 'Talent/ability',
        consideration: c7[collegeCDS.talentAbility],
        trend: compareACFields('talentAbility')
      }
    );

    admissionConsiderationsFieldsTwo.push(
      {
        name: 'Character/personal qualities',
        consideration: c7[collegeCDS.character],
        trend: compareACFields('character')
      },
      {
        name: 'First generation',
        consideration: c7[collegeCDS.firstGeneration],
        trend: compareACFields('firstGeneration')
      },
      {
        name: 'Alumni/ae relation',
        consideration: c7[collegeCDS.alumni],
        trend: compareACFields('alumni')
      },
      {
        name: 'Geographical residence',
        consideration: c7[collegeCDS.geographicResidence],
        trend: compareACFields('geographicResidence')
      },
      {
        name: 'State residency',
        consideration: c7[collegeCDS.stateResidence],
        trend: compareACFields('stateResidence')
      },
      {
        name: 'Religious affiliation/commitment',
        consideration: c7[collegeCDS.religion],
        trend: compareACFields('religion')
      },
      {
        name: 'Volunteer work',
        consideration: c7[collegeCDS.volunteerWork],
        trend: compareACFields('volunteerWork')
      },
      {
        name: 'Work experience',
        consideration: c7[collegeCDS.workExperience],
        trend: compareACFields('workExperience')
      },
      {
        name: `Level of applicant's interest`,
        consideration: c7[collegeCDS.levelOfInterest],
        trend: compareACFields('levelOfInterest')
      }
    )
  }

  const returnPillColor = (consideration) => {
    return consideration === 'Very Important' ? 'rgba(152, 242, 155, 0.7)' : consideration === 'Important' ? '#DAF8B9' : consideration === 'Considered' ? '#DFEFFF' : '#F2F2F2'
  }

  const mobileTrendLegend = (
    <div
      className='d-flex flex-column align-self-start'
      style={{ marginLeft: '30px', marginTop: '30px', marginBottom: '30px' }}><span className='dca-font-M'>Current Importance vs. Prior Year</span>
      <div id='college_details_admissions_considerations_trend_legend' className='d-flex flex-column dca-font-R'>
        <div>
          <img src={trendUp} alt='trend_up' className='dca-college-details-admission-considerations-trend-icon'></img>&nbsp;&nbsp;&nbsp;Increase in relative importance
        </div>

        <div>
          <img src={trendDown} alt='trend_down' className='dca-college-details-admission-considerations-trend-icon'></img>&nbsp;&nbsp;&nbsp;Decrease in relative importance
        </div>

        <div>
          <img src={trendNoChange} alt='trend_no_change' className='dca-college-details-admission-considerations-trend-icon-B'></img>&nbsp;&nbsp;No change
        </div>
      </div>
    </div>
  )

  const desktopTrendLegend = (
    <div className='d-flex flex-column'>
      <span className='dca-font-M text-end'>Current Importance vs. Prior Year</span>
      <div id='college_details_admissions_considerations_trend_legend'
        className='d-flex flex-row dca-font-R'>
        <div>
          <img src={trendUp} alt='trend_up' className='dca-college-details-admission-considerations-trend-icon'></img> Increase in relative importance
        </div>

        &nbsp;&nbsp;&nbsp;

        <div>
          <img src={trendDown} alt='trend_down' className='dca-college-details-admission-considerations-trend-icon'></img> Decrease in relative importance
        </div>

        &nbsp;&nbsp;&nbsp;

        <div>
          <img src={trendNoChange} alt='trend_no_change' className='dca-college-details-admission-considerations-trend-icon-B'></img> No change
        </div>
      </div>
    </div>)

  const trendLegend = mobile ? (
    mobileTrendLegend) : (desktopTrendLegend)

  const admissionsConsiderations = (
    <div className='d-flex flex-column'>

      <div style={{ margin: '30px 30px 0px 30px' }} className='d-flex flex-row justify-content-between'>
        <h3 className='dca-font-R'>Admission Considerations</h3>
        {trendLegend}
      </div>

      <div className={`d-flex flex-row justify-content-between`} style={{ width: '65vw', marginLeft: '30px' }}>

        <ul className='d-flex flex-column justify-content-between' style={{ width: '30vw', marginTop: '30px', padding: '0px' }}>
          {admissionConsiderationsFieldsOne.map((res, index) => (
            <li key={index} className='d-flex flex-column' style={{ height: '45px' }}>
              <div className='d-flex flex-row justify-content-between align-items-center text-start' style={{ height: '30px', minHeight: '30px', maxHeight: '30px' }}>
                <div className='dca-font-R'>{res.name}</div>
                <div className='d-flex flex-row align-items-center'>
                  <span className='badge rounded-pill dca-font-R'
                    style={{
                      background: returnPillColor(res.consideration),
                      height: '25px',
                      marginRight: '8px'
                    }}>{res.consideration}
                  </span>
                  {res.trend != undefined ? returnTrend(res.trend) : <div className='dca-college-details-admission-considerations-trend-icon-B'></div>}
                </div>
              </div>
              {index != admissionConsiderationsFieldsOne.length - 1 && <div className='m-2 separator'></div>}
            </li>
          ))}
        </ul>
        <ul className='d-flex flex-column justify-content-between' style={{ width: '30vw', marginTop: '30px' }}>
          {admissionConsiderationsFieldsTwo.map((res, index) => (
            <li key={index} className='d-flex flex-column' style={{ height: '45px' }}>
              <div className='d-flex flex-row justify-content-between align-items-center text-start' style={{ height: '30px', minHeight: '30px', maxHeight: '30px' }}>
                <div className='dca-font-R'>{res.name}</div>
                <div className='d-flex flex-row align-items-center'>
                  <span className='badge rounded-pill dca-font-R'
                    style={{
                      background: returnPillColor(res.consideration),
                      height: '25px',
                      marginRight: '8px'
                    }}>{res.consideration}
                  </span>
                  {res.trend != undefined ? returnTrend(res.trend) : <div className='dca-college-details-admission-considerations-trend-icon-B'></div>}
                </div>
              </div>
              {index != admissionConsiderationsFieldsTwo.length - 1 && <div className='m-2 separator'></div>}
            </li>
          ))}
        </ul>

      </div>
    </div >
  )

  let combinedAdmissionsConsiderations = [
    ...admissionConsiderationsFieldsOne,
    ...admissionConsiderationsFieldsTwo,
  ]

  const admissionsConsiderationsMobile = (
    <div className='d-flex flex-column'>

      <h3 className='dca-font-R' style={{ marginTop: '30px', marginLeft: '30px' }}>Admission Considerations</h3>

      <div className={`d-flex flex-column justify-content-between`} style={{ width: '90%' }}>
        <ul className='d-flex flex-column justify-content-between' style={{ width: '100%', marginTop: '30px', marginLeft: '30px', padding: '0px' }}>
          {combinedAdmissionsConsiderations.map((res, index) => (
            <li key={index} className='d-flex flex-column'>
              <div className='d-flex flex-row justify-content-between align-items-center'>
                <div className='dca-font-R'>{res.name}</div>
                <div className='d-flex flex-row align-items-center'>
                  <span className='badge rounded-pill dca-font-R'
                    style={{
                      background: returnPillColor(res.consideration),
                      height: '25px',
                      marginRight: '8px'
                    }}>{res.consideration}
                  </span>
                  {res.trend != undefined ? returnTrend(res.trend) : <div className='dca-college-details-admission-considerations-trend-icon-B'></div>}
                </div>
              </div>
              {index != combinedAdmissionsConsiderations.length - 1 && <div className='m-2 separator'></div>}
            </li>
          ))}
        </ul>
      </div>

      {trendLegend}
    </div>
  )

  // Application Deadline
  let appDeadlines: any[] = []
  if (college && college.college_application_rounds && college.college_application_rounds.length > 0) {
    college.college_application_rounds.map((deadline) => appDeadlines.push(deadline))
  }

  const applicationDeadline = appDeadlines.length > 0 ? (
    <div>
      <h3 className='dca-font-R' style={{ marginTop: '30px', marginLeft: '30px' }}>Application Deadlines (For 2024-2025 Admissions Cycle)</h3>

      <div>
        <table className="table table-borderless" style={{ marginTop: '30px', marginLeft: '35px', width: '60vw' }}>
          <thead>
            <tr>
              <th scope="col">OPTION</th>
              <th scope="col">DEADLINE</th>
              <th scope="col">DECISION</th>
              {!mobile && <th scope="col">RESTRICTIVE</th>}
              {!mobile && <th scope="col">BINDING</th>}
            </tr>
          </thead>

          {appDeadlines.map((deadline, index) => (
            <tbody key={index} style={{ borderBottom: index < appDeadlines.length - 1 ? '1px solid #f1f1f2' : 'none' }}>
              <tr style={{ padding: '10px' }}>
                <th scope="row">{deadline.name ? deadline.name : 'N/A'}</th>
                <td>{deadline.deadline ? dateConverter(deadline.deadline) : 'N/A'}</td>
                <td>{deadline.decision ? deadline.decision : 'N/A'}</td>
                {!mobile && <td>{deadline.restrictive === null || deadline.restrictive === 'undefined' ? 'N/A' : deadline.restrictive === false ? 'No' : 'Yes'}</td>}
                {!mobile && <td>{deadline.binding === null || deadline.binding === 'undefined' ? 'N/A' : deadline.binding === false ? 'No' : 'Yes'}</td>}
              </tr>
            </tbody>
          ))}
        </table>
      </div>

      {college && college.appProcessNotes !== null ? <p style={{ marginTop: '30px', marginLeft: '35px', paddingBottom: '60px' }}>NOTES:</p> : <div></div>}
    </div>
  ) : (
    <div>
      <h3 className='dca-font-R' style={{ margin: '30px' }}>No Application Deadline Data Available</h3>
    </div>)

  const admissionTables = (
    <div>
      <div className='card'>
        {mobile ? admissionsConsiderationsMobile : admissionsConsiderations}
      </div>
      <div className='card' style={{ marginTop: '30px' }}>
        {applicationDeadline}
      </div>
    </div>
  )

  // switch (selectedTab) {
  //   case 'Total':
  //     return (
  //       <div style={{ marginTop: '40px' }}>
  //         <div className='card'>
  //           <div style={{ marginTop: '40px', marginLeft: '30px' }}>
  //             <h3>Admission Statistics</h3>
  //             {admissionTabs}
  //           </div>
  //           <div style={{ marginTop: '30px', marginLeft: '20px' }}>
  //             {totalCharts}
  //           </div>
  //         </div>
  //         <div style={{ marginTop: '20px' }}>
  //           {admissionTables}
  //         </div>
  //       </div>
  //     )
  //   case 'By Round':
  //     return (
  // <div style={{ marginTop: '40px' }}>
  //   <div className='card'>
  //     <div style={{ marginTop: '40px', marginLeft: '30px' }}>
  //       <h3>Admission Statistics</h3>
  //       {admissionTabs}
  //     </div>
  //     <div style={{ marginTop: '30px', marginLeft: '20px' }}>
  //       {byRoundCharts}
  //     </div>

  //     <span className='mt-5'>{roundNotes}</span>
  //   </div>
  //   <div style={{ marginTop: '20px' }}>
  //     {admissionTables}
  //   </div>
  // </div>
  //     )
  //   case 'By Gender':
  //     return (
  //       <div style={{ marginTop: '40px' }}>
  //         <div className='card'>
  //           <div style={{ marginTop: '40px', marginLeft: '30px' }}>
  //             <h3>Admission Statistics</h3>
  //             {admissionTabs}
  //           </div>
  //           <div style={{ marginTop: '30px', marginLeft: '20px' }}>
  //             {byGenderCharts}
  //           </div>

  //           <span className='mt-5'>{roundNotes}</span>
  //         </div>
  //         <div style={{ marginTop: '20px' }}>
  //           {admissionTables}
  //         </div>
  //       </div>
  //     )
  //   default:
  //     return (
  //       <div style={{ marginTop: '40px' }}>
  //         <div className='card'>
  //           <div style={{ marginTop: '40px', marginLeft: '30px' }}>
  //             <h3>Admission Statistics</h3>
  //             {admissionTabs}
  //           </div>
  //           <div style={{ marginTop: '20px', marginLeft: '20px' }}>
  //             {totalCharts}
  //           </div>
  //         </div>
  //         <div style={{ marginTop: '20px' }}>
  //           {admissionTables}
  //         </div>
  //       </div>
  //     )
  // }

  const returnChart = (selectedTab) => {
    switch (selectedTab) {
      case 'Total':
        return totalCharts
      case 'By Round':
        return (
          <div className='d-flex flex-column'>
            {byRoundCharts}
            <span className='mt-5'>{roundNotes}</span>
          </div>)
      case 'By Gender':
        return byGenderCharts
      default:
        return totalCharts
    }
  }

  return (
    <div style={{ marginTop: '40px' }}>
      <div className='card'>
        <div style={{ margin: '40px 30px 0px 30px' }}>
          <h3 className='dca-font-R'>Admission Statistics</h3>
          {admissionTabs}
        </div>
        <div style={{ margin: '30px 20px 0px 20px' }}>
          {returnChart(selectedTab)}
        </div>
      </div>
      <div style={{ marginTop: '20px' }}>
        {admissionTables}
      </div>
    </div>
  )
}

export { AdmissionInfo }
