import { closeModalFunction, URL, closeModalIconTestScoreGuidance } from "../dictionary"
import axios from "axios"
import { getAuth, useAuth } from "../../modules/auth"
import { TestScoreStrategyReactGAHandlers } from "../ReactGAHandlers"
import { handleUpdateUserProfile } from "../../pages/UserProfile"

let apiToken: any = undefined

if (getAuth()?.api_token && getAuth()?.groups) {
  apiToken = getAuth()?.api_token
}

const EditScoreProfileModal = ({ formValues, setFormValues, setUserSATVerbal, setUserSATMath, setUserACTBestScore, setUserActEnglish, setUserActMath, setUserActWriting, setUserActScience, setUserActReading, setUserprofile, setUserSATSuperScore, setUser }) => {

  const {
    handleGAEventEditScoreProfile,
    handleGAEventOverlayButton
  } = TestScoreStrategyReactGAHandlers()

  const { currentUser } = useAuth()

  const handleOnChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value })
    console.log(`${[event.target.name]}: ${event.target.value}`)
  }

  const handleOnChangeNull = (targetName) => {
    setFormValues({ ...formValues, [targetName]: "" })
    console.log(targetName, ":", formValues.targetName)
  }

  const handleSubmit = (event, userId, formValues) => {
    event.preventDefault();

    let cleanedFormValues = formValues

    for (const key in cleanedFormValues) {
      if (cleanedFormValues.hasOwnProperty(key) && cleanedFormValues[key] === '') {
        cleanedFormValues[key] = null;
      }
    }

    console.log('submitting these values:', cleanedFormValues)

    const updateUserProfileTestScores = async () => {
      const res = await axios.patch(`${URL}/user_profiles/${userId}`, cleanedFormValues)

      console.log('Patch request successful:', res.data)
    }

    const updateStates = async () => {
      let res = await axios.get(`${URL}/user_profiles/${userId}`)
      setUserprofile(res.data)
      console.log('Retrieved and updated user profile:', res.data)

      setUserSATVerbal(res.data.satVerbal)
      setUserSATMath(res.data.satMath)
      setUserSATSuperScore(res.data.satVerbal + res.data.satMath)
      setUserActEnglish(res.data.actEnglish)
      setUserActMath(res.data.actMath)
      setUserActWriting(res.data.actWriting)
      setUserActScience(res.data.actScience)
      setUserActReading(res.data.actReading)
      setUserACTBestScore(res.data.actComposite)
    }

    try {

      updateUserProfileTestScores().then(updateStates).then(() => handleUpdateUserProfile(setUser, apiToken))

      closeModalFunction('editScoreProfile')
    } catch (error) {
      console.log('Error updating user profile test scores:', error);
      throw error
    } finally {
      handleGAEventOverlayButton("Submit")
    }
  }

  const returnInputBlock = ({ title, name, min, max, value, act2 = false }) => {
    return <div>
      <label className={`form-label dca-testscore-editscore-field-title${act2 ? 2 : ''}`}>{title}</label>
      <div className="d-flex flex-row">
        <input
          type="number"
          name={name}
          min={min}
          max={max}
          className="form-control dca-testscore-editscore-input"
          // placeholder={userSATMath}
          value={value}
          onChange={(event) => handleOnChange(event)}
        /><i
          className="bi bi-x-circle align-self-center"
          style={{ cursor: 'pointer', marginLeft: '5px' }}
          onClick={() => handleOnChangeNull(name)}></i></div>
    </div>
  }

  const satScores = (
    <div>
      <p className='dca-testscore-editscore-sat-section-title'>SAT Scores</p>
      <div className="dca-editscoreprofile-modal-score-wrapper">
        {returnInputBlock({ title: 'Best SAT Reading Score', name: 'satVerbal', min: 200, max: 800, value: formValues.satVerbal })}

        {returnInputBlock({ title: 'Best SAT Math Score', name: 'satMath', min: 200, max: 800, value: formValues.satMath })}
      </div>
      {/* <div>
        <label className="form-label fw-bold" style={{ fontSize: '18px', marginTop: '15px' }}>SAT Total SuperScore</label>
        <input
          type="number"
          disabled
          min={400}
          max={1600}
          className="form-control"
          placeholder={userSATSuperScore}
          // value={satCompositeInput}
          // onChange={(e) => setSatCompositeInput(e.target.value)}
          style={{ background: gray, border: 'transparent' }}
        />
      </div> */}
    </div>
  )

  const actScores = (
    <div>
      <p className='dca-testscore-editscore-act-section-title'>ACT Scores</p>

      <div className="dca-editscoreprofile-modal-score-wrapper">
        {returnInputBlock({ title: 'Best ACT Composite Score', name: 'actComposite', min: 1, max: 36, value: formValues.actComposite })}

        {returnInputBlock({ title: 'Best ACT Reading Score', name: 'actReading', min: 1, max: 36, value: formValues.actReading })}

      </div>

      <div className="dca-editscoreprofile-modal-score-wrapper">

        {returnInputBlock({ title: 'Best ACT Math Score', name: 'actMath', min: 1, max: 36, value: formValues.actMath, act2: true })}

        {returnInputBlock({ title: 'Best ACT English Score', name: 'actEnglish', min: 1, max: 36, value: formValues.actEnglish, act2: true })}

      </div>

      <div className="dca-editscoreprofile-modal-score-wrapper">

        {returnInputBlock({ title: 'Best ACT Science Score', name: 'actScience', min: 1, max: 36, value: formValues.actScience, act2: true })}

        {returnInputBlock({ title: 'Best ACT Writing Score', name: 'actWriting', min: 2, max: 12, value: formValues.actWriting, act2: true })}
      </div>

    </div>
  )

  return (<div className="modal fade" id="editScoreProfile">
    <div className="modal-dialog modal-dialog modal-dialog-centered">
      {/* <div className="modal-dialog modal-dialog"> */}
      <div className="modal-content dca-editscore-profile-modal">

        <div className="modal-body fw-bold d-flex flex-column">
          <p className="dca-testscore-editscore-title">Your Score Profile</p>

          {currentUser ? <form onSubmit={(event) => handleSubmit(event, currentUser?.user_id, formValues)}
          >
            {closeModalIconTestScoreGuidance(handleGAEventOverlayButton)}

            <div className='d-flex flex-column justify-content-center' style={{ width: '100%' }}>
              {satScores}
              {actScores}
            </div>


            <div className="d-flex flex-row justify-content-center" style={{ marginTop: '45px' }}>
              <button
                type="button"
                className="btn btn-light dca-testscore-editscore-discard-bttn"
                data-bs-dismiss="modal"
                id="closeModalIcon"
                onClick={() => handleGAEventOverlayButton("Discard")}
                style={{ marginRight: '12px' }}
              >
                Discard
              </button>
              <button
                type="submit"
                className="rounded dca-testscore-editscore-submit-bttn"
                style={{ marginLeft: '12px' }}
              >
                Submit
              </button>
            </div>
          </form> : <div>Loading User Data ...</div>}
        </div>

      </div>
    </div>
  </div>)
}

export { EditScoreProfileModal }