import axios from 'axios'
import { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { gray, pageRoutes, selectedTabGreen, titleSpace, URL, returnHyphenatedString, updateTitleAndDescription } from '../components/dictionary'
import ECLogo from '../../custom_assets/ec_logo.png'
import { Tabs } from '../components/ECPremium/Tabs'
import { getAuth } from '../modules/auth'
import { Error404 } from '../modules/errors/components/Error404'
import { TooltipComponent, TooltipComponentDown, TooltipComponentMobile, TooltipComponentMobileInline } from '../components/Tooltip'
import { extracurricularMap } from '../components/extracurricularMap'
import { useLocation } from 'react-router-dom'
import { LoadingIndicator } from '../components/LoadingIndicator'

const ECPremiumInsights = ({ user, cip, setPrompt, setNavigateTo }) => {

  let apiToken: any = undefined
  let permissionGroups: any = undefined
  let navigate = useNavigate()
  let location = useLocation()
  let ecHyphenated = location.pathname.split('extracurricular-insights-')[1]

  const linkCheck = (ecHyphenated) => {
    return ecHyphenated && ecHyphenated != '' ? true : false
  }

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
    permissionGroups = getAuth()?.groups
  }

  useEffect(() => {
    if (getAuth()?.api_token && getAuth()?.groups) {
      apiToken = getAuth()?.api_token
      permissionGroups = getAuth()?.groups
    }

  }, [user])

  // const { ecId } = useParams()

  useEffect(() => {
    let matchingEC = extracurricularMap.find(ecMapObj => ecMapObj.hyphenated == ecHyphenated)

    matchingEC ? setECId(parseInt(matchingEC.id)) : setECId(undefined)
  }, [])

  const [ecId, setECId] = useState<any>(undefined)
  const [ec, setEC] = useState<any>([])
  const [result, setResult] = useState<any>([])
  const [advisorECReports, setAdvisorECReports] = useState<any>([])

  useEffect(() => {

    const getEC = async () => {
      let res = await axios.get(`${URL}/ec_activity/${ecId}`)
      setEC(res.data)
    };

    const getECInsights = async () => {
      let res = await axios.get(`${URL}/ec_insights/${ecId}`)
      setResult(res.data)
    }

    // Returns available ec reports matching the ecactivity id and report_status is "1"
    const getAdvisorECReports = async () => {
      let res = await axios.get(`${URL}/advisor_ec_reports/${ecId}`)
      // returns empty array if none found
      // console.log('advisor EC Reports ', res.data)
      setAdvisorECReports(res.data)
    }

    if (ecId) {
      getEC();
      getECInsights();
      getAdvisorECReports();
    }

  }, [ecId])

  useEffect(() => {
    if (ecHyphenated && ec?.name) {
      updateTitleAndDescription(pageRoutes.ecPremiumInsightsWithId(ecHyphenated), ec?.name, ecHyphenated)
    }
  }, [ec, ecHyphenated])

  if (apiToken === undefined) {
    navigate(pageRoutes.home)
  }

  const returnAlumniSampleSizeTooltip = (ecName, resultInsights) => {

    let sampleSize: any = '-'
    let sampleSizeLast5: any = '-'
    let res: any = '-'

    if (result.insights && result.insights['Core Data'] && result.insights['Core Data'][0] && result.insights['Core Data'][0].sampleSize) {
      sampleSize = result.insights['Core Data'][0].sampleSize
    }

    if (result.insights && result.insights['Core Data'] && result.insights['Core Data'][0] && result.insights['Core Data'][0].sampleSizeLast5) {
      sampleSizeLast5 = result.insights['Core Data'][0].sampleSizeLast5
    }

    if (sampleSize != '-' && sampleSizeLast5 != '-') {
      res = (sampleSizeLast5 / sampleSize)
      res = res * 100
      res = res.toFixed(0)
    }

    return `The data shown below for ${ecName} is based on analysis of ${sampleSize} students who previously participated in this activity. ${res}% of the students in this alumni sample participated in the activity in the last 5 years.`

  }

  const checkForNotes = (result) => {
    let notesText1 = ''
    let notesText2 = ''
    let notesText3 = ''


    if (result.insights && result.insights['Core Data'] && result.insights['Core Data'][0]) {
      if (result.insights['Core Data'][0].notesText1 != '') {
        notesText1 = result.insights['Core Data'][0].notesText1
      }

      if (result.insights['Core Data'][0].notesText2 != '') {
        notesText2 = result.insights['Core Data'][0].notesText2
      }

      if (result.insights['Core Data'][0].notesText3 != '') {
        notesText3 = result.insights['Core Data'][0].notesText3
      }
    }

    if (notesText1 != '' || notesText2 != '' || notesText3 != '') {

      return (
        <ul className='mt-5'>
          {notesText1 != '' && <li>{notesText1}</li>}
          {notesText2 != '' && <li>{notesText2}</li>}
          {/* {notesText3 != '' && <li>{notesText3}</li>} */}
        </ul>
      )
    }

    else {
      return <></>
    }
  }

  const desktopHeader = (<div
    className='d-flex flex-row justify-content-between'
    style={{ marginTop: '20px', width: '100%' }}
  >

    <Link
      className="dca-font-M"
      to={pageRoutes.ecPremiumInsightsBase}
      style={{ color: 'black' }}>
      <i className="me-3 fa-solid fa-arrow-left" style={{ color: 'black' }} ></i>
      Back To Purchased Reports</Link>

    <span
      className="badge rounded-pill"
      style={{ backgroundColor: selectedTabGreen, marginRight: '30px', padding: '8px' }}
    >Premium Data</span>

  </div>)

  const mobileHeader = (<div
    className='d-flex flex-row justify-content-between'
    style={{ marginTop: '20px', width: '100%' }}
  >

    <Link
      className="dca-font-M"
      // to={linkCheck(ecHyphenated) ? pageRoutes.ecDetailWithId(ecHyphenated) : pageRoutes.home}
      to={pageRoutes.ecPremiumInsightsBase}
      style={{ color: 'black' }}>
      <i className="me-3 fa-solid fa-arrow-left" style={{ color: 'black' }} ></i>
      Back To Purchased Reports</Link>

    <span
      className="badge rounded-pill"
      style={{ backgroundColor: selectedTabGreen, marginRight: '30px', padding: '8px' }}
    >Premium Data</span>

  </div>)

  const desktopHeaderB = (<div style={{ width: '100%' }}><div className='mt-5 d-flex flex-row align-items-center justify-content-start'>
    {/* <img className="m-3" style={{ height: '75.47px', maxHeight: '75.47px' }} alt="ec_logo" src={ECLogo} /> */}

    <div className='dca-search-title'>{ec.name}</div>
  </div>

    <div className='d-flex flex-row justify-content-start' style={{ marginTop: '15px' }}>
      <div className='d-flex flex-column'>
        <div className='fw-bold card shadow d-flex flex-row' style={{ width: 'fit-content', maxWidth: 'fit-content', height: '146px', maxHeight: '146px', background: 'rgba(228,228,228,1)' }}>
          <div style={{ padding: '40px' }}>
            <div style={{ letterSpacing: titleSpace, whiteSpace: 'nowrap' }}>ALUMNI SAMPLE SIZE
              <span className='ms-2'><TooltipComponent content={returnAlumniSampleSizeTooltip(ec.name, result.insights)} /></span>
            </div>
            <div className='pt-3'>{(result.insights && result.insights['Core Data']) ? result.insights['Core Data'][0].sampleSize : '-'}</div>
          </div>

          <div style={{ padding: '40px' }}>
            <div style={{ letterSpacing: titleSpace, whiteSpace: 'nowrap' }}>IMPACT SCORE
              <span className='ms-2'><TooltipComponentDown content={`This figure is a summary score for the estimated impact of ${ec.name} from a college admissions perspective. The Impact Score is based on an aggregate analysis of the college matriculation outcomes for this activity's alumni sampling. Conceptually, higher percentages of alumni matriculating to higher-ranked colleges (as shown in the Admission Correlation tab below) leads to a higher score. The Impact Score is based on a 1-100 scale, with 100 being the best.`} /></span>
            </div>
            <div className='pt-3'>{(result.insights && result.insights['Core Data']) ? result.insights['Core Data'][0].impactScore : '-'}</div>
          </div>

        </div>
        {checkForNotes(result)}
      </div>

      <div className='d-flex justify-content-start fw-bold' style={{ fontSize: '14px', padding: '40px', paddingTop: '0px' }}>{ec.description}</div>
    </div></div>)

  const mobileHeaderB = (<div className='d-flex flex-column' style={{ width: '100%' }}><div className='mt-5 d-flex flex-row align-items-center justify-content-start'>
    {/* <img className="m-3" style={{ height: '75.47px', maxHeight: '75.47px' }} alt="ec_logo" src={ECLogo} /> */}

    <div className='dca-search-title'>{ec.name}</div>
  </div>

    <div className='d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className='fw-bold card shadow d-flex flex-row justify-content-between' style={{ background: 'rgba(228,228,228,1)', width: '100%', maxWidth: '100%', padding: '40px' }}>

        <div>

          <div className="d-flex flex-row align-items-center" style={{ letterSpacing: titleSpace }}>
            <TooltipComponentMobileInline
              inlineText={'ALUMNI SAMPLE SIZE'}
              content={returnAlumniSampleSizeTooltip(ec.name, result.insights)}
            />
          </div>


          {/* <div className='d-flex flex-row' style={{ letterSpacing: titleSpace }}><div>ALUMNI SAMPLE SIZE
            <span className='ms-2'><TooltipComponentMobile content={returnAlumniSampleSizeTooltip(ec.name, result.insights)} /></span></div>
          </div> */}

          <div className='pt-3'>{(result.insights && result.insights['Core Data']) ? result.insights['Core Data'][0].sampleSize : '-'}</div>
        </div>

        <div style={{ marginLeft: '15px' }}>
          {/* <div className='d-flex flex-row' style={{ letterSpacing: titleSpace }}>IMPACT SCORE
            <span className='ms-2'><TooltipComponentMobile content={`This figure is a summary score for the estimated impact of ${ec.name} from a college admissions perspective. The Impact Score is based on an aggregate analysis of the college matriculation outcomes for this activity's alumni sampling. Conceptually, higher percentages of alumni matriculating to higher-ranked colleges (as shown in the Admission Correlation tab below) leads to a higher score. The Impact Score is based on a 1-100 scale, with 100 being the best.`} /></span>
          </div> */}

          <div className="d-flex flex-row align-items-center" style={{ letterSpacing: titleSpace }}>
            <TooltipComponentMobileInline
              inlineText={'IMPACT SCORE'}
              content={`This figure is a summary score for the estimated impact of ${ec.name} from a college admissions perspective. The Impact Score is based on an aggregate analysis of the college matriculation outcomes for this activity's alumni sampling. Conceptually, higher percentages of alumni matriculating to higher-ranked colleges (as shown in the Admission Correlation tab below) leads to a higher score. The Impact Score is based on a 1-100 scale, with 100 being the best.`}
              placeBottom={true}
            />
          </div>
          <div className='pt-3'>{(result.insights && result.insights['Core Data']) ? result.insights['Core Data'][0].impactScore : '-'}</div>
        </div>

      </div>
      {checkForNotes(result)}

      <div className='d-flex justify-content-start fw-bold' style={{ fontSize: '14px', padding: '20px 2px 0px 2px' }}>{ec.description}</div>
    </div></div>)

  return (
    <div style={{ margin: '0px 35px' }}>

      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex' style={{ width: '100%' }}>{desktopHeader}</div>
      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-flex d-md-none' style={{ width: '100%', marginRight: '35px' }}>{mobileHeader}</div>

      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex' style={{ width: '100%' }}>{desktopHeaderB}</div>
      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-flex d-md-none' style={{ width: '100%', marginRight: '35px' }}>{mobileHeaderB}</div>

      {/* {checkForNotes(result)} */}

      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex' style={{ marginTop: '50px', width: '100%' }}><Tabs
        result={result}
        cip={cip}
        advisorECReports={advisorECReports}
      /></div>
      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-flex d-md-none' style={{ width: '100%', maxWidth: '100%', marginRight: '35px' }}><Tabs
        result={result}
        cip={cip}
        advisorECReports={advisorECReports}
        mobile={true}
      /></div>
    </div>
  )
}

export { ECPremiumInsights }
