import { useState } from "react"
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { handleUpdateUserData, handleUpdateUserProfile } from "../../../pages/UserProfile"

const TestScoresForm = ({ user, setUser, apiToken, userData, setUserData }) => {

  const [loading, setLoading] = useState(false)

  const testScoreTitleArray = [
    ['Best SAT Reading & Writing Score', 'satVerbal'],
    ['Best SAT Math Score', 'satMath'],
    // ['Best SAT Total Score', 'satComposite'],
    ['Best ACT Composite Score', 'actComposite'],
    ['Best ACT Reading Score', 'actReading'],
    ['Best ACT Math Score', 'actMath'],
    ['Best ACT English Score', 'actEnglish'],
    ['Best ACT Science Score', 'actScience'],
    ['Best ACT Writing Score', 'actWriting']
  ]

  const formSchema = Yup.object().shape({
    satVerbal: Yup.number()
      .nullable()
      .min(200, 'Score cannot be lower than 200')
      .max(800, 'Score cannot exceed 800'),
    satMath: Yup.number()
      .nullable()
      .min(200, 'Score cannot be lower than 200')
      .max(800, 'Score cannot exceed 800'),
    // satComposite: Yup.number()
    //   .nullable()
    //   .min(400, 'Score cannot be lower than 400')
    //   .max(1600, 'Score cannot exceed 1600'),
    actComposite: Yup.number()
      .nullable()
      .min(1, 'Score cannot be lower than 1')
      .max(36, 'Score cannot exceed 36'),
    actReading: Yup.number()
      .nullable()
      .min(1, 'Score cannot be lower than 1')
      .max(36, 'Score cannot exceed 36'),
    actMath: Yup.number()
      .nullable()
      .min(1, 'Score cannot be lower than 1')
      .max(36, 'Score cannot exceed 36'),
    actEnglish: Yup.number()
      .nullable()
      .min(1, 'Score cannot be lower than 1')
      .max(36, 'Score cannot exceed 36'),
    actScience: Yup.number()
      .nullable()
      .min(1, 'Score cannot be lower than 1')
      .max(36, 'Score cannot exceed 36'),
    actWriting: Yup.number()
      .nullable()
      .min(2, 'Score cannot be lower than 2')
      .max(12, 'Score cannot exceed 12'),
  })

  let initialValues = {
    satVerbal: userData.satVerbal, satMath: userData.satMath, actComposite: userData.actComposite, actReading: userData.actReading, actMath: userData.actMath, actEnglish: userData.actEnglish, actScience: userData.actScience, actWriting: userData.actWriting
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)

      const cleanedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [key, value === "" ? null : value])
      );

      if (cleanedValues.satVerbal == null && cleanedValues.satMath == null) {
        cleanedValues.satComposite = null
      } else {
        cleanedValues.satComposite = cleanedValues.satVerbal + cleanedValues.satMath
      }

      try {
        await handleUpdateUserData(user, cleanedValues, setUserData, apiToken);
        await handleUpdateUserProfile(setUser, apiToken)
      } catch (error) {
        console.error(error)
        alert('Error encountered')
        setStatus('The test score details are incorrect')
        setSubmitting(false)
      } finally {
        setLoading(false)
      }
    }
  })

  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        id="testscoresForm">

        <div className='dca-userProfile-form-field-wrapper'>
          <div className='dca-userProfile-form-field-column'>
            <div className='dca-userProfile-form-field'>{testScoreTitleArray[0][0]}</div>
            <div className='dca-userProfile-form-field'>{testScoreTitleArray[1][0]}</div>
            <div className='dca-userProfile-form-field'>{testScoreTitleArray[2][0]}</div>
            <div className='dca-userProfile-form-field'>{testScoreTitleArray[3][0]}</div>
            <div className='dca-userProfile-form-field'>{testScoreTitleArray[4][0]}</div>
            <div className='dca-userProfile-form-field'>{testScoreTitleArray[5][0]}</div>
            <div className='dca-userProfile-form-field'>{testScoreTitleArray[6][0]}</div>
            <div className='dca-userProfile-form-field'>{testScoreTitleArray[7][0]}</div>
          </div>

          <div className='d-flex flex-column' style={{ width: '100%' }}>
            {testScoreTitleArray.map((arr, index) =>
              <div key={index} className="dca-userProfile-form-field">
                <input
                  placeholder={arr[0]}
                  {...formik.getFieldProps(arr[1])}
                  className={clsx(
                    'form-control form-control-solid',
                    { 'is-invalid': formik.touched[arr[1]] && formik.errors[arr[1]] },
                    {
                      'is-valid': formik.touched[arr[1]] && !formik.errors[arr[1]],
                    }
                  )}
                  type='number'
                  name={arr[1]}
                  autoComplete='off'
                />
                {formik.touched[arr[1]] && formik.errors[arr[1]] && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors[arr[1]]}</span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className='separator'></div>

        <div className='d-flex flex-row justify-content-end align-items-end'>
          <button
            className='dca-userProfile-discard-changes-button text-muted'
            onClick={(e) => {
              e.preventDefault();
              formik.setValues({
                satVerbal: userData.satVerbal, satMath: userData.satMath, actComposite: userData.actComposite, actReading: userData.actReading, actMath: userData.actMath, actEnglish: userData.actEnglish, actScience: userData.actScience, actWriting: userData.actWriting
              })
            }}>Discard</button>

          <button
            type='submit'
            id='testscoresForm_submit'
            className='dca-userProfile-save-changes-button'
            disabled={formik.isSubmitting || !formik.isValid || !formik.values}
          >
            {!loading && <span className='indicator-label'>Save Changes</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )

}

export { TestScoresForm }
