import { ContentBlock } from "../ContentBlock"
import { pageRoutes } from "../../dictionary"
import CollegeApplicationsIcon from '../../../../custom_assets/dashboard/checked_flag.png'
import { useState, useEffect } from "react"

const CollegeApplications = ({ user, wizardActive, setWizardActive, status, expand, statusLabelMaxWidth }) => {

  // const body = `After finalizing your college list, and deciding if/where you want to apply for early admission, you will need to prepare your college applications. This is typically done in the fall of senior year, though we recommend getting a head-start in the summer after junior year. Key aspects of this phase are a) requesting letters of recommendation; b) sharing your personal information/background via applications, such as the Common App; and c) writing essays/personal statements.`

  let blockClass = 'CollegeApplications'
  let gradYr = user?.hsGradYr ?? undefined
  // let title = 'College Applications'

  const [title, setTitle] = useState(window.innerWidth <= 500 ? 'College Apps' : 'College Applications');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setTitle('College Apps');
      } else {
        setTitle('College Applications');
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const body = <div className='d-flex flex-column'>
    <span>After finalizing your college list, and deciding if/where you want to apply for early admission, you will need to prepare your college applications. This is typically done in the fall of senior year, though we recommend getting a head-start in the summer after junior year. Key aspects of this phase are:</span>
    <ol
      type="a"
      style={{
        listStyleType: 'none',
        paddingLeft: '0.75em'
      }}>
      <li>
        <span>{`a)`}</span> requesting letters of recommendation
      </li>
      <li>
        <span>{`b)`}</span> sharing your personal information/background via applications
      </li>
      <li>
        <span>{`c)`}</span> writing essays/personal statements
      </li>
    </ol>

  </div>

  const resources = [
    {
      navigate: pageRoutes.advisorNetwork,
      title: 'Advisors Network',
      description: `Strengthen your application by learning from students who got in to your dream schools`
    },
    {
      navigate: pageRoutes.personalizedExpertHelp,
      title: 'Expert Q&A',
      description: 'Get personalized help for your overall application strategy'
    }
  ]

  const insights = [
    {
      navigate: pageRoutes.overviewAdmissionsAdviceWithHash('maximizing-impact-from-letters-of-recommendation'),
      text: 'Maximizing Impact From Letters of Recommendation'
    },
    {
      navigate: pageRoutes.overviewAdmissionsAdviceWithHash('applying-early-vs-regular'),
      text: 'Trade-offs For Applying Early vs. Regular'
    },
    {
      navigate: pageRoutes.overviewCollegeAppWalkthroughWithHash('personal-statement-and-supplemental-essays'),
      text: 'Representative Essay Topics and Themes'
    }
  ]

  const block = {
    class: blockClass,
    title: title,
    body: body,
    icon: CollegeApplicationsIcon,
    specialIconClass: 'dca-dashboard-collegeAppIcon',
    resources: resources,
    insights: insights,
    status: 'inactive',
    gradYr: gradYr,
    expand: expand
  }

  return <ContentBlock block={block} wizardActive={wizardActive} setWizardActive={setWizardActive} statusLabelMaxWidth={statusLabelMaxWidth} />
}

export { CollegeApplications }