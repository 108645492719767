import { useEffect, useState } from "react"
import { bttnGray, gray, selectedTabGreen } from "./dictionary"
import axios from "axios"
import { URL, advisorConnectAccessCode } from "./dictionary"
import { getAuth } from "../modules/auth"
import { setNoAuthSaveButton, setNoAuthAdvisorsConnectButton, setPurchaseModal } from "./Auth/setPrompts"
import { AuthReactGAHandlers, CollegeReactGAHandlers, MajorReactGAHandlers, ECReactGAHandlers, AdvisorsReactGAHandlers } from "./ReactGAHandlers"

import heart_no_save from '../../custom_assets/heart_not_saved.png'
import heart_saved from '../../custom_assets/heart_saved.png'

let apiToken: any = undefined
let permissionGroups: any = undefined

if (getAuth()?.api_token && getAuth()?.groups) {
  apiToken = getAuth()?.api_token
  permissionGroups = getAuth()?.groups
}

export const SaveButtonInCard = () => {
  //pass onclick function as prop when ready to include user saving functionality

  const [clicked, setClicked] = useState(false)

  const handleClick = () => {
    setClicked(!clicked);
    // await saveFunction();
  }

  return (
    <button
      type="button"
      className="btn btn-sm"
      style={{ background: clicked ? selectedTabGreen : bttnGray, position: 'absolute', top: '15px', right: '15px' }}
      onClick={(e) => {
        e.stopPropagation();
        handleClick();
      }}
    >
      <i
        className={`fa-heart${clicked ? " fa-solid" : " fa-regular"}`}
        style={clicked ? { color: 'rgba(123, 212, 125, 1)' } : {}}
      ></i>
      Save
    </button>
  )
}

export const SaveButtonInline = () => {
  //pass onclick function as prop when ready to include user saving functionality

  const [clicked, setClicked] = useState(false)

  return (
    <button
      type="button"
      className="btn btn-sm dca-save-button-inline"
      style={{ background: clicked ? selectedTabGreen : bttnGray }}
      onClick={(e) => {
        e.stopPropagation();
        setClicked(!clicked)
      }}
    >
      <i
        className={`fa-heart${clicked ? " fa-solid" : " fa-regular"}`}
        style={clicked ? { color: 'rgba(123, 212, 125, 1)' } : {}}
      ></i>
      Save
    </button>
  )
}

// // Creating Connect button within file to use setter functions
// export const ConnectButtonInCard = () => {
//   //pass onclick function as prop when ready to include user saving functionality

//   return (
//     <button
//       type="button"
//       className="btn btn-sm dca-connect-button-card"
//       data-bs-toggle="modal"
//       data-bs-target="#purchase_modal"
//     // onClick={(e) => {
//     //   e.stopPropagation();
//     //   // setPurchaseModal(setPurchaseID, advisorConnectAccessCode);
//     //   setPurchaseID(advisorConnectAccessCode)
//     //   setAdvisorID(advisorID)
//     //   handleGAEventPurchaseModalOpen(advisorID)
//     // }}
//     >
//       Connect
//     </button>
//   )
// }

// export const ConnectButtonInCardNoAuth = ({ setPrompt, setNavigateTo, biLock = true, advisorID, mobile = false }) => {

//   const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()
//   const { handleAdvisorGAEventCardConnectClick } = AdvisorsReactGAHandlers()

//   return mobile ? (<button
//     type="button"
//     className="btn btn-sm dca-connectbuttonincardnoauth-mobile"
//     data-bs-toggle="modal"
//     data-bs-target="#login_modal"
//     onClick={(e) => {
//       e.stopPropagation();
//       setNoAuthAdvisorsConnectButton(setPrompt, setNavigateTo);
//       handleGAEventSignInModalOpen('forced');
//       handleAdvisorGAEventCardConnectClick(advisorID)
//     }}
//   >
//     Connect
//   </button>) : (
//     <div className={mobile ? "d-flex justify-content-center" : ""}>
//       <a
//         type="button"
//         className={mobile ? "btn btn-sm dca-connectbuttonincardnoauth-mobile" : "btn btn-sm dca-connect-button-card fw-bold"}
//         data-bs-toggle="modal"
//         href="#login_modal"
//         onClick={(e) => {
//           e.stopPropagation();
//           setNoAuthAdvisorsConnectButton(setPrompt, setNavigateTo);
//           handleGAEventSignInModalOpen('forced');
//           handleAdvisorGAEventCardConnectClick(advisorID)
//         }}
//       >{biLock && <i className="bi bi-lock align-self-center" style={{ color: 'black' }}></i>}Connect
//       </a>
//     </div>
//   )
// }

// export const ConnectButtonNoAuth = ({ setPrompt, setNavigateTo, biLock = true, advisorID }) => {

//   const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()
//   const { handleAdvisorGAEventDetailConnectClick } = AdvisorsReactGAHandlers()

//   return (
//     <div>
//       <a
//         type="button"
//         className="btn btn-sm dca-connect-button-card-detail fw-bold"
//         data-bs-toggle="modal"
//         href="#login_modal"
//         onClick={(e) => {
//           e.stopPropagation();
//           setNoAuthAdvisorsConnectButton(setPrompt, setNavigateTo);
//           handleGAEventSignInModalOpen('forced');
//           handleAdvisorGAEventDetailConnectClick(advisorID)
//         }}
//       >{biLock && <i className="bi bi-lock align-self-center" style={{ color: 'black' }}></i>}Connect
//       </a>
//     </div>
//   )

// }

// College
export const SaveButtonCollegeCard = ({ collegeId, list, user, setUserCollegeInterest, testScoreStrategy = false, mobile = false }) => {

  const {
    handleGAEventSearchSave,
    handleTestScoreStrategyGAEventSave
  } = CollegeReactGAHandlers()
  const [check, setCheck] = useState(false)

  const checkCollegeList = () => {
    if (list && list.find((item) => item.college_id === collegeId && item.user_profile_id === user.user_id)) {
      setCheck(true)
    }
    else {
      setCheck(false)
    }
  }

  const handleSaveCollege = async () => {
    try {
      const post = await axios.post(`${URL}/user_college_interest/create/`, {
        user_profile_id: user.user_id,
        college_id: collegeId,
        fit: 1
      })

      console.log('Post successful,', post.data)

      const res = await axios.get(`${URL}/user_college_interest`)

      res.data.filter((item) => item.user_profile_id === user.user_id)
      setUserCollegeInterest(res.data.filter((item) => item.user_profile_id === user.user_id))

      console.log('User college list retrieved,', res.data)

    } catch (error) {
      console.log(error)
      throw (error)
    } finally {
      testScoreStrategy == true ? handleTestScoreStrategyGAEventSave(collegeId) : handleGAEventSearchSave(collegeId)
    }

  }

  const handleDeleteCollege = async () => {

    if (list && list.find((item) => item.college_id === collegeId && item.user_profile_id === user.user_id)) {

      let collegeToRemove = list.find((item) => item.college_id === collegeId && item.user_profile_id === user.user_id)

      let pk = collegeToRemove.id

      const del = await axios.delete(`${URL}/user_college_interest/delete/${pk}`)

      console.log('College removed from list,', del.data)

      const res = await axios.get(`${URL}/user_college_interest`)

      res.data.filter((item) => item.user_profile_id === user.user_id)
      setUserCollegeInterest(res.data.filter((item) => item.user_profile_id === user.user_id))

      console.log('User college list retrieved,', res.data)
    }

    else {
      return alert('Could not find pk number, try again later')
    }
  }

  const handleClick = async (check) => {
    check ? await handleDeleteCollege() : await handleSaveCollege()
  }

  useEffect(() => {
    checkCollegeList()
  }, [list])

  return (
    <button
      type="button"
      className={mobile ? `dca-save-button-card-mobile` : `btn btn-sm dca-save-button-card`}
      style={{ background: mobile ? 'transparent' : check ? selectedTabGreen : bttnGray }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleClick(check)
      }}
    >
      {mobile ? (check ? <img className="dca-mobile-save-heart-icon" alt="heart_saved" src={heart_saved}></img> : <img className="dca-mobile-save-heart-icon" alt='heart_no_saved' src={heart_no_save}></img>) : (<i
        className={`fa-heart${check ? " fa-solid" : " fa-regular"}`}
        style={check ? { color: 'rgba(123, 212, 125, 1)' } : {}}
      ></i>)}
      {mobile === false && (check ? 'Saved' : 'Save')}
    </button>
  )
}

export const SaveButtonCollegeInline = ({ collegeId, list, user, setUserCollegeInterest, mobile = false }) => {

  const [check, setCheck] = useState(false)
  const { handleGAEventDetailsSave } = CollegeReactGAHandlers()

  const checkCollegeList = () => {
    if (list && list.find((item) => item.college_id === collegeId && item.user_profile_id === user.user_id)) {
      setCheck(true)
    }
    else {
      setCheck(false)
    }
  }

  const handleSaveCollege = async () => {
    try {
      const post = await axios.post(`${URL}/user_college_interest/create/`, {
        user_profile_id: user.user_id,
        college_id: collegeId,
        fit: 1
      })

      console.log('Post successful,', post.data)

      const res = await axios.get(`${URL}/user_college_interest`)

      res.data.filter((item) => item.user_profile_id === user.user_id)
      setUserCollegeInterest(res.data.filter((item) => item.user_profile_id === user.user_id))

      console.log('User college list retrieved,', res.data)

    } catch (error) {
      console.log(error)
      throw (error)
    } finally {
      handleGAEventDetailsSave(collegeId)
    }

  }

  const handleDeleteCollege = async () => {

    if (list && list.find((item) => item.college_id === collegeId && item.user_profile_id === user.user_id)) {
      let collegeToRemove = list.find((item) => item.college_id === collegeId && item.user_profile_id === user.user_id)

      let pk = collegeToRemove.id

      const del = await axios.delete(`${URL}/user_college_interest/delete/${pk}`)

      console.log('College removed from list,', del.data)

      const res = await axios.get(`${URL}/user_college_interest`)

      res.data.filter((item) => item.user_profile_id === user.user_id)
      setUserCollegeInterest(res.data.filter((item) => item.user_profile_id === user.user_id))

      console.log('User college list retrieved,', res.data)
    }

    else {
      return alert('Could not find pk number, try again later')
    }
  }

  const handleClick = async (check) => {
    check ? await handleDeleteCollege() : await handleSaveCollege()
  }

  useEffect(() => {
    checkCollegeList()
  }, [list])

  return mobile ? (
    <div><button type="button"
      style={{ border: 'transparent', background: 'transparent' }}
      onClick={(e) => {
        e.stopPropagation();
        handleClick(check)
      }}><img className="dca-mobile-save-heart-icon" alt='heart_no_saved' src={heart_no_save}></img></button></div>
  ) : (
    <button
      type="button"
      className="btn btn-sm dca-save-button-inline"
      style={{ background: check ? selectedTabGreen : bttnGray }}
      onClick={(e) => {
        e.stopPropagation();
        handleClick(check)
      }}
    >
      <i
        className={`fa-heart${check ? " fa-solid" : " fa-regular"}`}
        style={check ? { color: 'rgba(123, 212, 125, 1)' } : {}}
      ></i>
      {check ? 'Saved' : 'Save'}
    </button>
  )
}

export const SaveButtonCardNoAuth = ({ setPrompt, setNavigateTo, mobile = false }) => {

  const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()

  return mobile ? (
    <div><button type="button"
      className="dca-save-button-card-mobile"
      data-bs-toggle="modal"
      data-bs-target="#login_modal"
      onClick={(e) => {
        e.stopPropagation();
        setNoAuthSaveButton(setPrompt, setNavigateTo);
        handleGAEventSignInModalOpen('forced')
      }}><img className="dca-mobile-save-heart-icon" alt='heart_no_saved' src={heart_no_save}></img></button></div>
  ) : (
    <div>
      <a
        type="button"
        className="btn btn-sm dca-save-button-card"
        style={{ background: bttnGray }}
        data-bs-toggle="modal"
        href="#login_modal"
        onClick={(e) => {
          e.stopPropagation();
          setNoAuthSaveButton(setPrompt, setNavigateTo);
          handleGAEventSignInModalOpen('forced')
        }}
      >
        <i
          className="fa-heart fa-regular"
        ></i>Save
      </a>
    </div>
  )
}

export const SaveButtonInlineNoAuth = ({ setPrompt, setNavigateTo, mobile = false }) => {

  const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()

  // return (
  //   <div>
  //     <a
  //       type="button"
  //       className="btn btn-sm fw-bold dca-save-button-inline"
  //       style={{ background: gray }}
  //       data-bs-toggle="modal"
  //       href="#login_modal"
  //       onClick={(e) => {
  //         e.stopPropagation();
  //         setNoAuthSaveButton(setPrompt, setNavigateTo);
  //         handleGAEventSignInModalOpen('forced')
  //       }}
  //     >
  //       <i
  //         className="fa-heart fa-regular"
  //       ></i>Save
  //     </a>
  //   </div>
  // )


  return mobile ? (
    <img
      className="dca-mobile-save-heart-icon"
      alt='heart_no_saved'
      src={heart_no_save}
      data-bs-toggle="modal"
      data-bs-target="#login_modal"
      onClick={(e) => {
        e.stopPropagation();
        setNoAuthSaveButton(setPrompt, setNavigateTo);
        handleGAEventSignInModalOpen('forced')
      }}
    ></img>) : (
    <button
      type="button"
      className="btn btn-sm dca-save-button-inline"
      style={{ background: bttnGray }}
      data-bs-toggle="modal"
      data-bs-target="#login_modal"
      onClick={(e) => {
        e.stopPropagation();
        setNoAuthSaveButton(setPrompt, setNavigateTo);
        handleGAEventSignInModalOpen('forced')
      }}
    >
      <span className="d-flex flex-row align-items-center"><i
        className="fa-heart fa-regular"
      ></i>&nbsp;&nbsp;Save</span>
    </button>
  )
}

export const SaveButtonMajorCard = ({ user, majorId, setUser, programID = undefined, mobile = false }) => {
  const {
    handleGAEventSearchSave,
    handleGAEventDetailsSave
  } = MajorReactGAHandlers()

  const handleAddToUser = async (userId, data, idToAdd, dataField, setUser, programID = undefined) => {

    let listToPatch: number[] = [idToAdd]

    if (data) {
      listToPatch = [...data, idToAdd]
    }

    const patchData = { [dataField]: listToPatch }

    try {
      const patch = await axios.patch(`${URL}/user_profiles/${userId}`, patchData)

      console.log('Patch successful,', patch.data)

      const res = await axios.get(`${URL}/user_profiles/${userId}`)
      setUser(res.data)
      console.log('Updated user:', res.data);

    } catch (error) {
      console.log(error)
      throw (error)
    } finally {
      programID != undefined ? handleGAEventDetailsSave(idToAdd, programID) : handleGAEventSearchSave(idToAdd)
    }
  }

  const handleRemoveFromUser = async (userId, data, dataField: string, idToRemove, setUser) => {

    try {

      let listToUpdate = [...data]

      let index = listToUpdate.indexOf(idToRemove)
      if (index > -1) {
        listToUpdate.splice(index, 1)
      }

      const patchData = { [dataField]: listToUpdate }

      const remove = await axios.patch(`${URL}/user_profiles/${userId}`, patchData)

      console.log('Patch successful,', remove.data)

      const res = await axios.get(`${URL}/user_profiles/${userId}`)
      setUser(res.data)
      console.log('Updated user:', res.data);

    } catch (error) {
      console.log(error)
      throw (error)
    }

  }

  const handleClick = async (check, userId, data, dataField: string, id, setUser, programID = undefined) => {
    return check ? await handleRemoveFromUser(userId, data, dataField, id, setUser) : await handleAddToUser(userId, data, id, dataField, setUser, programID)
  }

  const [check, setCheck] = useState(false)

  const checkMajorsList = () => {
    if (user && user.majorsInterest) {
      user.majorsInterest.includes(majorId) ? setCheck(true) : setCheck(false)
    }
  }

  useEffect(() => {
    checkMajorsList()
  }, [user])

  // let check = user?.majorsInterest.includes(majorId)

  return (
    <button
      type="button"
      className={mobile ? `dca-save-button-card-mobile` : `btn btn-sm dca-save-button-card`}
      style={{ background: mobile ? 'transparent' : check ? selectedTabGreen : bttnGray, position: 'absolute', top: '15px', right: '15px' }}
      onClick={(e) => {
        e.stopPropagation();
        handleClick(check, user.user_id, user.majorsInterest, 'majorsInterest', majorId, setUser, programID)
      }}
    >
      {mobile ? (check ? <img className="dca-mobile-save-heart-icon" alt="heart_saved" src={heart_saved}></img> : <img className="dca-mobile-save-heart-icon" alt='heart_no_saved' src={heart_no_save}></img>) : (<i
        className={`fa-heart${check ? " fa-solid" : " fa-regular"}`}
        style={check ? { color: 'rgba(123, 212, 125, 1)' } : {}}
      ></i>)}
      {mobile === false && (check ? 'Saved' : 'Save')}
    </button>
  )
}

// export const SaveButtonMajorInLine = ({ user, majorId, setUser }) => {

//   const handleAddToUser = async (userId, data, idToAdd, dataField, setUser) => {

//     let listToPatch: number[] = [idToAdd]

//     if (data) {
//       listToPatch = [...data, idToAdd]
//     }

//     const patchData = { [dataField]: listToPatch }

//     try {
//       const patch = await axios.patch(`${URL}/user_profiles/${userId}`, patchData)

//       console.log('Patch successful,', patch.data)

//       const res = await axios.get(`${URL}/user_profiles/${userId}`)
//       setUser(res.data)
//       console.log('Updated user:', res.data);

//     } catch (error) {
//       console.log(error)
//       throw (error)
//     }
//   }

//   const handleRemoveFromUser = async (userId, data, dataField: string, idToRemove, setUser) => {

//     try {

//       let listToUpdate = [...data]

//       let index = listToUpdate.indexOf(idToRemove)
//       if (index > -1) {
//         listToUpdate.splice(index, 1)
//       }

//       const patchData = { [dataField]: listToUpdate }

//       const remove = await axios.patch(`${URL}/user_profiles/${userId}`, patchData)

//       console.log('Patch successful,', remove.data)

//       const res = await axios.get(`${URL}/user_profiles/${userId}`)
//       setUser(res.data)
//       console.log('Updated user:', res.data);

//     } catch (error) {
//       console.log(error)
//       throw (error)
//     }

//   }

//   const handleClick = async (check, userId, data, dataField: string, id, setUser) => {
//     return check ? await handleRemoveFromUser(userId, data, dataField, id, setUser) : await handleAddToUser(userId, data, id, dataField, setUser)
//   }

//   const [check, setCheck] = useState(false)

//   const checkMajorsList = () => {
//     if (user && user.majorsInterest) {
//       user.majorsInterest.includes(majorId) ? setCheck(true) : setCheck(false)
//     }
//   }

//   useEffect(() => {
//     checkMajorsList()
//   }, [user])


//   return (
//     <button
//       type="button"
//       className="btn btn-sm fw-bold dca-save-button-inline"
//       style={{ background: check ? selectedTabGreen : gray }}
//       onClick={(e) => {
//         e.stopPropagation();
//         handleClick(check, user.user_id, user.majorsInterest, 'majorsInterest', majorId, setUser)
//       }}
//     >
//       <i
//         className={`fa-heart${check ? " fa-solid" : " fa-regular"}`}
//         style={check ? { color: 'rgba(123, 212, 125, 1)' } : {}}
//       ></i>
//       {check ? 'Saved' : 'Save'}
//     </button>
//   )
// }

export const SaveButtonECActivityCard = ({ user, ecId, setUser, mobile = false }) => {

  const { handleGAEventSearchSave } = ECReactGAHandlers()

  const handleAddToUser = async (userId, data, idToAdd, dataField, setUser) => {

    let listToPatch: number[] = [idToAdd]

    if (data) {
      listToPatch = [...data, idToAdd]
    }

    const patchData = { [dataField]: listToPatch }

    try {
      const patch = await axios.patch(`${URL}/user_profiles/${userId}`, patchData)

      console.log('Patch successful,', patch.data)

      const res = await axios.get(`${URL}/user_profiles/${userId}`)
      setUser(res.data)
      console.log('Updated user:', res.data);

    } catch (error) {
      console.log(error)
      throw (error)
    } finally {
      handleGAEventSearchSave(idToAdd)
    }
  }

  const handleRemoveFromUser = async (userId, data, dataField: string, idToRemove, setUser) => {

    try {

      let listToUpdate = [...data]

      let index = listToUpdate.indexOf(idToRemove)
      if (index > -1) {
        listToUpdate.splice(index, 1)
      }

      const patchData = { [dataField]: listToUpdate }

      const remove = await axios.patch(`${URL}/user_profiles/${userId}`, patchData)

      console.log('Patch successful,', remove.data)

      const res = await axios.get(`${URL}/user_profiles/${userId}`)
      setUser(res.data)
      console.log('Updated user:', res.data);

    } catch (error) {
      console.log(error)
      throw (error)
    }

  }

  const handleClick = async (check, userId, data, dataField: string, id, setUser) => {
    return check ? await handleRemoveFromUser(userId, data, dataField, id, setUser) : await handleAddToUser(userId, data, id, dataField, setUser)
  }

  const [check, setCheck] = useState(false)

  const checkECActivitiesList = () => {
    if (user && user.activitiesInterest) {
      user.activitiesInterest.includes(ecId) ? setCheck(true) : setCheck(false)
    }
  }

  useEffect(() => {
    checkECActivitiesList()
  }, [user])


  return (
    <button
      type="button"
      className={mobile ? `dca-save-button-card-mobile` : `btn btn-sm dca-save-button-card`}
      style={{ background: mobile ? 'transparent' : check ? selectedTabGreen : bttnGray }}
      onClick={(e) => {
        e.stopPropagation();
        handleClick(check, user.user_id, user.activitiesInterest, 'activitiesInterest', ecId, setUser)
      }}
    >
      {/* <i
        className={`fa-heart${check ? " fa-solid" : " fa-regular"}`}
        style={check ? { color: 'rgba(123, 212, 125, 1)' } : {}}
      ></i>
      Save */}
      {mobile ? (check ? <img className="dca-mobile-save-heart-icon" alt="heart_saved" src={heart_saved}></img> : <img className="dca-mobile-save-heart-icon" alt='heart_no_saved' src={heart_no_save}></img>) : (<i
        className={`fa-heart${check ? " fa-solid" : " fa-regular"}`}
        style={check ? { color: 'rgba(123, 212, 125, 1)' } : {}}
      ></i>)}
      {mobile === false && (check ? 'Saved' : 'Save')}
    </button>
  )
}

export const SaveButtonECActivityInline = ({ user, ecId, setUser, mobile = false }) => {

  const { handleGAEventDetailsSave } = ECReactGAHandlers()

  const handleAddToUser = async (userId, data, idToAdd, dataField, setUser) => {


    let listToPatch: number[] = [idToAdd]

    if (data) {
      listToPatch = [...data, idToAdd]
    }

    const patchData = { [dataField]: listToPatch }

    try {
      const patch = await axios.patch(`${URL}/user_profiles/${userId}`, patchData)

      console.log('Patch successful,', patch.data)

      const res = await axios.get(`${URL}/user_profiles/${userId}`)
      setUser(res.data)
      console.log('Updated user:', res.data);

    } catch (error) {
      console.log(error)
      throw (error)
    } finally {
      handleGAEventDetailsSave(idToAdd)
    }
  }

  const handleRemoveFromUser = async (userId, data, dataField: string, idToRemove, setUser) => {

    try {

      let listToUpdate = [...data]

      let index = listToUpdate.indexOf(idToRemove)
      if (index > -1) {
        listToUpdate.splice(index, 1)
      }

      const patchData = { [dataField]: listToUpdate }

      const remove = await axios.patch(`${URL}/user_profiles/${userId}`, patchData)

      console.log('Patch successful,', remove.data)

      const res = await axios.get(`${URL}/user_profiles/${userId}`)
      setUser(res.data)
      console.log('Updated user:', res.data);

    } catch (error) {
      console.log(error)
      throw (error)
    }

  }

  const handleClick = async (check, userId, data, dataField: string, id, setUser) => {
    return check ? await handleRemoveFromUser(userId, data, dataField, id, setUser) : await handleAddToUser(userId, data, id, dataField, setUser)
  }

  const [check, setCheck] = useState(false)

  const checkECActivitiesList = () => {
    if (user && user.activitiesInterest) {
      user.activitiesInterest.includes(ecId) ? setCheck(true) : setCheck(false)
    }
  }

  useEffect(() => {
    checkECActivitiesList()
  }, [user])


  return mobile ? (
    <button
      type="button"
      className="btn btn-sm dca-save-button-inline"
      style={{ background: mobile ? 'transparent' : check ? selectedTabGreen : bttnGray }}
      onClick={(e) => {
        e.stopPropagation();
        handleClick(check, user.user_id, user.activitiesInterest, 'activitiesInterest', ecId, setUser)
      }}
    >
      {mobile ? (check ? <img className="dca-mobile-save-heart-icon" alt="heart_saved" src={heart_saved}></img> : <img className="dca-mobile-save-heart-icon" alt='heart_no_saved' src={heart_no_save}></img>) : (<i
        className={`fa-heart${check ? " fa-solid" : " fa-regular"}`}
        style={check ? { color: 'rgba(123, 212, 125, 1)' } : {}}
      ></i>)}
    </button>
  ) : (
    <button
      type="button"
      className="btn btn-sm dca-save-button-inline"
      style={{ background: check ? selectedTabGreen : bttnGray }}
      onClick={(e) => {
        e.stopPropagation();
        handleClick(check, user.user_id, user.activitiesInterest, 'activitiesInterest', ecId, setUser)
      }}
    >
      <i
        className={`fa-heart${check ? " fa-solid" : " fa-regular"}`}
        style={check ? { color: 'rgba(123, 212, 125, 1)' } : {}}
      ></i>
      Save
    </button>
  )
}


// Creating Connect button within file to use setter functions
export const ConnectButtonInCard = () => {
  //pass onclick function as prop when ready to include user saving functionality

  return (
    <button
      type="button"
      className="btn btn-sm dca-connect-button-card"
      data-bs-toggle="modal"
      data-bs-target="#purchase_modal"
    // onClick={(e) => {
    //   e.stopPropagation();
    //   // setPurchaseModal(setPurchaseID, advisorConnectAccessCode);
    //   setPurchaseID(advisorConnectAccessCode)
    //   setAdvisorID(advisorID)
    //   handleGAEventPurchaseModalOpen(advisorID)
    // }}
    >
      Connect
    </button>
  )
}

export const ConnectButtonInCardNoAuth = ({ setPrompt, setNavigateTo, biLock = true, advisorID, mobile = false }) => {

  const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()
  const { handleAdvisorGAEventCardConnectClick } = AdvisorsReactGAHandlers()

  return mobile ? (<button
    type="button"
    className="btn btn-sm dca-connectbuttonincardnoauth-mobile"
    data-bs-toggle="modal"
    data-bs-target="#login_modal"
    onClick={(e) => {
      e.stopPropagation();
      setNoAuthAdvisorsConnectButton(setPrompt, setNavigateTo);
      handleGAEventSignInModalOpen('forced');
      handleAdvisorGAEventCardConnectClick(advisorID)
    }}
  >
    Connect
  </button>) : (
    <button
      type="button"
      className="btn btn-sm dca-connect-button-card-v2"
      data-bs-toggle="modal"
      data-bs-target="#login_modal"
      onClick={(e) => {
        e.stopPropagation();
        setNoAuthAdvisorsConnectButton(setPrompt, setNavigateTo);
        handleGAEventSignInModalOpen('forced');
        handleAdvisorGAEventCardConnectClick(advisorID)
      }}
    >
      {biLock && <i className="bi bi-lock align-self-center" style={{ color: 'black' }}></i>}Connect
    </button>
  )
}

export const ConnectButtonNoAuth = ({ setPrompt, setNavigateTo, biLock = true, advisorID, customWidth = '' }) => {

  const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()
  const { handleAdvisorGAEventDetailConnectClick } = AdvisorsReactGAHandlers()

  // return (
  //   <div>
  //     <a
  //       type="button"
  //       className="btn btn-sm dca-connect-button-card-detail"
  //       data-bs-toggle="modal"
  //       href="#login_modal"
  //       onClick={(e) => {
  //         e.stopPropagation();
  //         setNoAuthAdvisorsConnectButton(setPrompt, setNavigateTo);
  //         handleGAEventSignInModalOpen('forced');
  //         handleAdvisorGAEventDetailConnectClick(advisorID)
  //       }}
  //     >{biLock && <i className="bi bi-lock align-self-center" style={{ color: 'black' }}></i>}Connect
  //     </a>
  //   </div>
  // )

  return (
    <button
      type="button"
      className="btn btn-sm dca-connect-button-card-detail"
      data-bs-toggle="modal"
      data-bs-target="#login_modal"
      onClick={(e) => {
        e.stopPropagation();
        setNoAuthAdvisorsConnectButton(setPrompt, setNavigateTo);
        handleGAEventSignInModalOpen('forced');
        handleAdvisorGAEventDetailConnectClick(advisorID)
      }}
    >{biLock && <i className="bi bi-lock align-self-center" style={{ color: 'black', width: customWidth != '' ? customWidth : '' }}></i>}Connect
    </button>
  )

}