import Select from 'react-select'

type SingleSelectProps = {
  options: any,
  value: any,
  onChange: any
};

export const SingleSelect = ({ options, value, onChange }: SingleSelectProps) => {
  return <Select
    options={options}
    styles={{
      control: (baseStyles, state) => ({
        ...baseStyles,
        border: 'transparent',
        backgroundColor: '#F9F9F9',
        borderRadius: '13px'
      }),
      menu: (baseStyles) => ({
        ...baseStyles,
        zIndex: 100
      })
    }}
    isClearable
    placeholder={false}
    value={value}
    onChange={onChange}
  />
}