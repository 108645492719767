import { FC, useState, useEffect } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { App } from '../App'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, useAuth, getAuth } from '../modules/auth'
import { ScrollToTop } from './ScrollToTop'
import { URL, pageRoutes, sessionStorageGet } from '../components/dictionary'
import axios from 'axios'

// Public Route Pages
import { Home } from '../pages/Home'
import { CollegeSearchPage } from '../pages/CollegeSearchPage'
import { ECSearchPage } from '../pages/ECSearchPage'
import { MajorSearchPage } from '../pages/MajorSearchPage'
import { ECPremiumInsights } from '../pages/ECPremiumInsights'
import { AdvisorSearchPage } from '../pages/AdvisorSearchPage'
import { AdvisorDetailPage } from '../pages/AdvisorDetail'
import { MarketingCrimeSafety } from '../pages/Marketing/CrimeSafety'
import { MarketingECInsight } from '../pages/Marketing/ECInsights'
import { MarketingRDSpend } from '../pages/Marketing/RDSpend'
import { MarketingSpecializedRankings } from '../pages/Marketing/SpecializedRankings'
import { MarketingAdvisorNetwork } from '../pages/Marketing/AdvisorNetwork'
import { SignUpPage } from '../pages/Auth/SignUpPage'
import { SignInPage } from '../pages/Auth/SignInPage'
import { OverviewPage } from '../pages/OverviewPage'
import { PrivacyPolicy } from '../pages/PrivacyPolicy'
import { TermsOfService } from '../pages/TermsOfService'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'

// Private Route Pages
import { TestScoreStrategy } from '../pages/TestScoreStrategy'
import { CollegeDataVisualization } from '../pages/CollegeDataVisualization'
import { UserProfile } from '../pages/UserProfile'
import { UpgradeTierPage } from '../pages/Auth/UpgradeTierPage'

// Modals
import { SignInModal } from '../components/Auth/SignInModal'
import { SignUpModal } from '../components/Auth/SignUpModal'
import { UpgradeAccountModal } from '../components/Auth/UpgradeAccountModal'
import { AddCardModal } from '../components/Payments/AddCardModal'
import { PurchaseDescriptionModal } from '../components/Payments/PurchaseDescriptionModal'
import { PremiumPreviewModal } from '../components/Payments/PremiumPreviewModal'
import { About } from '../pages/About'
import { FAQ } from '../pages/FAQ'
import { Contact } from '../pages/Contact'
import { EmailVerificationRequestModal } from '../components/Auth/EmailVerificationRequestModal'

import { collegeMap } from '../components/collegeMap'
import { majorMap } from '../components/majorMap'
import { extracurricularMap } from '../components/extracurricularMap'
import { premiumecsMap } from '../components/premiumECs'

import { CollegeDetailPage } from '../pages/CollegeDetailPage'
import { ECDetailPage } from '../pages/ECDetailPage'
import { MajorDetailPage } from '../pages/MajorDetailPage'
import { LoadingIndicator } from '../components/LoadingIndicator'
import { MarketingPersonalizedExpertHelp } from '../pages/Marketing/PersonalizedExpertHelp'
import { Dashboard } from '../pages/Dashboard'

const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser, setCurrentUser } = useAuth()
  const [userCollegeInterest, setUserCollegeInterest] = useState<any>([])
  const [collegesId, setCollegesID] = useState<any>([])
  const [cip, setCIP] = useState<any>([])
  const [ecActivities, setECActivities] = useState<any>([])
  const [introQuestions, setIntroQuestions] = useState<any>({
    a: undefined,
    b: undefined,
    c: undefined
  })

  // Initialize modal variables
  const [prompt, setPrompt] = useState('')
  const [navigateTo, setNavigateTo] = useState('')
  const [purchaseID, setPurchaseID] = useState('')
  const [purchaseModalCollegeName, setPurchaseModalCollegeName] = useState('')
  const [upgradeUserGroupAccessCode, setUpgradeUserGroupAccessCode] = useState('')
  const [ecID, setECID] = useState<any>(null)
  const [ecName, setECName] = useState('')
  const [ecNumAlumni, setECNumAlumni] = useState<any>(undefined)
  const [showNavAfterPayment, setShowNavAfterPayment] = useState(false)
  const [collegeDetailSelectedTab, setCollegeDetailSelectedTab] = useState('Admission Info')
  const userprofileTabOptions = ['Profile Info', 'Colleges of Interest', 'Majors of Interest', 'EC Activities of Interest', 'Account Management']
  const [userprofileSelectedTab, setUserprofileSelectedTab] = useState<any>(userprofileTabOptions[0])
  const [advisorID, setAdvisorID] = useState<any>(null)
  const [overrideTestingWizard, setOverrideTestingWizard] = useState(false)
  // For test score strategy
  const [onlyInterestsSelect, setOnlyInterestsSelect] = useState(false)

  // Stripe embedded checkout
  const [clientSecret, setClientSecret] = useState('');
  // const [stripeStatus, setStripeStatus] = useState(null)

  // Location history states
  const [collegeSearchLocation, setCollegeSearchLocation] = useState(pageRoutes.collegeSearch)
  const [majorSearchLocation, setMajorSearchLocation] = useState(pageRoutes.majorSearch)
  const [ecSearchLocation, setECSearchLocation] = useState(pageRoutes.ecSearch)
  const [advisorSearchLocation, setAdvisorSearchLocation] = useState(pageRoutes.advisorSearch)
  const [collegeDataVisLocation, setCollegeDataVisLocation] = useState(pageRoutes.collegeDataVisualization)

  let apiToken: any = undefined
  let permissionGroups: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
    permissionGroups = getAuth()?.groups
  }

  const getCollegesNameID = async () => {
    let res = await axios.get(`${URL}/colleges-id-name/`)
    // console.log('Colleges Name ID Data:', res.data)
    setCollegesID(res.data)
  }

  const getCIP = async () => {
    let res = await axios.get(`${URL}/cip_taxonomy/`)
    setCIP(res.data)
  }

  const getECActivities = async () => {
    let res = await axios.get(`${URL}/ec_activity/`)
    setECActivities(res.data)
  }

  useEffect(() => {

    const getUserCollegeInterest = async (currentUser) => {
      let res = await axios.get(`${URL}/user_college_interest/`)
      res = res.data.filter((item) => item.user_profile_id === currentUser.user_id)
      setUserCollegeInterest(res)
    }

    if (getAuth()?.api_token && getAuth()?.groups) {
      apiToken = getAuth()?.api_token
      permissionGroups = getAuth()?.groups
    }

    if (currentUser?.emailAddress) {
      getUserCollegeInterest(currentUser)
    }

    // introQuestions
    // a. Are you a parent or student
    // Integer: 1 == Student ; 2 == Parent
    // b. What year will you/your child graduate high school
    // c. How familiar are you with the college admissions process 
    // user?.familiarityWithProcess == integer field capturing user's response to how much they already know about the college admissions process
    // 1 == "Just starting to learn about it"
    // 2 == "Somewhat familiar"
    // 3 == "Very familiar"

    let a = currentUser?.userType ?? undefined
    let b = currentUser?.hsGradYr ?? undefined
    let c = currentUser?.familiarityWithProcess ?? undefined

    if (!currentUser) {
      setIntroQuestions({
        a: undefined,
        b: undefined,
        c: undefined
      })

      setUserCollegeInterest([])
    }

    else {
      setIntroQuestions({ a: a, b: b, c: c })
    }

  }, [currentUser])

  useEffect(() => {
    getCollegesNameID();
    getCIP();
    getECActivities();
  }, [])

  const showMarketing = process.env.REACT_APP_MARKETING_TOGGLE

  const allRoutes = (
    <>
      <Route path={pageRoutes.home} element={
        <Home
          setPrompt={setPrompt}
          setNavigateTo={setNavigateTo}
          setCurrentUser={setCurrentUser}
        />
      } />

      <Route path={pageRoutes.dashboard} element={
        <Dashboard
          user={currentUser}
          setUser={setCurrentUser}
          userCollegeInterest={userCollegeInterest}
          setUserCollegeInterest={setUserCollegeInterest}
          setPrompt={setPrompt}
          setNavigateTo={setNavigateTo}
          introQuestions={introQuestions}
          setIntroQuestions={setIntroQuestions}
          setUserprofileSelectedTab={setUserprofileSelectedTab}
          collegesId={collegesId}
          setOnlyInterestsSelect={setOnlyInterestsSelect}
          overrideTestingWizard={overrideTestingWizard}
          setOverrideTestingWizard={setOverrideTestingWizard}
        />
      } />

      {/* For specific request of SEO friendly URLs on overview */}
      <Route path={pageRoutes.overviewIntroduction} element={<OverviewPage
        setPrompt={setPrompt}
        setNavigateTo={setNavigateTo}
        user={currentUser}
        setUser={setCurrentUser}
      />} />
      <Route path={pageRoutes.overviewCollegeAppWalkthrough} element={<OverviewPage
        setPrompt={setPrompt}
        setNavigateTo={setNavigateTo}
        user={currentUser}
        setUser={setCurrentUser}
      />} />
      <Route path={pageRoutes.overviewCollegeAppWalkthroughForRouter} element={<OverviewPage
        setPrompt={setPrompt}
        setNavigateTo={setNavigateTo}
        user={currentUser}
        setUser={setCurrentUser}
      />} />
      <Route path={pageRoutes.overviewAdmissionsAdvice} element={<OverviewPage
        setPrompt={setPrompt}
        setNavigateTo={setNavigateTo}
        user={currentUser}
        setUser={setCurrentUser}
      />} />
      <Route path={pageRoutes.overviewAdmissionsAdviceForRouter} element={<OverviewPage
        setPrompt={setPrompt}
        setNavigateTo={setNavigateTo}
        user={currentUser}
        setUser={setCurrentUser}
      />} />
      {/*  */}

      <Route path={pageRoutes.collegeSearch}
        element={<CollegeSearchPage
          user={currentUser}
          userCollegeInterest={userCollegeInterest}
          setUserCollegeInterest={setUserCollegeInterest}
          collegesId={collegesId}
          setPrompt={setPrompt}
          setNavigateTo={setNavigateTo}
          collegeSearchLocation={collegeSearchLocation}
          setCollegeSearchLocation={setCollegeSearchLocation}
        />} />

      {collegeMap.map((college) => (
        <Route
          key={college.hyphenated}
          path={`/${college.hyphenated}`}
          element={<CollegeDetailPage
            user={currentUser}
            setUser={setCurrentUser}
            userCollegeInterest={userCollegeInterest}
            setUserCollegeInterest={setUserCollegeInterest}
            collegesId={collegesId}
            collegeListLength={collegeMap.length}
            prompt={prompt}
            setPrompt={setPrompt}
            navigateTo={navigateTo}
            setNavigateTo={setNavigateTo}
            setPurchaseID={setPurchaseID}
            setPurchaseModalCollegeName={setPurchaseModalCollegeName}
            selectedTab={collegeDetailSelectedTab}
            setSelectedTab={setCollegeDetailSelectedTab}
            collegeSearchLocation={collegeSearchLocation}
            idToSend={college.id}
          />} />))}

      {showMarketing === 'true' && <Route
        path={pageRoutes.premiumCrimeSafety}
        element={<MarketingCrimeSafety
          user={currentUser}
          clientSecret={clientSecret}
          setClientSecret={setClientSecret}
          setUpgradeUserGroupAccessCode={setUpgradeUserGroupAccessCode}
          setSelectedTab={setCollegeDetailSelectedTab}
          collegesId={collegesId}
          setNavigateTo={setNavigateTo} />}
      />}

      {showMarketing === 'true' && collegeMap.map((college) => (
        <Route
          key={`/${college.hyphenated}-crime-and-safety-report`}
          path={`/${college.hyphenated}-crime-and-safety-report`}
          element={<MarketingCrimeSafety
            user={currentUser}
            clientSecret={clientSecret}
            setClientSecret={setClientSecret}
            setUpgradeUserGroupAccessCode={setUpgradeUserGroupAccessCode}
            setSelectedTab={setCollegeDetailSelectedTab}
            collegesId={collegesId}
            setNavigateTo={setNavigateTo} />}
        />))}

      {showMarketing === 'true' && <Route
        path={pageRoutes.premiumRDSpend}
        element={<MarketingRDSpend
          user={currentUser}
          clientSecret={clientSecret}
          setClientSecret={setClientSecret}
          setUpgradeUserGroupAccessCode={setUpgradeUserGroupAccessCode}
          setSelectedTab={setCollegeDetailSelectedTab}
          setNavigateTo={setNavigateTo} />}
      />}

      {showMarketing === 'true' && <Route
        path={pageRoutes.advisorNetwork}
        element={<MarketingAdvisorNetwork
          user={currentUser} />}
      />}

      {showMarketing === 'true' && <Route
        path={pageRoutes.personalizedExpertHelp}
        element={<MarketingPersonalizedExpertHelp
          user={currentUser}
          setNavigateTo={setNavigateTo}
          setPurchaseID={setPurchaseID} />}
      />}

      {showMarketing === 'true' && collegeMap.map((college) => (
        <Route
          key={`/${college.hyphenated}-research-spend-report`}
          path={`/${college.hyphenated}-research-spend-report`}
          element={<MarketingRDSpend
            user={currentUser}
            clientSecret={clientSecret}
            setClientSecret={setClientSecret}
            setUpgradeUserGroupAccessCode={setUpgradeUserGroupAccessCode}
            setSelectedTab={setCollegeDetailSelectedTab}
            setNavigateTo={setNavigateTo} />}
        />))}

      {showMarketing === 'true' && <Route
        path={pageRoutes.premiumSpecializedRankings}
        element={<MarketingSpecializedRankings
          user={currentUser}
          clientSecret={clientSecret}
          setClientSecret={setClientSecret}
          setUpgradeUserGroupAccessCode={setUpgradeUserGroupAccessCode}
          setSelectedTab={setCollegeDetailSelectedTab}
          setNavigateTo={setNavigateTo} />}
      />}

      {showMarketing === 'true' && collegeMap.map((college) => (
        <Route
          key={`/${college.hyphenated}-specialized-rankings`}
          path={`/${college.hyphenated}-specialized-rankings`}
          element={<MarketingSpecializedRankings
            user={currentUser}
            clientSecret={clientSecret}
            setClientSecret={setClientSecret}
            setUpgradeUserGroupAccessCode={setUpgradeUserGroupAccessCode}
            setSelectedTab={setCollegeDetailSelectedTab}
            setNavigateTo={setNavigateTo} />}
        />))}

      <Route
        path={pageRoutes.collegeDataVisualization}
        element={<CollegeDataVisualization
          user={currentUser}
          setUser={setCurrentUser}
          collegesId={collegesId}
          prompt={prompt}
          setPrompt={setPrompt}
          navigateTo={navigateTo}
          setNavigateTo={setNavigateTo}
          collegeDataVisLocation={collegeDataVisLocation}
          setCollegeDataVisLocation={setCollegeDataVisLocation}
        />} />

      <Route path={pageRoutes.ecSearch}
        element={<ECSearchPage
          user={currentUser}
          setUser={setCurrentUser}
          prompt={prompt}
          setPrompt={setPrompt}
          navigateTo={navigateTo}
          setNavigateTo={setNavigateTo}
          setECName={setECName}
          setECNumAlumni={setECNumAlumni}
          setECID={setECID}
          setPurchaseID={setPurchaseID}
          setShowNavAfterPayment={setShowNavAfterPayment}
          ecSearchLocation={ecSearchLocation}
          setECSearchLocation={setECSearchLocation} />} />

      {extracurricularMap.map((ec) => (
        <Route
          key={ec.hyphenated}
          path={`/${ec.hyphenated}`}
          element={
            <ECDetailPage
              user={currentUser}
              setUser={setCurrentUser}
              setPrompt={setPrompt}
              setNavigateTo={setNavigateTo}
              setECName={setECName}
              setECNumAlumni={setECNumAlumni}
              setECID={setECID}
              setPurchaseID={setPurchaseID}
              clientSecret={clientSecret}
              setClientSecret={setClientSecret}
              ecName={ecName}
              ecSearchLocation={ecSearchLocation}
              idToSend={ec.id}
            />} />))}

      {showMarketing === 'true' &&
        <Route
          path={pageRoutes.ecPremiumInsightsBase}
          element={<MarketingECInsight
            user={currentUser}
            clientSecret={clientSecret}
            setClientSecret={setClientSecret}
            setNavigateTo={setNavigateTo}
            navigateTo={navigateTo}
            ecID={ecID}
            setECID={setECID}
            setUpgradeUserGroupAccessCode={setUpgradeUserGroupAccessCode} />}
        />
      }

      {extracurricularMap.map(ec => (
        <Route
          key={ec.hyphenated}
          path={premiumecsMap.includes(ec.id) ? `/extracurricular-insights-${ec.hyphenated}` : undefined}
          element={permissionGroups?.includes(`EC${ec.id}`) ?
            <ECPremiumInsights
              user={currentUser}
              cip={cip}
              setPrompt={setPrompt}
              setNavigateTo={setNavigateTo}
            /> : <MarketingECInsight
              user={currentUser}
              clientSecret={clientSecret}
              setClientSecret={setClientSecret}
              setNavigateTo={setNavigateTo}
              navigateTo={navigateTo}
              ecID={ecID}
              setECID={setECID}
              setUpgradeUserGroupAccessCode={setUpgradeUserGroupAccessCode} />}
        />
      ))}

      <Route
        path={pageRoutes.majorSearch}
        element={
          <MajorSearchPage
            user={currentUser}
            setUser={setCurrentUser}
            prompt={prompt}
            setPrompt={setPrompt}
            navigateTo={navigateTo}
            setNavigateTo={setNavigateTo}
            majorSearchLocation={majorSearchLocation}
            setMajorSearchLocation={setMajorSearchLocation}
          />} />
      {majorMap.map((major) => (
        <Route
          key={major.hyphenated}
          path={`/${major.hyphenated}`}
          element={
            <MajorDetailPage
              user={currentUser}
              setUser={setCurrentUser}
              collegeListLength={collegeMap.length}
              prompt={prompt}
              setPrompt={setPrompt}
              navigateTo={navigateTo}
              setNavigateTo={setNavigateTo}
              majorSearchLocation={majorSearchLocation}
              idToSend={major.id}
            />} />))}

      <Route
        path={pageRoutes.advisorSearch}
        element={<AdvisorSearchPage
          user={currentUser}
          collegesId={collegesId}
          cip={cip}
          ecActivities={ecActivities}
          setPrompt={setPrompt}
          setNavigateTo={setNavigateTo}
          setPurchaseID={setPurchaseID}
          setAdvisorID={setAdvisorID}
          advisorSearchLocation={advisorSearchLocation}
          setAdvisorSearchLocation={setAdvisorSearchLocation}
        />} />
      <Route
        path={pageRoutes.advisorDetailGeneric}
        element={
          <AdvisorDetailPage
            user={currentUser}
            setPrompt={setPrompt}
            setNavigateTo={setNavigateTo}
            setPurchaseID={setPurchaseID}
            setAdvisorID={setAdvisorID}
            advisorSearchLocation={advisorSearchLocation}
          />} />

      <Route
        path={pageRoutes.signUp}
        element={<SignUpPage
          setUser={setCurrentUser}
        />}
      />
      <Route
        path={pageRoutes.signIn}
        element={<SignInPage />}
      />
      <Route
        path={pageRoutes.privacyPolicy}
        element={<PrivacyPolicy />}
      />
      <Route
        path={pageRoutes.termsOfService}
        element={<TermsOfService />}
      />
      <Route
        path={pageRoutes.about}
        element={<About />}
      />
      <Route
        path={pageRoutes.faq}
        element={<FAQ />}
      />
      <Route
        path={pageRoutes.contact}
        element={<Contact />}
      />
      <Route path='*' element={<ErrorsPage />} />
    </>
  )

  const privateRoutes = (
    <>
      <Route
        path={pageRoutes.testScoreStrategy}
        element={<TestScoreStrategy
          user={currentUser}
          setUser={setCurrentUser}
          userCollegeInterest={userCollegeInterest}
          setUserCollegeInterest={setUserCollegeInterest}
          collegesId={collegesId}
          setPrompt={setPrompt}
          setNavigateTo={setNavigateTo}
          onlyInterestsSelect={onlyInterestsSelect}
          setOnlyInterestsSelect={setOnlyInterestsSelect}
        />} />
      <Route
        path={pageRoutes.userProfileGeneric}
        element={
          <UserProfile
            user={currentUser}
            setUser={setCurrentUser}
            userCollegeInterest={userCollegeInterest}
            setUserCollegeInterest={setUserCollegeInterest}
            setPrompt={setPrompt}
            setNavigateTo={setNavigateTo}
            setCollegeDetailSelectedTab={setCollegeDetailSelectedTab}
            selectedTab={userprofileSelectedTab}
            setSelectedTab={setUserprofileSelectedTab}
            tabOptions={userprofileTabOptions} />
        } />
      <Route
        path='upgrade-tier'
        element={
          <UpgradeTierPage
          // setPrompt={setPrompt}
          // setNavigateTo={setNavigateTo}
          />} />
    </>
  )

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <ScrollToTop />
      <Header
        setPrompt={setPrompt}
        setNavigateTo={setNavigateTo}
        setUserprofileSelectedTab={setUserprofileSelectedTab} />
      <Routes>
        <Route element={<App />}>
          {/* <Route path='error/*' element={<ErrorsPage />} />
          <Route path='*' element={<Navigate to='/error/404' />} /> */}
          <Route path='error/*' element={<Home
            setPrompt={setPrompt}
            setNavigateTo={setNavigateTo}
            setCurrentUser={setCurrentUser} />} />
          <Route path='*' element={<Navigate to='/home' />} />
          <Route path='logout' element={<Logout />} />
          {apiToken ? (
            <>
              <Route index element={<Navigate to='/home' />} />
              {allRoutes}
              {privateRoutes}
            </>
          ) : (
            <>
              <Route index element={<Navigate to='/home' />} />
              {allRoutes}
            </>
          )}

        </Route>
      </Routes>

      {/* Modals */}
      <SignInModal
        prompt={prompt}
        setPrompt={setPrompt}
        navigateTo={navigateTo}
        setNavigateTo={setNavigateTo}
        setCollegeDetailSelectedTab={setCollegeDetailSelectedTab}
        introQuestions={introQuestions}
        setOverrideTestingWizard={setOverrideTestingWizard}
      />

      <SignUpModal
        prompt={prompt}
        setPrompt={setPrompt}
        navigateTo={navigateTo}
        setNavigateTo={setNavigateTo}
        setCollegeDetailSelectedTab={setCollegeDetailSelectedTab}
        introQuestions={introQuestions}
        setOverrideTestingWizard={setOverrideTestingWizard} />

      <PurchaseDescriptionModal
        purchaseID={purchaseID}
        collegeName={purchaseModalCollegeName}
        collegeListLength={collegesId.length > 0 ? collegesId.length : 100}
        setUpgradeUserGroupAccessCode={setUpgradeUserGroupAccessCode}
        ecID={ecID}
        ecName={ecName}
        ecNumAlumni={ecNumAlumni}
        clientSecret={clientSecret}
        setClientSecret={setClientSecret}
        advisorID={advisorID}
        currentUser={currentUser} />

      <PremiumPreviewModal
        purchaseID={purchaseID} />

      <UpgradeAccountModal />

      {/* ID: add_payment_method_modal */}
      <AddCardModal
        upgradeUserGroupAccessCode={upgradeUserGroupAccessCode}
        setSelectedTab={setCollegeDetailSelectedTab}
        clientSecret={clientSecret}
        setClientSecret={setClientSecret}
        showNavAfterPayment={showNavAfterPayment}
        setShowNavAfterPayment={setShowNavAfterPayment}
        ecID={ecID}
        ecName={ecName}
        advisorID={advisorID}
      />

      <EmailVerificationRequestModal currentUser={currentUser} />

      <Footer
        setPrompt={setPrompt}
        setNavigateTo={setNavigateTo} />
    </BrowserRouter>
  )
}

export { AppRoutes }
