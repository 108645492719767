import { useNavigate } from "react-router-dom"
import { pageRoutes, updateTitleAndDescription } from "../dictionary";
import Chart from 'react-apexcharts'
import { useEffect } from "react";
import { getAuth } from "../../modules/auth";
import { setTestScoreStrategyModal } from "../Auth/setPrompts";
import { KTIcon } from '../../../_metronic/helpers'
import { AuthReactGAHandlers } from "../ReactGAHandlers";

import admissionsAdvicePhoto1 from '../../../custom_assets/Overview/AdmissionAdvice/dca_admission_advice_photo1v4.png'
import admissionsAdvicePhoto2 from '../../../custom_assets/Overview/AdmissionAdvice/dca_admission_advice_photo2v4.png'
import admissionsAdvicePhoto3 from '../../../custom_assets/Overview/AdmissionAdvice/dca_admission_advice_photo3v3.png'
import admissionsAdvicePhoto4 from '../../../custom_assets/Overview/AdmissionAdvice/dca_admission_advice_photo4v3.png'
import admissionsAdvicePhoto5 from '../../../custom_assets/Overview/AdmissionAdvice/dca_admission_advice_photo5v4.png'
import admissionsAdvicePhoto6 from '../../../custom_assets/Overview/AdmissionAdvice/dca_admission_advice_photo6v4.png'
import admissionsAdvicePhoto7 from '../../../custom_assets/Overview/AdmissionAdvice/dca_admission_advice_photo7v4.png'
import admissionsAdvicePhoto8 from '../../../custom_assets/Overview/AdmissionAdvice/dca_admission_advice_photo8v4.png'
import admissionsAdvicePhoto9 from '../../../custom_assets/Overview/AdmissionAdvice/dca_admission_advice_photo9v4.png'
import admissionsAdvicePhoto10 from '../../../custom_assets/Overview/AdmissionAdvice/dca_admission_advice_photo10v4.png'
import admissionsAdvicePhoto11 from '../../../custom_assets/Overview/AdmissionAdvice/dca_admission_advice_photo11v2.png'
import admissionsAdvicePhoto12 from '../../../custom_assets/Overview/AdmissionAdvice/dca_admission_advice_photo12v4.png'

import navHamburger from '../../../custom_assets/hamburger.png'

const AdviceForNavigatingAdmissions = ({ hashPath, setPrompt, setNavigateTo, verticalTabs, overviewSidebarActive, sidebar, handleClickOffcanvas, pagination }) => {

  const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()

  const navigate = useNavigate();
  let apiToken: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
  }

  useEffect(() => {
    if (getAuth()?.api_token && getAuth()?.groups) {
      apiToken = getAuth()?.api_token
    }

  }, [])

  useEffect(() => {
    hashPath != '' ? updateTitleAndDescription(pageRoutes.overviewAdmissionsAdviceWithHash(hashPath), hashPath) : updateTitleAndDescription(pageRoutes.overviewAdmissionsAdvice)
  }, [hashPath])

  const placeholderChart = (
    <Chart
      options={{
        chart: {
          id: 'placeholder'
        },
        xaxis: {
          categories: [1, 2],
          labels: {
            show: true,
            rotate: -45,
          }
        },
        yaxis: {
          min: 0,
          max: 100,
          labels: {
            style: {
              colors: ['#9AA2B6'],
            },
            formatter: function (val) {
              return val.toFixed(0) + "%";
            }
          }
        },
        colors: ["#98F29B"],
        tooltip: {
          enabled: false,
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#000000'],
          },
          background: {
            enabled: false,
            borderWidth: 0,
            foreColor: '#000',
          },
          formatter: function (val, opts) {
            return val + "%";
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            dataLabels: {
              position: 'top',
            },
          }
        }
      }}
      series={[
        {
          name: 'Placeholder',
          data: [20, 80]
        }
      ]}
      type='bar'
      width={'450'}
      height={'300'}
    />
  )

  const returnPathname = (hashPath) => {
    switch (true) {
      case hashPath == 'identifying-core-application-theme':
        return 'Identifying Core Application Theme'
      case hashPath == 'selecting-intended-major(s)':
        return 'Selecting Intended Major(s)'
      case hashPath == 'crafting-an-extracurriculars-profile':
        return 'Crafting an Extracurriculars Profile'
      case hashPath == 'creating-a-college-interest-list':
        return 'Creating a College Interest List'
      case hashPath == 'determining-a-standardized-testing-strategy':
        return 'Determining a Standardized Testing Strategy'
      case hashPath == 'maximizing-impact-from-letters-of-recommendation':
        return 'Maximizing Impact From Letters of Recommendation'
      case hashPath == 'applying-early-vs-regular':
        return 'Should You Apply Early or Regular'
      case hashPath == 'overall-reference-timeline':
        return 'Overall Reference Timeline'
      default:
        return ''
    }
  }

  let breadcrumbs = [
    { title: 'Overview', path: `/introduction` },
    { title: 'Advice For Navigating Admissions Process', path: `/admissions-advice` }
  ]

  if (hashPath !== '') {
    breadcrumbs = [
      { title: 'Overview', path: `/introduction` },
      { title: 'Advice For Navigating Admissions Process', path: `/admissions-advice` },
      { title: returnPathname(hashPath), path: `/admissions-advice/${hashPath}` }
    ]
  }

  const breadcrumbComponent = (
    <ol className="breadcrumb text-muted fs-6 fw-bold align-self-start">
      {breadcrumbs.map((item, index) => (
        <li key={index} className="breadcrumb-item pe-3">
          {index < breadcrumbs.length - 1 ? (
            <a href="#" className="pe-3 dca-breadcrumb-link" onClick={() => navigate(item.path)}>
              {item.title}
            </a>
          ) : (
            <span className="px-3 text-muted">{item.title}</span>
          )}
        </li>
      ))}
    </ol>
  );

  //''
  const base = (
    <>
      <span className="dca-overview-title">Advice For Navigating Admissions Process</span>

      <span className="dca-overview-font-standard" style={{ marginTop: '5vh', lineHeight: '1.75em' }}>In the prior sections, we outlined the core criteria for college admissions decisions and how the corresponding details are surfaced in a representative college application. Building on this baseline, in the following pages we share guidance to help you strategically navigate through high school, prepare for the college application process, and ultimately help increase your chances for gaining admission. This advice is based on data-driven insights and further augmented and validated with learnings from recent applicants who secured admission to the most selective U.S. college.</span>
    </>
  )

  //#identifying_core_application_theme
  const core = (
    <>
      <span className="dca-overview-title">Identifying Core Application Theme</span>

      <span className="dca-overview-font-standard" style={{ marginTop: '5vh', lineHeight: '1.75em' }}>Your college applications will consist of several foundational building blocks: high school coursework/grades, standardized test scores, extracurricular activities, essays, interviews, intended field(s) of study, etc. These are the key components that the admissions committees will be reviewing to learn about you, and ultimately determine if you will receive an offer of admission from each college. Though each of those components could theoretically be developed and presented independently from another, we believe the best approach is to align these elements around a core application theme (or personal narrative) that serves to bring together many of these components, and hence increase the coherence of your overall application.
      </span>

      <span className="dca-overview-subheading">Why is an application theme important?</span>

      <span className="dca-overview-paragraph">Developing and aligning on a central theme is critical for the following reasons:</span>

      <span className="dca-overview-ordered-item" style={{ marginTop: '40px' }}>1. <span className="fw-bold">Establishing a clear and memorable applicant identity with the admissions committee.</span> As a general rule of thumb, we assume the admissions officer(s) tasked with reading your application will have only ~10-15 minutes to read, digest, and assess your entire application. It seems unfair given the weeks/months you will spend writing and perfecting the application, not to mention the years of hard work across your high school career that underlies the application. However that is the likely reality, especially given the ever-increasing size of the applicant pool and the corresponding unwieldy workload created for the admissions teams. As such, we strongly believe that it is advantageous to have a clear theme unifying your application - inclusive of your academic interests, activities/accomplishments, values and perspectives - as this makes it significantly easier for the reader to comprehend your personal story and aspirations. Moreover, assuming the personal narrative is compelling, it can serve to distinguish your file and set you apart from the overall application pool, which is essential at the more selective institutions.
      </span>

      <span className="dca-overview-ordered-item" style={{ marginTop: '40px' }}>2. <span className="fw-bold">Establishing a prospective fit within the context of the incoming class and broader campus community.</span> We often hear about how admissions committees have an objective to build a “well rounded” (i.e. representing a wide set of interests and skills) with each incoming freshman class. However, this doesn't necessarily mean they are looking for a class full of well-rounded students. On the contrary, recent trends suggest that colleges are looking for “pointy” students that have specific areas of expertise and interests, that when mixed with students with a diversity of specific interests, together form a well-rounded cohort. The underlying premise is that “specialists” are more likely to be thought-leaders and change-makers that will bring vibrancy to the college community. With this mental model, we can see why having a clear application theme is important, it enables the admissions officers to more confidently envision how a student could fit into the incoming class and/or align with institutional needs and objectives.
      </span>

      <span className="dca-overview-ordered-item" style={{ marginTop: '40px' }}>3. <span className="fw-bold">Establishing a north star to enable decision making.</span> As you navigate through high school, you will naturally be making many decisions, such as which classes to take, which school clubs to join, how to spend your summer breaks, etc. Having an application theme in mind to help inform these decisions will be immensely helpful, as it will help ensure that each of these point decisions ladder up to a consistent, holistic narrative. For instance, as you enter junior year and are deciding to take on the challenge of either AP Physics or AP European History, it is ideal to think ahead to whether you are leaning towards STEM or Humanities fields for college. To be clear, it would be unhealthy and overkill to suggest that every choice be strictly governed by its alignment with your college application narrative. But there is definite value in having a framework that can help make principled decisions and to feel more confident in your choices.
      </span>

      <span className="dca-overview-subheading">How Can I Develop A Central Application Theme?</span>

      <span className="dca-overview-paragraph">Ultimately it requires some self-reflection but we offer the following advice to help you get started:</span>

      <ul className="dca-overview-font-standard" style={{ marginTop: '20px', lineHeight: '1.75em' }}>
        <li><span className="fw-bold">Think about your natural skills and interests.</span> There is no right or wrong answer, and so you should feel free to examine what you are genuinely excited about, especially as it will be easiest to excel in an area that you care about.</li>

        <li><span className="fw-bold">Consider your future major and career-related ambitions.</span> Anchoring your theme in what you are likely to focus on academically in college is a very prudent approach. Note this doesn't mean that you have to be 100% certain about what you want to do with the rest of your life while still a high schooler, but it is beneficial to have a preliminary sense that provides an initial direction for more learning and discovery.</li>

        <li><span className="fw-bold">Explore and iterate.</span> This doesn't have to be a decision made at a single point in time and forever locked. Rather, it should be a living and evolving decision that gets confirmed (or adapted) over time based on your ongoing experiences during high school. This mindset also suggests you can start broad and narrow down iteratively. For instance, are you drawn to STEM-centric fields or the humanities?  Are you more passionate about sports, performing arts or social justice? These are very broad categories that can serve as guideposts for deeper exploration. (Of course this iterative approach will benefit from starting early in high school as opposed to spring of junior year!)</li>
      </ul>

      <span className="dca-overview-subheading">How Does The Theme Surface In The Application?</span>

      <span className="dca-overview-paragraph">As you develop, solidify, and possibly evolve your application theme, it should provide the connectivity tissue to coalesce your application. Even if it is never explicitly stated, it should be “shown” throughout the application:</span>

      <ul className="dca-overview-font-standard" style={{ marginTop: '20px', lineHeight: '1.75em' }}>
        <li><span className="fw-bold">Academic Interests.</span> By electing to take Honors, AP, or generally more rigorous coursework in subject areas that correlate with your indicated areas of college study.</li>

        <li><span className="fw-bold">Extracurricular Profile.</span> Showing passion and extensive engagement related to your theme via several activities in your profile.</li>

        <li><span className="fw-bold">Recommendation Letters.</span> Soliciting recommendations from teachers representing the subject areas closest to your theme and perhaps with knowledge of your accomplishments/experience in related endeavors outside the classroom. Also, many colleges allow for at least one optional recommendation which you can use to have a non-teacher (i.e. coach, research mentor, manager, clergy member, etc.) share deeper insights on your unique focus area.</li>

        <li><span className="fw-bold">Essays.</span> The essays will challenge you to narrate not only your accomplishments, but more importantly, the “why” behind them and the learnings/reflections gained from them. Writing with your personal narrative in mind will strategically help you tie together your stories in a coordinated manner which amplifies your key qualities, accomplishments and what you will bring to enrich your college community.</li>
      </ul>

      <span className="dca-overview-paragraph">We recognize that proactively developing a core application profile is not easy. It is inherently uncomfortable and challenging for most students to be reflective, identify academic passions, and make decisions (even non-binding ones!) about future focus areas (i.e. majors, careers, etc.) However, we believe the upside is well worth the pain, both in terms of establishing guideposts to help navigate decisions through high school as well as creating a strong, cohesive application to colleges.</span>
    </>
  )

  //#selecting_intended_major(s)
  const majors = (
    <>
      <span className="dca-overview-title">Selecting Your Intended Major(s)</span>

      <span className="dca-overview-font-standard" style={{ marginTop: '5vh', lineHeight: '1.75em' }}>Given the importance of having a core application theme, we believe that selecting your intended field(s) of study is one of the most critical decisions for every high school student. This choice will be directly reflected on the college application, but just as importantly, it will ideally help influence and guide other decisions you need to make during high school, such as which classes to take and which extracurricular activities to pursue. As such, it is highly recommended to make this decision (or at least narrow it down to a short list of candidates) before senior year!</span>

      <span className="dca-overview-subheading">How should I decide my intended field(s) of study?</span>

      <span className="dca-overview-paragraph">Selecting an intended major while still in high school can be quite daunting, especially as there are literally hundreds of interesting (but not necessarily easily understood) options. Recognizing this inherent challenge, we suggest a few important considerations that may be helpful in narrowing down your choices:</span>

      <div className="dca-overview-paragraph"><span className='fw-bold'>High school coursework.</span>  If there were certain high school classes which you both loved and excelled in, you should consider majors in areas that are directly or approximately related. For instance, if math has consistently been your favorite subject, you may want to explore majors such as math, applied math, statistics, quantitative sciences, data science, etc.</div>

      <div className="dca-overview-paragraph"><span className='fw-bold'>Passions and interests.</span> Reflect on what you most enjoy doing and learning about, including outside the classroom and through your extracurricular activities, and then explore majors in related areas. For instance, if you love to travel and speak foreign languages, perhaps consider international business or studying about particular region of the world (i.e. Southeast Asian Studies.)  If your passion is arts, perhaps consider art history or graphic design. Pursuing a major that is aligned with topics you are already passionate about will likely be a strong fit.</div>

      <div className="dca-overview-paragraph"><span className='fw-bold'>Potential career interests.</span> If you have thought ahead to what type of career you may pursue, that can be a natural guide to inform what to study in college. Some career choices may require a more specialized education (such as computer engineering or nursing) in which case it is more important to align your major selection with that prospective career. However, typically your major will not limit you from employment opportunities; even highly sought after positions coming out of college, such as management consulting, will recruit from a wide range of college majors, including liberal arts.</div>

      <div className="dca-overview-paragraph"><span className='fw-bold'>Availability at preferred college(s).</span> Not every major is available at every college. For example, even some majors that are very popular at some schools (such as Business) are not offered at many of the most selective colleges, so if you already have a strong sense of which colleges you want to apply to, you should check out the list of majors available at those school(s) and confirm availability (or adjacent alternatives.)</div>

      <div className="dca-overview-paragraph"><span className='fw-bold'>Earnings potential.</span> A very practical consideration is the correlation between your major and future earnings. Of course your earnings are not directly based on your major, rather they will depend on the jobs you take after graduating and your performance in those jobs. And while no major guarantees you will secure a specific job, and the vast majority of jobs aren't strictly limited to students with a specific major, there are still general correlations between certain jobs and majors. For instance, having a STEM degree will likely be an advantage in pursuing a relatively high-paying engineering job.</div>

      <div className="card dca-overview-cta-container">
        <span className="dca-overview-cta-text">Search for college majors that match your interests and goals</span>
        <button
          className="dca-overview-cta-button"
          onClick={() => navigate(pageRoutes.majorSearch)}
        >
          Explore majors
        </button>
      </div>

      <span className="dca-overview-paragraph">As outlined above, there are a number of angles you can consider to help narrow down your intended fields of study. To further help in your consideration, we provide features to help evaluate your different options, including the <span className="dca-overview-navlink" onClick={() => navigate(pageRoutes.majorSearch)}>majors search engine</span> and the <span className="dca-overview-navlink" onClick={() => navigate(pageRoutes.advisorSearch)}>advisors network</span> to help connect with college students who are currently enrolled in majors you are considering.</span>

      <span className="dca-overview-paragraph">Keep in mind, you don't need to narrow it down to only a single major. In fact, many colleges provide space on the application to indicate your top 2-3 preferences, and we recommend you make selections for all the available preferences to highlight a broader range of interests, especially if your application theme relates to multiple majors. Also worth noting, especially as it may help reduce some anxiety: you are typically not locked in to the intended areas of study marked on the application. With some exceptions, which will be noted in the college's application materials, you will have the opportunity to confirm or change your intention after actually getting to college, typically by the end of your sophomore year.</span>

      <span className="dca-overview-subheading">Does my intended field of study impact my admissions consideration?</span>

      <span className="dca-overview-paragraph">Yes.</span>

      <span className="dca-overview-paragraph">The fact is that colleges request students to indicate their intended major(s) as part of their application, even though they are (most commonly) enrolling freshman as undeclared and allowing them to officially select their major after starting college. So why do they ask?  One of the jobs of the admission committee is to create an incoming class with diverse interests. In the extreme, they do not want a class made up exclusively of Computer Science students. More generally, they want to ensure some balance between STEM and humanities-centric students, and ensure all schools and majors offered by the college are appropriately represented.</span>

      <span className="dca-overview-paragraph">The implication of this dynamic is that it may be marginally harder to get accepted to a college when indicating interest in a very popular (i.e. over-subscribed) major vs a less popular (i.e. under-subscribed) major. As colleges do not typically publish data on admission statistics by intended major, there is an inherent lack of transparency that renders it difficult to quantify the impact for a given major at a given college. However, the University of California does publish admit rate by major for transfer applicants, which is reflected in the chart below illustrating the impact of major on admit rate:</span>

      <img
        src={admissionsAdvicePhoto1}
        alt="analysis_of_admit_rate"
        className='align-self-center'
        style={{
          width: '100%',
          objectFit: 'cover',
          objectPosition: '50% 50%',
          borderRadius: '10px',
          margin: '4vh 0vh'
        }}
      ></img>

      <span className="dca-overview-paragraph">The two key take-aways from this analysis are:</span>

      <span className="dca-overview-ordered-item" style={{ marginTop: '40px' }}>1. As the size of the major increases (represented by the number of current enrollees in the major), the admission rate generally decreases, as reflected by the downward sloping trendline.</span>

      <span className="dca-overview-ordered-item" style={{ marginTop: '40px' }}>2. In general, Humanities majors tend to be more admissible when compared to STEM and Social Sciences majors. In this particular analysis, 73% of the Humanities majors have an admit rate above the median, as compared to 35% for STEM and 36% for Social Sciences.</span>

      <span className="dca-overview-paragraph">There are some important caveats with this analysis: 1{')'} we assume consistency between the transfer and freshman admissions process at UC Berkeley; and 2{')'} the data is scoped to a single institution for a single year. Nonetheless, we believe the take-aways are conceptually sound as they are very consistent with both anecdotal evidence from many prior students' experiences and commentary from industry experts. As such, we recommend at least considering the relative popularity of intended major(s) as one element in your decision-making.</span>

      <span className="dca-overview-paragraph">Some majors are almost always amongst the largest at the top-ranked colleges (i.e. Computer Science, Economics, Biology, etc.) while other majors are consistently small (i.e. Classics, Anthropology, etc.) Many majors will exhibit varying popularity based on the school. For instance, Philosophy is in the highest decile of major size at University of Pennsylvania, but only in the third decile at Princeton. Similarly, Aerospace Engineering is the the top decile at University of Michigan, close to the median at UCLA and in the lower third at UC Berkeley. We recommend referencing our <span className="dca-overview-navlink" onClick={() => navigate(pageRoutes.majorSearch)}>Majors search feature</span> to help identify the relative size of majors at the colleges that offer them, and incorporate that information into your assessment.</span>

      <span className="dca-overview-paragraph">We recommend being strategic about your major selection and at least understanding the popularity index concept. But this should be a secondary consideration. The most important criteria (as outlined above) is to pursue major(s) that you are genuinely interested and passionate about, which will therefore enable you to excel and build a deep and distinctive application profile.</span>

      <span className="dca-overview-paragraph">One hybrid approach is to consider “adjacent” majors correlated with your fields of interest. For instance, if you're interested in <span onClick={() => { navigate(pageRoutes.collegeDetailWithId('economics')); }} className="dca-overview-navlink">Economics</span>, you may also wish to explore related, but less popular, majors such as <span onClick={() => { navigate(pageRoutes.collegeDetailWithId('economics-statistics')); }} className="dca-overview-navlink">Economics-Statistics</span> at Columbia; <span onClick={() => { navigate(pageRoutes.collegeDetailWithId('economics-and-history')); }} className="dca-overview-navlink">Economics and History</span> at Vanderbilt; or <span onClick={() => { navigate(pageRoutes.collegeDetailWithId('ethics-politics-and-economics')); }} className="dca-overview-navlink">Ethics, Politics, and Economics</span> at Yale. We recommend using the Field Of Study filter (with the accompanying "Also Show Majors In Related Fields" option enabled) in the <span className="dca-overview-navlink" onClick={() => navigate(pageRoutes.majorSearch)}>Majors metasearch</span> interface to help identify more options.</span>

    </>
  )

  //#crafting_an_extracurriculars_profile
  const ecProfile = (
    <>
      <span className="dca-overview-title">Crafting an Extracurriculars Profile</span>

      <span className="dca-overview-font-standard" style={{ marginTop: '5vh', lineHeight: '1.75em' }}>The activities profile will be a very important element of your college application. It provides an opportunity to reinforce your application theme and distinguish yourself from other competing applicants, especially those with similar academic profiles. These activities could provide a compelling signal of how you interact with the world around you and what you are likely to bring to the college community, which is important for admissions officers who are mindful of constructing an incoming class that will contribute to the vibrancy and diversity of their community.</span>

      <span className="dca-overview-paragraph">Analyzing the data (as published by most colleges in their Common Data Set report) reinforces the importance of extracurricular activities in the admissions decisioning process. 77% of the top 100-ranked colleges indicate extracurriculars are either and Important or Very Important aspect of their consideration:</span>

      <img
        src={admissionsAdvicePhoto2}
        alt="importance_of_ec_activities"
        className='align-self-center'
        style={{
          width: '100%',
          objectFit: 'cover',
          objectPosition: '50% 50%',
          borderRadius: '10px',
          margin: '4vh 0vh'
        }}
      ></img>

      <span className="dca-overview-paragraph">Also worth noting, 39% of these top-100 colleges place the same or greater importance on extracurricular activities as academic GPA, including 7 of the top 10 colleges (according to our metarank). And 81% place the same or greater importance on extracurricular activities as compared with standardized test scores. Clearly, from both a qualitative and quantitative perspective, your extracurricular activities are an integral component of your application and therefore you should plan accordingly.
      </span>

      <span className="dca-overview-subheading">What Are My Choices For Extracurricular Activities?</span>

      <span className="dca-overview-paragraph">The options for extracurricular activities are virtually limitless; some of the most popular categories with representative example are indicated below:</span>

      <ul className="dca-overview-paragraph">
        <li>School organizations: Clubs, student government, affinity groups, publications, etc.</li>
        <li>Athletics: JV/varsity school teams, club sports, individual competitions, etc.</li>
        <li>Work experience: Paid jobs, internships, family responsibilities, etc.</li>
        <li>Research: Summer research programs, individual capstone projects, research publications, etc.</li>
        <li>Community service: Volunteering, civic engagement, social justice initiatives, etc.</li>
        <li>Academic enrichment: Pre-college programs, academic competitions, speech/debate, etc.</li>
        <li>Visual/Performing Arts: Musical instruments, theater performances, art competitions, etc.</li>
      </ul>

      <div className="card dca-overview-cta-container">
        <span className="dca-overview-cta-text">Identify and achieve in extracurricular activities that are impactful and aligned with your application profile</span>
        <button
          className="dca-overview-cta-button"
          onClick={() => navigate(pageRoutes.ecSearch)}
        >Get inspired</button>
      </div>

      <span className="dca-overview-paragraph">The abundance of options is actually a double-edged sword. There will be lots of opportunities to find activities that fit with your personal passion, interests, and expertise. On the flip side, there is a danger of getting involved in too many activities, spreading yourself too thin, and potentially diluting their collective impact. Moreover, high school is already a busy life phase and you need to be mindful of guarding your time, preserving a focus on doing well in your academic course load, and of course getting sufficient sleep and ensuring your overall well being!</span>

      <span className="dca-overview-subheading">How Should I Prioritize Which Activities To Focus On?</span>

      <span className="dca-overview-paragraph">Therefore it is very important to prioritize and selectively engage in activities that are genuinely aligned with your interests and are likely to be additive to your college admissions candidacy. To achieve the latter, we recommend pursuing an EC activities strategy that optimizes for the following qualities:</span>

      <div className="dca-overview-ordered-item" style={{ marginTop: '40px' }}>1. <span className="fw-bold">Thematic alignment. </span>An important objective of your activities list is to strengthen your overall application narrative and make it easier for the admissions reader to quickly identify your central theme. This means that many (though not necessarily all) activities in your profile should have some commonality and consistency, as opposed to being entirely disconnected. And more specifically, the core activity theme should be generally correlated with your intended field(s) of study in college. Students who have already demonstrated a deep level of engagement and passion connected to the major(s) indicated in their application are more likely to be well-received by admissions committees. For instance, if a prospective history major has interned at a local museum, completed independent research leading to publication in a history journal, competed in National History Day, and founded the history club at their high school, the student has shown a clear commitment to their academic focus area, and reinforced an application narrative that will be easy for admissions committees to identify and assess for fit at their respective institutions.</div>

      <div className="dca-overview-ordered-item" style={{ marginTop: '40px' }}>2. <span className="fw-bold">Leadership and Initiative </span>Colleges aspire to build communities of leaders, and therefore it is beneficial to demonstrate your leadership experience through your extracurriculars during high school. Leadership can take many forms, from participating in student government to holding a position of responsibility in a community organization to being captain for your sports team. Leadership can also be exhibited by taking initiative to start and/or scale something important to you, such as a school club, computer application, or non-profit organization. </div>

      <div className="dca-overview-ordered-item" style={{ marginTop: '40px' }}>3. <span className="fw-bold">Impact. </span>Your time in high school is a scarce resource and so you need to be particularly mindful of making the most of any time you invest in your extracurricular activities. An important question to ask yourself in this regard is how are you making an impact. Are you making your community a better place? Are you creating new knowledge that helps to further a particular field? Are you building skills and experiences that will serve you well in college and beyond? To strengthen your college application, it will be important to highlight not just what your activities were, but why they matter and what your personal impact and accomplishments were.</div>

      <div className="dca-overview-ordered-item" style={{ marginTop: '40px' }}>4. <span className="fw-bold">Differentiation. </span> Keeping in mind that the most selective colleges are receiving a myriad of applications for a precious few slots, there is an urgent need to create differentiation with your application to increase the probability of standing out from the pool. We believe your extracurricular profile is one of the best opportunities to do so. To maximize this opportunity, be strategic so you may be able to distinguish yourself from other high schoolers. For instance, joining a few clubs at school is a relatively easy way to highlight some interest areas. However, that ease of accessibility also will make it very common from other high schoolers. Contrast that with an example on the other end of the spectrum, such as winning an intentionally-recognized competition. That is, of course, much harder to attain, but it will also probably be “worth” an order of magnitude more in terms of demonstrated interest and excellence in the field related to that competition. More generally, we recommend considering a mix of school-based activities, activities through organizations outside of school, and independent activities to create a bespoke and compelling extracurricular profile.</div>

      <div className="dca-overview-ordered-item" style={{ marginTop: '40px' }}>5. <span className="fw-bold">Consistency. </span>It is more important to focus on the depth and quality of engagement in your activities, versus just the sheer quantity. Therefore, it is helpful to pursue activities that you are passionate about for longer durations, as opposed to churning through a different set of activities each quarter. By doing so, it will be easier to go deeper, show progression of responsibility, and make an impact. This is not to imply that every activity you try needs to be a four-year commitment. On the contrary, you should feel free to experiment with various activities and interests, and use that exploration as the best means to discover what you're most excited about. That information will then help you determine which activities to drop and which activities to further invest in.</div>

      <div className="dca-overview-ordered-item" style={{ marginTop: '40px' }}>6. <span className="fw-bold">Opportunity for learning, personal development and reflection. </span>The most important benefit of your extracurricular activities is to help you grow as an individual and prepare you for college life and beyond. These activities will likely help you develop soft skills such as time management, team leadership, project management, and multi-tasking. They should concurrently help you discover (or confirm) your interests, what you want to study in college, and perhaps even the career you want to pursue. Diving into activities that challenge you, push you to learn, and navigate new experiences will be personally rewarding and therefore important irrespective of any bearing on the college application process. And as a bonus, those types of rich experiences, which provide opportunity for self-reflection and growth, will be very valuable to draw upon when writing your personal statement and supplemental essays for college applications. Not every essay will revolve around your extracurriculars, and not every activity in your profile will necessarily feature in an essay, but most successful applicants will incorporate multiple activities into their essays as they provide a natural way to help the admission committee to learn more about yourself as an individual outside the classroom.</div>

      <span className="dca-overview-paragraph">Considering the six objectives outlined above, coupled with the endless array of choices, we recommend you start thinking about your extracurricular activities from the start of high school. By doing so, you give yourself plenty of time to explore a broad set of interest areas before narrowing down on a smaller subset where you can focus on achieving impact, demonstrating leadership, etc. Also, we recommend being thoughtful of using your school breaks (especially the summer breaks) to make productive investments in your interest areas while you likely have more available time and flexibility as compared to during the school year. We recommend referencing our <span className="dca-overview-navlink" onClick={() => navigate(pageRoutes.ecSearch)}>extracurriculars search interface</span> to identify and explore activities of potential interest. You may also wish to learn more about specific activities by <span className="dca-overview-navlink" onClick={() => navigate(pageRoutes.advisorSearch)}>speaking with past participants</span> to hear about their recent, first-hand experiences with that activity.</span>

      <span className="dca-overview-subheading">How Should I Capture My Activities In The College Application?</span>

      <span className="dca-overview-paragraph">As you draw closer to senior year and start writing your college applications, you will need to capture your extracurriculars profile into a structured list of activities and awards within the Common Application (and/or other application formats.)  Here are some best practices to keep in mind:</span>

      <ul className="dca-overview-font-standard" style={{ marginTop: '20px', lineHeight: '1.75em' }}>
        <li>Quality over quantity! Only list activities which have been meaningful for you and where you've made significant efforts for the duration you were involved. Facing a choice, it is better to leave spots blank than fill with “weak” filler content that may dilute from your more compelling activities (remembering that admissions officers may only be spending a few minutes on your overall application.) But important to note that the very strongest applicants will optimize on both the quality and quantity dimensions.</li>
        <li>List the activities in order of importance, reflecting both your time investment as well as alignment with your application theme/intended fields of study.</li>
        <li>Focus on your personal impact and accomplishments for each line item, not just on what the organization does. Be sure to highlight leadership roles, achievements, significant contributions, etc.</li>
        <li>Be as quantitative and specific as possible. The application will typically ask you to quantify time commitment (in terms of hours per week and weeks per year) but you should also try to quantify details about number of people involved/impacted, milestones met, indicators of scale, etc.</li>
        <li>Word allocations are very limited, for instance the Common App allows only 150 characters for the Activity Description. One helpful strategy is to first maximize the information you can include in the Position/Leadership Description and Organization Name fields to preserve more capacity in the Activity Description field. Be concise by avoiding repetition, filtering out  unnecessary/filler words, and using abbreviations when obvious/easily recognized. Maintain correct grammar, but complete sentences are not necessary.</li>
        <li>If your target college list spans multiple applications, start by writing the activity list with the most capacity and then iterate/refine to create alternative formats for more restrictive applications. A common example would be to write the activity list for the University of California application (which allows for 20 entries and longer descriptions) first and then use that baseline to create a scoped-down list to fit the Common App format (with 10 activities and 5 honors/awards.)</li>
      </ul>
    </>
  )

  //#determining_a_standardized_testing_strategy
  const returnTestScoreStrategyCTAButton = (apiToken) => {
    if (apiToken != undefined) {
      return (
        <button
          className="dca-overview-cta-button"
          onClick={() => navigate(pageRoutes.testScoreStrategy)}
        >Submit my score or not?</button>
      )
    }

    else {
      return (
        <a
          className="dca-overview-cta-button"
          data-bs-toggle="modal"
          href="#login_modal"
          onClick={() => {
            setTestScoreStrategyModal(apiToken, navigate, setPrompt, setNavigateTo);
            handleGAEventSignInModalOpen('forced')
          }}
          style={{
            color: 'rgb(00, 00, 00)'
          }}
        >Submit my score or not?</a>

      )
    }
  }

  const testStrategy = (
    <>
      <span className="dca-overview-title">Determining a Strategy for SAT/ACT Standardized Testing</span>

      <span className="dca-overview-font-standard" style={{ marginTop: '5vh', lineHeight: '1.75em' }}>Though the SAT and ACT tests have been an integral component of the college application process for decades now (with the SAT nearing its 100th birthday!) the last few years have seen a significant change to the standardized testing landscape. Catalyzed by the Covid pandemic, coupled with ongoing challenges to the equitability of these tests, many colleges have adopted test-optional, or even, test-blind policies. In the current landscape, there are three primary questions that high schoolers should now be considering:
      </span>

      <div className="dca-overview-ordered-item" style={{ marginTop: '40px' }}>1. Should I be taking a standardized test for my college application?</div>
      <div className="dca-overview-ordered-item">2. If I take a test, should I choose the SAT or ACT?
      </div>
      <div className="dca-overview-ordered-item">3. Should I submit my scores to test-optional colleges?</div>

      <span className="dca-overview-paragraph">We tackle these important questions in turn below...</span>

      <span className="dca-overview-subheading">Should I take a standardized test for my college application?</span>

      <span className="dca-overview-paragraph">Our general recommendation is yes, it is prudent to plan on taking the SAT and/or ACT as part of your preparations for the college application process.</span>

      <span className="dca-overview-paragraph">One benefit of taking the test is preserving the option to apply for schools where test scores are required. This is applicable for a minority of the top schools, with currently ~15% of the 100 top-ranked colleges featured on our site currently requiring test scores. However, this group does include popular institutions such as MIT and Georgetown, and more recently, several Ivy League schools (Harvard, Yale, Dartmouth, and Brown) announced they are reverting to a test-required policy for the 2024-2025 admissions cycle (applicable for students applying to start college in Fall of 2025). Additional selective schools (i.e. Stanford, Cornell, etc.) have already announced a move back to test-required for the following year (applicable to students starting college in Fall of 2026,) further highlighting a growing trend towards requiring standardized testing in the college admissions process.</span>

      <span className="dca-overview-paragraph">More broadly, we believe a strong test score is likely to help strengthen your overall application, even for test-optional colleges. While test-optional colleges state that opting to not submit a test score will not disadvantage an applicant, there are also several signals that suggest the continued importance of test scores as part of their holistic review.</span>

      <span className="dca-overview-paragraph">For instance, Harvard states on their admissions website: “SAT and ACT tests are better predictors of Harvard grades than high school grades, but this can vary greatly for any individual.” And University of Chicago notes, “These tests can provide valuable information about a student which we and other colleges will consider alongside the other elements in a student's application. We encourage students to take standardized tests, like the SAT and ACT, and to share your scores with us if you think that they are reflective of your ability and potential.”</span>

      <span className="dca-overview-paragraph">In addition, our data analysis shows that ~40% of the top 100 colleges indicate that standardized testing is either an Important or Very Important consideration in their admissions decisions.</span>

      <img
        src={admissionsAdvicePhoto9}
        alt="importance_of_standardized_tests_in_admissions"
        className='align-self-center'
        style={{
          width: '100%',
          objectFit: 'cover',
          objectPosition: '50% 50%',
          borderRadius: '10px',
          margin: '4vh 0vh'
        }}
      ></img>

      <span className="dca-overview-paragraph">Moreover, while the percentage of students submitting test scores has certainly decreased with the proliferation of test-optional policies, the majority of students enrolling at the top colleges are still choosing to submit test scores. For instance, based on the 2022-2023 data for the Ivy Leagues schools (when all Ivy League schools were still test-optional), we note that on average across those 8 schools, 51% of enrollees submitted an SAT score and 24% submitted an ACT score, indicating that up to 76% of enrolled students submitted standardized test scores.</span>

      <span className="dca-overview-paragraph">It is important to remember that you can safely separate the decisions of whether to take a test and whether to submit a test score to a college. If you take a test and are unhappy with your test score, you can always choose to apply without submitting your score. Another secondary benefit to having a score (even if you choose not to use it) is it provides a helpful data-point that will help you determine if colleges on your target list are safety, target, or reach schools.</span>

      <span className="dca-overview-paragraph">For the reasons outlined above, we recommend taking a standardized test to gain more optionality for the application process, unless you are definitively only planning to apply to test-blind colleges (such as the University of California system.)  That being said, if you choose to not take a test for logistics, expense, or any other personal reason, rest assured that you will still be able to apply to most colleges thanks to the recent shift towards a more prevalent test-optional policy.</span>

      <span className="dca-overview-subheading">Should I take the SAT or ACT?</span>

      <span className="dca-overview-paragraph">The two most popular standardized tests for undergraduate college admissions are the Scholastic Aptitude Test (SAT) and American College Testing (ACT). Nearly all colleges that consider test scores will accept both of these nationally recognized tests, and they do not have a stated preference for one over the other. Therefore the decision of which test to take is truly an individual decision.</span>

      <span className="dca-overview-paragraph">The primary decision factor should be which test you can achieve the best score. Both tests are generally similar in nature and cover the same topics, with a primary focus on reading, writing and skills. A few differences between the tests include:</span>

      <ul className="dca-overview-paragraph">
        <li>ACT includes a dedicated Science Reasoning section to test for critical thinking skills</li>
        <li>SAT math is primarily focused on algebra and data analysis, while ACT has a higher relative focus on geometry and trigonometry </li>
        <li>SAT includes one Math section (out of two) for which you may not use a calculator</li>
        <li>Math accounts for ~50% of the total SAT score, as compared to ~25% of the total ACT score</li>
        <li>Relatively speaking the SAT provides more time per question than the ACT so the ACT may feel like a quicker pace</li>
        <li>ACT offers an optional essay</li>
        <li>SAT is moving to a digital format (as of March 2024) while ACT remains a paper test for the time being
        </li>
        <li>There is greater score granularity with the SAT (scored on a 1600 point scale) as compared to the ACT (scored on a 36 point scale)</li>
      </ul>

      <span className="dca-overview-paragraph">You may consider these factors to help determine which test to prioritize, but our general recommendation would be to explore both tests to determine where you can score higher. You can take full-length practice tests, or even opt to take an official test, to establish a comparative baseline for both tests and use that information to help determine which test will yield the higher relative score.</span>

      <span className="dca-overview-paragraph">A secondary consideration for test choice may be to consider the relative popularity of the tests at your target school(s). While all colleges will accept both scores, and the SAT is generally more commonly submitted across the top-ranked institutions, there is a variance by school. For instance, at Tulane, the ACT is more than 2x submitted as compared to SAT (34% vs 16%). At Duke, the submission rates are almost identical (~46-47%), while at Cornell, the SAT is 2.5x more common than the ACT (43% vs 17%).</span>

      <span className="dca-overview-subheading">Should I submit my scores to test-optional colleges?</span>

      <span className="dca-overview-paragraph">Excluding the minority of colleges that are either test-required or test-blind, you will need to determine whether or not to submit your test score(s) as part of your application to any test-optional colleges. Conceptually, our recommendation boils down to submitting scores to colleges if and when they are likely to add strength to your overall application.</span>

      <div className="card dca-overview-cta-container">
        <span className="dca-overview-cta-text">Get personalized advice for navigating the evolving test-optional landscape</span>
        {returnTestScoreStrategyCTAButton(apiToken)}
      </div>

      <span className="dca-overview-paragraph">To make this determination, you should consider your test score(s) against the score benchmarks published by the colleges, where available, and submit scores with your application if they are comparatively strong. One important consideration is that many of the top-ranked schools have shown an upward tick in standardized test score benchmarks in recent years, in large part due to the shift to test-optional. As test scores are not strictly required, students with (relatively) lower scores may opt to not submit, while (relatively) higher scorers continue to submit, leading to higher overall benchmarks for recently matriculated students. Consider the graph below with data for five colleges:</span>

      <img
        src={admissionsAdvicePhoto10}
        alt="sat_submission_rates"
        className='align-self-center'
        style={{
          width: '100%',
          objectFit: 'cover',
          objectPosition: '50% 50%',
          margin: '4vh 0vh'
        }}
      ></img>

      <span className="dca-overview-paragraph">This chart highlights a few key trends. The figures in magenta above the chart indicate how the percentage of students submitting SAT scores has dropped between 2018 and 2022, primarily due to the advent of test-optional policies. (ACT submission rates are not shown here but are directionally similar.) Concurrent with the decrease in submission rates, the scores for those students submitting has significantly increased. The largest increase is at the lower range, represented by the 25% percentile data-set, where the composite 25th percentile score (estimated as the sum of the 25th percentile scores from each section) increased by 80+ points for this representative sample of top colleges. There was more moderate growth at the top end of the score range (i.e. 75th percentile) as the higher scores are more likely to continue submitting scores and there is less room for score growth.</span>

      <span className="dca-overview-paragraph">Given this dynamic, it is important to compare your score(s) to the most recent score benchmarks and other college-specific indicators (i.e. consideration level for test scores in admissions criteria, percentage of enrolled students submitting scores, etc.) in order to make an informed decision on whether to submit your best SAT and/or ACT scores. We recommend referencing our {apiToken ? <span onClick={() => navigate(pageRoutes.testScoreStrategy)} className="dca-overview-navlink">test strategy feature</span> :
        <a
          className="dca-overview-navlink"
          data-bs-toggle="modal"
          href="#login_modal"
          onClick={() => {
            setTestScoreStrategyModal(apiToken, navigate, setPrompt, setNavigateTo);
            handleGAEventSignInModalOpen('forced')
          }}
        >test strategy feature</a>} to get personalized recommendations for your individual situation.</span>
    </>
  )

  //#maximizing_impact_from_letters_of_recommendation
  const lettersOfRec = (
    <>
      <span className="dca-overview-title">Maximizing Impact From Letters Of Recommendation</span>

      <span className="dca-overview-font-standard" style={{ marginTop: '5vh', lineHeight: '1.75em' }}>Most of the top-ranked colleges will require multiple letters of recommendation as part of their application requirements. These letters are an integral aspect of your application as they provide the opportunity to select recommenders to help the admissions committee better understand you as a person by writing letters on your behalf. Think of these recommendation letters as providing “color commentary” to complement other aspects of your application, convey additional context and insights into your individual character, capabilities, and other intangibles that may not be otherwise communicated in your application. These letters can be vital for helping admissions readers distinguish between students with otherwise similar academic profiles by providing a lens into their classroom presence, curiosity, learning traits, etc.</span>

      <span className="dca-overview-subheading">Recommendation Types</span>

      <span className="dca-overview-paragraph">There are generally three different categories of recommendations:</span>

      <div className="dca-overview-ordered-item" style={{ marginTop: '40px' }}>1. Teachers - These are the most important recommendations as your teachers would be best positioned to discuss your academic characteristics in depth. The number of teacher recommendations required will vary by school (typically 1-3) but for general planning purposes (ahead of finalizing your college list) you should plan to have at least two strong recommenders.</div>
      <div className="dca-overview-ordered-item" style={{ marginTop: '40px' }}>2. Counselor - Most colleges also require a recommendation from the school counselor, who also will be asked to typically provide supplemental information (i.e. grade report, school profile report, etc.) The depth of relationship between students and counselors varies widely by high school, so while it is advantageous to build a solid relationship with your counselor, it won't be necessarily expected if that is not the norm at your school.</div>
      <div className="dca-overview-ordered-item" style={{ marginTop: '40px' }}>3. Non-school - Some colleges provide applicants the option to select “other” recommenders in addition to your school-centric teacher/counselor recommendations. Common choices include athletic coaches, music/art instructors, research mentors, internship supervisors, employers, clergy staff, etc. These are always optional and the number of recommenders allowed varies by college, but our general recommendation is to plan for at least one strong non-school recommendation, ideally one that is aligned with your overall application theme and a major extracurricular activity.</div>

      <span className="dca-overview-subheading">Deciding Which Teachers To Ask For Recommendation</span>

      <span className="dca-overview-paragraph">Our baseline guidance is to request recommendations from one math/science 11th grade teacher and one history/english/language 11th grade teacher. Junior year teachers are preferable because they will have taught you for the most recent full academic year prior to submitting your applications, and also junior year coursework typically will be more rigorous as compared to earlier years. Selecting teachers across STEM and humanities subjects can help to provide a balanced perspective on academic breadth.</span>

      <span className="dca-overview-paragraph">Building on this baseline, we suggest these key considerations for deciding on the specific teachers to ask:</span>

      <ul className="dca-overview-paragraph">
        <li>In which classes, did you perform your absolute best? This naturally will be centered on your academic performance/grade, but it's important to be broader in your consideration. For instance, think about where you had the most classroom participation, followed your curiosity, demonstrated initiative, and/or encountered challenges that you were able to overcome.</li>
        <li>Which classes are most closely aligned with your intended major(s)?</li>
        <li>Which teacher(s) do you have the strongest personal connection with and know you the best?</li>
        <li>Do you have shared context with any teacher(s) beyond the classroom - i.e. independent project mentorship, involvement with a club or other extracurricular activity, etc. - providing an opportunity for them to have more interactions with you?</li>
        <li>Did you have any teacher(s) for multiple classes which allows for the teacher to comment on your interactions across different courses and perhaps comment on your academic growth in the interim?</li>
        <li>Which pair of teachers are most complementary because their recommendations would likely focus on disparate aspects of your academic and personal profile?</li>
      </ul>

      <span className="dca-overview-subheading">What Actually Will Go Into the Teacher Recommendations</span>

      <span className="dca-overview-paragraph">The specific details for what is asked of the teacher in the recommendation form may vary by college. But referring again to the Common App as a representative example, there are three core sections of the Teacher Evaluation form:</span>

      <div className="dca-overview-ordered-item" style={{ marginTop: '40px' }}>1. Background: To indicate the context for how the teacher knows the student, including subject, grade(s), and course(s) taught.</div>
      <div className="dca-overview-ordered-item" style={{ marginTop: '40px' }}>2. Free-form comments: For the teacher to provide a description of the student's academic and personal characteristics from the classroom.</div>
      <div className="dca-overview-ordered-item" style={{ marginTop: '40px' }}>3. Ratings: For the teacher to provide relative assessment across a number of dimensions, see below for a representative example from the 2023-2024 application cycle:</div>

      <img
        src={admissionsAdvicePhoto11}
        alt="teacher_recommendation_form"
        className='align-self-center'
        style={{
          width: '100%',
          objectFit: 'cover',
          objectPosition: '50% 50%',
          borderRadius: '10px',
          margin: '4vh 0vh'
        }}
      ></img>

      <span className="dca-overview-subheading">How To Get The Best Recommendations</span>

      <span className="dca-overview-paragraph">It will require significant time and energy from your teachers who agree to provide you with a letter of recommendation, especially considering that there will most likely be other classmates asking for recommendations as well. Therefore it is important that you proactively support your teachers to help make their job easier, and also to increase the strength of your recommendation.</span>

      <span className="dca-overview-paragraph">Tactically, be sure to ask nicely, ideally in person. We recommend making the request as early as possible. Before the end of junior year is best, with one month before the deadline being the absolute minimum. (Be sure to follow any specific rules/processes at your high school, if applicable.)</span>

      <span className="dca-overview-paragraph">Most importantly, even though you are asking teachers who ostensibly know you well, you still need to provide teachers with written information as an input before they start writing your recommendation. At some high schools, your teacher and/or guidance counselor will provide questionnaires for you to fill out, in which case be sure to respond comprehensively and punctually. If there is no organized process, you should just create your own document of information to provide your recommenders.</span>

      <span className="dca-overview-paragraph">Independent of the specific form factor, you should try to provide all of the following information to your teachers:</span>

      <ul className="dca-overview-paragraph">
        <li>Details from the class you took with the teacher
          <ul>
            <li>Summary recap of key achievements in the class, including papers, projects, discussions, tests, etc.
            </li>
            <li>A specific paper or project from the class as a representative example of your best work
            </li>
            <li>Instances of class participation, demonstrated leadership, etc.</li>
            <li>Takeaways on key learnings from the class and your reflection on those learnings</li>
          </ul>
        </li>
        <li>Broader personal context
          <ul>
            <li>Overview of your broader profile (i.e. interests, activities, and achievements) beyond the classroom context</li>
            <li>Information about what you're planning to study in college</li>
          </ul>
        </li>
      </ul>

      <span className="dca-overview-paragraph">Yes, it will take considerable time and energy to do a great job in preparing the information above (and it's highly recommended to complete it prior to the start of senior year.) But we believe it will be worth it to help your recommender write the strongest possible letter of recommendation. By providing detailed reminders of your experience in their class, it will help jog the teacher's memory about your distinct experience in the class, and make it easier for the teacher to bring those (or other) specifics into their letter. Moreover, by guiding the teacher towards a recommendation that supports the overall theme of your application, it will amplify and strengthen the overall narrative. </span>
    </>
  )

  //#creating_a_college_interest_list
  const collegeInterest = (
    <>
      <span className="dca-overview-title">Creating a College Interest List</span>

      <span className="dca-overview-font-standard" style={{ marginTop: '5vh', lineHeight: '1.75em' }}>We recommend starting to deliberately think about your prospective college list by the middle of high school and have a goal of finalizing it by the summer before senior year. Whether you've long had a specific dream school in mind, or are starting with a blank slate, it's important to thoughtfully research and consider your options with the objective of building a balanced college list. Fortunately, there are many ways to learn more about colleges - ranging from online resources to in-person/virtual college tours to <span className="dca-overview-navlink" onClick={() => navigate(pageRoutes.advisorSearch)}>speaking with current students/recent alumni</span>. In the course of your research, we recommend focusing on the following questions:</span>

      <span className="dca-overview-ordered-item" style={{ marginTop: '40px' }}>1. What do I want from college academically and in preparation for life beyond college?</span>

      <span className="dca-overview-ordered-item" style={{ marginTop: '40px' }}>2. What are my most important non-academic criteria?</span>

      <span className="dca-overview-ordered-item" style={{ marginTop: '40px' }}>3. What is my likelihood of gaining admissions to the colleges on my list?</span>

      <span className="dca-overview-paragraph">We dive into each of these important dimensions below…</span>

      <div className="card dca-overview-cta-container">
        <span className="dca-overview-cta-text">Deep dive on the most selective U.S. colleges to identify the right schools for your profile and interests</span>
        <button
          className="dca-overview-cta-button"
          onClick={() => navigate(pageRoutes.collegeSearch)}
        >Dive into college data</button>
      </div>

      <span className="dca-overview-subheading">What do I want from college academically and in preparation for life beyond college?</span>

      <span className="dca-overview-paragraph">This should be an important element of your evaluation, especially as the academic experience is at the core of your college journey. Consider the following questions as you're exploring colleges:</span>

      <ul className="dca-overview-paragraph">
        <li>Which colleges offer my intended major(s)?</li>
        <li>Are there colleges that have especially renowned departments and/or faculty in my fields of interest?</li>
        <li>What is the college's academic reputation?</li>
        <li>Will I be able to pursue double majors, minors, certificates, etc. if I want more academic breadth?</li>
        <li>What kind of learning environment will I thrive in, considering data-points such as average class size and student:faculty ratio?</li>
        <li>Is there a strong research emphasis with potential opportunities for undergraduates to get involved?</li>
        <li>Is the college's location a positive (or negative) in terms of proximity to internship or other co-curricular opportunities of potential interest?</li>
        <li>Will a college help me achieve my goals upon graduation, considering factors such as career advisory services, job placement rates, average salary, graduate school matriculation, etc?</li>
      </ul>

      <span className="dca-overview-paragraph">Thinking through these questions (among others) should help you determine prospective colleges that would be well-aligned from an academic perspective.</span>

      <span className="dca-overview-subheading">What are my most important non-academic criteria?</span>

      <span className="dca-overview-paragraph">While the core of college is a learning experience, it will also be your new home for ~4 years and so it's equally important to assess fit from a broader life perspective. Consider the following questions as you're learning about schools:</span>

      <ul className="dca-overview-paragraph">
        <li>Location. Do you prefer to stay close to home, or are you open to a new experience across the country? Does weather matter or are you ready to take on Chicago winters?</li>
        <li>Setting. Do you want to live in the middle of a big city, or do you prefer a smaller-town or suburban vibe?</li>
        <li>School Size. Do you prefer a big school (often with accompanying breadth of resources and large alumni networks) or smaller schools that may provide a more individualized experience?</li>
        <li>Athletics. How important are D1 sports and robust school spirit? Are there options for non-varsity athletes to continue playing competitively in college, perhaps through club sports or intramurals?</li>
        <li>Extracurriculars. Are there specific activities that you want to continue in college, or want to start exploring?</li>
        <li>Cost. What are the cost and affordability parameters for your family? Refer to each school's financial aid policy and net price calculator to help assess financial compatibility.</li>
        <li>Residential Options. Do you want to live on-campus for four years? How much of the college experience is centered around the residences?</li>
      </ul>

      <span className="dca-overview-paragraph">Considering dimensions such as these will help you identify prospective colleges that would provide a student life experience compatible to your preferences.</span>

      <span className="dca-overview-subheading">What is my likelihood of gaining admissions to the colleges on my interest list?</span>

      <span className="dca-overview-paragraph">As you explore colleges and use the aforementioned criteria (or whichever factors are most important to you) to help narrow down the list, it is important to also consider the likelihood of admission. This will help you curate a balanced college list that should include a healthy mix of Safety, Target, and Reach schools. There is no universal definition for these commonly used categories, but generally speaking, we think of Safety schools (or more appropriately, “likely” schools) as colleges where you are highly confident ({'>'}75%) of receiving admission. On the other end of the spectrum, Reach schools are those where you estimate having {'<'}20% chance of getting in, and Target schools are everything in between. The ideal college list would be generally evenly balanced across these three categories, and with each college (including the Safeties!) being a good fit with your key considerations and somewhere that you would be genuinely excited to attend.</span>

      <span className="dca-overview-paragraph">We recommend taking a data-driven approach to assessing the likelihood of admission for your individual profile. A good starting point would be to look at the <span className="dca-overview-navlink" onClick={() => navigate(pageRoutes.collegeSearch)}>school's overall admission rate</span> in recent years to get a baseline calibration on the likelihood of admission for the average applicant.</span>

      <span className="dca-overview-paragraph">Naturally your likelihood of admission is heavily dependent on the strength of your individual application. We recommend comparing a few quantitative aspects of your profile against benchmarks published by the college to improve your understanding of how your profile compares with the statistics of previously enrolled students, as a proxy for what the admissions committee will be looking for when you apply.</span>

      <span className="dca-overview-paragraph">A good starting point is to consider your GPA and standardized test scores (SAT or ACT). Use our <span className="dca-overview-navlink" onClick={() => navigate(pageRoutes.collegeDataVisualization)}>college data visualization feature</span> to get an intuitive sense for the average metrics of recently enrolled students at each college.</span>

      <img
        src={admissionsAdvicePhoto3}
        alt="college_data_visualization_1"
        className='align-self-center'
        style={{
          width: '100%',
          objectFit: 'cover',
          objectPosition: '50% 50%',
          borderRadius: '10px',
          margin: '4vh 0vh'
        }}
      ></img>

      <span className="dca-overview-paragraph">Conceptually, if a college marker is in close proximity to where your individual GPA/test score data would land, that is a good preliminary indication it could be considered a Target school. If your statistics land further up/right relative to a college, then that college could be a Likely school. And if your data-point is further down/left relative to a college, then that college may be a Reach school. Note that the most selective schools, with admission rates (indicated in our visualization by the size of the bubble) less than 10%-15% should be considered Reaches for all students.</span>

      <span className="dca-overview-paragraph">Practically speaking, comparing your GPA with college benchmarks is an imprecise science. There is a frustrating lack of consistency (and transparency) in how colleges report GPAs, ranging from unweighted, weighted, or a mix of both. Moreover, in the case of weighted GPAs, colleges may have their own individual methodology for computing weighted GPAs which could possibly differ from how your high school calculates weighted GPAs. As such, the best approach is to compare unweighted GPAs, shown in green markers on our visualization interface when available, as that averts the potential complexity of disparate weighting methodologies. But the trade off is that an unweighted GPA will not reflect course load rigor (i.e. honors and AP classes, etc.)  Despite these speed bumps, it's still worth reviewing GPA benchmarks, especially given that your high school academic record is typically the single most important admission consideration for top-ranked colleges.</span>


      <span className="dca-overview-paragraph">In addition, we also recommend reviewing class rank benchmarks. Our <span className="dca-overview-navlink" onClick={() => navigate(pageRoutes.collegeDataVisualization)}>college data visualization interface</span> illustrates the percentage of enrolled students that were in the top 10% of their high school graduating class:</span>

      <img
        src={admissionsAdvicePhoto4}
        alt="college_data_visualization_2"
        className='align-self-center'
        style={{
          width: '100%',
          objectFit: 'cover',
          objectPosition: '50% 50%',
          borderRadius: '10px',
          margin: '4vh 0vh'
        }}
      ></img>

      <span className="dca-overview-paragraph">This can be a very instructive metric, as we believe the higher the percentage of previously enrolled students in the top decile of class rank, the higher the expectation for academic achievement (GPA) from future applicants. And therefore this information can help you calibrate against the strength of your academic profile and class rank. Note, if your high school doesn't publish a formal class rank, we recommend to ask your counselor for at least a directional indication (i.e. top 20%) as it is important to have a sense of where you stack up within your class as you could be compared against classmates who may be applying to the same colleges.</span>

      <span className="dca-overview-paragraph">An additional way to gauge your potential for admission at schools is to learn about the experience from applicants from your high school in the prior ~3 years. Fortunately, high schools are increasingly partnering with software platforms (i.e. Naviance, Ciaflo, Scoir, etc.) to provide current students with high-fidelity data on prior years' students. Typically the data is surfaced in a “scattergram” format which illustrates academic profiles (i.e. GPA and test scores) and outcomes (i.e. accepted, deferred, declined) for recents applicants to a specific college. Assuming there is sufficient data for the colleges on your interest list, you can see how your academic profile compares with prior students from your high school, and because these are all students from the same school (with the same class offerings and GPA computation methodology) it enables a solid apples-to-apples comparison. The outcomes for students that are closely clustered near your own profile will help you assess your likelihood for admission by college and its corresponding Reach/Target/Safety categorization. Even if one of these software platforms are unavailable at your high school, you should still pursue similar insights by speaking with your counselor and/or other students.</span>

      <span className="dca-overview-paragraph">Considering these quantitative benchmarks will provide an important baseline for classifying the colleges on your interest list. However, keep in mind that there will be many other factors that the admission committee will consider and those will certainly further impact your likelihood of admission. For instance, if you have a very strong narrative and supporting extracurricular profile, it will likely help strengthen your candidacy relative to other students with the same academic profile (i.e. GPA, class rank, test scores, etc.)  And the converse would apply as well, so we recommend to think holistically and critically about your profile to build a robust and balanced college list.</span>
    </>
  )

  //#applying_early_vs_regular
  const earlyVsReg = (
    <>
      <span className="dca-overview-title">Should You Apply Early or Regular?</span>

      <span className="dca-overview-font-standard" style={{ marginTop: '5vh', lineHeight: '1.75em' }}>As you are finalizing your college list, you should also be strategizing on whether you want to apply early to one or more schools on your list. The decision on applying early is becoming an increasingly critical one in the current college admissions landscape, and there are several factors you should consider in determining your individual application strategy.</span>

      <span className="dca-overview-subheading">What are the options for applying early?</span>

      <span className="dca-overview-paragraph">There are two primary flavors of early application options, Early Action and Early Decision.</span>

      <span className="dca-overview-paragraph">Early Action (EA) is a non-binding option where students may apply to, and receive a decision, earlier than the regular round. Most commonly, early action applications are due early-mid November, decisions are released in December/January, and students reply to their offers by May 1. Students may apply to one or more schools early action, except for a few exceptions where colleges offer a “restrictive” early action option which limits you from applying early to other colleges.</span>

      <span className="dca-overview-paragraph">Early Decision (ED) is a binding option whereby if you receive admission, you are essentially obligated to attend. Naturally you can only apply Early Decision to a single school, though you may still apply Early Action to other schools (with the expectation that you will withdraw any other applications if you receive admission to your Early Decision college.)  The timeline for Early Decision is typically similar to Early Action (i.e. early-mid November application deadline with December/January response.) In addition, some colleges offer a second early round, typically called “Early Decision II” that provides students with an option to apply ED in December/January (typically at or near the same time as their regular round application deadline). Early Decision II therefore enables students to submit a binding application after having received a response from prior Early Action or Early Decision applications.</span>

      <span className="dca-overview-paragraph">Each college establishes its own policy for which (if any) early application options are offered and their corresponding dates and restrictions. The chart below illustrates the breadth of application plans offered by the top-100 ranked colleges on our site.</span>

      <img
        src={admissionsAdvicePhoto5}
        alt="early_app_options_for_top_100"
        className='align-self-center'
        style={{
          width: '100%',
          objectFit: 'cover',
          objectPosition: '50% 50%',
          borderRadius: '10px',
          margin: '4vh 0vh'
        }}
      ></img>

      <span className="dca-overview-subheading">Why do colleges offer an early application option(s)?</span>

      <span className="dca-overview-paragraph">The early application option provides students who have a strong idea of their top-choice school(s) an opportunity to signal that interest, receive admission decision(s) ahead of the regular cycle, and potentially be “finished” with the process earlier in their senior year.</span>

      <span className="dca-overview-paragraph">Colleges highly value the conveyed signal of student interest as it helps inform them of the likelihood that a student would choose to enroll if offered admission. This is important as it will impact the college's yield rate, which is the percentage admitted students who opt to enroll. Colleges generally believe that their yield rate is perceived by prospective applicants as an indicator of the “popularity” or “desirability” of the school, and therefore they generally prefer to have a higher yield rate. By allowing students to apply early (especially in the case of a binding Early Decision plan) it can help colleges somewhat “manage” their yield rates.</span>

      <span className="dca-overview-paragraph">The chart below illustrates the relationship between yield rate and metarank:</span>

      <img
        src={admissionsAdvicePhoto6}
        alt="college_metarank_vs_yield"
        className='align-self-center'
        style={{
          width: '100%',
          objectFit: 'cover',
          objectPosition: '50% 50%',
          borderRadius: '10px',
          margin: '4vh 0vh'
        }}
      ></img>

      <span className="dca-overview-paragraph">This diagram reinforces the correlation between higher yield rates and rankings, even if it is not a directly causal relationship . The diagram also highlights the generally higher yield rate for colleges offering Early Decision application option(s) which is expected given that a subset of their admits are obligated to enroll if admitted.</span>

      <span className="dca-overview-subheading">What are the advantages to applying early?</span>

      <span className="dca-overview-paragraph">The primary advantages to applying early are the following:</span>

      <ul className="dca-overview-paragraph">
        <li>Receiving an application decision earlier (typically December/January) than waiting for regular round decisions (typically in March.)</li>
        <li>If you receive early admission, and are ready to accept it (or are already bound via Early Decision), then you can bypass applying to additional schools in the regular round and save the associated time, cost, and stress of additional applications.</li>
        <li>Most importantly, you may be able to gain an admissions edge by applying early.</li>
      </ul>

      <span className="dca-overview-paragraph">The last point is a critical consideration for your application strategy so let's explore further. We know for certain that admission rates in the early application rounds are typically higher than the regular round for the same school, based on available data:</span>

      <img
        src={admissionsAdvicePhoto7}
        alt="analysis_of_early_round_admit_rates"
        className='align-self-center'
        style={{
          width: '100%',
          objectFit: 'cover',
          objectPosition: '50% 50%',
          borderRadius: '10px',
          margin: '4vh 0vh'
        }}
      ></img>

      <span className="dca-overview-paragraph">This analysis, which is based on the top 100 (by our metarank) colleges which publish statistics on early round admissions, indicates nearly all (98%) of colleges exhibit a higher admit rate in the early round when compared to the regular round. The lone exception is <span onClick={() => {
        // navigate(pageRoutes.collegeDetailWithId(131496));
        navigate(pageRoutes.collegeDetailWithId('georgetown-university'));
      }} className="dca-overview-navlink">Georgetown University</span>, but an important caveat is that Georgetown only accepts or defers in the early round, there are no denials. Moreover, the magnitude of the “bump” in admit rate is very meaningful, with the early round admit rate an average 2.5x of the corresponding college's total admit rate for colleges offering Early Decision option(s) and an average 1.5x for colleges offering Early Action but not Early Decision. Data on admit rate by round for top-ranked colleges publishing that information is available through our <span onClick={() => navigate(pageRoutes.collegeSearch)} className="dca-overview-navlink">college search</span> feature.</span>

      <span className="dca-overview-paragraph">But does this admission rate bump definitely mean it is “easier” to get in if you apply early?  One counter-argument is that the quality of the applicant pool in the early round(s) tends to be stronger than the regular round, as there is a self-selection bias of the strongest candidates applying early to their top-choice school(s). In addition, the higher early round admit rate is often at least partially attributable to the early round including “special case” candidates, such as recruited athletes (as explicitly noted by Dartmouth for example.)</span>

      <span className="dca-overview-paragraph">Even with those (valid) dynamics, we nevertheless believe that it is marginally easier to get in to a school in its early round, especially for colleges offering Early Decision. This perspective is based on extensive learnings from prior applicants, and is further evidenced by analyzing data from ~2,000 applications to the top 30 colleges (according to metarank) over the last 5 years:</span>

      <img
        src={admissionsAdvicePhoto8}
        alt="comparison_of_regular_vs_early_round_admit"
        className='align-self-center'
        style={{
          width: '100%',
          objectFit: 'cover',
          objectPosition: '50% 50%',
          borderRadius: '10px',
          margin: '4vh 0vh'
        }}
      ></img>

      <span className="dca-overview-paragraph">The chart above highlights the stronger academic profile for the candidates gaining admission in the regular round as compared to admits to the same college via the early round. The more meaningful delta is exhibited with the colleges offering an Early Decision options, where schools may be willing to accept slightly lower credentials in exchange for students expressing an early and binding commitment to their school. In particular the 3.0% differential in GPA shown in this particular analysis is quite compelling. Translated to a numerical example, it is analogous to the difference between a ~3.9 and a 4.0 GPA, which is the equivalent of getting B's (instead of A's) in 2-3 classes over your 4-year high school tenure.</span>

      <span className="dca-overview-paragraph">Though colleges do not typically publish official statistics distinguishing the credentials for admits by round, this analysis provides some directional indication of recent trends, and further informs our recommendation to strongly consider applying early to take advantage of the potential edge in admissions.</span>

      <span className="dca-overview-subheading">What are the disadvantages to applying early?</span>

      <span className="dca-overview-paragraph">There are several trade-offs to consider for applying early:</span>

      <ul className="dca-overview-paragraph">
        <li>Timing. The early rounds (with the exception of Early Decision II options) have an earlier deadline (typically early-mid November) which puts more time pressure to complete your applications as they are due earlier in senior year.</li>
        <li>Consideration of senior year. Due to the timing of the early cycle, your junior year grades will typically be the last set of grades considered, whereas if you apply early, typically your grades from the first half of senior year would be considered. This is especially relevant for candidates who may want to show an upward trend with senior year grades. Likewise, with the early round applications there is less time to develop extracurriculars, awards, etc. for potential inclusion in your application.</li>
        <li>Commitment. If you choose to apply via a binding option (i.e. Early Decision) than you effectively foregoing the option to compare between multiple admitting schools. Similarly, you are losing the option to compare financial aid offers across schools, if applicable.</li>
      </ul>

      <span className="dca-overview-subheading">Making The Best Decision For Yourself</span>

      <span className="dca-overview-paragraph">As outlined above, there are a number of important considerations in deciding whether to apply early. These should be evaluated carefully for your individual situation, but if you feel you can be ready to submit an application by the early deadline that would be equally strong to the application you would submit by the regular deadline, you should strongly consider applying early. </span>

      <span className="dca-overview-paragraph">If applying early, you need to determine if you are willing to bind yourself to a specific college, which then opens access to colleges offering only an Early Decision option. Also keep in mind that conceptually you can apply early to multiple colleges provided you abide by any indicated restrictions. For instance, some general scenarios that can be considered:</span>

      <ul className="dca-overview-paragraph">
        <li>Applying EA (restrictive or nonrestrictive) to a single college.</li>
        <li>Applying ED to a single college.</li>
        <li>Applying EA to multiple colleges with non-restrictive EA options.</li>
        <li>Applying ED to one college and EA to other colleges with non-restrictive EA options.</li>
        <li>Applying ED I (first cycle) to one college, and if not admitted, applying ED II (second cycle) to another college.</li>
        <li>Applying restrictive EA to a single college, and applying EA to public universities or private universities with a concurrent scholarship deadline. For instance, you can apply with Restrictive Early Action to Stanford, and also apply early to University of Michigan (public institution) and USC (scholarship consideration).</li>
      </ul>

      <span className="dca-overview-paragraph">We recommend to review the application options and admission data by round (summarized in our <span onClick={() => navigate(pageRoutes.collegeSearch)} className="dca-overview-navlink">college explorer</span> feature) for all the colleges on your interest list to help guide your strategy. </span>

    </>
  )

  //#overall_reference_timeline
  const overallRef = (
    <>
      <span className="dca-overview-title">Overall Reference Timeline</span>

      <span className="dca-overview-font-standard" style={{ marginTop: '5vh', lineHeight: '1.75em' }}>Perhaps the most important decision you can make to maximize your probability of admission to the top-ranked colleges is a relatively easy one: start early!  By being mindful of what you're working towards throughout your time in high school, you will be best-prepared to create a compelling college application when you arrive in senior year.</span>

      <span className="dca-overview-paragraph">Please refer to the guide below as a high-level roadmap to help stay organized across all the key dimensions that will matter most for admissions decisions.</span>

      <img
        src={admissionsAdvicePhoto12}
        alt="application_roadmap"
        className='align-self-center'
        style={{
          width: '100%',
          objectFit: 'cover',
          objectPosition: '50% 50%',
          margin: '4vh 0vh'
        }}
      ></img>
    </>
  )

  const returnContent = (hashPath) => {
    switch (true) {
      case hashPath === '':
        return base
      case hashPath === 'identifying-core-application-theme':
        return core
      case hashPath === 'selecting-intended-major(s)':
        return majors
      case hashPath === 'crafting-an-extracurriculars-profile':
        return ecProfile
      case hashPath === 'determining-a-standardized-testing-strategy':
        return testStrategy
      case hashPath === 'maximizing-impact-from-letters-of-recommendation':
        return lettersOfRec
      case hashPath === 'creating-a-college-interest-list':
        return collegeInterest
      case hashPath === 'applying-early-vs-regular':
        return earlyVsReg
      case hashPath === 'overall-reference-timeline':
        return overallRef
      default:
        return base
    }
  }

  return (
    <>
      <div className='dca-header-menu-toggle d-flex d-lg-none align-items-center me-n2' title='Show header menu' onClick={() => { handleClickOffcanvas() }}>
        <button
          // className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
          className='btn btn-icon btn-active-light-primary'
          id='kt_header_menu_mobile_toggle'
        >
          {/* <KTIcon iconName='text-align-left' className='fs-1' /> */}
          <img style={{ height: '7.29px', width: '16px' }} alt='dca-hamburger-nav-icon' src={navHamburger}></img>
        </button>
      </div>

      <div className='d-flex flex-row'>

        <div className='dca-overview-vertical-tabs'>{verticalTabs}</div>

        <div className='dca-overview-sidebar'>{sidebar}</div>

        <div className='d-flex flex-column align-items-center dca-overview-main-content'>

          <div className="dca-overview-breadcrumb align-self-start">{breadcrumbComponent}</div>

          {returnContent(hashPath)}

          {pagination}
        </div>
      </div>
    </>
  )
}

export { AdviceForNavigatingAdmissions }
