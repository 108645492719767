import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login, getUserProfile } from '../../modules/auth/core/_requests'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { useAuth } from '../../modules/auth'
import { useNavigate } from 'react-router-dom'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .matches(/(?=.*\d)/, 'Password must contain at least one number')
    .required('Password is required'),
})

// const initialValues = {
//   email: '',
//   password: '',
// }

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function SignInPage() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, currentUser, setCurrentUser } = useAuth()
  const [password, setPassword] = useState('password')
  const [eye, setEye] = useState(false)
  const navigate = useNavigate()

  const eyeTrue = (<i className="bi bi-eye"></i>)
  const eyeFalse = (<i className="bi bi-eye-slash"></i>)


  const handlePasswordView = () => {
    if (password === 'password') {
      setEye(true);
      setPassword('text')
    }
    else {
      setEye(false);
      setPassword('password')
    }
  }

  const eyeComponent = (
    <span className='d-flex justify-content-between' onClick={handlePasswordView}>
      {eye ? eyeTrue : eyeFalse}
    </span>
  )

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: authToken } = await login(values.email, values.password)
        console.log('Login successful')

        const { data: user } = await getUserByToken(authToken.key)
        console.log('Django User:', user)

        const { data: userprofile } = await getUserProfile(user.pk, authToken.key)
        setCurrentUser(userprofile)
        console.log('Current User:', userprofile)

        const groupIds = user.groups.map((group => group.id))

        saveAuth({ api_token: authToken.key, groups: groupIds })
        // setAuth(authToken.key)

        navigate('/home')
      } catch (error) {
        console.error(error)
        alert('Login unsuccessful')
        saveAuth(undefined)
        setStatus('The login details provided are incorrect. Please re-enter your account credentials or contact support@nextfour.ai for assistance.')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const signInForm = (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >

      <div className='text-center mb-11'>
        <div className='text-dark fw-bolder mb-3' style={{ fontSize: '24px' }}>Sign In</div>
      </div>

      <div className='row g-3 mb-9'>
        <div className='col-md-6'>
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </a>
        </div>

        <div className='col-md-6'>
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Apple
          </a>
        </div>
      </div>

      <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div>

      <div className='fv-row mb-8'>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>


      <div className='fv-row mb-3'>
        <input
          type={password}
          autoComplete='off'
          placeholder='Password'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />{eyeComponent}
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* <Link
  to='/auth/forgot-password' className='link-primary'
> */}
        <Link
          to='/dashboard' className='link-primary'
        >
          Forgot Password ?
        </Link>
      </div>

      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
        // disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Sign In</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/sign-up' className='link-primary'>
          Sign up
        </Link>
      </div>
    </form>
  )

  return (
    <div className='d-flex flex-column'>
      <Link
        className="dca-font-M align-self-center"
        to={'/home'}
        style={{ color: 'black', margin: '50px' }}>
        <i className="me-3 fa-solid fa-arrow-left" style={{ color: 'black', marginTop: '30px' }} ></i>
        Back to Home</Link>
      <div className='card align-self-center' style={{ padding: '40px', margin: '50px', width: '60%' }}>
        {signInForm}
      </div>
    </div>
  )
}