import { useEffect, useState } from 'react'
import { selectedTabGreen, unselectedTabGray, rdSpendAccessCode, specializedRankingsAccessCode, safetyAccessCode, gray, pageRoutes, URL } from '../dictionary'
import { CollegeDetailTabContent } from './CollegeDetailTabContent'
import { useNavigate } from 'react-router'
import { getAuth } from '../../modules/auth'
import { CollegeReactGAHandlers } from '../ReactGAHandlers'
import { collegeMap } from '../collegeMap'
import axios from 'axios'

const CollegeDetailTabs = ({ college, setUser, collegeListLength, setPrompt, setNavigateTo, setPurchaseID, setPurchaseModalCollegeName, collegesId, setSelectedTab, selectedTab, setRnDModalData, setSAModalData, setSafety1KModalData, setSafetyTotalModalData, setSRModalData, mobile = false }) => {

  let apiToken: any = undefined
  let permissionGroups: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
    permissionGroups = getAuth()?.groups
  }

  const {
    handleGAEventTabClick
  } = CollegeReactGAHandlers()

  const navigate = useNavigate()
  const [majorTab, setMajorTab] = useState(false)

  useEffect(() => {

    const checkProgramDataCount = async (collegeID) => {
      let res = await axios.get(`${URL}/program_data_by_college_id/${collegeID}`)

      return res.data.programDataCount > 0
    }

    if (college?.id) {
      checkProgramDataCount(college.id).then((hasPrograms) => {
        if (hasPrograms) {
          setMajorTab(true)
        }

        else {
          setMajorTab(false);
          if (selectedTab === 'Majors') {
            setSelectedTab('Admission Info')
          }
        }
      });
    }

  }, [college])

  let hyphenated: any = collegeMap.find(obj => obj.id == `${college.id}`)?.hyphenated ? collegeMap.find(obj => obj.id == `${college.id}`)?.hyphenated : undefined

  const restrictedTabOptions = [
    {
      name: 'Specialized Rankings',
      route: pageRoutes.premiumSpecializedRankingsWithId(hyphenated),
      hyphenated: hyphenated,
      accessCode: specializedRankingsAccessCode
    },
    {
      name: 'R&D Spend',
      route: pageRoutes.premiumRDSpendWithId(hyphenated),
      hyphenated: hyphenated,
      accessCode: rdSpendAccessCode
    },
    {
      name: 'Crime & Safety',
      route: pageRoutes.premiumCrimeSafetyWithId(hyphenated),
      hyphenated: hyphenated,
      accessCode: safetyAccessCode
    }
  ]

  let tabOptions = majorTab ? ['Admission Info', 'GPA Data', 'SAT Score Data', 'ACT Score Data', 'Majors', 'Industry Rankings'] : ['Admission Info', 'GPA Data', 'SAT Score Data', 'ACT Score Data', 'Industry Rankings']

  const handleSelectedTab = (val) => {
    setSelectedTab(val)
  }

  const guestOrNoAuthTabs = (
    <div className='d-flex flex-row'>

      {tabOptions.map((tab, index) => (
        <div
          key={tab}
          className="d-flex rounded rounded-3 justify-content-center align-items-center"
          style={{
            backgroundColor: selectedTab === tabOptions[index] ? selectedTabGreen : unselectedTabGray,
            height: '70px',
            cursor: 'pointer',
            border: '1px rgba(219, 223, 233, 0.4) dashed',
            borderRadius: '50%',
            display: 'inline-block',
            marginLeft: index === 0 ? '0px' : '15px'
          }}

          onClick={() => {
            handleSelectedTab(tabOptions[index]);
            handleGAEventTabClick(tabOptions[index], college.id)
          }}
        >
          <span className='m-3 text-nowrap align-self-end'>{tab}</span>
        </div>))}

      {restrictedTabOptions.map((tab) => (
        <div
          key={tab.name}
          className="d-flex rounded rounded-3 align-items-end"
          onClick={() => {
            navigate(tab.route)
            // setCollegeDetailTabModal(setPrompt, setNavigateTo, tab);
            handleGAEventTabClick(tab, college.id);
            // handleGAEventSignInModalOpen('forced')
          }}
          style={{
            backgroundColor: gray,
            height: '70px',
            cursor: 'pointer',
            border: '1px rgba(219, 223, 233, 0.4) dashed',
            borderRadius: '50%',
            display: 'inline-block',
            marginLeft: '15px',
            color: 'black'
          }}
        >
          <span className='d-flex flex-column'>
            <i className="bi bi-lock align-self-center" style={{ color: 'black' }}></i>
            <span className='m-3 text-nowrap align-self-end'>{tab.name}</span>
          </span>
        </div>

      ))}

    </div>
  )

  const returnTabs = (apiToken) => {

    if (!apiToken) {
      return guestOrNoAuthTabs
    }

    else {
      return <div className='d-flex flex-row'>
        {tabOptions.map((tab, index) => (
          <div
            key={tab}
            className="d-flex rounded rounded-3 justify-content-center align-items-center"
            style={{
              backgroundColor: selectedTab === tabOptions[index] ? selectedTabGreen : unselectedTabGray,
              height: '70px',
              cursor: 'pointer',
              border: '1px rgba(219, 223, 233, 0.4) dashed',
              borderRadius: '50%',
              display: 'inline-block',
              marginLeft: index === 0 ? '0px' : '15px'
            }}
            onClick={() => {
              handleSelectedTab(tabOptions[index]);
              handleGAEventTabClick(tabOptions[index], college.id)
            }}
          >
            <span className='m-3 text-nowrap align-self-end'>{tab}</span>
          </div>
        ))}

        {restrictedTabOptions.map((tabObj, index) => (
          permissionGroups.indexOf(tabObj.accessCode) !== -1 ? <div
            key={tabObj.name}
            className='d-flex rounded rounded-3 justify-content-center align-items-center'
            style={{
              backgroundColor: selectedTab === tabObj.name ? selectedTabGreen : unselectedTabGray,
              height: '70px',
              cursor: 'pointer',
              border: '1px rgba(219, 223, 233, 0.4) dashed',
              borderRadius: '50%',
              display: 'inline-block',
              marginLeft: '15px'
            }}
            onClick={() => {
              handleSelectedTab(tabObj.name);
              handleGAEventTabClick(tabObj.name, college.id)
            }}><span className='m-3 text-nowrap align-self-end'>{tabObj.name}</span></div> : <span
              className="d-flex rounded rounded-3 align-items-end"
              onClick={() => {
                navigate(tabObj.route)
                handleGAEventTabClick(tabObj.name, college.id);
              }}
              style={{
                backgroundColor: gray,
                height: '70px',
                cursor: 'pointer',
                border: '1px rgba(219, 223, 233, 0.4) dashed',
                borderRadius: '50%',
                display: 'inline-block',
                marginLeft: '15px',
                color: 'black'
              }}
            >
            <span className='d-flex flex-column'>
              <i className="bi bi-lock align-self-center" style={{ color: 'black' }}></i>
              <span className='m-3 text-nowrap align-self-end'>{tabObj.name}</span>
            </span>
          </span>
        ))}

      </div>
    }
  }

  const accessCheck = (tab, permissionGroups) => {

    if (permissionGroups) {
      // if permissionGroups contains the access code, will return a non negative therefore true, else false
      switch (tab) {
        case restrictedTabOptions[0].name:
          return permissionGroups?.indexOf(specializedRankingsAccessCode) !== -1
        case restrictedTabOptions[1].name:
          return permissionGroups?.indexOf(rdSpendAccessCode) !== -1
        case restrictedTabOptions[2].name:
          return permissionGroups?.indexOf(safetyAccessCode) !== -1
        default:
          return false
      }
    }

    return false
  }

  const premiums = restrictedTabOptions.map((tab) => tab.name);

  const handleTabChange = (event, permissionGroups) => {
    const selectedTab = event.target.value

    handleGAEventTabClick(selectedTab, college.id);

    if (!accessCheck(selectedTab, permissionGroups) && premiums.includes(selectedTab)) {
      switch (selectedTab) {
        case 'Specialized Rankings':
          return navigate(pageRoutes.premiumSpecializedRankingsWithId(hyphenated));
        case 'R&D Spend':
          return navigate(pageRoutes.premiumRDSpendWithId(hyphenated));
        case 'Crime & Safety':
          return navigate(pageRoutes.premiumCrimeSafetyWithId(hyphenated));
      }
    }

    else {
      return setSelectedTab(selectedTab)
    }

  }

  const returnMobileTabs = () => {
    return <select
      className='ms-2 form-select align-bottom'
      data-control="select2"
      value={selectedTab}
      onChange={(e) => {
        handleTabChange(e, permissionGroups)
      }}
      style={{ cursor: 'pointer', width: 'auto' }}
    >{tabOptions.map((tab, index) => <option value={tab} key={index}>{tab}</option>)}

      {restrictedTabOptions.map((tabObj, index) => (<option value={tabObj.name} key={index}>{tabObj.name}</option>))}
    </select>
  }

  const selectWarningModal = (
    <div className="modal fade" id="permissionGroupWarning">
      <div className="modal-dialog modal-dialog modal-dialog-centered">
        <div className="modal-dialog modal-dialog">
          <div className="modal-body d-flex flex-column">
            Trouble Loading User Permission Groups, Please Reload or Try Again
          </div>
        </div>
      </div>
    </div>
  )

  return mobile ? (
    <div style={{ width: '100%', marginTop: '20px' }}>
      {returnMobileTabs()}

      <CollegeDetailTabContent
        college={college}
        selectedTab={selectedTab}
        tabOptions={tabOptions}
        collegeListLength={collegeListLength}
        collegesId={collegesId}
        setSelectedTab={setSelectedTab}
        setPrompt={setPrompt}
        setNavigateTo={setNavigateTo}
        mobile={mobile}
        majorTab={majorTab}

        setRnDModalData={setRnDModalData}
        setSAModalData={setSAModalData}
        setSafety1KModalData={setSafety1KModalData}
        setSafetyTotalModalData={setSafetyTotalModalData}
        setSRModalData={setSRModalData}
      />

      {selectWarningModal}
    </div>) : (<div className='mt-5' style={{ width: '100%' }}>
      {returnTabs(apiToken)}

      <CollegeDetailTabContent
        college={college}
        selectedTab={selectedTab}
        tabOptions={tabOptions}
        collegeListLength={collegeListLength}
        collegesId={collegesId}
        setSelectedTab={setSelectedTab}
        setPrompt={setPrompt}
        setNavigateTo={setNavigateTo}
        majorTab={majorTab}

        setRnDModalData={setRnDModalData}
        setSAModalData={setSAModalData}
        setSafety1KModalData={setSafety1KModalData}
        setSafetyTotalModalData={setSafetyTotalModalData}
        setSRModalData={setSRModalData}
      />
    </div>)
}

export { CollegeDetailTabs }
















// import { useEffect, useState } from 'react'
// import { selectedTabGreen, unselectedTabGray, rdSpendAccessCode, specializedRankingsAccessCode, safetyAccessCode, gray, pageRoutes, URL } from '../dictionary'
// import { CollegeDetailTabContent } from './CollegeDetailTabContent'
// import { useNavigate } from 'react-router'
// import { getAuth, useAuth } from '../../modules/auth'
// import { setCollegeDetailTabModal, setPurchaseModal } from '../Auth/setPrompts'
// import { CollegeReactGAHandlers } from '../ReactGAHandlers'
// import { AuthReactGAHandlers } from '../ReactGAHandlers'
// import { collegeMap } from '../collegeMap'
// import axios from 'axios'

// const CollegeDetailTabs = ({ college, setUser, collegeListLength, setPrompt, setNavigateTo, setPurchaseID, setPurchaseModalCollegeName, collegesId, setSelectedTab, selectedTab, setRnDModalData, setSAModalData, setSafety1KModalData, setSafetyTotalModalData, setSRModalData, mobile = false }) => {

//   let apiToken: any = undefined
//   let permissionGroups: any = undefined

//   if (getAuth()?.api_token && getAuth()?.groups) {
//     apiToken = getAuth()?.api_token
//     permissionGroups = getAuth()?.groups
//   }

//   const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()

//   const {
//     handleGAEventTabClick,
//     handleGAEventPurchaseModalOpen
//   } = CollegeReactGAHandlers()

//   const navigate = useNavigate()
//   const [majorTab, setMajorTab] = useState(false)

//   useEffect(() => {

//     const checkProgramDataCount = async (collegeID) => {
//       let res = await axios.get(`${URL}/program_data_by_college_id/${collegeID}`)

//       return res.data.programDataCount > 0
//     }

//     if (college && college.id) {
//       checkProgramDataCount(college.id).then((hasPrograms) => {
//         console.log('Has programs:', hasPrograms)
//         hasPrograms ? setMajorTab(true) : setMajorTab(false);
//       });
//     }

//   }, [college])

//   let hyphenated: any = collegeMap.find(obj => obj.id == `${college.id}`)?.hyphenated ? collegeMap.find(obj => obj.id == `${college.id}`)?.hyphenated : undefined

//   const restrictedTabOptions = [
//     {
//       name: 'Specialized Rankings',
//       route: pageRoutes.premiumSpecializedRankingsWithId(hyphenated),
//       hyphenated: hyphenated,
//       accessCode: specializedRankingsAccessCode
//     },
//     {
//       name: 'R&D Spend',
//       route: pageRoutes.premiumRDSpendWithId(hyphenated),
//       hyphenated: hyphenated,
//       accessCode: rdSpendAccessCode
//     },
//     {
//       name: 'Crime & Safety',
//       route: pageRoutes.premiumCrimeSafetyWithId(hyphenated),
//       hyphenated: hyphenated,
//       accessCode: safetyAccessCode
//     }
//   ]

//   // All access
//   // const tabOptions = ['Admission Info', 'GPA Data', 'SAT Score Data', 'ACT Score Data', 'Industry Rankings', 'Specialized Rankings', 'R&D Spend', 'Crime & Safety']

//   let tabOptions = majorTab ? ['Admission Info', 'GPA Data', 'SAT Score Data', 'ACT Score Data', 'Majors', 'Industry Rankings'] : ['Admission Info', 'GPA Data', 'SAT Score Data', 'ACT Score Data', 'Industry Rankings']

//   // Open access
//   const openAccessTabOptions = ['Admission Info', 'GPA Data', 'SAT Score Data', 'ACT Score Data', 'Industry Rankings']

//   // Purchased Access Tab Options
//   let optionATrue: any = undefined
//   let optionBTrue: any = undefined
//   let optionCTrue: any = undefined
//   let optionDTrue: any = undefined
//   let optionETrue: any = undefined
//   let optionFTrue: any = undefined
//   let optionGTrue: any = undefined

//   const tabOptionsA = openAccessTabOptions.concat(restrictedTabOptions[0].name)
//   const tabOptionsB = openAccessTabOptions.concat(restrictedTabOptions[1].name)
//   const tabOptionsC = openAccessTabOptions.concat(restrictedTabOptions[2].name)
//   const tabOptionsD = openAccessTabOptions.concat(restrictedTabOptions[0].name).concat(restrictedTabOptions[1].name)
//   const tabOptionsE = openAccessTabOptions.concat(restrictedTabOptions[1].name).concat(restrictedTabOptions[2].name)
//   const tabOptionsF = openAccessTabOptions.concat(restrictedTabOptions[0].name).concat(restrictedTabOptions[2].name)

//   if (permissionGroups !== undefined && Array.isArray(permissionGroups)) {
//     optionATrue = permissionGroups.indexOf(specializedRankingsAccessCode) !== -1

//     optionBTrue = permissionGroups.indexOf(rdSpendAccessCode) !== -1

//     optionCTrue = permissionGroups.indexOf(safetyAccessCode) !== -1

//     optionDTrue = [specializedRankingsAccessCode, rdSpendAccessCode].every((code) => permissionGroups.includes(code));

//     optionETrue = [rdSpendAccessCode, safetyAccessCode].every((code) => permissionGroups.includes(code))

//     optionFTrue = [specializedRankingsAccessCode, safetyAccessCode].every((code) => permissionGroups.includes(code))

//     optionGTrue = [specializedRankingsAccessCode, rdSpendAccessCode, safetyAccessCode].every((code) => permissionGroups.includes(code));
//   }

//   const handleSelectedTab = (val) => {
//     setSelectedTab(val)
//   }

//   const guestOrNoAuthTabs = (
//     <div className='d-flex flex-row'>

//       {tabOptions.map((tab, index) => (
//         <div
//           key={tab}
//           className="d-flex rounded rounded-3 justify-content-center align-items-center"
//           style={{
//             backgroundColor: selectedTab === tabOptions[index] ? selectedTabGreen : unselectedTabGray,
//             height: '70px',
//             cursor: 'pointer',
//             border: '1px rgba(219, 223, 233, 0.4) dashed',
//             borderRadius: '50%',
//             display: 'inline-block',
//             marginLeft: index === 0 ? '0px' : '15px'
//           }}

//           onClick={() => {
//             handleSelectedTab(tabOptions[index]);
//             handleGAEventTabClick(tabOptions[index], college.id)
//           }}
//         >
//           <span className='m-3 text-nowrap align-self-end'>{tab}</span>
//         </div>))}

//       {restrictedTabOptions.map((tab) => (
//         <div
//           key={tab.name}
//           className="d-flex rounded rounded-3 align-items-end"
//           onClick={() => {
//             navigate(tab.route)
//             // setCollegeDetailTabModal(setPrompt, setNavigateTo, tab);
//             handleGAEventTabClick(tab, college.id);
//             // handleGAEventSignInModalOpen('forced')
//           }}
//           style={{
//             backgroundColor: gray,
//             height: '70px',
//             cursor: 'pointer',
//             border: '1px rgba(219, 223, 233, 0.4) dashed',
//             borderRadius: '50%',
//             display: 'inline-block',
//             marginLeft: '15px',
//             color: 'black'
//           }}
//         >
//           <span className='d-flex flex-column'>
//             <i className="bi bi-lock align-self-center" style={{ color: 'black' }}></i>
//             <span className='m-3 text-nowrap align-self-end'>{tab.name}</span>
//           </span>
//         </div>

//       ))}

//     </div>
//   )

//   const returnTabRequiringPurchase = (text, route) => {
//     return (
//       <span
//         className="d-flex rounded rounded-3 align-items-end"
//         onClick={() => {
//           navigate(route)
//           handleGAEventTabClick(text, college.id);
//         }}
//         style={{
//           backgroundColor: gray,
//           height: '70px',
//           cursor: 'pointer',
//           border: '1px rgba(219, 223, 233, 0.4) dashed',
//           borderRadius: '50%',
//           display: 'inline-block',
//           marginLeft: '15px',
//           color: 'black'
//         }}
//       >
//         <span className='d-flex flex-column'>
//           <i className="bi bi-lock align-self-center" style={{ color: 'black' }}></i>
//           <span className='m-3 text-nowrap align-self-end'>{text}</span>
//         </span>
//       </span>
//     )
//   }

//   const authWithoutAccessTabs = (

//     <div className='d-flex flex-row'>

//       {openAccessTabOptions.map((tab, index) => (
//         <div
//           key={tab}
//           className="d-flex rounded rounded-3 justify-content-center align-items-center"
//           style={{
//             backgroundColor: selectedTab === tabOptions[index] ? selectedTabGreen : unselectedTabGray,
//             height: '70px',
//             cursor: 'pointer',
//             border: '1px rgba(219, 223, 233, 0.4) dashed',
//             borderRadius: '50%',
//             display: 'inline-block',
//             marginLeft: index === 0 ? '0px' : '15px'
//           }}

//           onClick={() => {
//             handleSelectedTab(tabOptions[index]);
//             handleGAEventTabClick(tabOptions[index], college.id)
//           }}
//         >
//           <span className='m-3 text-nowrap align-self-end'>{tab}</span>
//         </div>))}

//       {returnTabRequiringPurchase('Specialized Rankings', pageRoutes.premiumSpecializedRankingsWithId(hyphenated))}

//       {returnTabRequiringPurchase('R&D Spend', pageRoutes.premiumRDSpendWithId(hyphenated))}

//       {returnTabRequiringPurchase('Crime & Safety', pageRoutes.premiumCrimeSafetyWithId(hyphenated))}

//     </div>
//   )

//   const fullAccessTabs = (

//     <div className='d-flex flex-row'>

//       {tabOptions.map((tab, index) => (
//         <div
//           key={tab}
//           className="d-flex rounded rounded-3 justify-content-center align-items-center"
//           style={{
//             backgroundColor: selectedTab === tabOptions[index] ? selectedTabGreen : unselectedTabGray,
//             height: '70px',
//             cursor: 'pointer',
//             border: '1px rgba(219, 223, 233, 0.4) dashed',
//             borderRadius: '50%',
//             display: 'inline-block',
//             marginLeft: index === 0 ? '0px' : '15px'
//           }}
//           onClick={() => {
//             handleSelectedTab(tabOptions[index]);
//             handleGAEventTabClick(tabOptions[index], college.id)
//           }}
//         >
//           <span className='m-3 text-nowrap align-self-end'>{tab}</span>
//         </div>))}

//     </div>
//   )

//   const returnPurchaseTab = (tabName) => {
//     switch (true) {
//       case tabName === 'Specialized Rankings':
//         return returnTabRequiringPurchase('Specialized Rankings', pageRoutes.premiumSpecializedRankingsWithId(hyphenated))
//       case tabName === 'R&D Spend':
//         return returnTabRequiringPurchase('R&D Spend', pageRoutes.premiumRDSpendWithId(hyphenated))
//       case tabName === 'Crime & Safety':
//         return returnTabRequiringPurchase('Crime & Safety', pageRoutes.premiumCrimeSafetyWithId(hyphenated))
//       default:
//         return null
//     }
//   }

//   const returnAccessTab = (userTabOptions) => {

//     return (
//       <div className='d-flex flex-row'>

//         {tabOptions.map((tab, index) => {

//           return userTabOptions.some((option) => option.includes(tab)) ? <div
//             key={index}
//             className="d-flex rounded rounded-3 justify-content-center align-items-center"
//             style={{
//               backgroundColor: selectedTab === tabOptions[index] ? selectedTabGreen : unselectedTabGray,
//               height: '70px',
//               cursor: 'pointer',
//               border: '1px rgba(219, 223, 233, 0.4) dashed',
//               borderRadius: '50%',
//               display: 'inline-block',
//               marginLeft: index === 0 ? '0px' : '15px'
//             }}

//             onClick={() => {
//               handleSelectedTab(tabOptions[index]);
//               handleGAEventTabClick(tabOptions[index], college.id)
//             }}
//           >
//             <span className='m-3 text-nowrap align-self-end'>{tab}</span>
//           </div> : returnPurchaseTab(tab)
//         })}

//       </div>
//     )
//   }

//   const returnTabs = (apiToken) => {
//     // switch (true) {
//     //   case !apiToken:
//     //     return guestOrNoAuthTabs
//     //   case apiToken !== undefined && optionGTrue:
//     //     return fullAccessTabs
//     //   case apiToken !== undefined && optionFTrue:
//     //     return returnAccessTab(tabOptionsF)
//     //   case apiToken !== undefined && optionETrue:
//     //     return returnAccessTab(tabOptionsE)
//     //   case apiToken !== undefined && optionDTrue:
//     //     return returnAccessTab(tabOptionsD)
//     //   case apiToken !== undefined && optionCTrue:
//     //     return returnAccessTab(tabOptionsC)
//     //   case apiToken !== undefined && optionBTrue:
//     //     return returnAccessTab(tabOptionsB)
//     //   case apiToken !== undefined && optionATrue:
//     //     return returnAccessTab(tabOptionsA)
//     //   case apiToken !== undefined:
//     //     return authWithoutAccessTabs
//     //   default:
//     //     return guestOrNoAuthTabs
//     // }

//     if (!apiToken) {
//       return guestOrNoAuthTabs
//     }

//     else {
//       return <div className='d-flex flex-row'>
//         {tabOptions.map((tab, index) => (
//           <div
//             key={tab}
//             className="d-flex rounded rounded-3 justify-content-center align-items-center"
//             style={{
//               backgroundColor: selectedTab === tabOptions[index] ? selectedTabGreen : unselectedTabGray,
//               height: '70px',
//               cursor: 'pointer',
//               border: '1px rgba(219, 223, 233, 0.4) dashed',
//               borderRadius: '50%',
//               display: 'inline-block',
//               marginLeft: index === 0 ? '0px' : '15px'
//             }}
//             onClick={() => {
//               handleSelectedTab(tabOptions[index]);
//               handleGAEventTabClick(tabOptions[index], college.id)
//             }}
//           >
//             <span className='m-3 text-nowrap align-self-end'>{tab}</span>
//           </div>
//         ))}

//         {restrictedTabOptions.map((tabObj, index) => (
//           permissionGroups.indexOf(tabObj.accessCode) !== -1 ? <div
//             key={tabObj.name}
//             className='d-flex rounded rounded-3 justify-content-center align-items-center'
//             style={{
//               backgroundColor: selectedTab === tabObj.name ? selectedTabGreen : unselectedTabGray,
//               height: '70px',
//               cursor: 'pointer',
//               border: '1px rgba(219, 223, 233, 0.4) dashed',
//               borderRadius: '50%',
//               display: 'inline-block',
//               marginLeft: '15px'
//             }}
//             onClick={() => {
//               handleSelectedTab(tabObj.name);
//               handleGAEventTabClick(tabObj.name, college.id)
//             }}><span className='m-3 text-nowrap align-self-end'>{tabObj.name}</span></div> : <span
//               className="d-flex rounded rounded-3 align-items-end"
//               onClick={() => {
//                 navigate(tabObj.route)
//                 handleGAEventTabClick(tabObj.name, college.id);
//               }}
//               style={{
//                 backgroundColor: gray,
//                 height: '70px',
//                 cursor: 'pointer',
//                 border: '1px rgba(219, 223, 233, 0.4) dashed',
//                 borderRadius: '50%',
//                 display: 'inline-block',
//                 marginLeft: '15px',
//                 color: 'black'
//               }}
//             >
//             <span className='d-flex flex-column'>
//               <i className="bi bi-lock align-self-center" style={{ color: 'black' }}></i>
//               <span className='m-3 text-nowrap align-self-end'>{tabObj.name}</span>
//             </span>
//           </span>
//         ))}

//       </div>
//     }
//   }

//   const accessCheck = (tab, permissionGroups) => {

//     if (permissionGroups) {
//       // if permissionGroups contains the access code, will return a non negative therefore true, else false
//       switch (tab) {
//         case restrictedTabOptions[0].name:
//           return permissionGroups?.indexOf(specializedRankingsAccessCode) !== -1
//         case restrictedTabOptions[1].name:
//           return permissionGroups?.indexOf(rdSpendAccessCode) !== -1
//         case restrictedTabOptions[2].name:
//           return permissionGroups?.indexOf(safetyAccessCode) !== -1
//         default:
//           return false
//       }
//     }

//     return false
//   }

//   const premiums = restrictedTabOptions.map((tab) => tab.name);

//   const handleTabChange = (event, permissionGroups) => {
//     const selectedTab = event.target.value

//     handleGAEventTabClick(selectedTab, college.id);

//     if (!accessCheck(selectedTab, permissionGroups) && premiums.includes(selectedTab)) {
//       switch (selectedTab) {
//         case 'Specialized Rankings':
//           return navigate(pageRoutes.premiumSpecializedRankingsWithId(hyphenated));
//         case 'R&D Spend':
//           return navigate(pageRoutes.premiumRDSpendWithId(hyphenated));
//         case 'Crime & Safety':
//           return navigate(pageRoutes.premiumCrimeSafetyWithId(hyphenated));
//       }
//     }

//     else {
//       return setSelectedTab(selectedTab)
//     }

//   }

//   const returnMobileTabs = () => {
//     return <select
//       className='ms-2 form-select align-bottom'
//       data-control="select2"
//       value={selectedTab}
//       onChange={(e) => {
//         handleTabChange(e, permissionGroups)
//       }}
//       style={{ cursor: 'pointer', width: 'auto' }}
//     >{tabOptions.map((tab, index) => <option value={tab} key={index}>{tab}</option>)}

//       {restrictedTabOptions.map((tabObj, index) => (<option value={tabObj.name} key={index}>{tabObj.name}</option>))}
//     </select>
//   }

//   const selectWarningModal = (
//     <div className="modal fade" id="permissionGroupWarning">
//       <div className="modal-dialog modal-dialog modal-dialog-centered">
//         <div className="modal-dialog modal-dialog">
//           <div className="modal-body d-flex flex-column">
//             Trouble Loading User Permission Groups, Please Reload or Try Again
//           </div>
//         </div>
//       </div>
//     </div>
//   )

//   return mobile ? (
//     <div style={{ width: '100%', marginTop: '20px' }}>
//       {returnMobileTabs()}

//       <CollegeDetailTabContent
//         college={college}
//         selectedTab={selectedTab}
//         tabOptions={tabOptions}
//         collegeListLength={collegeListLength}
//         collegesId={collegesId}
//         setSelectedTab={setSelectedTab}
//         setPrompt={setPrompt}
//         setNavigateTo={setNavigateTo}
//         mobile={mobile}
//         majorTab={majorTab}

//         setRnDModalData={setRnDModalData}
//         setSAModalData={setSAModalData}
//         setSafety1KModalData={setSafety1KModalData}
//         setSafetyTotalModalData={setSafetyTotalModalData}
//         setSRModalData={setSRModalData}
//       />

//       {selectWarningModal}
//     </div>) : (<div className='mt-5' style={{ width: '100%' }}>
//       {returnTabs(apiToken)}

//       <CollegeDetailTabContent
//         college={college}
//         selectedTab={selectedTab}
//         tabOptions={tabOptions}
//         collegeListLength={collegeListLength}
//         collegesId={collegesId}
//         setSelectedTab={setSelectedTab}
//         setPrompt={setPrompt}
//         setNavigateTo={setNavigateTo}
//         majorTab={majorTab}

//         setRnDModalData={setRnDModalData}
//         setSAModalData={setSAModalData}
//         setSafety1KModalData={setSafety1KModalData}
//         setSafetyTotalModalData={setSafetyTotalModalData}
//         setSRModalData={setSRModalData}
//       />
//     </div>)
// }

// export { CollegeDetailTabs }