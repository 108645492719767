import { useEffect, useRef } from "react"
import { useLocation } from 'react-router';

const CollegeSet = ({ collegeSetOptions, collegeSetSelect, setCollegeSetSelect, setCollegeSetParams, resetStateToggle }) => {

  const location = useLocation()
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setCollegeSetSelect('');
  }, [resetStateToggle]);

  const handleCollegeSetSelectChange = (event) => {
    let res = event.target.value
    if (res != '') {
      res = res.split(',').map(res => parseInt(res))
    }
    setCollegeSetSelect(res)
  }

  useEffect(() => {

    const params = new URLSearchParams(location.search);
    const collegeSetParam = params.get('collegeSet');

    if (collegeSetParam) {
      let res = collegeSetParam?.split(',').map(res => parseInt(res))
      setCollegeSetSelect(res);
    }

    else {
      setCollegeSetSelect('')
    }
  }, [location.search]);


  useEffect(() => {

    let params = new URLSearchParams(location.search);

    if (collegeSetSelect != '') {
      params.set('collegeSet', collegeSetSelect)
    } else {
      params.delete('collegeSet')
    }

    setCollegeSetParams(`${params.toString()}`)

  }, [collegeSetSelect])

  return <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
    <div className='dca-aside-header'>COLLEGE SET</div>
    <select
      className='form-select form-select-solid'
      data-control="select2"
      value={`${collegeSetSelect}` || ''}
      onChange={handleCollegeSetSelectChange}
      style={{ cursor: 'pointer' }}
    >
      <option value={''}>All</option>
      {collegeSetOptions.map((res) =>
        <option
          key={res.value}
          value={res.value}>{res.label}</option>
      )}
    </select>
  </div>
}

export { CollegeSet }