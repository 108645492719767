import { Name } from './components/Name'
import { Type } from './components/Type'
import { Location } from './components/Location'
import { Setting } from './components/Setting'
import { Size } from './components/Size'
import { Selectivity, SelectivityDesktop } from './components/Selectivity'
import { TestPolicy } from './components/TestPolicy'
import { AppDetails } from './components/AppDetails'
import { OnlyInterests } from './components/OnlyInterests'
import { AppDate } from './components/AppDate'
import { CollegeSet } from './components/CollegeSet'

const CollegeSearchAside = ({ filteredColleges, selectorNameOptions, selectorLocationOptions, setNameFilter, institutionTypeFilter, setInstitutionTypeFilter, setLocationFilter, setSettingFilter, setSizeFilter, selectivityMin, selectivityMax, setSelectivityMin, setSelectivityMax, setTestPolicyFilter, setEarlyAppOptionFilter, setCommonAppFilter, setCoalitionFilter, setIndividualAppFilter, setConsidersInterestFilter, earlyApplicationOfferedCount, commonAppCount, coalitionCount, individualAppCount, considersInterestCount, earlyAppOptionFilter, commonAppFilter, coalitionFilter, individualAppFilter, considersInterestFilter, collegeSearchLocation, setCollegeSearchLocation, setNameParams, setTypeParams, setLocationParams, setSettingParams, setSizeParams, setAdmitParams, setTestPolicyParams, setApplicationDetailsParams, onlyInterestsSelect, setOnlyInterestsSelect, setOnlyInterestsParams, appDateSelect, setAppDateSelect, setAppDateParams, resetStateToggle, collegeSetOptions, setCollegeSetParams, collegeSetSelect, setCollegeSetSelect }) => {


  return (
    <div style={{ width: '260px', maxWidth: '260px', whiteSpace: 'nowrap' }}>

      <div style={{ zIndex: 9 }}>
        <Name selectorNameOptions={selectorNameOptions} setNameFilter={setNameFilter} setNameParams={setNameParams} resetStateToggle={resetStateToggle} />
      </div>

      <div style={{ zIndex: 8 }}>
        <CollegeSet collegeSetOptions={collegeSetOptions} collegeSetSelect={collegeSetSelect} setCollegeSetSelect={setCollegeSetSelect} setCollegeSetParams={setCollegeSetParams} resetStateToggle={resetStateToggle} />
      </div>

      <div style={{ zIndex: 7 }}>
        <Type institutionTypeFilter={institutionTypeFilter} setInstitutionTypeFilter={setInstitutionTypeFilter} setTypeParams={setTypeParams} />
      </div>

      <div style={{ zIndex: 6 }}>
        <Location setLocationFilter={setLocationFilter} selectorLocationOptions={selectorLocationOptions} setLocationParams={setLocationParams} resetStateToggle={resetStateToggle} />
      </div>

      <div style={{ zIndex: 5 }}>
        <Setting setSettingFilter={setSettingFilter} setSettingParams={setSettingParams} resetStateToggle={resetStateToggle} />
      </div>

      <div style={{ zIndex: 4 }}>
        <Size setSizeFilter={setSizeFilter} setSizeParams={setSizeParams} resetStateToggle={resetStateToggle} />
      </div>

      <div style={{ zIndex: 1 }}>
        {/* <Selectivity
          selectivityMin={selectivityMin}
          selectivityMax={selectivityMax}
          setSelectivityMin={setSelectivityMin}
          setSelectivityMax={setSelectivityMax}
          setAdmitParams={setAdmitParams}
          resetStateToggle={resetStateToggle} /> */}
        <SelectivityDesktop
          selectivityMin={selectivityMin}
          selectivityMax={selectivityMax}
          setSelectivityMin={setSelectivityMin}
          setSelectivityMax={setSelectivityMax}
          setAdmitParams={setAdmitParams}
          resetStateToggle={resetStateToggle} />
      </div>

      <TestPolicy setTestPolicyFilter={setTestPolicyFilter} setTestPolicyParams={setTestPolicyParams} resetStateToggle={resetStateToggle} />

      <AppDate
        appDateSelect={appDateSelect}
        setAppDateSelect={setAppDateSelect}
        setAppDateParams={setAppDateParams} />

      <AppDetails
        filteredColleges={filteredColleges}
        setEarlyAppOptionFilter={setEarlyAppOptionFilter}
        setCoalitionFilter={setCoalitionFilter}
        setCommonAppFilter={setCommonAppFilter}
        setConsidersInterestFilter={setConsidersInterestFilter}
        setIndividualAppFilter={setIndividualAppFilter}
        earlyApplicationOfferedCount={earlyApplicationOfferedCount}
        commonAppCount={commonAppCount}
        coalitionCount={coalitionCount}
        individualAppCount={individualAppCount}
        considersInterestCount={considersInterestCount}
        earlyAppOptionFilter={earlyAppOptionFilter}
        commonAppFilter={commonAppFilter}
        coalitionFilter={coalitionFilter}
        individualAppFilter={individualAppFilter}
        considersInterestFilter={considersInterestFilter}
        setApplicationDetailsParams={setApplicationDetailsParams}
      />

      <OnlyInterests onlyInterestsSelect={onlyInterestsSelect} setOnlyInterestsSelect={setOnlyInterestsSelect} setOnlyInterestsParams={setOnlyInterestsParams} />
    </div>
  )
}

export { CollegeSearchAside }