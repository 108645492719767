import { ContentBlock } from "../ContentBlock"
import { currentYear, pageRoutes, URL } from "../../dictionary"
import { useNavigate } from "react-router"
import StandardizedTestingIcon from '../../../../custom_assets/dashboard/standardized_testing.png'
import { setTestScoreStrategyModal } from "../../Auth/setPrompts"
import { getAuth } from "../../../modules/auth"
import { AuthReactGAHandlers } from "../../ReactGAHandlers"
import { useState, useEffect, useRef } from "react"
import axios from "axios"
import Select from 'react-select'
import { handleUpdateUserData } from "../../../pages/UserProfile"
import wizardHome from '../../../../custom_assets/dashboard/wizardHome.png'
import { returnProgressTrackerText } from "../ProgressTrackerText/StandardizedTesting"
import { MultiSelect } from "../../MultiSelect"
import { RadioInput } from "../../RadioInputs"
import { scrollToWizard } from "../../../pages/Dashboard"
import { DashboardReactGAHandlers } from "../../ReactGAHandlers"

const StandardizedTesting = ({ user, setUser, setPrompt, setNavigateTo, wizardActive, setWizardActive, userCollegeInterest, setUserCollegeInterest, collegesId, collegeData, setOnlyInterestsSelect, status, expand, statusLabelMaxWidth }) => {

  const {
    handleGAEventCTAClick, handleGAEventTestingWizardNextClick } = DashboardReactGAHandlers()

  const {
    handleGAEventSignInModalOpen,
    handleGAEventSignUpModalOpen
  } = AuthReactGAHandlers()

  let apiToken: any = undefined
  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
  }

  let navigate = useNavigate()
  let blockClass = 'StandardizedTesting'
  // let title = 'Standardized Testing'
  const [title, setTitle] = useState(window.innerWidth <= 500 ? 'SAT/ACT' : 'Standardized Testing');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setTitle('SAT/ACT');
      } else {
        setTitle('Standardized Testing');
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const standardizedTestingPositionRef = useRef<HTMLDivElement>(null);

  let body = `Even though many colleges have adopted test-optional policies in recent years, our general recommendation is to plan on taking the SAT and/or ACT tests during high school. You can subsequently decide whether or not to submit your scores, assisted by our personalized recommendations based on your scores and the specific colleges to which you are applying.`

  let gradYr = user?.hsGradYr ?? undefined
  let testScores = false

  const [wizard, setWizard] = useState<any>({
    page: 1
  })

  // user sat / act ?? undefined
  const [formResponses, setFormResponses] = useState<any>({
    sat: undefined,
    act: undefined,
    satMath: undefined,
    satVerbal: undefined,
    actComposite: undefined,
    actEnglish: undefined,
    actMath: undefined,
    actReading: undefined,
    actScience: undefined,
    actWriting: undefined,
    selectedColleges: userCollegeInterest ? userCollegeInterest.map(obj => obj.college_id) : undefined,
    updateUserColleges: true
  })

  const [collegeOptions, setCollegeOptions] = useState<any>([])
  useEffect(() => {
    const getCollegeOptions = async () => {

      const collegesAxios = await axios.get(`${URL}/colleges/${0}-to-${collegesId?.length}?sortKey=name`)

      let filtered = collegesAxios.data.filter((item) => item.college_cds !== null)
      let collegeOptArr: any = [];
      filtered.forEach(res => {
        collegeOptArr.push({ value: res.id, label: res.name })
      });
      setCollegeOptions(collegeOptArr)
    }

    getCollegeOptions()
  }, [collegesId])

  useEffect(() => {
    if (wizardActive?.StandardizedTesting === false) {
      setWizard({ page: 1 });

      setFormResponses({
        sat: undefined,
        act: undefined,
        satMath: undefined,
        satVerbal: undefined,
        actComposite: undefined,
        selectedColleges: userCollegeInterest ? userCollegeInterest.map(obj => obj.college_id) : undefined,
        updateUserColleges: true
      });
    }

    const updatePosition = () => {
      if (standardizedTestingPositionRef?.current) {
        const element = standardizedTestingPositionRef.current;
        const yOffset = element.getBoundingClientRect().top + window.scrollY - 150;
        window.scrollTo({ top: yOffset, behavior: "smooth" });
      }
    };

    if (wizardActive?.StandardizedTesting == true && standardizedTestingPositionRef?.current) {
      updatePosition();
      window.addEventListener("resize", updatePosition);
    }

    return () => {
      window.removeEventListener("resize", updatePosition);
    };
  }, [wizardActive.StandardizedTesting]);

  useEffect(() => {
    setFormResponses(prevState => ({
      ...prevState, selectedColleges: userCollegeInterest ? userCollegeInterest.map(obj => obj.college_id) : undefined
    }))
  }, [userCollegeInterest])

  if (gradYr) {
    let yearDif = gradYr - currentYear

    if (yearDif && yearDif > 3) {
      body = `Even though many colleges have adopted test-optional policies in recent years, our general recommendation is to plan on taking the SAT and/or ACT tests during high school. No action required now as we recommend waiting until at least sophomore year of high school before diving into test preparation. In the meantime you can explore the test score guidance feature to get a sense for current score benchmarks at various colleges.`
    }
  }

  if (user) {
    let sat = (user?.satMath && user?.satVerbal);
    let act = !!user?.actComposite;

    if (sat || act) {
      testScores = true
    }
  }

  const authButton = <button
    onClick={() => {
      handleGAEventCTAClick(title);

      testScores ? navigate(pageRoutes.testScoreStrategy) : setWizardActive(prevState => ({
        ...prevState, StandardizedTesting: true
      }))
    }}
    className="dca-dashboard-button"
    style={{ marginTop: status === 'inactive' ? '34px' : '' }}>Test Score Guidance</button>

  const nonAuthButton = <button
    className="dca-dashboard-button text-center align-items-center"
    data-bs-toggle="modal"
    data-bs-target="#login_modal"
    style={{ color: 'black', cursor: 'pointer', marginTop: status === 'inactive' ? '34px' : '' }}
    onClick={() => {
      handleGAEventCTAClick(title);
      // Remove modal setter, don't navigate yet since we need to check for wizard first
      // setTestScoreStrategyModal(apiToken, navigate, setPrompt, setNavigateTo);
      setPrompt('Log-in required to access personalized recommendations for test scores. Please sign-in to your account (or sign-up for a free account) below:')

      setNavigateTo('standardizedTestingWizard')

      apiToken == undefined && handleGAEventSignInModalOpen('forced')

    }}
  >Test Score Guidance</button>

  const button = apiToken ? authButton : nonAuthButton

  const resources = [
    {
      navigate: pageRoutes.personalizedExpertHelp,
      title: 'Expert Q&A',
      description: 'Get personalized help for deciding whether to re-take or submit current scores'
    }
  ]

  const insights = [
    {
      navigate: pageRoutes.overviewAdmissionsAdviceWithHash('determining-a-standardized-testing-strategy'),
      text: 'Strategy for SAT/ACT Standardized Testing'
    }
  ]

  const returnWizardQuestion = () => {
    switch (true) {
      case wizard.page == 1:
        return `Have you already taken the SAT and/or ACT?`
      case wizard.page == 2 && (formResponses.sat || formResponses.act):
        return `What are your highest test scores?`
      case wizard.page == 2 && !(formResponses.sat && formResponses.act):
        return `What colleges are you considering?`
      // 3a
      case wizard.page == 3 && (formResponses.sat || formResponses.act):
        return `What colleges are you considering?`
      // 3b
      case wizard.page == 3 && !(formResponses.sat && formResponses.act):
        return ``
    }
  }

  const returnWizardResponseForm = () => {

    let page1 = (
      <div className='dca-dashboard-wizard-form-wrapper'>
        <div className="checkbox-list d-flex flex-column" style={{ marginTop: '5px' }}>

          <RadioInput
            selectedState={formResponses.sat === true && formResponses.act === false}
            onClick={() => setFormResponses(prevState => ({
              ...prevState, sat: true, act: false
            }))}
            text="I've taken the SAT"
            name="test_taken"
            id="test_taken" />

          <RadioInput
            selectedState={formResponses.sat === false && formResponses.act === true}
            onClick={() => setFormResponses(prevState => ({
              ...prevState, sat: false, act: true
            }))}
            text="I've taken the ACT"
            name="test_taken"
            id="test_taken"
            mt="10px"
            mobileMT="18px" />

          <RadioInput
            selectedState={formResponses.sat === true && formResponses.act === true}
            onClick={() => setFormResponses(prevState => ({
              ...prevState, sat: true, act: true
            }))}
            text="I've taken the SAT and ACT"
            name="test_taken"
            id="test_taken"
            mt="10px"
            mobileMT="18px" />

          <RadioInput
            selectedState={formResponses.sat === false && formResponses.act === false}
            onClick={() => setFormResponses(prevState => ({
              ...prevState, sat: false, act: false
            }))}
            text="I've taken neither"
            name="test_taken"
            id="test_taken"
            mt="10px"
            mobileMT="18px" />

        </div>

        <button
          className="dca-dashboard-wizard-button"
          onClick={() => {
            handleGAEventTestingWizardNextClick('Taken');

            setWizard(prevState => ({
              ...prevState, page: prevState.page + 1
            }))
          }
          }
          disabled={(formResponses.sat != undefined && formResponses.act != undefined) ? false : true}>Next</button>
      </div>)

    const enterSATScores = (
      <div className="d-flex flex-column justify-content-center" style={{ marginBottom: '30px' }}>
        {formResponses.sat && formResponses.act && <p className='dca-testscore-editscore-sat-section-title text-center' style={{ marginTop: '0px' }}>SAT Scores</p>}

        <div className="d-flex flex-column">

          <div style={{ marginRight: '20px', marginBottom: '15px' }}>
            <label className={`form-label dca-testscore-editscore-field-title`}>Best SAT Reading Score</label>
            <div className="d-flex flex-row">
              <input
                type="number"
                name="SATVerbal"
                min={0}
                max={800}
                className="form-control dca-dashboard-editscore-input"
                placeholder={'0-800'}
                value={formResponses.satVerbal === undefined ? '' : formResponses.satVerbal}
                onChange={(event) => {
                  setFormResponses(prevState => ({ ...prevState, satVerbal: parseInt(event.target.value) }))
                }}
              /><i
                className="bi bi-x-circle align-self-center"
                style={{ cursor: 'pointer', marginLeft: '5px' }}
                onClick={() => setFormResponses(prevState => ({ ...prevState, satVerbal: undefined }))}
              ></i></div>
          </div>

          <div>
            <label className={`form-label dca-testscore-editscore-field-title`}>Best SAT Math Score</label>
            <div className="d-flex flex-row">
              <input
                type="number"
                name="SATMath"
                min={0}
                max={800}
                className="form-control dca-dashboard-editscore-input"
                placeholder={'0-800'}
                value={formResponses.satMath === undefined ? '' : formResponses.satMath}
                onChange={(event) => {
                  setFormResponses(prevState => ({ ...prevState, satMath: parseInt(event.target.value) }))
                }}
              /><i
                className="bi bi-x-circle align-self-center"
                style={{ cursor: 'pointer', marginLeft: '5px' }}
                onClick={() => setFormResponses(prevState => ({ ...prevState, satMath: undefined }))}
              ></i></div></div>
        </div>
      </div>
    )

    const enterACTScores = (
      <div className="d-flex flex-column justify-content-center" style={{ marginBottom: '30px' }}>
        {formResponses.sat && formResponses.act && <p className='dca-testscore-editscore-sat-section-title text-center' style={{ marginTop: '0px' }}>ACT Score</p>}
        <label className={`form-label dca-testscore-editscore-field-title`}>Best ACT Composite Score</label>
        <div className="d-flex flex-row">
          <input
            type="number"
            name={"ACT Composite"}
            min={0}
            max={36}
            className="form-control dca-dashboard-editscore-input"
            placeholder={`0-36`}
            value={formResponses.actComposite === undefined ? '' : formResponses.actComposite}
            onChange={(event) => {
              setFormResponses(prevState => ({ ...prevState, actComposite: parseInt(event.target.value) }))
            }}
          /><i
            className="bi bi-x-circle align-self-center"
            style={{ cursor: 'pointer', marginLeft: '5px' }}
            onClick={() => setFormResponses(prevState => ({ ...prevState, actComposite: undefined }))}
          ></i>
        </div>
      </div>
    )

    const handleCollegeSelectChange = (selectedColleges) => {
      setFormResponses(prevState => ({
        ...prevState, selectedColleges: selectedColleges.map(obj => obj?.value)
      }))
    }

    // sets only interests select to true
    const handleUpdateUserColleges = async (selectedColleges) => {
      let removed = []
      let added = []
      let userColleges = userCollegeInterest ? userCollegeInterest.map(obj => obj.college_id) : undefined

      if (userColleges) {
        removed = userColleges?.filter(collegeId => !selectedColleges?.includes(collegeId))
        added = selectedColleges?.filter(collegeId => !userColleges?.includes(collegeId))
      }

      const addColleges = async (added) => {
        try {
          const postRequests = added?.map((collegeId) => axios.post(`${URL}/user_college_interest/create/`, {
            user_profile_id: user.user_id,
            college_id: collegeId,
            fit: 1
          }))

          await Promise.all(postRequests)
        } catch (error) {
          console.log('Error:', error)
        }
      }

      const removeColleges = async (removed) => {
        try {
          const deleteRequests = removed?.map((collegeId) => {
            let pk = userCollegeInterest.find((item) => item.college_id === collegeId && item.user_profile_id === user?.user_id)?.id
            return axios.delete(`${URL}/user_college_interest/delete/${pk}`)
          })

          await Promise.all(deleteRequests)
        } catch (error) {
          console.log('Error:', error)
        }
      }

      try {
        if (added.length > 0) {
          await addColleges(added)
        }

        if (removed.length > 0) {
          await removeColleges(removed)
        }
      } catch (error) {
        console.log('Error:', error)
      } finally {
        if (added.length > 0 || removed.length > 0) {
          const updateUserInterest = async () => {
            let res = await axios.get(`${URL}/user_college_interest_by_user_id/${user?.user_id}`)
            setUserCollegeInterest(res.data)
          }
          await updateUserInterest();
          setOnlyInterestsSelect(true)
        }
      }
    }

    // #984
    // wrapped in form for min max on submit value validation
    let page2a = (
      <form
        onSubmit={(e) => {
          e.preventDefault();

          const handleNav = async () => {
            //  if user college interest, set the only interest select to true and if there are more than 5 colleges saved already, update the test scores and go directly to the test score strategy page. otherwise hold the test score form responses and update user data on the multiselect college wizard pages
            if (userCollegeInterest?.length > 0) {
              setOnlyInterestsSelect(true);
            }

            let formValues = {
              satMath: formResponses.satMath ?? null,
              satVerbal: formResponses.satVerbal ?? null,
              satComposite: (formResponses.satVerbal != null && formResponses.satMath != null) ? (formResponses.satVerbal + formResponses.satMath) : null,

              actComposite: formResponses.actComposite ?? null,
            }

            try {
              if ((formResponses.sat && formResponses.satMath && formResponses.satVerbal) || (formResponses.act && formResponses.actComposite)) {
                await handleUpdateUserData(user, formValues, setUser, apiToken)
              }
            } catch (error) {
              console.log('Error:', error)
              return
            }

            navigate(pageRoutes.testScoreStrategy)
          }

          userCollegeInterest?.map(obj => obj?.college_id)?.length > 5 ? handleNav() : setWizard(prevState => ({
            ...prevState, page: prevState.page + 1
          }))
        }}
        className='dca-dashboard-wizard-form-wrapper'>

        {formResponses.sat && enterSATScores}

        {formResponses.act && enterACTScores}

        <button
          className="dca-dashboard-wizard-button align-self-center"
          onClick={() => handleGAEventTestingWizardNextClick('Scores')}
          disabled={((formResponses.sat && formResponses.satMath && formResponses.satVerbal) || (formResponses.act && formResponses.actComposite)) ? false : true}>Next</button>
      </form>)

    let page2b = (
      <div className='dca-dashboard-wizard-form-wrapper' style={{ width: '100%' }}>
        <div className='mt-4 d-flex flex-column' style={{ width: '100%' }}>
          <div className='dca-aside-header dca-dashboard-select-label'>COLLEGES</div>
          <MultiSelect options={collegeOptions} value={collegeOptions.filter(obj => formResponses?.selectedColleges?.includes(obj.value))} onChange={handleCollegeSelectChange} />
        </div>

        <button
          className="dca-dashboard-wizard-button"
          onClick={() => {
            handleGAEventTestingWizardNextClick('Colleges');

            setWizard(prevState => ({
              ...prevState, page: prevState.page + 1
            }))
          }}
          disabled={(formResponses?.selectedColleges?.length > 0) ? false : true}>Next</button>

        {(formResponses.selectedColleges == undefined || formResponses.selectedColleges?.length === 0) && <span
          className="dca-dashboard-wizard-skip-wizard-link"
          onClick={() => {
            setFormResponses(prevState => ({
              ...prevState, updateUserColleges: false
            }));
            setWizard(prevState => ({
              ...prevState, page: prevState.page + 1
            }))
          }}>Skip this for now</span>}
      </div>)

    let page3a = <div className='dca-dashboard-wizard-form-wrapper' style={{ width: '100%' }}>
      <div className='mt-4 d-flex flex-column' style={{ width: '100%' }}>
        <div className='dca-aside-header dca-dashboard-select-label'>COLLEGES</div>
        <MultiSelect options={collegeOptions} value={collegeOptions.filter(obj => formResponses?.selectedColleges?.includes(obj.value))} onChange={handleCollegeSelectChange} />
      </div>

      <button
        className="dca-dashboard-wizard-button"
        onClick={async () => {
          handleGAEventTestingWizardNextClick('Colleges');

          try {
            let formValues = {
              satMath: formResponses.satMath ?? null,
              satVerbal: formResponses.satVerbal ?? null,
              satComposite: (formResponses.satVerbal != null && formResponses.satMath != null) ? (formResponses.satVerbal + formResponses.satMath) : null,

              actComposite: formResponses.actComposite ?? null,
            }

            if ((formResponses.sat && formResponses.satMath && formResponses.satVerbal) || (formResponses.act && formResponses.actComposite)) {
              await handleUpdateUserData(user, formValues, setUser, apiToken)
            }

            if (formResponses.updateUserColleges === true) {
              await handleUpdateUserColleges(formResponses.selectedColleges);
            }
          } catch (error) {
            console.log('Error:', error)
            setOnlyInterestsSelect(false)
            return
          }
          navigate(pageRoutes.testScoreStrategy)
        }}
        disabled={(formResponses.selectedColleges?.length > 0) ? false : true}>Next</button>

      {(formResponses.selectedColleges == undefined || formResponses.selectedColleges?.length === 0) && <span
        className="dca-dashboard-wizard-skip-wizard-link"
        onClick={async () => {
          let formValues = {
            satMath: formResponses.satMath ?? null,
            satVerbal: formResponses.satVerbal ?? null,
            satComposite: (formResponses.satVerbal != null && formResponses.satMath != null) ? (formResponses.satVerbal + formResponses.satMath) : null,

            actComposite: formResponses.actComposite ?? null,
          }

          try {
            if ((formResponses.sat && formResponses.satMath && formResponses.satVerbal) || (formResponses.act && formResponses.actComposite)) {
              await handleUpdateUserData(user, formValues, setUser, apiToken)
            }
          } catch (error) {
            console.log('Error:', error)
            return
          }

          setOnlyInterestsSelect(false);
          navigate(pageRoutes.testScoreStrategy)
        }}>Skip this for now</span>}
    </div>

    let page3b = (
      <div className='d-flex flex-column'>
        <span className="dca-dashboard-standardized-3b-text">{`You must have a current SAT and/or ACT score in order to recieve personalized test strategy guidance. In the meantime, you may still click through to explore information on standardized testing for ${userCollegeInterest?.length > 1 || formResponses.selectedColleges.length > 1 ? `the colleges on your interest list (along with all the top-ranked schools.)` : `the top-ranked schools.`} You will find school-specific details on test policy, benchmark scores, and submission rates`}
        </span>

        <button
          className="dca-dashboard-wizard-button"
          style={{ marginBottom: '0px', marginTop: '50px', width: '33.1%', minWidth: '334px' }}
          onClick={async () => {
            handleGAEventTestingWizardNextClick('Research Benchmarks');

            try {
              if (formResponses?.selectedColleges?.length > 0 && formResponses?.updateUserColleges === true) {
                await handleUpdateUserColleges(formResponses.selectedColleges);
              }
            } catch (error) {
              console.log('Error:', error);
              setOnlyInterestsSelect(false)
              return
            }
            navigate(pageRoutes.testScoreStrategy)
          }}>Research Score Benchmarks</button>

        <button
          className="dca-dashboard-wizard-button"
          style={{ background: '#fafafa', margin: '35px auto 0 auto', width: '33.1%', minWidth: '334px' }}
          onClick={() => {
            setWizardActive(prevState => ({
              ...prevState,
              [blockClass]: false,
            }));
          }}
        >Close</button>

      </div>
    )

    switch (true) {
      // Test Score checkbox selector
      case wizard.page == 1:
        return page1
      // Input test scores
      case wizard.page == 2 && (formResponses.sat || formResponses.act):
        return page2a
      // Skip entering test scores and Select colleges
      case wizard.page == 2 && !(formResponses.sat && formResponses.act):
        return page2b
      // Select colleges after entering test scores
      case wizard.page == 3 && (formResponses.sat || formResponses.act):
        return page3a
      // Dynamic text
      case wizard.page == 3 && !(formResponses.sat && formResponses.act):
        return page3b
    }
  }

  const sharedWizardContent = <div className='dca-dashboard-wizard-header'>
    <div
      className="dca-intro-back-link"
      onClick={() => wizard.page > 1 ? setWizard(prevState => ({
        ...prevState, page: prevState.page - 1
      })) : setWizardActive(prevState => ({
        ...prevState, [blockClass]: false
      }))}><i className="fa-solid fa-arrow-left dca-intro-backArrow"></i>&nbsp;&nbsp;Back</div>

    <img className='dca-dashboard-wizard-home-icon' src={wizardHome} alt='home_icon' onClick={() => {
      setWizardActive(prevState => ({
        ...prevState,
        [blockClass]: false,
      }));
    }}></img>

    <div
      className="dca-intro-back-link"
      style={{ visibility: 'hidden' }}
    ><i className="fa-solid fa-arrow-left dca-intro-backArrow"></i>&nbsp;&nbsp;Back</div>
  </div>

  let noteTrueA = wizard.page == 2 && formResponses.sat == false && formResponses.act == false
  let noteTrueB = wizard.page == 3 && (formResponses.sat == true || formResponses.act == true)

  const returnNoteTrue = () => {
    if (noteTrueA) {
      return true
    }

    if (noteTrueB) {
      return true
    }

    return false
  }

  const returnNoteText = () => {

    let len = userCollegeInterest?.length ?? 0

    if (len > 0 && collegeOptions?.length > 0) {
      return `Previously saved colleges are displayed; you can add/modify the list here (or later)`
    }

    if (noteTrueA) {
      return `You can select multiple (and always add/modify your list later)`
    }

    if (noteTrueB) {
      return `You can select multiple (and always add/modify your list later)`
    }


  }

  const wizardContent = (<div ref={standardizedTestingPositionRef} className="d-flex flex-column justify-content-center">

    {sharedWizardContent}

    <h4 className="dca-introflow-question-text" id={wizard.page == 3 && !formResponses.sat && !formResponses.act ? "" : "dca-introflow-question-text-wizard-margin"}>{returnWizardQuestion()}</h4>
    {returnNoteTrue() && <span className="dca-dashboard-wizard-select-multiple-note">{returnNoteText()}</span>}

    {returnWizardResponseForm()}

  </div>
  );

  const progressTracker = {
    color: status,
    text: returnProgressTrackerText(testScores, userCollegeInterest, collegeData, user),
    count: undefined,
    subject: undefined
  }

  const block = {
    class: blockClass,
    title: title,
    body: body,
    icon: StandardizedTestingIcon,
    specialIconClass: 'dca-dashboard-standardizedIcon',
    button: button,
    resources: resources,
    insights: insights,
    wizardContent: wizardContent,
    status: status,
    expand: expand,
    progressTracker: status != 'inactive' && progressTracker
  }

  return <ContentBlock block={block} wizardActive={wizardActive} setWizardActive={setWizardActive} statusLabelMaxWidth={statusLabelMaxWidth} />
}

export { StandardizedTesting }