import axios from 'axios'
import { updateTitleAndDescription, pageRoutes, sortLogic, setting, locationOptions, URL, handleLocalStorageData } from '../components/dictionary'
import { useState, useEffect, Fragment } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CollegeSearchAside } from '../components/CollegeSearch/CollegeSearchAside'
import { CollegeCard } from '../components/CollegeSearch/CollegeCard'
import { useAuth, getAuth } from '../modules/auth'
import { concatLocation, returnParameterString } from '../components/searchPageURLManager'
import { LoadingIndicator } from '../components/LoadingIndicator'
import { CollegeSearchAsideMobile } from '../components/CollegeSearch/CollegeSearchAsideMobile'

const sortKeyOptions = { 'Metarank': 'metaRank', 'Earnings': 'median4yrEarnings', 'Undergrads': 'numUndergrads', 'Median SAT': 'satComposite50', 'Median ACT': 'actComposite50' }

const CollegeSearchPage = ({ user, userCollegeInterest, setUserCollegeInterest, collegesId, setPrompt, setNavigateTo, collegeSearchLocation, setCollegeSearchLocation }) => {

  const location = useLocation()
  const navigate = useNavigate()

  // initialize variable to return in component(s)
  let apiToken: any = undefined
  let permissionGroups: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
    permissionGroups = getAuth()?.groups
  }

  const { currentUser, auth } = useAuth()

  useEffect(() => {
    if (getAuth()?.api_token && getAuth()?.groups) {
      apiToken = getAuth()?.api_token
      permissionGroups = getAuth()?.groups
    }
  }, [user])

  //////// useStates //////// 
  // Full college result array
  const [colleges, setColleges] = useState<any>([])

  // Full array of filtered colleges
  const [filteredCollegesFull, setFilteredCollegesFull] = useState<any>([])

  // Filtered college array, after splice
  const [filteredColleges, setFilteredColleges] = useState<any>([])

  // Max items to display as results
  const [showLength, setShowLength] = useState(25)

  // Colleges filtered state, false when sort and filter in progress or filters return no results, true when complete
  const [filteredResults, setFilteredResults] = useState<any>(false)

  const [selectorNameOptions, setSelectorNameOptions] = useState<any>([])
  const [selectorLocationOptions, setSelectorLocationOptions] = useState<any>([])
  const [sortKey, setSortKey] = useState<any>('metaRank')
  const [sortOrder, setSortOrder] = useState<any>('Ascending')
  const [earlyApplicationOfferedCount, setEarlyAppOptionCount] = useState<any>(0)
  const [commonAppCount, setCommonAppCount] = useState<any>(0)
  const [coalitionCount, setCoalitionCount] = useState<any>(0)
  const [individualAppCount, setIndividualAppCount] = useState<any>(0)
  const [considersInterestCount, setConsidersInterestCount] = useState<any>(0)

  // Show only interests states
  // State of checkbox
  const [onlyInterestsSelect, setOnlyInterestsSelect] = useState(false)
  // State to pass to sort and filter, if true override all card results with a message
  const [guestClickShowOnlyInterest, setGuestClickShowOnlyInterest] = useState(false)

  const [advisorData, setAdvisorData] = useState<any>([])
  const [advisorCollegeIDs, setAdvisorCollegeIDs] = useState<any>([])

  const [collegeSetOptions, setCollegeSetOptions] = useState<any>([])

  const [showOnlyMyInterestsFilter, setShowOnlyMyInterestsFilter] = useState<any>((item) => {
    return item => item
  })

  useEffect(() => {

    const returnShowOnlyInterestFilter = (onlyInterestsSelect) => {

      if (onlyInterestsSelect === true && userCollegeInterest && userCollegeInterest.length > 0) {
        setShowOnlyMyInterestsFilter((item) => {
          return item => userCollegeInterest.some((obj) => obj.college_id == item.id
          )
        }
        )
      }

      else if (onlyInterestsSelect === true && (!userCollegeInterest || (userCollegeInterest && !(userCollegeInterest.length > 0)))) {
        setShowOnlyMyInterestsFilter((item) => {
          return item => false
        })
      }

      else {
        setShowOnlyMyInterestsFilter((item) => {
          return item => item
        })
      }

    }
    returnShowOnlyInterestFilter(onlyInterestsSelect)

  }, [onlyInterestsSelect])

  //////// End of useStates //////// 

  const handleSortChange = () => {
    sortOrder === 'Ascending' ? setSortOrder('Descending') : setSortOrder('Ascending')
  }

  const handleSortKeyChange = (event) => {
    const selectedKey = event.target.value
    setSortKey(selectedKey)
  }

  // States for filter values
  const [nameFilter, setNameFilter] = useState<any>([])
  const [institutionTypeFilter, setInstitutionTypeFilter] = useState(0)
  const [locationFilter, setLocationFilter] = useState<any>([])
  const [settingFilter, setSettingFilter] = useState<any>('')
  const [sizeFilter, setSizeFilter] = useState(0)
  const [selectivityMin, setSelectivityMin] = useState<any>(0)
  const [selectivityMax, setSelectivityMax] = useState<any>(100)
  const [testPolicyFilter, setTestPolicyFilter] = useState<any>('')
  const [earlyAppOptionFilter, setEarlyAppOptionFilter] = useState<any>(null)
  const [commonAppFilter, setCommonAppFilter] = useState<any>(null)
  const [coalitionFilter, setCoalitionFilter] = useState<any>(null)
  const [considersInterestFilter, setConsidersInterestFilter] = useState<any>(null)
  const [individualAppFilter, setIndividualAppFilter] = useState<any>(null)
  const [appDateSelect, setAppDateSelect] = useState<any>(0)
  const [collegeSetSelect, setCollegeSetSelect] = useState<any>('')

  const [resetStateToggle, setResetStateToggle] = useState(false)

  const resetFilterStates = () => {

    setNameFilter([]);
    setInstitutionTypeFilter(0);
    setLocationFilter([]);
    setSettingFilter('');
    setSizeFilter(0);
    setSelectivityMin(0);
    setSelectivityMax(100);
    setTestPolicyFilter('');
    setEarlyAppOptionFilter(null);
    setCommonAppFilter(null);
    setCoalitionFilter(null);
    setConsidersInterestFilter(null);
    setIndividualAppFilter(null);
    setAppDateSelect(0);
    setOnlyInterestsSelect(false);
    setGuestClickShowOnlyInterest(false);

    setResetStateToggle(!resetStateToggle)

  }

  const [filtersActive, setFiltersActive] = useState(0)

  // States to store filter logic based on the filter values
  const [filteredNames, setFilteredNames] = useState<any>((item) => {
    return item => item
  })
  const [filteredType, setFilteredType] = useState<any>((item) => {
    return item => item
  })
  const [filteredLocations, setFilteredLocations] = useState<any>((item) => {
    return item => item
  })
  const [filteredSetting, setFilteredSetting] = useState<any>((item) => {
    return item => item
  })
  const [filteredSize, setFilteredSize] = useState<any>((item) => {
    return item => item
  })
  const [filteredSelectivity, setFilteredSelectivity] = useState<any>((item) => {
    return item => item
  })
  const [filteredTestPolicy, setFilteredTestPolicy] = useState<any>((item) => {
    return item => item
  })
  const [filteredEarlyAppOptions, setFilteredEarlyAppOptions] = useState<any>((item) => {
    return item => item
  })
  const [filteredCommonApp, setFilteredCommonApp] = useState<any>((item) => {
    return item => item
  })
  const [filteredCoalition, setFilteredCoalition] = useState<any>((item) => {
    return item => item
  })
  const [filteredIndividualApp, setFilteredIndividualApp] = useState<any>((item) => {
    return item => item
  })
  const [filteredConsidersInterest, setFilteredConsidersInterest] = useState<any>((item) => { return item => item })
  const [filteredAppDate, setFilteredAppDate] = useState<any>((item) => { return item => item })
  const [filteredCollegeSet, setFilteredCollegeSet] = useState<any>((item) => { return item => item })

  //////// useEffects //////// 
  useEffect(() => {
    const sampleInput = {};
    const individualFilters = [
      filteredNames,
      filteredCollegeSet,
      filteredType,
      filteredLocations,
      filteredSetting,
      filteredSize,
      filteredSelectivity,
      filteredTestPolicy,
      filteredAppDate
    ];

    const groupedFilters = [
      filteredEarlyAppOptions,
      filteredCommonApp,
      filteredCoalition,
      filteredIndividualApp,
      filteredConsidersInterest,
    ];

    const isFilterActive = (filter) => filter(sampleInput) !== sampleInput;

    const activeIndividualFiltersCount = individualFilters.filter(isFilterActive)?.length || 0;
    const isGroupedFilterActive = groupedFilters.some(isFilterActive) ? 1 : 0;
    const countOfActive = activeIndividualFiltersCount + isGroupedFilterActive;

    setFiltersActive(countOfActive);


  }, [
    filteredNames,
    filteredCollegeSet,
    filteredType,
    filteredLocations,
    filteredSetting,
    filteredSize,
    filteredSelectivity,
    filteredTestPolicy,
    filteredEarlyAppOptions,
    filteredCommonApp,
    filteredCoalition,
    filteredIndividualApp,
    filteredConsidersInterest,
    filteredAppDate,
  ])

  useEffect(() => {
    updateTitleAndDescription(pageRoutes.collegeSearch)

    const getData = async () => {

      const handleSetAdvisors = async () => {

        const getAdvisorsData = async () => {
          let res = await axios.get(`${URL}/advisor_profiles_connect_with/`);
          return res.data
        }

        let advisorCache: any = await handleLocalStorageData('dantech-advisors', getAdvisorsData)

        let advisorColleges: any = []
        advisorCache.map(advisor => {
          if (advisor.college_id) {
            advisorColleges.push(parseInt(advisor.college_id))
          }
        })

        setAdvisorData(advisorCache)
        setAdvisorCollegeIDs([...new Set(advisorColleges)])
      }

      await handleSetAdvisors()

      const getCollegeSet = async () => {
        const collegeSetAxios = await axios.get(`${URL}/collegeset/`)

        let collegeSetRes: any = []
        collegeSetAxios.data.forEach((res) => {
          collegeSetRes.push({ value: res.colleges, label: res.name })
        });

        setCollegeSetOptions(collegeSetRes)
      }

      await getCollegeSet()
    }

    getData()

  }, [])

  useEffect(() => {

    const getCollegesData = async () => {
      let res = await axios.get(`${URL}/colleges/${0}-to-${collegesId.length}?sortKey=${sortKey}`)
      return res.data
    }

    const getColleges = async () => {

      let collegeCache: any = await handleLocalStorageData('dantech-colleges', getCollegesData)

      const mapAndSet = (collegesRes) => {
        let nameOps = collegesRes.map((college) => ({
          value: college.name,
          label: college.name,
        }));
        nameOps.sort((a, b) => a.value.localeCompare(b.value));
        setSelectorNameOptions(nameOps);
      };

      setColleges(collegeCache)
      mapAndSet(collegeCache)
      setSelectorLocationOptions(locationOptions)
    }

    getColleges();
  }, [collegesId])

  //// Filter triggers to update state of filter logic variables ////
  // nameFilter Trigger
  useEffect(() => {
    const filterCollegesByName = (nameFilter) => {
      //nameFilter is an array of strings
      if (nameFilter.length !== 0) {
        setFilteredNames((item) => {
          // Return one result
          // return item => item.name.includes(nameFilter)

          // Switch back to return each selected name if present
          return item => nameFilter.includes(item.name)
        })
      }
      else {
        setFilteredNames((item) => {
          return item => item
        })
      }
    };
    filterCollegesByName(nameFilter)
  }, [nameFilter])

  // typeFilter Trigger
  useEffect(() => {

    const filterCollegesByType = (institutionTypeFilter) => {
      switch (institutionTypeFilter) {
        case 0:
          setFilteredType((item) => {
            return item => item
          })
          break;

        case 1:
          setFilteredType((item) => {
            return item => item.type === 1
          })
          break;

        case 2:
          setFilteredType((item) => {
            return item => [2, 3].includes(item.type)
          })
          break;

        default:
          setFilteredType((item) => {
            return item => item
          })
      };
    };

    filterCollegesByType(institutionTypeFilter)

  }, [institutionTypeFilter])

  ///Need to change locationFilter value ? locationState is an int, if value is the string there wont be a match
  // locationFilter Trigger
  useEffect(() => {

    const filterCollegesByLocation = (locationFilter) => {
      if (locationFilter.length !== 0) {
        setFilteredLocations((item) => {
          return item => locationFilter.includes(item.locationState)
        })
      }
      else {
        setFilteredLocations((item) => {
          return item => item
        })
      }
    };
    filterCollegesByLocation(locationFilter)

  }, [locationFilter])

  // settingFilter Trigger
  useEffect(() => {

    const filterCollegesBySetting = (settingFilter) => {
      if (settingFilter !== '') {
        setFilteredSetting((item) => {
          return item => setting[settingFilter].includes(item.setting)
        })
      }

      else {
        setFilteredSetting((item) => {
          return item => item
        })
      };
    }
    filterCollegesBySetting(settingFilter)
  }, [settingFilter])

  // sizeFilter Trigger
  useEffect(() => {

    const filterCollegesBySize = (sizeFilter) => {
      if (sizeFilter > 0) {
        setFilteredSize((item) => {
          return item => item.size === sizeFilter
        })
      }

      else {
        setFilteredSize((item) => {
          return item => item
        })
      };
    }
    filterCollegesBySize(sizeFilter)

  }, [sizeFilter])

  // selectivityFilter Trigger
  useEffect(() => {

    const filterCollegesBySelectivity = (selectivityMin, selectivityMax) => {

      if (selectivityMin !== 0) {
        setFilteredSelectivity((item) => {
          return item => (Array.isArray(item.college_cds) &&
            item.college_cds[0] &&
            item.college_cds[0].totalAdmitRate * 100 >= selectivityMin &&
            item.college_cds[0].totalAdmitRate * 100 <= selectivityMax)
        })
      }

      else if (selectivityMax !== 100) {
        setFilteredSelectivity((item) => {
          return item => (Array.isArray(item.college_cds) &&
            item.college_cds[0] &&
            item.college_cds[0].totalAdmitRate * 100 >= selectivityMin &&
            item.college_cds[0].totalAdmitRate * 100 <= selectivityMax)
        })
      }

      else {
        setFilteredSelectivity((item) => {
          return item => item
        })
      }

    };
    filterCollegesBySelectivity(selectivityMin, selectivityMax)

  }, [selectivityMin, selectivityMax])

  // testPolicyFilter Trigger
  useEffect(() => {

    const filterCollegesByTestPolicy = (testPolicyFilter) => {
      if (testPolicyFilter > 0) {
        setFilteredTestPolicy((item) => {
          return item => item.testPolicy === testPolicyFilter
        })
      }

      else {
        setFilteredTestPolicy((item) => {
          return item => item
        })
      };
    }
    filterCollegesByTestPolicy(testPolicyFilter)

  }, [testPolicyFilter])

  // appDetails Trigger
  useEffect(() => {
    const filterAppOptions = (earlyAppOptionFilter, commonAppFilter, coalitionFilter, individualAppFilter, considersInterestFilter) => {

      switch (earlyAppOptionFilter) {
        case true:
          setFilteredEarlyAppOptions((item) => {
            return item => item.earlyApplicationOffered === true
          })
          break;
        default:
          setFilteredEarlyAppOptions((item) => {
            return item => item
          })
      };

      switch (commonAppFilter) {
        case true:
          setFilteredCommonApp((item) => {
            return item => item.commonApp === true
          })
          break;
        default:
          setFilteredCommonApp((item) => {
            return item => item
          })
      };

      switch (coalitionFilter) {
        case true:
          setFilteredCoalition((item) => {
            return item => item.coalitionApp === true
          })
          break;
        default:
          setFilteredCoalition((item) => {
            return item => item
          })
      };

      switch (individualAppFilter) {
        case true:
          setFilteredIndividualApp((item) => {
            return item => item.individualApp === true
          })
          break;
        default:
          setFilteredIndividualApp((item) => {
            return item => item
          })
      };

      switch (considersInterestFilter) {
        case true:
          setFilteredConsidersInterest((item) => {
            return item => item.considersInterest === true
          })
          break;
        default:
          setFilteredConsidersInterest((item) => {
            return item => item
          })
      };
    };
    filterAppOptions(earlyAppOptionFilter, commonAppFilter, coalitionFilter, individualAppFilter, considersInterestFilter)
  }, [earlyAppOptionFilter, commonAppFilter, coalitionFilter, individualAppFilter, considersInterestFilter])

  useEffect(() => {

    const returnAppDateFilter = (appDateSelect) => {
      const currentDate = new Date();

      const dateExtender = (date: Date, days: number) => {
        const res = new Date(date);
        res.setDate(res.getDate() + days);
        return res;
      };

      const getAppDates = (item) => {
        return item.college_application_rounds
          ? item.college_application_rounds.map(round => round.deadline).filter(date => date).map(date => new Date(date)) : [];
      };

      switch (appDateSelect) {
        case 0:
          return setFilteredAppDate((item) => {
            return item => item;
          });

        // In the next week, 7 days
        case 1:
          return setFilteredAppDate((item) => {
            return item => {
              const dueDates = getAppDates(item);
              return dueDates?.some(date => date > currentDate && date <= dateExtender(currentDate, 7));
            };
          });

        // In the next 2 weeks, 14 days
        case 2:
          return setFilteredAppDate((item) => {
            return item => {
              const dueDates = getAppDates(item);
              return dueDates?.some(date => date > currentDate && date <= dateExtender(currentDate, 14));
            };
          });

        // In the next 4 weeks, 28 days
        case 3:
          return setFilteredAppDate((item) => {
            return item => {
              const dueDates = getAppDates(item);
              return dueDates?.some(date => date > currentDate && date <= dateExtender(currentDate, 28));
            };
          });

        default:
          return setFilteredAppDate((item) => {
            return item => item;
          });
      }
    };
    returnAppDateFilter(appDateSelect);

  }, [appDateSelect])

  useEffect(() => {

    const returnCollegeSetFilter = (collegeSetSelect) => {

      if (collegeSetSelect == '') {
        setFilteredCollegeSet((item) => {
          return item => item
        })
      }

      else {
        setFilteredCollegeSet((item) => {
          return item => collegeSetSelect.includes(item.id)
        })
      }
    }

    returnCollegeSetFilter(collegeSetSelect)

  }, [collegeSetSelect])

  //////// Apply all filters once filter logic has changed ////////
  useEffect(() => {
    const applyFilters = (filteredNames, filteredType, filteredLocations, filteredSetting, filteredSize, filteredTestPolicy, filteredEarlyAppOptions, filteredCommonApp, filteredCoalition, filteredIndividualApp, filteredConsidersInterest, filteredSelectivity, sortKey, sortOrder, showOnlyMyInterestsFilter, filteredAppDate, filteredCollegeSet) => {

      if (onlyInterestsSelect == true && (!currentUser || !user)) {
        return setGuestClickShowOnlyInterest(true)
      }

      else {
        setGuestClickShowOnlyInterest(false)
      }

      let res: any[] = colleges

      res = res.filter(filteredNames).filter(filteredType).filter(filteredLocations).filter(filteredSetting).filter(filteredSize).filter(filteredTestPolicy).filter(filteredEarlyAppOptions).filter(filteredCommonApp).filter(filteredCoalition).filter(filteredIndividualApp).filter(filteredConsidersInterest).filter(filteredSelectivity).filter(showOnlyMyInterestsFilter).filter(filteredAppDate).filter(filteredCollegeSet)

      if (res.length === 0) {
        return setFilteredResults(false)
      }

      // Before splice, set full to res
      setFilteredCollegesFull(res)

      switch (true) {
        case sortKey === 'satComposite50':
          sortOrder === 'Descending' ?
            res = res.sort((a, b) => sortLogic(b.college_cds[0], a.college_cds[0], 'satComposite50', 'name')) :
            res = res.sort((a, b) => sortLogic(a.college_cds[0], b.college_cds[0], 'satComposite50', 'name'))
          break;
        case sortKey === 'actComposite50':
          sortOrder === 'Descending' ?
            res = res.sort((a, b) => sortLogic(b.college_cds[0], a.college_cds[0], 'actComposite50', 'name')) :
            res = res.sort((a, b) => sortLogic(a.college_cds[0], b.college_cds[0], 'actComposite50', 'name'))
          break;
        default:
          sortOrder === 'Descending' ?
            res = res.sort((a, b) => sortLogic(b, a, sortKey, 'name')) :
            res = res.sort((a, b) => sortLogic(a, b, sortKey, 'name'))
      }

      let earlyCount = res.filter((item) => item.earlyApplicationOffered === true).length
      let commonCount = res.filter((item) => item.commonApp === true).length
      let coalitionCount = res.filter((item) => item.coalitionApp === true).length
      let individualCount = res.filter((item) => item.individualApp === true).length
      let considersInterest = res.filter((item) => item.considersInterest === true).length

      setEarlyAppOptionCount(earlyCount)
      setCommonAppCount(commonCount)
      setCoalitionCount(coalitionCount)
      setIndividualAppCount(individualCount)
      setConsidersInterestCount(considersInterest)

      ////// Set filtered colleges 
      // Create copy of res using slice and set to new variable
      // Splice on the copy by the amount of showLength
      // Then set state of filtered colleges for cards component
      let splicedRes = res.slice()
      splicedRes = splicedRes.splice(0, showLength)
      setFilteredColleges(splicedRes)

      if (filteredResults === false) {
        setFilteredResults(true)
      }

      if (guestClickShowOnlyInterest == true) {
        setGuestClickShowOnlyInterest(false)
      }

    };

    applyFilters(filteredNames, filteredType, filteredLocations, filteredSetting, filteredSize, filteredTestPolicy, filteredEarlyAppOptions, filteredCommonApp, filteredCoalition, filteredIndividualApp, filteredConsidersInterest, filteredSelectivity, sortKey, sortOrder, showOnlyMyInterestsFilter, filteredAppDate, filteredCollegeSet);

  }, [filteredNames, filteredType, filteredLocations, filteredSetting, filteredSize, filteredTestPolicy, filteredEarlyAppOptions, filteredCommonApp, filteredCoalition, filteredIndividualApp, filteredConsidersInterest, filteredSelectivity, sortOrder, sortKey, colleges, showLength, showOnlyMyInterestsFilter, filteredAppDate, filteredCollegeSet])

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let sortParam = params.get('sort');
    let showParam = params.get('show')

    if (sortParam) {
      const sorts = sortParam.split(',').map(res => res);
      setSortKey(sorts[0])
      setSortOrder(sorts[1])
    }

    if (showParam) {
      setShowLength(parseInt(showParam))
    }

  }, [location.search]);

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let sortDetails: any = [sortKey, sortOrder]

    sortDetails = sortDetails.map((res) => res).join(',')
    params.set('sort', sortDetails)

    setSortParams(`${params.toString()}`)

  }, [sortKey, sortOrder])

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    params.set('show', `${showLength}`)

    setShowLengthParams(`${params.toString()}`)

  }, [showLength])

  //// URL and filter store states
  const [showLengthParams, setShowLengthParams] = useState('')
  const [sortParams, setSortParams] = useState('')
  const [nameParams, setNameParams] = useState('')
  const [typeParams, setTypeParams] = useState('')
  const [locationParams, setLocationParams] = useState('')
  const [settingParams, setSettingParams] = useState('')
  const [sizeParams, setSizeParams] = useState('')
  const [admitParams, setAdmitParams] = useState('')
  const [testPolicyParams, setTestPolicyParams] = useState('')
  const [applicationDetailsParams, setApplicationDetailsParams] = useState('')
  const [onlyInterestsParams, setOnlyInterestsParams] = useState('')
  const [appDateParams, setAppDateParams] = useState('')
  const [collegeSetParams, setCollegeSetParams] = useState('')

  useEffect(() => {

    //  Create an array of the parameter strings
    let params: string[] = []
    // Use the returnParameterString function to fix the string if needed
    params = [
      returnParameterString(onlyInterestsParams, 'onlyInterests'),
      returnParameterString(showLengthParams, 'show'),
      returnParameterString(sortParams, 'sort'),
      returnParameterString(nameParams, 'name'),
      returnParameterString(collegeSetParams, 'collegeSet'),
      returnParameterString(typeParams, 'type'),
      returnParameterString(locationParams, 'location'),
      returnParameterString(settingParams, 'setting'),
      returnParameterString(sizeParams, 'size'),
      returnParameterString(admitParams, 'admitRate'),
      returnParameterString(testPolicyParams, 'testPolicy'),
      returnParameterString(appDateParams, 'appDate'),
      returnParameterString(applicationDetailsParams, 'appDetails')
    ]

    // Set the location string using the base pageRoute and the return from the concat location function
    setCollegeSearchLocation(`${pageRoutes.collegeSearch}${concatLocation(params)}`)
  }, [nameParams, collegeSetParams, typeParams, locationParams, settingParams, sizeParams, admitParams, testPolicyParams, applicationDetailsParams, sortParams, showLengthParams, onlyInterestsParams, appDateParams])

  useEffect(() => {

    window.history.replaceState({}, '', collegeSearchLocation)

  }, [collegeSearchLocation])

  //////// Components to render ////////
  const collegeSearchHeader = (
    <div className="dca-search-header-wrapper">
      <div className="dca-search-header-title-button-container">
        <span className='dca-search-title align-self-center'>Explore Colleges</span>

        <div className="dca-search-header-bttn-container-double">
          <button
            type="button"
            aria-pressed="true"
            onClick={() => navigate(pageRoutes.personalizedExpertHelp)}
            className='dca-search-header-bttn'
          >Get Personalized Help</button>

          <button
            type="button"
            aria-pressed="true"
            onClick={() => navigate(pageRoutes.collegeDataVisualization)}
            className='dca-search-header-bttn'
            style={{ marginLeft: '8px' }}
          >Admission Data Scattergrams</button>

        </div>
      </div>

      <span className="dca-search-description">Find the top-ranked colleges that fit your personal preferences and academic profile. Dive into data on admission statistics, current student benchmarks, and proprietary rankings to help build a balanced interest list.</span>

    </div>
  )

  const collegeSearchHeaderMobile = (
    <div className="dca-search-header-wrapper">
      <div className="dca-search-header-title-button-container">
        <span className='dca-search-title align-self-center'>Explore Colleges</span>

        <span className="dca-search-description">Find the top-ranked colleges that fit your personal preferences and academic profile. Dive into data on admission statistics, current student benchmarks, and proprietary rankings to help build a balanced interest list.</span>

        <CollegeSearchAsideMobile
          filteredColleges={filteredColleges}
          selectorNameOptions={selectorNameOptions}
          collegeSetOptions={collegeSetOptions}
          collegeSetSelect={collegeSetSelect}
          setCollegeSetSelect={setCollegeSetSelect}
          selectorLocationOptions={selectorLocationOptions}
          setNameFilter={setNameFilter}
          institutionTypeFilter={institutionTypeFilter}
          setInstitutionTypeFilter={setInstitutionTypeFilter}
          setLocationFilter={setLocationFilter}
          setSettingFilter={setSettingFilter}
          setSizeFilter={setSizeFilter}
          selectivityMin={selectivityMin}
          selectivityMax={selectivityMax}
          setSelectivityMin={setSelectivityMin}
          setSelectivityMax={setSelectivityMax}
          setTestPolicyFilter={setTestPolicyFilter}
          setEarlyAppOptionFilter={setEarlyAppOptionFilter}
          setCommonAppFilter={setCommonAppFilter}
          setCoalitionFilter={setCoalitionFilter}
          setIndividualAppFilter={setIndividualAppFilter}
          setConsidersInterestFilter={setConsidersInterestFilter}
          earlyApplicationOfferedCount={earlyApplicationOfferedCount}
          commonAppCount={commonAppCount}
          coalitionCount={coalitionCount}
          individualAppCount={individualAppCount}
          considersInterestCount={considersInterestCount}
          collegeSearchLocation={collegeSearchLocation}
          setCollegeSearchLocation={setCollegeSearchLocation}
          earlyAppOptionFilter={earlyAppOptionFilter}
          commonAppFilter={commonAppFilter}
          coalitionFilter={coalitionFilter}
          individualAppFilter={individualAppFilter}
          considersInterestFilter={considersInterestFilter}
          onlyInterestsSelect={onlyInterestsSelect}
          setOnlyInterestsSelect={setOnlyInterestsSelect}
          appDateSelect={appDateSelect}
          setAppDateSelect={setAppDateSelect}
          filtersActive={filtersActive}

          setNameParams={setNameParams}
          setCollegeSetParams={setCollegeSetParams}
          setTypeParams={setTypeParams}
          setLocationParams={setLocationParams}
          setSettingParams={setSettingParams}
          setSizeParams={setSizeParams}
          setAdmitParams={setAdmitParams}
          setTestPolicyParams={setTestPolicyParams}
          setApplicationDetailsParams={setApplicationDetailsParams}
          setOnlyInterestsParams={setOnlyInterestsParams}
          setAppDateParams={setAppDateParams}
          resetFilterStates={resetFilterStates}
          resetStateToggle={resetStateToggle} />

        <div>
          <div className='dca-college-search-resources-links-header'>RESOURCES</div>
          <div
            className='dca-college-search-resources-links'
            onClick={() => navigate(pageRoutes.collegeDataVisualization)}
          >Admission Data Scattergrams</div>
          <div
            className='dca-college-search-resources-links'
            onClick={() => navigate(pageRoutes.personalizedExpertHelp)}
          >Get Personalized Help</div>
        </div>
      </div>
    </div>
  )

  const returnResults = (filteredResults, guestClickShowOnlyInterest, onlyInterestsSelect, colleges) => {

    if (colleges?.length == 0) {
      return (<Fragment>
        {/* Use Bootstrap d-md-block to render while 768px or above */}
        <div className='d-none d-md-flex flex-column justify-content-start align-items-center'>
          <LoadingIndicator text={"Loading Information On Colleges..."} />
        </div>

        {/* Mobile Replacement, only shows at below 768px */}
        <div className='d-flex d-md-none flex-column justify-content-center align-items-center'>
          <LoadingIndicator text={"Loading Information On Colleges..."} mt={'40px'} ml={'0px'} />
        </div>
      </Fragment>)
    }

    else {
      switch (true) {
        case (onlyInterestsSelect === true && colleges.filter(showOnlyMyInterestsFilter).length === 0 && !filteredResults):
          return <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: '1129px', maxWidth: '1129px' }}>
            <div className='mt-5'>Please save colleges to your interest list in order for them to appear here.</div>
          </div>
        case guestClickShowOnlyInterest == true:
          return <div className="d-flex flex-column align-items-center" style={{ width: '1129px', maxWidth: '1129px' }}>
            <div style={{ marginTop: '180px' }}>Please first log-in to your account in order for your saved colleges to appear here.</div>
          </div>
        case filteredResults == true:
          return <CollegeCard
            user={user}
            userCollegeInterest={userCollegeInterest}
            colleges={colleges}
            setColleges={setColleges}
            collegeListLength={collegesId.length}
            filteredColleges={filteredColleges}
            setFilteredColleges={setFilteredColleges}
            setUserCollegeInterest={setUserCollegeInterest}
            sortKeyOptions={sortKeyOptions}
            handleSortKeyChange={handleSortKeyChange}
            handleSortChange={handleSortChange}
            showLength={showLength}
            setShowLength={setShowLength}
            filteredCollegesFull={filteredCollegesFull}
            filtersActive={filtersActive}

            filteredNames={filteredNames}
            filteredType={filteredType}
            filteredLocations={filteredLocations}
            filteredSetting={filteredSetting}
            filteredSize={filteredSize}
            filteredTestPolicy={filteredTestPolicy}
            filteredEarlyAppOptions={filteredEarlyAppOptions}
            filteredCommonApp={filteredCommonApp}
            filteredCoalition={filteredCoalition}
            filteredIndividualApp={filteredIndividualApp}
            filteredConsidersInterest={filteredConsidersInterest}
            filteredSelectivity={filteredSelectivity}
            setFilteredResults={setFilteredResults}
            setSelectorNameOptions={setSelectorNameOptions}
            sortKey={sortKey}
            setSortKey={setSortKey}
            sortOrder={sortOrder}
            setPrompt={setPrompt}
            setNavigateTo={setNavigateTo}

            advisorCollegeIDs={advisorCollegeIDs}
          />
        case filteredResults != true && colleges?.length > 0:
          return <div className="d-flex flex-column align-items-center" style={{ width: '1129px', maxWidth: '1129px' }}>
            <div style={{ marginTop: '180px' }}>No results meet your criteria</div>
          </div>
      }
    }
  }

  // const returnResultsMobile = (filteredResults, guestClickShowOnlyInterest, onlyInterestsSelect, colleges) => {

  //   switch (true) {
  //     case (onlyInterestsSelect === true && colleges.filter(showOnlyMyInterestsFilter).length === 0 && !filteredResults):
  //       return <div className="d-flex flex-column justify-content-center align-items-center">
  //         <div className='mt-5'>Please save colleges to your interest list in order for them to appear here.</div>
  //       </div>
  //     case guestClickShowOnlyInterest == true:
  //       return <div className="d-flex flex-column justify-content-center align-items-center">
  //         <div className='mt-5'>Please first log-in to your account in order for your saved colleges to appear here.</div>
  //       </div>
  //     case filteredResults == true:
  //       return <CollegeCard
  //         user={user}
  //         userCollegeInterest={userCollegeInterest}
  //         colleges={colleges}
  //         setColleges={setColleges}
  //         collegeListLength={collegesId.length}
  //         filteredColleges={filteredColleges}
  //         setFilteredColleges={setFilteredColleges}
  //         setUserCollegeInterest={setUserCollegeInterest}
  //         sortKeyOptions={sortKeyOptions}
  //         handleSortKeyChange={handleSortKeyChange}
  //         handleSortChange={handleSortChange}
  //         showLength={showLength}
  //         setShowLength={setShowLength}
  //         filteredCollegesFull={filteredCollegesFull}
  //         filtersActive={filtersActive}

  //         filteredNames={filteredNames}
  //         filteredType={filteredType}
  //         filteredLocations={filteredLocations}
  //         filteredSetting={filteredSetting}
  //         filteredSize={filteredSize}
  //         filteredTestPolicy={filteredTestPolicy}
  //         filteredEarlyAppOptions={filteredEarlyAppOptions}
  //         filteredCommonApp={filteredCommonApp}
  //         filteredCoalition={filteredCoalition}
  //         filteredIndividualApp={filteredIndividualApp}
  //         filteredConsidersInterest={filteredConsidersInterest}
  //         filteredSelectivity={filteredSelectivity}
  //         setFilteredResults={setFilteredResults}
  //         setSelectorNameOptions={setSelectorNameOptions}
  //         sortKey={sortKey}
  //         setSortKey={setSortKey}
  //         sortOrder={sortOrder}
  //         setPrompt={setPrompt}
  //         setNavigateTo={setNavigateTo}

  //         advisorCollegeIDs={advisorCollegeIDs}
  //       />
  //     case filteredResults != true:
  //       return <div className="d-flex flex-column justify-content-center align-items-center">
  //         <div className='mt-5'>No results meet your criteria</div>
  //       </div>
  //   }
  // }

  return (
    <div id='dca-college-search-wrapper'>
      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex'>
        {collegeSearchHeader}
      </div>

      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-flex d-md-none'>
        {collegeSearchHeaderMobile}
      </div>

      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex'>
        <div className='mt-5 d-flex flex-row' style={{ marginLeft: '35px', marginRight: '35px', width: '100%' }}>
          <CollegeSearchAside
            filteredColleges={filteredColleges}
            selectorNameOptions={selectorNameOptions}
            collegeSetOptions={collegeSetOptions}
            collegeSetSelect={collegeSetSelect}
            setCollegeSetSelect={setCollegeSetSelect}
            selectorLocationOptions={selectorLocationOptions}
            setNameFilter={setNameFilter}
            institutionTypeFilter={institutionTypeFilter}
            setInstitutionTypeFilter={setInstitutionTypeFilter}
            setLocationFilter={setLocationFilter}
            setSettingFilter={setSettingFilter}
            setSizeFilter={setSizeFilter}
            selectivityMin={selectivityMin}
            selectivityMax={selectivityMax}
            setSelectivityMin={setSelectivityMin}
            setSelectivityMax={setSelectivityMax}
            setTestPolicyFilter={setTestPolicyFilter}
            setEarlyAppOptionFilter={setEarlyAppOptionFilter}
            setCommonAppFilter={setCommonAppFilter}
            setCoalitionFilter={setCoalitionFilter}
            setIndividualAppFilter={setIndividualAppFilter}
            setConsidersInterestFilter={setConsidersInterestFilter}
            earlyApplicationOfferedCount={earlyApplicationOfferedCount}
            commonAppCount={commonAppCount}
            coalitionCount={coalitionCount}
            individualAppCount={individualAppCount}
            considersInterestCount={considersInterestCount}
            collegeSearchLocation={collegeSearchLocation}
            setCollegeSearchLocation={setCollegeSearchLocation}
            earlyAppOptionFilter={earlyAppOptionFilter}
            commonAppFilter={commonAppFilter}
            coalitionFilter={coalitionFilter}
            individualAppFilter={individualAppFilter}
            considersInterestFilter={considersInterestFilter}
            onlyInterestsSelect={onlyInterestsSelect}
            setOnlyInterestsSelect={setOnlyInterestsSelect}
            appDateSelect={appDateSelect}
            setAppDateSelect={setAppDateSelect}

            setNameParams={setNameParams}
            setCollegeSetParams={setCollegeSetParams}
            setTypeParams={setTypeParams}
            setLocationParams={setLocationParams}
            setSettingParams={setSettingParams}
            setSizeParams={setSizeParams}
            setAdmitParams={setAdmitParams}
            setTestPolicyParams={setTestPolicyParams}
            setApplicationDetailsParams={setApplicationDetailsParams}
            setOnlyInterestsParams={setOnlyInterestsParams}
            setAppDateParams={setAppDateParams}
            resetStateToggle={resetStateToggle}
          />
          {returnResults(filteredResults, guestClickShowOnlyInterest, onlyInterestsSelect, colleges)}
        </div>
      </div>

      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-flex d-md-none justify-content-center'>
        {returnResults(filteredResults, guestClickShowOnlyInterest, onlyInterestsSelect, colleges)}
      </div>
    </div>)
}

export { CollegeSearchPage }
