import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { useState } from "react";
import { sessionStorageStore } from "./dictionary";
import { handleUpdateUserData } from "../pages/UserProfile";

export const TooltipComponent = ({ content }) => {

  return (
    <OverlayTrigger
      delay={{ hide: 450, show: 300 }}
      overlay={(props) => (
        <Tooltip {...props} className='dca-tooltip'>
          {content}
        </Tooltip>
      )}
      placement="top"
    ><i
      className="bi bi-info-circle-fill"
      style={{ color: 'black' }}
    ></i>
    </OverlayTrigger>
  )
}

export const TooltipComponentDown = ({ content }) => {

  return (
    <OverlayTrigger
      delay={{ hide: 450, show: 300 }}
      overlay={(props) => (
        <Tooltip {...props} className='dca-tooltip'>
          {content}
        </Tooltip>
      )}
      placement="bottom"
    ><i
      className="bi bi-info-circle-fill"
      style={{ color: 'black' }}
    ></i>
    </OverlayTrigger>
  )
}

export const TooltipComponentMobile = ({ content }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleToggleTooltip = () => {
    setShowTooltip((prev) => !prev);
  };

  const handleHideTooltip = () => {
    setShowTooltip(false);
  };

  return (
    <div onClick={(e) => {
      e.stopPropagation();
      e.preventDefault()
    }}>
      <OverlayTrigger
        // delay={{ hide: 450, show: 300 }}
        overlay={(props) => (
          <Tooltip {...props} className='dca-tooltip'>
            <div className="d-flex flex-row">
              <span style={{ paddingRight: '20px' }}>{content}</span>

              <span
                style={{
                  position: 'absolute',
                  top: '2px',
                  right: '10px',
                  cursor: 'pointer',
                  color: 'white',
                  zIndex: 1001,
                  fontSize: '25px',
                  height: '100%'
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleHideTooltip();
                }}>&times;</span>
            </div>

          </Tooltip>
        )}
        placement="top"
        show={showTooltip}
      >
        <i
          className="bi bi-info-circle-fill"
          style={{ color: 'black', cursor: 'pointer', zIndex: '1000' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleToggleTooltip()
          }}
        // onMouseLeave={handleHideTooltip}
        ></i>
      </OverlayTrigger>
    </div>
  );
};

export const TooltipComponentMobileInline = ({ inlineText, content, placeBottom = false }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleToggleTooltip = () => {
    setShowTooltip((prev) => !prev);
  };

  const handleHideTooltip = () => {
    setShowTooltip(false);
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault()
      }}>{inlineText}
      <OverlayTrigger
        // delay={{ hide: 450, show: 300 }}
        overlay={(props) => (
          <Tooltip {...props} className='dca-tooltip'>
            <div className="d-flex flex-row">
              <span style={{ paddingRight: '20px' }}>{content}</span>

              <span
                style={{
                  position: 'absolute',
                  top: '2px',
                  right: '10px',
                  cursor: 'pointer',
                  color: 'white',
                  zIndex: 1001,
                  fontSize: '25px',
                  height: '100%'
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleHideTooltip();
                }}>&times;</span>
            </div>

          </Tooltip>
        )}
        placement={placeBottom ? "bottom" : "top"}
        show={showTooltip}
      >
        <i
          className="ms-2 bi bi-info-circle-fill"
          style={{ color: 'black', cursor: 'pointer', zIndex: '1000' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleToggleTooltip()
          }}
        // onMouseLeave={handleHideTooltip}
        ></i>
      </OverlayTrigger>
    </div>
  );
};

export const TooltipDashboard = ({ show, position, user, setUser, apiToken, setShowDashboardAlert }) => {

  const arrow = (<div
    className="dca-header-dashboard-alert-pointer"
    style={{
      position: "absolute",
      top: "-8px",
      left: "16%",
      transform: "translateX(-50%)",
      width: "0",
      height: "0",
    }}
  ></div>)

  return <div
    className="dca-header-dashboard-alert"
    style={{
      position: "absolute",
      top: `${position.top}px`,
      left: `${position.left}px`,
    }}
  >
    <span>Use this link to get back to your dashboard anytime!</span>
    <span
      className="dca-font-M align-self-end"
      style={{
        cursor: "pointer",
        fontSize: "18px",
        lineHeight: "170.4%",
      }}
      onClick={() => {

        if (user?.dashboardAlertShown === false) {
          handleUpdateUserData(user, { dashboardAlertShown: true }, setUser, apiToken)
        }

        sessionStorageStore('dashboardAlertShown', 'true')
        setShowDashboardAlert(false);
      }}
    >
      Got it
    </span>

    {arrow}
  </div>
}

export const TooltipDashboardMobile = ({ show, position, user, setUser, apiToken, setShowDashboardAlert }) => {
  const arrow = (<div
    className="dca-header-dashboard-alert-pointer"
    style={{
      position: "absolute",
      top: "-8px",
      left: "12.5%",
      transform: "translateX(-50%)",
      width: "0",
      height: "0",
    }}
  ></div>)

  return <div
    className="dca-header-dashboard-alert"
    style={{
      position: "absolute",
      top: `${position.top}px`,
      left: `${position.left}px`,
    }}
  >
    <span>Use this menu to get back to your dashboard anytime!</span>
    <span
      className="dca-font-M align-self-end"
      style={{
        cursor: "pointer",
        fontSize: "18px",
        lineHeight: "170.4%",
      }}
      onClick={() => {

        if (user?.dashboardAlertShown === false) {
          handleUpdateUserData(user, { dashboardAlertShown: true }, setUser, apiToken)
        }

        sessionStorageStore('dashboardAlertShown', 'true')
        setShowDashboardAlert(false);
      }}
    >
      Got it
    </span>

    {arrow}
  </div>
}