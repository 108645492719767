import * as dictionary from '../dictionary'

const CollegeDetailCardData = ({ college, mobile = false }) => {

  const numberConverter = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const convertSettingVal = (settingDictionary, settingVal) => {
    for (const key in settingDictionary) {
      if (settingDictionary.hasOwnProperty(key) && settingDictionary[key].includes(settingVal)) {
        return key
      }
    }
    return 'N/A'
  }

  const cdsDataReturn = (college, cdsVal) => {

    switch (cdsVal) {
      case 'totalAdmitRate':
        return college.college_cds ? college.college_cds[0]?.totalAdmitRate || 'N/A' : 'N/A';
      case 'averageGPA':
        return college.college_cds ? college.college_cds[0]?.averageGPA || 'N/A' : 'N/A';
      case 'satComposite50':
        return college.college_cds ? college.college_cds[0]?.satComposite50 || 'N/A' : 'N/A';
      case 'actComposite50':
        return college.college_cds ? college.college_cds[0]?.actComposite50 || 'N/A' : 'N/A';
      default:
        return 'N/A'
    }
  };

  const numProgramsReturn = (college) => {
    const check = college.numPrograms && college.numPrograms != 0

    return check ? college.numPrograms : 'N/A'
  }

  // let numMajorsCount: any = 'N/A'

  // if (college && programData) {

  //   numMajorsCount = programData.filter((item) => item.college_id == college.id).length

  // }

  const desktopReturn = (<div style={{ width: '100%' }}>

    <div className='ms-2 me-2 d-flex flex-row justify-content-between'>

      <div className="d-flex justify-content-around card card-custom p-3" style={{ minHeight: '300px', height: 'auto', width: '45vw', background: 'rgba(250, 250, 250, 1)', borderRadius: '20px' }}>

        <div className="ms-2 me-2 d-flex flex-row justify-content-between">
          <div className='d-flex flex-column'>
            <p className='dca-header-spacing-B'>META-RANK</p>
            <p>{college.metaRank ? college.metaRank : 'N/A'}</p>
          </div>
          <div className='d-flex flex-column'>
            <p className='dca-header-spacing-B'>TYPE</p>
            <p>{college.type ? dictionary.typeRender[college.type] : 'N/A'}</p>
          </div>
          <div className='d-flex flex-column'>
            <p className='dca-header-spacing-B'>SETTING</p>
            <p>{college.setting ? convertSettingVal(dictionary.setting, college.setting) : 'N/A'}</p>
          </div>
          <div className='d-flex flex-column'>
            <p className='dca-header-spacing-B'>ADMISSION RATE</p>
            <p>{(cdsDataReturn(college, 'totalAdmitRate') * 100).toFixed(2)}%</p>
          </div>
        </div>

        <div className="ms-2 me-2 d-flex flex-row justify-content-between">
          <div className='d-flex flex-column'>
            <p className='dca-header-spacing-B'>UNDERGRAD (TOTAL)</p>
            <p>{college.numUndergrads ? numberConverter(college.numUndergrads) : 'N/A'} ({college.numStudents ? numberConverter(college.numStudents) : 'N/A'})</p>
          </div>
          <div className='d-flex flex-column'>
            <p className='dca-header-spacing-B'>STUDENT:FACULTY</p>
            <p>{college.studentFacultyRatio ? `${college.studentFacultyRatio}:1` : 'N/A'} </p>
          </div>
          <div className='d-flex flex-column'>
            <p className='dca-header-spacing-B'>AVG. GPA</p>
            <p>{cdsDataReturn(college, "averageGPA")}</p>
          </div>
          <div className='d-flex flex-column'>
            <p className='dca-header-spacing-B'>MDN. SAT</p>
            <p>{cdsDataReturn(college, "satComposite50")}</p>
          </div>
        </div>

        <div className="ms-2 me-2 d-flex flex-row justify-content-between">
          <div className='d-flex flex-column'>
            <p className='dca-header-spacing-B'>MDN. ACT</p>
            <p>{cdsDataReturn(college, "actComposite50")}</p>
          </div>

          <div className='d-flex flex-column'>
            <p className='dca-header-spacing-B'>TUITION</p>
            <p>{college.inStateTuition ? `$${numberConverter(college.inStateTuition)}` : college.outStateTuition ? `$${numberConverter(college.outStateTuition)}` : 'N/A'}</p>
          </div>

          <div className='d-flex flex-column'>
            <p className='dca-header-spacing-B'># OF MAJORS</p>
            <p>{numProgramsReturn(college)}</p>
          </div>

          <div className='d-flex flex-column'>
            <p className='dca-header-spacing-B'>MEDIAN EARNINGS</p>
            <p>{college.median4yrEarnings ? `$${numberConverter(college['median4yrEarnings'])}` : 'N/A'}</p>
          </div>
        </div>
      </div>

      <div className='align-self-center' style={{ height: '100%', width: '45vw' }}>
        <img
          alt={`collegeHero_${college.id}`}
          src={dictionary.collegeHeroes[college.id]}
          style={{ height: '100%', width: '100%', margin: '10px 0 10px 0', borderRadius: '20px' }}
        />
      </div>
    </div>

  </div>)

  const mobileReturn = (<div style={{ width: '100%' }}>

    <div className='ms-2 me-2 d-flex flex-column justify-content-between'>

      <div className='align-self-center' style={{ height: '100%', width: 'auto' }}>
        <img
          alt={`collegeHero_${college.id}`}
          src={dictionary.collegeHeroes[college.id]}
          style={{ height: '100%', width: '100%', margin: '10px 0 10px 0', borderRadius: '20px' }}
        />
      </div>

      <div className="d-flex justify-content-around card card-custom" style={{ minHeight: '300px', height: 'auto', background: 'rgba(250, 250, 250, 1)', borderRadius: '20px', padding: '20px 20px 10px 40px' }}>

        <div className='d-flex flex-row'>
          <div className='d-flex flex-column' style={{ width: '33%' }}>
            <div className='d-flex flex-column' style={{ height: '90px' }}>
              <p className='dca-header-spacing-B'>META-RANK</p>
              <p>{college.metaRank ? college.metaRank : 'N/A'}</p>
            </div>
            <div className='d-flex flex-column dca-400-fc'
            // style={{ height: '90px' }}
            >
              <p className='dca-header-spacing-B'>ADMISSION RATE</p>
              <p>{(cdsDataReturn(college, 'totalAdmitRate') * 100).toFixed(2)}%</p>
            </div>
            <div className='d-flex flex-column' style={{ height: '90px' }}>
              <p className='dca-header-spacing-B'>AVG. GPA</p>
              <p>{cdsDataReturn(college, "averageGPA")}</p>
            </div>
            <div className='d-flex flex-column' style={{ height: '90px' }}>
              <p className='dca-header-spacing-B'>TUITION</p>
              <p>{college.inStateTuition ? `$${numberConverter(college.inStateTuition)}` : college.outStateTuition ? `$${numberConverter(college.outStateTuition)}` : 'N/A'}</p>
            </div>
          </div>

          <div className='d-flex flex-column' style={{ width: '33%', padding: '0px 5px 0px 5px' }}>
            <div className='d-flex flex-column' style={{ height: '90px' }}>
              <p className='dca-header-spacing-B'>TYPE</p>
              <p>{college.type ? dictionary.typeRender[college.type] : 'N/A'}</p>
            </div>
            <div
              className='d-flex flex-column dca-400-fc'
            // style={{ height: '90px' }}
            >
              <p className='dca-header-spacing-B'>UNDERGRAD (TOTAL)</p>
              <p>{college.numUndergrads ? numberConverter(college.numUndergrads) : 'N/A'} ({college.numStudents ? numberConverter(college.numStudents) : 'N/A'})</p>
            </div>
            <div className='d-flex flex-column' style={{ height: '90px' }}>
              <p className='dca-header-spacing-B'>MDN. SAT</p>
              <p>{cdsDataReturn(college, "satComposite50")}</p>
            </div>
            <div className='d-flex flex-column' style={{ height: '90px' }}>
              <p className='dca-header-spacing-B'># OF MAJORS</p>
              <p>{numProgramsReturn(college)}</p>
            </div>
          </div>

          <div className='d-flex flex-column' style={{ width: '33%' }}>
            <div className='d-flex flex-column' style={{ height: '90px' }}>
              <p className='dca-header-spacing-B'>SETTING</p>
              <p>{college.setting ? convertSettingVal(dictionary.setting, college.setting) : 'N/A'}</p>
            </div>
            <div className='d-flex flex-column dca-400-fc'
            // style={{ height: '90px' }}
            >
              <p className='dca-header-spacing-B'>STUDENT:FACULTY</p>
              <p>{college.studentFacultyRatio ? `${college.studentFacultyRatio}:1` : 'N/A'} </p>
            </div>
            <div className='d-flex flex-column' style={{ height: '90px' }}>
              <p className='dca-header-spacing-B'>MDN. ACT</p>
              <p>{cdsDataReturn(college, "actComposite50")}</p>
            </div>
            <div className='d-flex flex-column' style={{ height: '90px' }}>
              <p className='dca-header-spacing-B'>MEDIAN EARNINGS</p>
              <p>{college.median4yrEarnings ? `$${numberConverter(college['median4yrEarnings'])}` : 'N/A'}</p>
            </div>

          </div>
        </div>
      </div>
    </div></div>)

  return college ? (mobile ? mobileReturn : desktopReturn) : (<div>Loading College Data ... </div>)
}

export { CollegeDetailCardData }