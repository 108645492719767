import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { getUserByToken, getUserProfile, login } from '../../modules/auth/core/_requests'
import { useAuth } from '../../modules/auth/core/Auth'
import { closeModalFunction, closeModalIcon, specializedRankingsAccessCode, rdSpendAccessCode, safetyAccessCode, pageRoutes, sessionStorageGet } from '../dictionary'
import * as Yup from 'yup'
import clsx, { ClassDictionary } from 'clsx'
import { useFormik } from 'formik'
import { AuthReactGAHandlers } from '../ReactGAHandlers'
import { GoogleAuthButton } from './GoogleAuthButton'
import { collegeMap } from '../collegeMap'
import {
  handleUpdateUserData,
  handleUserProfilePatchIntroQuestions, handleUserProfilePatchOverviewVisits
} from '../../pages/UserProfile'

// LoginSchema not tracking on mount ? set sign in button to disabled if formik.values.email and .password are null/undefined, then call loginSchema for isValid when user enters in characters to both fields
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export const SignInModal = ({ prompt, navigateTo, setPrompt, setNavigateTo, setCollegeDetailSelectedTab, introQuestions, setOverrideTestingWizard }: { prompt: string; navigateTo: string, setPrompt: Function, setNavigateTo: Function, setCollegeDetailSelectedTab: Function, introQuestions: any, setOverrideTestingWizard: Function }) => {

  const {
    handleGAEventNotAMemberYet,
    handleGAEventSuccessfulLogin
  } = AuthReactGAHandlers()

  const location = useLocation();
  const modalId = 'login'
  const { saveAuth, setCurrentUser, requestUser } = useAuth()
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('password')
  const [eye, setEye] = useState(false)
  const navigate = useNavigate()
  const eyeTrue = (<i className="bi bi-eye"></i>)
  const eyeFalse = (<i className="bi bi-eye-slash"></i>)

  const handlePasswordView = () => {
    if (password === 'password') {
      setEye(true);
      setPassword('text')
    }
    else {
      setEye(false);
      setPassword('password')
    }
  }

  const eyeComponent = (
    <span className='d-flex justify-content-between align-self-center ms-5' onClick={handlePasswordView}>
      {eye ? eyeTrue : eyeFalse}
    </span>
  )

  // For spacing email input
  const eyeComponentFake = (
    <span className='d-flex justify-content-between align-self-center ms-5' style={{ visibility: 'hidden' }}>
      {eye ? eyeTrue : eyeFalse}
    </span>
  )

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    validateOnMount: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setStatus(null)

      try {

        const { data: authToken } = await login(values.email, values.password)

        handleGAEventSuccessfulLogin()

        const { data: user } = await getUserByToken(authToken.key)
        // console.log('Django User:', user)

        const { data: userprofile } = await getUserProfile(user.pk, authToken.key)

        let userprofileResponses = {
          a: userprofile?.userType ?? undefined,
          b: userprofile?.hsGradYr ?? undefined,
          c: userprofile?.familiarityWithProcess ?? undefined
        }

        let overviewIntroductionKey = sessionStorageGet('overviewIntroduction')
        let overviewCollegeAppKey = sessionStorageGet('overviewCollegeApp')

        if (
          (userprofile?.overviewIntroductionVisit == false || userprofile?.overviewCollegeAppVisit == false) &&
          (overviewIntroductionKey != null || overviewCollegeAppKey != null)) {
          handleUserProfilePatchOverviewVisits(userprofile, setCurrentUser, authToken.key)
        }

        let dashboardAlertShown = sessionStorageGet('dashboardAlertShown')

        if (userprofile?.dashboardAlertShown === false && dashboardAlertShown != null) {
          handleUpdateUserData(userprofile, { dashboardAlertShown: true }, setCurrentUser, authToken.key)
        }

        if (userprofileResponses.a != introQuestions.a || userprofileResponses.b != introQuestions.b || userprofileResponses.c != introQuestions.c) {
          handleUserProfilePatchIntroQuestions(introQuestions, userprofile, setCurrentUser, authToken.key)
        } else {
          setCurrentUser(userprofile)
          // console.log('Current User:', userprofile)
        }

        let path = location.pathname.split('/')[1]
        const groupIds = user.groups.map((group => group.id))
        const groupNames = user.groups.map((group => group.name))

        saveAuth({ api_token: authToken.key, groups: groupIds })

        requestUser(authToken.key)

        if (prompt != '') {
          setPrompt('')
        }

        closeModalFunction(modalId)

        const restrictedTabOptions = ['Specialized Rankings', 'R&D Spend', 'Crime & Safety']

        if (collegeMap.some(collegeObj => path === collegeObj.hyphenated)) {

          switch (true) {
            case navigateTo === restrictedTabOptions[0] && groupNames.includes(specializedRankingsAccessCode):
              setCollegeDetailSelectedTab(navigateTo)
              break;
            case navigateTo === restrictedTabOptions[1] && groupNames.includes(rdSpendAccessCode):
              setCollegeDetailSelectedTab(navigateTo)
              break;
            case navigateTo === restrictedTabOptions[2] && groupNames.includes(safetyAccessCode):
              setCollegeDetailSelectedTab(navigateTo)
              break;
            case navigateTo === pageRoutes.testScoreStrategy:
              navigate(navigateTo)
              break;
            default:
              setCollegeDetailSelectedTab('Admission Info')
          }
        }

        if (navigateTo === 'standardizedTestingWizard') {
          let testScores = false
          if (userprofile) {
            let sat = (userprofile?.satMath && userprofile?.satVerbal);
            let act = !!userprofile?.actComposite;

            if (sat || act) {
              testScores = true
            }
          }
          // testScores == true ? navigate(pageRoutes.testScoreStrategy) : setOverrideTestingWizard(true)

          if (testScores) {
            navigate(pageRoutes.testScoreStrategy);
          } else {
            setTimeout(() => {
              setOverrideTestingWizard(true);
            }, 1500);
          }
        }

        if (navigateTo != '' && !(collegeMap.some(collegeObj => path === collegeObj.hyphenated)) && navigateTo !== 'standardizedTestingWizard') {
          navigate(navigateTo)
        }

      } catch (error) {

        console.error(error)
        // alert('Login unsuccessful')
        saveAuth(undefined)
        setStatus('The login details provided are incorrect. Please re-enter your account credentials or contact support@nextfour.ai for assistance.')
        setSubmitting(false)

      } finally {
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    const handleModalHidden = () => {
      formik.resetForm();
    };

    const modalElement = document.getElementById(`${modalId}_modal`);
    if (modalElement) {
      modalElement.addEventListener('hidden.bs.modal', handleModalHidden);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
      }
    };
  }, [formik]);

  return (
    <div className="modal fade" id={`${modalId}_modal`}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{ minWidth: '75vw', maxWidth: '75vw', padding: '60px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', overflowY: 'auto' }}>
          {closeModalIcon(modalId)}

          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >

            <div className='text-center mb-11'>
              <div className='text-dark fw-bolder mb-3' style={{ fontSize: '24px' }}>Sign In</div>
            </div>

            {prompt != '' ? <div
              style={{
                marginTop: '15px',
                marginBottom: '25px',
                color: 'rgb(192, 0, 0)'
              }}>{prompt}</div> : <></>}

            <div className='row g-3 mb-9 justify-content-center'>
              <GoogleAuthButton
                modalId={modalId}
                prompt={prompt}
                setPrompt={setPrompt}
                navigateTo={navigateTo}
                setCollegeDetailSelectedTab={setCollegeDetailSelectedTab}
                setOverrideTestingWizard={setOverrideTestingWizard}
                introQuestions={introQuestions}
              />
              {/* <div className='col-md-6 align-items-center'>
              </div> */}

              {/* Place another social sign in here */}

            </div>

            <div className='separator separator-content my-14'>
              <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
            </div>

            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}

            <div className='fv-row mb-8'>
              <div className='d-flex flex-row'>
                <input
                  placeholder='Email'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control bg-transparent',
                    { 'is-invalid': formik.touched.email && formik.errors.email },
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  type='email'
                  name='email'
                  autoComplete='off'
                />
                {eyeComponentFake}
              </div>
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              )}
            </div>


            <div className='fv-row mb-3'>
              <div className='d-flex flex-row'>
                <input
                  type={password}
                  autoComplete='off'
                  placeholder='Password'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                {eyeComponent}
              </div>
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
              <div />

              {/* <Link
  to='/auth/forgot-password' className='link-primary'
> */}
              {/* <Link
                to='/dashboard' className='link-primary'
              >
                Forgot Password ?
              </Link> */}
            </div>

            <div className='d-grid mb-10'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn'
                style={{ background: '#DAF8B9' }}
                disabled={formik.isSubmitting || !formik.isValid || (!formik.values.email && !formik.values.password)}
              >
                {!loading && <span className='signin_signup-submit-bttn-text'>Sign In</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>

            <div className='d-flex flex-row justify-content-center text-gray-500 text-center fw-semibold fs-6'>
              Not a Member yet?&nbsp;

              <div
                className='link-primary'
                data-bs-target="#signup_modal"
                data-bs-toggle="modal"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleGAEventNotAMemberYet()
                }}
              >Sign up</div>

            </div>
          </form>

        </div>
      </div>
    </div>
  )
}