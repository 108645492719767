export const DisplayingText = ({ filteredItemsLength, fullFilteredItemsLength = 0, itemsName, filtersActiveCount, pagination = false }) => {

  let itemNameReturn = itemsName == 'activity' ? `${filteredItemsLength == 1 ? 'activity' : 'activities'}` : `${itemsName}${filteredItemsLength == 1 ? '' : 's'}`

  let defaultLine = `Displaying ${filteredItemsLength} ${itemNameReturn}`

  // let collegeTestLine = fullFilteredItemsLength > filteredItemsLength ? `Displaying ${filteredItemsLength} (of ${fullFilteredItemsLength}) ${filtersActiveCount > 0 ? '' : itemNameReturn}` : defaultLine

  // return <div className='align-bottom dca-card-items-found-text d-flex flex-column' style={{ marginRight: '10px' }}>

  //   <span className="dca-custom-break-word-advisor">{collegeOrTestSearch ? collegeTestLine : defaultLine}</span>

  //   {filtersActiveCount > 0 && <span className="dca-custom-break-word-advisor">{collegeOrTestSearch && fullFilteredItemsLength > filteredItemsLength ? `${itemNameReturn} ` : ''}matching active filter{filtersActiveCount == 1 ? '' : 's'}</span>}

  let collegeTestLine = fullFilteredItemsLength > filteredItemsLength ? `Displaying ${filteredItemsLength} (of ${fullFilteredItemsLength}) ${itemNameReturn}` : defaultLine

  return <div className='align-bottom dca-card-items-found-text d-flex flex-column' style={{ marginRight: '10px' }}>

    <span className="dca-custom-break-word-advisor">{pagination ? collegeTestLine : defaultLine}</span>

    {filtersActiveCount > 0 && <span className="dca-custom-break-word-advisor">matching active filter{filtersActiveCount == 1 ? '' : 's'}</span>}

  </div>
}