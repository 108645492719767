import { GoogleLogin } from '@react-oauth/google';
import { getGoogleToken, getUserByToken, getUserByEmail, getUserProfile, getUserProfileFromEmail, createNewUserProfile, validateUserEmail } from '../../modules/auth/core/_requests';
import { useAuth } from '../../modules/auth';
import { AuthReactGAHandlers } from '../ReactGAHandlers';
import { useNavigate, useLocation } from 'react-router';
import { closeModalFunction, specializedRankingsAccessCode, safetyAccessCode, rdSpendAccessCode, URL, pageRoutes, sessionStorageGet } from '../dictionary';
import { collegeMap } from '../collegeMap';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import {
  handleUserProfilePatchOverviewVisits, handleUserProfilePatchIntroQuestions,
  handleUpdateUserData
} from '../../pages/UserProfile';

// No formik setStatus, setSubmitting... if we want to display an error message let's create a new conditional text to appear if there is an error triggered via the catch
const GoogleAuthButton = ({ modalId, prompt, setPrompt, navigateTo, setCollegeDetailSelectedTab, signup = false, buttonTextSignUp = false, setOverrideTestingWizard, introQuestions }) => {

  const {
    handleGAEventSuccessfulLogin,
    handleGAEventSuccessfulRegistration,
  } = AuthReactGAHandlers();
  const { saveAuth, setCurrentUser, requestUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation()
  const [conditionalText, setConditionalText] = useState<any>(undefined)
  const [loading, setLoading] = useState(false)

  // Reset conditional text when modal is closed
  useEffect(() => {
    const signupModal: HTMLElement | null = document.getElementById('signup_modal');
    const loginModal: HTMLElement | null = document.getElementById('login_modal');

    const observerCallback = (mutations: MutationRecord[], observer: MutationObserver) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'style') {
          const style = window.getComputedStyle(mutation.target as HTMLElement);
          if (style.display === 'none') {
            setConditionalText(undefined);
          }
        }
      });
    };

    const observer = new MutationObserver(observerCallback);

    if (signupModal) {
      observer.observe(signupModal, { attributes: true, attributeFilter: ['style'] });
    }

    if (loginModal) {
      observer.observe(loginModal, { attributes: true, attributeFilter: ['style'] });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const returnConditionalTextComponent = (error: string = 'generic') => {
    switch (error) {
      case 'userExists':
        return <div style={{ color: 'red' }}>A user profile with this email address already exists. Please instead sign-in or sign-up directly with a NextFour account. If you require any assistance, please email us at <a href="mailto:support@nextfour.ai">support@nextfour.ai</a></div>
      default:
        return <div style={{ color: 'red' }}>Signing in via Google was not successful. Please instead sign-in or sign-up directly with a NextFour account. If you require any assistance, please email us at <a href="mailto:support@nextfour.ai">support@nextfour.ai</a></div>
    }
  }

  // Carry over from sign up modal
  const doesEmailAlreadyExist = async (email) => {

    let res = await axios.get(`${URL}/user_emails/`)

    let emailFound = res.data.find((item) => item.email === email)

    return emailFound ? true : false

  }

  // Added returns on some catch blocks to set conditional text in cases where user login / userProfile issues arise
  const handleGoogleAuth = async (codeResponse, modalId, prompt, setPrompt, navigateTo, setCollegeDetailSelectedTab) => {

    // initialize variables
    let token
    let user
    let userProfile
    let codeResponseEmail: any
    let decoded: any = jwtDecode(codeResponse.credential)
    codeResponseEmail = decoded?.email

    const handleGetDjangoUser = async (codeResponse) => {
      // Set the token from the Django GoogleLogin view
      token = await getGoogleToken(codeResponse)
      // Get the user data by the token
      user = await getUserByToken(token)
      // console.log('user:', user)
    }

    const handleCreateNewUserProfileAndLogin = async (user, token) => {
      // Create a userProfile
      await createNewUserProfile(parseInt(user.data.pk), user.data.first_name, user.data.last_name, user.data.email, true);

      // Trigger GA registration event
      handleGAEventSuccessfulRegistration();

      // Validate email after creation of new userProfile
      await validateUserEmail(user.data.email);

      // Set current user state in app
      userProfile = await getUserProfile(user.data.pk, token);
      // setCurrentUser(userProfile.data);

      let userprofile = userProfile.data

      let userprofileResponses = {
        a: userprofile?.userType ?? undefined,
        b: userprofile?.hsGradYr ?? undefined,
        c: userprofile?.familiarityWithProcess ?? undefined
      }

      let overviewIntroductionKey = sessionStorageGet('overviewIntroduction')
      let overviewCollegeAppKey = sessionStorageGet('overviewCollegeApp')

      if (
        (userprofile?.overviewIntroductionVisit == false || userprofile?.overviewCollegeAppVisit == false) &&
        (overviewIntroductionKey != null || overviewCollegeAppKey != null)) {
        handleUserProfilePatchOverviewVisits(userprofile, setCurrentUser, token)
      }

      let dashboardAlertShown = sessionStorageGet('dashboardAlertShown')

      if (userprofile?.dashboardAlertShown === false && dashboardAlertShown != null) {
        handleUpdateUserData(userprofile, { dashboardAlertShown: true }, setCurrentUser, token)
      }

      if (userprofileResponses.a != introQuestions.a || userprofileResponses.b != introQuestions.b || userprofileResponses.c != introQuestions.c) {
        handleUserProfilePatchIntroQuestions(introQuestions, userprofile, setCurrentUser, token)
      } else {
        setCurrentUser(userprofile)
        // console.log('Current User:', userprofile)
      }

      // Trigger GA login event
      handleGAEventSuccessfulLogin();

      if (navigateTo === 'standardizedTestingWizard') {
        setTimeout(() => {
          setOverrideTestingWizard(true);
        }, 1500);
      }
      // console.log('userProfile:', userProfile)
    }

    const handleLoginFromFoundEmail = async (codeResponseEmail, token) => {
      // Set current user state in app
      userProfile = await getUserProfileFromEmail(codeResponseEmail, token)
      // setCurrentUser(userProfile.data);

      let userprofile = userProfile.data

      let userprofileResponses = {
        a: userprofile?.userType ?? undefined,
        b: userprofile?.hsGradYr ?? undefined,
        c: userprofile?.familiarityWithProcess ?? undefined
      }

      let overviewIntroductionKey = sessionStorageGet('overviewIntroduction')
      let overviewCollegeAppKey = sessionStorageGet('overviewCollegeApp')

      if (
        (userprofile?.overviewIntroductionVisit == false || userprofile?.overviewCollegeAppVisit == false) &&
        (overviewIntroductionKey != null || overviewCollegeAppKey != null)) {
        handleUserProfilePatchOverviewVisits(userprofile, setCurrentUser, token)
      }

      let dashboardAlertShown = sessionStorageGet('dashboardAlertShown')

      if (userprofile?.dashboardAlertShown === false && dashboardAlertShown != null) {
        handleUpdateUserData(userprofile, { dashboardAlertShown: true }, setCurrentUser, token)
      }

      if (userprofileResponses.a != introQuestions.a || userprofileResponses.b != introQuestions.b || userprofileResponses.c != introQuestions.c) {
        handleUserProfilePatchIntroQuestions(introQuestions, userprofile, setCurrentUser, token)
      } else {
        setCurrentUser(userprofile)
        // console.log('Current User:', userprofile)
      }

      // Trigger GA login event
      handleGAEventSuccessfulLogin()

      console.log('userProfile:', userProfile)
    }

    if (!codeResponseEmail) {
      return setConditionalText(returnConditionalTextComponent())
    }

    // Handle sign in / sign up and return userProfile
    try {
      let wasEmailFound = await doesEmailAlreadyExist(codeResponseEmail)

      await handleGetDjangoUser(codeResponse)

      // Sign in
      if (wasEmailFound) {
        await handleLoginFromFoundEmail(codeResponseEmail, token)
      }

      // No email found already, sign up first and then sign in
      else {
        await handleCreateNewUserProfileAndLogin(user, token)
      }

      // Handle when userProfile data is found
      if (userProfile.data) {
        // Since we are logging in via Google, validate email for user if it is not already validated
        if (!userProfile.data.validatedEmail) {
          try {
            // Validate the email address
            await validateUserEmail(userProfile.data.emailAddress);

            // Set current user state in app
            userProfile = await getUserProfileFromEmail(userProfile.data.emailAddress, token);
            setCurrentUser(userProfile.data);
          } catch (error) {
            console.log('Error updating userProfile to validate email address')
          }
        }
      }

      // No userProfile.data --
      else {
        return setConditionalText(returnConditionalTextComponent())
      }

    } catch (error) {
      console.log('Error:', error)
      return setConditionalText(returnConditionalTextComponent())
    }

    try {
      // Save the auth data, store key in localStorage
      let path = location.pathname.split('/')[1]
      const groupIds = user.data.groups.map((group => group.id));
      const groupNames = user.data.groups.map((group => group.name));
      saveAuth({ api_token: token, groups: groupIds });
      // Request user again to refresh user data
      requestUser(token);

      if (prompt != '') {
        setPrompt('')
      }

      closeModalFunction(modalId)

      if (collegeMap.some(collegeObj => path === collegeObj.hyphenated)) {

        const restrictedTabOptions = ['Specialized Rankings', 'R&D Spend', 'Crime & Safety']

        switch (true) {
          case navigateTo === restrictedTabOptions[0] && groupNames.includes(specializedRankingsAccessCode):
            setCollegeDetailSelectedTab(navigateTo)
            break;
          case navigateTo === restrictedTabOptions[1] && groupNames.includes(rdSpendAccessCode):
            setCollegeDetailSelectedTab(navigateTo)
            break;
          case navigateTo === restrictedTabOptions[2] && groupNames.includes(safetyAccessCode):
            setCollegeDetailSelectedTab(navigateTo)
            break;
          case navigateTo === pageRoutes.testScoreStrategy:
            navigate(navigateTo)
            break;
          default:
            setCollegeDetailSelectedTab('Admission Info')
        }
      }

      if (navigateTo === 'standardizedTestingWizard') {
        let testScores = false
        let user = userProfile?.data
        if (user) {
          let sat = (user?.satMath && user?.satVerbal);
          let act = !!user?.actComposite;

          if (sat || act) {
            testScores = true
          }
        }
        // testScores == true ? navigate(pageRoutes.testScoreStrategy) : setOverrideTestingWizard(true)

        if (testScores) {
          navigate(pageRoutes.testScoreStrategy);
        } else {
          setTimeout(() => {
            setOverrideTestingWizard(true);
          }, 1500);
        }

      }

      if (navigateTo != '' && !(collegeMap.some(collegeObj => path === collegeObj.hyphenated)) && navigateTo !== 'standardizedTestingWizard') {
        navigate(navigateTo)
      }

    } catch (error) {
      console.log('Error saving auth data:', error);
    }

  }

  // Set up the GoogleLogin from react-oauth
  const googleLoginComponent = (
    <GoogleLogin
      text={buttonTextSignUp ? 'signup_with' : 'signin_with'}
      onSuccess={codeResponse => handleGoogleAuth(codeResponse, modalId, prompt, setPrompt, navigateTo, setCollegeDetailSelectedTab)}
      onError={() => {
        console.log('Login Failed');
        setConditionalText(returnConditionalTextComponent())
      }}
    />
  )

  // Create wrapper for conditional text div and GoogleLogin in event of error or catch
  const googleLoginWithText = (
    <div className='d-flex flex-column'>
      <div style={{ margin: '10px 0px 30px 0px' }}>{conditionalText}</div>

      <div style={{ alignSelf: 'center' }}>{googleLoginComponent}</div>
    </div>
  )

  return (
    <div
      className='d-flex flex-row'
      style={{ width: '100%', justifyContent: 'center' }}>
      {conditionalText ? googleLoginWithText : googleLoginComponent}
    </div>
  )
};

export { GoogleAuthButton };

