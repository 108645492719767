import { currentYear, ecPremiumAccessCode } from "../../dictionary"

// sentence_savedECs
export const sentence1_ec0 = `Review the Insights materials referenced below and then explore the database of extracurriculars to indicate activities you already have experience with or would like to pursue.`

export const sentence1_ec1_3 = `Continue to build your extracurricular profile with a suggested focus on activities that are impactful and aligned with your academic and personal interests.`

export const sentence1_ec4a = `Great job identifying a mix of extracurricular activities, please continue to strenghen your profile through high school with an increasing focus on depth, leadership, and alignment with your academic interests/intended major(s).`

export const sentence1_ec4b = `Great job identifying a mix of extracurricular activities, please continue to explore your personal and academic interests to help determine the areas you wish to go deeper in during high school. `

export const sentence2_ec4_8 = `Keep in mind that the Common App provides space for up to 10 activities and 5 honors/awards.`

export const sentence3_ec4a = `Consider pursuing activities that will be most impactful in the admissions process (for instance, those categorized as "Best" impact in the Explorer.)`

export const sentence3_ec4b = `Consider pursuing some independent initatives to help distinguish your overall EC profile.`

export const sentence4_ec1 = `EC Insights reports are available for selected activities and may help develop your EC strategy.`

export const sentence5_ec1 = `You should consider engaging with Advisors with direct experience in your selected extracurriculars to learn more about them. `

export const returnProgressTrackerText = (userECs, permissionGroups, ecData, ecPremiumInsights, advisors, gradYr) => {
  let capValue = 5
  let len = userECs?.length ?? undefined
  let yearDif = gradYr ? gradYr - currentYear : undefined
  let concat: any = []

  // Sentence 1
  if (len === 0) {
    return sentence1_ec0
  }

  else if (len > 0 && len <= 3) {
    concat.push(sentence1_ec1_3)
  }

  else if (len >= 4 && yearDif != undefined) {
    if (yearDif <= 3) {
      concat.push(sentence1_ec4a)
    }
    else if (yearDif > 3) {
      concat.push(sentence1_ec4b)
    }
  }

  // Sentence 2
  if (4 <= len && len <= 8 && yearDif != undefined && yearDif <= 3) {
    concat.push(sentence2_ec4_8)
  }

  // Sentence 3
  if (len >= 4 && yearDif != undefined && yearDif <= 3) {

    // impact = best === parseInt(impactIndex) == 3
    let anyImpactBest = userECs?.some((userEC) => ecData.find((res) => userEC === res.id && parseInt(res?.impactIndex) == 3)) ?? undefined

    // activityType = Independent === activityType == 8
    let noneIndependent = userECs?.every((userEC) => ecData.find((res) => userEC === res.id && res?.activityType != 8)) ?? undefined

    if (userECs?.length > 0 && anyImpactBest == false) {
      concat.push(sentence3_ec4a)
    }

    else if (userECs?.length > 0 && anyImpactBest == true && noneIndependent == true) {
      concat.push(sentence3_ec4b)
    }
  }

  const checkSentence4 = (ecPremiumInsights, permissionGroups, userECs) => {
    const ecGroup = (id) => `${ecPremiumAccessCode}${id}`;

    return userECs.some((ecId) => {
      const isIdInPremiumInsights = ecPremiumInsights.some((insight) => insight.id === ecId);

      const group = ecGroup(ecId);

      const isGroupNotInPermissionGroups = !permissionGroups.includes(group);

      return isIdInPremiumInsights && isGroupNotInPermissionGroups;
    });
  };

  // Sentence 4
  if (len > 0 && checkSentence4(ecPremiumInsights, permissionGroups, userECs)) {
    concat.push(sentence4_ec1)
  }

  // Sentence 5
  if (len > 0 && advisors.some((advisor) => advisor?.ecActivities.some((ec) => userECs.includes(ec)))) {
    concat.push(sentence5_ec1)
  }

  // if multiple sentences, join with single space
  if (concat?.length > 0) {
    concat = concat.join(" ")
    return concat
  }

  return sentence1_ec0
}