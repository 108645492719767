import { ContentBlock } from "../ContentBlock"
import OverviewIcon from '../../../../custom_assets/dashboard/overview.png'
import { pageRoutes } from "../../dictionary"
import { returnProgressTrackerText } from "../ProgressTrackerText/Introduction"
import { useState, useEffect } from "react"

const Introduction = ({ user, wizardActive, setWizardActive, status, expand, introQuestions, statusLabelMaxWidth }) => {
  let blockClass = 'Introduction'

  const [title, setTitle] = useState(window.innerWidth <= 500 ? 'Intro' : 'Introduction');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setTitle('Intro');
      } else {
        setTitle('Introduction');
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const body = `Our recommended starting point is to ensure a baseline understanding of the holistic college admissions process and how the key admissions considerations will come together in the actual application that will be submitted senior year. This perspective will help anchor your decision-making and preparation throughout your high school years.`

  // const resources = [
  //   { navigate: pageRoutes.personalizedExpertHelp, title: 'Expert Q&A', description: 'Get personalized help to support your journey towards college' }
  // ]

  const insights = [
    { navigate: pageRoutes.overviewIntroduction, text: 'Core College Admissions Criteria' },
    { navigate: pageRoutes.overviewCollegeAppWalkthrough, text: 'Previewing A Typical College Application' },
    { navigate: pageRoutes.overviewAdmissionsAdviceWithHash('overall-reference-timeline'), text: 'Admissions Process Roadmap' }
  ]

  let progressTracker = {
    color: status,
    text: returnProgressTrackerText(status, user, introQuestions),
  }

  let block = {
    class: blockClass,
    title: title,
    body: body,
    icon: OverviewIcon,
    specialIconClass: 'dca-dashboard-overviewIcon',
    insights: insights,
    status: status,
    expand: expand,
    progressTracker: progressTracker
  }

  return <ContentBlock block={block} wizardActive={wizardActive} setWizardActive={setWizardActive} statusLabelMaxWidth={statusLabelMaxWidth} />
}

export { Introduction }