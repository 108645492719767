import { useState, useEffect } from 'react'
import axios from 'axios'
import * as dictionary from '../components/dictionary'
// import ECLogo from '../../custom_assets/ec_logo.png'
import { Link, useParams } from 'react-router-dom'
import { ECDetailCardData, ECDetailCardDataMobile } from '../components/ECDetail/ECDetailCardData'
import { ECDetailTabs } from '../components/ECDetail/ECDetailTabs'
import { SaveButtonECActivityInline, SaveButtonInlineNoAuth } from '../components/SaveButton'
import { useNavigate } from "react-router-dom";
import { AddCardECInsights } from '../components/Payments/AddCardECInsights'
import { UpgradeAccountModal } from '../components/Auth/UpgradeAccountModal'
import { getAuth, useAuth } from '../modules/auth'
import { handleCreateOrAssignECGroups } from './Auth/UpgradeTierPage'
import { ECReactGAHandlers } from '../components/ReactGAHandlers'
import { AuthReactGAHandlers } from '../components/ReactGAHandlers'
import { LoadingIndicator } from '../components/LoadingIndicator'
import { ConnectWithAdvisorButton } from '../components/ConnectWithAdvisorButton'
import { extracurricularMap } from '../components/extracurricularMap'

const ECDetailPage = ({ user, setUser, setPrompt, setNavigateTo, ecName, setECName, setECNumAlumni, setPurchaseID, setECID, clientSecret, setClientSecret, ecSearchLocation, idToSend }) => {

  const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()

  const {
    handleGAEventViewPremium,
    handleGAEventPurchaseModalOpen
  } = ECReactGAHandlers()

  let apiToken: any = undefined
  let permissionGroups: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
    permissionGroups = getAuth()?.groups
  }

  const { requestUser } = useAuth()

  const ecId = idToSend
  const [ec, setEC] = useState<any>([])
  const [insights, setInsights] = useState<any>(false)
  const [careers, setCareers] = useState<any>([])
  const [numAlumni, setNumAlumni] = useState<any>(undefined)
  const [accessCase, setAccessCase] = useState<any>('A')
  const [advisorData, setAdvisorData] = useState<any>([])
  const [advisorECs, setAdvisorECs] = useState<any>([])

  let navigate = useNavigate();

  useEffect(() => {

    const getEC = async () => {
      let res = await axios.get(`${dictionary.URL}/ec_activity/${ecId}`)
      setEC(res.data)
      console.log('ec:', res.data)
    };
    getEC();

    const checkInsights = async () => {
      let res = await axios.get(`${dictionary.URL}/ec_insights/`)

      if (res.data.filter((item) => (item.id == ecId)).length > 0) {

        if (res.data.filter((item) => (item.id == ecId))[0].insights['Core Data'][0].sampleSize) {
          setNumAlumni(res.data.filter((item) => (item.id == ecId))[0].insights['Core Data'][0].sampleSize)
        }
        setInsights(true)
      }

      else {
        setInsights(false)
      }

    }
    checkInsights();

    const getCareers = async () => {
      let res = await axios.get(`${dictionary.URL}/careers/`)
      setCareers(res.data)
    };
    getCareers()

    const getAdvisorData = async () => {
      const handleSetAdvisors = async () => {

        const getAdvisorsData = async () => {
          let res = await axios.get(`${dictionary.URL}/advisor_profiles_connect_with/`);
          return res.data
        }

        let advisorCache: any = await dictionary.handleLocalStorageData('dantech-advisors', getAdvisorsData)

        let advisorECs: any = []
        advisorCache.map(advisor => {
          advisor.ecActivities.forEach(ecID => advisorECs.push(ecID))
        })

        setAdvisorData(advisorCache)
        setAdvisorECs([...new Set(advisorECs)])


        // let advisorCache: string | null = localStorage.getItem('dantech-advisors')

        // if (!advisorCache) {
        //   // axios request to get advisors
        //   let res = await axios.get(`${dictionary.URL}/advisor_profiles_connect_with/`);
        //   let advisorECs: any = []
        //   res.data.map(advisor => {
        //     advisor.ecActivities.forEach(ecID => advisorECs.push(ecID))
        //   })

        //   try {
        //     localStorage.setItem('dantech-advisors-ecActivities', JSON.stringify(res.data))
        //   } catch (error) {
        //     console.log('Error:', error)
        //   }

        //   setAdvisorData(res.data)
        //   setAdvisorECs([...new Set(advisorECs)])

        // } else {
        //   try {

        //     const advisorDataParsed = JSON.parse(advisorCache)
        //     setAdvisorData(advisorDataParsed)

        //     let advisorECs: any = []
        //     advisorDataParsed.map(advisor => {
        //       advisor.ecActivities.forEach(ecID => advisorECs.push(ecID))
        //     })
        //     setAdvisorECs([...new Set(advisorECs)])

        //   } catch (error) {

        //     console.log('Error grabbing data, using axios instead:', error)
        //     let res = await axios.get(`${dictionary.URL}/advisor_profiles_connect_with/`);
        //     let advisorECs: any = []
        //     res.data.map(advisor => {
        //       advisor.ecActivities.forEach(ecID => advisorECs.push(ecID))
        //     })

        //     try {
        //       localStorage.setItem('dantech-advisors-ecActivities', JSON.stringify(res.data))
        //     } catch (error) {
        //       console.log('Error:', error)
        //     }

        //     setAdvisorData(res.data)
        //     setAdvisorECs([...new Set(advisorECs)])

        //   }
        // }
      }

      await handleSetAdvisors()
    }
    getAdvisorData()

    let apiToken: any = undefined

    if (getAuth()?.api_token && getAuth()?.groups) {
      apiToken = getAuth()?.api_token
      permissionGroups = getAuth()?.groups
    }

    if (apiToken !== undefined) {
      requestUser(apiToken)
    }

  }, [])

  useEffect(() => {
    dictionary.updateTitleAndDescription(dictionary.pageRoutes.ecDetailGeneric, ec?.name, ec?.description)
  }, [ec])

  const returnAccessPremiumData = (insights, id) => {

    const tokenTrue = (apiToken != undefined)
    const insightsTrue = (insights === true)
    const groupsTrue = (permissionGroups != undefined)
    const hasGroup2 = (permissionGroups?.includes(`EC${ecId}`))

    const caseA = !insightsTrue

    const caseB = tokenTrue && (groupsTrue && hasGroup2) && insightsTrue

    const caseC = (tokenTrue && !groupsTrue) || (tokenTrue && (groupsTrue && !hasGroup2))

    const caseD = !tokenTrue && !groupsTrue

    let matchingEC = extracurricularMap.find(ecObj => parseInt(ecObj.id) == id)

    switch (true) {
      // Premium Data not available for this EC Activity
      case caseA:
        return <></>

      // Premium data available and authenticated user has access
      case caseB:
        return <a
          role="button"
          className="me-5 btn btn-sm fw-bold dca-premium-data-button-color"
          onClick={() => {
            navigate(dictionary.pageRoutes.ecPremiumInsightsWithId(matchingEC?.hyphenated));

            handleGAEventViewPremium('Details', id)
          }}
        >View Premium Data</a>

      // Premium insights available but authenticated user does not have access
      case caseC:
        return <a
          role="button"
          className="me-5 btn btn-sm fw-bold dca-premium-data-button-color"
          // style={{ height: mobile ? '30px' : '' }}
          onClick={() => {
            navigate(dictionary.pageRoutes.ecPremiumInsightsWithId(matchingEC?.hyphenated));

            // setPurchaseID(dictionary.ecPremiumAccessCode);
            // setNavigateTo(dictionary.pageRoutes.ecPremiumInsightsWithId(matchingEC?.hyphenated));
            // setECID(id);

            // handleGAEventPurchaseModalOpen(id)

            // if (ec.name) {
            //   setECName(ec.name);
            // }
            // if (numAlumni != undefined)
            //   setECNumAlumni(numAlumni)
          }}
        >
          <span className='m-3 text-nowrap align-self-end'><i className="bi bi-lock align-self-center" style={{ color: 'black', marginRight: '4px' }}></i>View Premium Data</span>
        </a>

      // Guest user, not authenticated 
      case caseD:
        return <a
          role="button"
          className="me-5 btn btn-sm dca-premium-data-button-color"
          // style={{ height: mobile ? '30px' : '' }}
          onClick={() => {
            navigate(dictionary.pageRoutes.ecPremiumInsightsWithId(matchingEC?.hyphenated));
            // setPrompt('Access to this premium content is reserved for qualifying user accounts. To proceed, please first sign-in to your account (or sign-up for an account) below:');
            // setNavigateTo('');
            // handleGAEventSignInModalOpen('forced')
          }}
        ><span className='m-3 text-nowrap align-self-end'><i className="bi bi-lock align-self-center" style={{ color: 'black', marginRight: '4px' }}></i>View Premium Data</span></a>

      // Same as guest user, not authenticated 
      default:
        // return <a
        //   role="button"
        //   className="me-5 btn btn-sm fw-bold dca-premium-data-button-color"
        //   data-bs-toggle="modal"
        //   href="#login_modal"
        //   onClick={() => {
        //     setPrompt('Access to this premium content is reserved for qualifying user accounts. To proceed, please first sign-in to your account (or sign-up for an account) below:');
        //     setNavigateTo('');
        //     handleGAEventSignInModalOpen('forced')
        //   }}
        // ><span className='m-3 text-nowrap align-self-end'><i className="bi bi-lock align-self-center" style={{ color: 'black', marginRight: '4px' }}></i>View Premium Data</span></a>
        return <a
          role="button"
          className="me-5 btn btn-sm fw-bold dca-premium-data-button-color"
          onClick={() => {
            navigate(dictionary.pageRoutes.ecPremiumInsightsWithId(matchingEC?.hyphenated));

            // setPrompt('Access to this premium content is reserved for qualifying user accounts. To proceed, please first sign-in to your account (or sign-up for an account) below:');
            // setNavigateTo('');
            // handleGAEventSignInModalOpen('forced')
          }}
        ><span className='m-3 text-nowrap align-self-end'><i className="bi bi-lock align-self-center" style={{ color: 'black', marginRight: '4px' }}></i>View Premium Data</span></a>

    }


  }

  const returnPastEndDateTrue = (dates) => {
    const currentDate = new Date();

    const isPastDate = (endDate) => {
      const date = new Date(endDate);
      return date < currentDate;
    };

    return dates?.some((res) => isPastDate(res?.endDate));
  };

  const desktopHeader = (<div className='d-flex flex-row justify-content-between align-items-center' style={{ marginTop: '30px', width: '100%' }}>

    <Link
      className="dca-font-M"
      to={ecSearchLocation}
      style={{ color: 'black' }}>
      <i className="me-3 fa-solid fa-arrow-left" style={{ color: 'black' }} ></i>
      Back to Exploring All Extracurriculars</Link>

    <div className='d-flex flex-row'>

      {/* <button type="button" className="ms-5 me-5 btn btn-sm fw-bold" style={{ background: dictionary.gray }}>Connect with {ec.name} students</button> */}

      {returnAccessPremiumData(insights, ec.id)}

      {advisorECs.some(ecID => ecID == ec.id) && <ConnectWithAdvisorButton
        cardID={ec.id}
        searchPage={dictionary.pageRoutes.ecSearch}
        inLine={true} />}

      {apiToken != undefined ? <SaveButtonECActivityInline
        user={user}
        ecId={ec.id}
        setUser={setUser} /> : <SaveButtonInlineNoAuth setPrompt={setPrompt}
          setNavigateTo={setNavigateTo} />}
    </div>
  </div>)

  const mobileHeader = (<div className='d-flex flex-row justify-content-between align-items-center' style={{ marginTop: '30px', width: '100%' }}>

    <Link
      className="dca-font-M me-2"
      to={ecSearchLocation}
      style={{ color: 'black' }}>
      <i className="me-3 fa-solid fa-arrow-left" style={{ color: 'black' }} ></i>
      Back to Exploring All Extracurriculars</Link>

    <div className='d-flex flex-row align-items-center'>

      {returnAccessPremiumData(insights, ec.id)}

      {apiToken != undefined ? <SaveButtonECActivityInline
        user={user}
        ecId={ec.id}
        setUser={setUser}
        mobile={true} /> : <SaveButtonInlineNoAuth setPrompt={setPrompt}
          setNavigateTo={setNavigateTo}
          mobile={true} />}
    </div>
  </div>)

  const desktopHeaderB = (<div style={{ width: '100%' }}>
    <div className='mt-5 d-flex flex-row align-items-center justify-content-start'>
      {/* <img className="m-3" style={{ height: '75.47px', maxHeight: '75.47px' }} alt="ec_logo" src={ECLogo} /> */}

      <div className='dca-search-title'>{ec.name}</div>
    </div>

    <div className='d-flex flex-row'>
      <ECDetailCardData ec={ec} returnPastEndDateTrue={returnPastEndDateTrue} />

      <div className='fw-bold' style={{ fontSize: '14px', maxWidth: '45vw', width: 'fit-content', padding: '40px' }}>{ec.description}</div>
    </div></div>)

  const mobileHeaderB = (<div style={{ width: '100%' }}>
    <div className='mt-5 d-flex flex-row align-items-center justify-content-start'>
      {/* <img className="m-3" style={{ height: '75.47px', maxHeight: '75.47px' }} alt="ec_logo" src={ECLogo} /> */}

      <div className='dca-search-title'>{ec.name}</div>
    </div>

    <div className='d-flex flex-column'>
      <div className='fw-bold' style={{ fontSize: '14px', padding: '20px 0px 20px 0px' }}>{ec.description}</div>

      <ECDetailCardDataMobile
        ec={ec}
        returnPastEndDateTrue={returnPastEndDateTrue} />

      {advisorECs.some(ecID => ecID == ec.id) && <ConnectWithAdvisorButton
        cardID={ec.id}
        searchPage={dictionary.pageRoutes.ecSearch}
        inLine={true}
        mobile={true} />}

    </div></div>)

  return ec ? (
    <div className='me-5' style={{ marginLeft: '35px' }}>

      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex' style={{ width: '100%' }}>{desktopHeader}</div>
      {/* Mobile Replacement, only shows at below 768px */}
      {/* marginRight to match parent div margin left */}
      <div className='d-flex d-md-none' style={{ width: '100%', marginRight: '35px' }}>{mobileHeader}</div>

      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex' style={{ width: '100%' }}>{desktopHeaderB}</div>
      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-flex d-md-none' style={{ width: '100%', marginRight: '35px' }}>{mobileHeaderB}</div>

      <UpgradeAccountModal />

      {/* <PurchaseDescription
        name={`Premium Insights for ${ec.name}`}
        price={20.00}
        description={premiumInsightsPurchaseDescription}
        modalRef="ec_purchase_modal"
        addCardModalID='' /> */}

      <AddCardECInsights
        handleSubmitFunction={() => handleCreateOrAssignECGroups(apiToken, ecId)}
        insights={insights}
        clientSecret={clientSecret}
        setClientSecret={setClientSecret}
        ecName={ecName} />

      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex' style={{ width: '100%' }}><ECDetailTabs
        ec={ec}
        returnPastEndDateTrue={returnPastEndDateTrue}
      /></div>
      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-flex d-md-none' style={{ width: '100%' }}><ECDetailTabs
        ec={ec}
        returnPastEndDateTrue={returnPastEndDateTrue}
        mobile={true}
      /></div>

    </div>

  ) : (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginLeft: '300px' }}>
      <LoadingIndicator text={"Getting EC Data ... "} mt={'0px'} ml={'0px'} />
    </div>
  )
}

export { ECDetailPage }
