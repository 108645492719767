import { useState, useEffect } from "react"
import axios from "axios"
import { URL, titleSpace, numberConverter } from "../../dictionary"
import { LoadingIndicator } from "../../LoadingIndicator"
import { PopularityIndex, PopularityIndexTable } from "../../MajorDetail/PopularityIndex"
import sortIcon from '../../../../custom_assets/noun-sort.png'

const returnAreaName = (major) => {

  let groupNumber = (major?.primaryGroup || major?.secondaryGroup) ?? undefined

  if (!groupNumber) {
    return 'N/A'
  }

  switch (groupNumber) {
    case 3:
      return 'Arts'
    case 9:
      return 'Business'
    case 1:
      return 'Computer & Information Sciences'
    case 10:
      return 'Education'
    case 5:
      return 'Engineering'
    case 8:
      return 'Health & Medicine'
    case 4:
      return 'Humanities'
    case 6:
      return 'Mathematics & Statistics'
    case 2:
      return 'Physical & Natural Sciences'
    case 7:
      return 'Social Sciences'
    case 11:
      return 'Other'
  }
}

const Majors = ({ college, majorTab }) => {

  const [programData, setProgramData] = useState<any>([])
  const [sortBy, setSortBy] = useState<any>('majorName')
  // true == ascending, false == descending
  const [sortDirection, setSortDirection] = useState(true)
  const [sorted, setSorted] = useState<any>([])
  // let sortOptions = ['majorName', 'area', 'enrolled', 'degrees', 'popIndex', 'earnings']

  useEffect(() => {
    const getProgramData = async (collegeID) => {
      let res = await axios.get(`${URL}/program_data_list_by_college_id/${collegeID}`)
      setProgramData(res.data)
    }

    if (college?.id) {
      getProgramData(college.id)
    }

  }, [college])

  useEffect(() => {

    const sortProgramData = () => {

      let res = [...programData]

      if (res.length > 0) {
        switch (sortBy) {
          case 'majorName':
            res = res.sort((a, b) => {
              return (a.major?.name.localeCompare(b.major?.name))
            })
            break;
          case 'area':
            res = res.sort((a, b) => {
              // using 'ZZZZZZ' as 'N/A' substitute to push unknown area to end of array
              let areaA = (returnAreaName(a?.major) == 'N/A' ? 'ZZZZZZ' : returnAreaName(a?.major)) ?? 'ZZZZZZ'
              let areaB = (returnAreaName(b?.major) == 'N/A' ? 'ZZZZZZ' : returnAreaName(b?.major)) ?? 'ZZZZZZ'
              return (areaA.localeCompare(areaB))
            })
            break;
          case 'enrolled':
            res = res.sort((a, b) => {
              const enrolledA = a?.numEnrollees ?? 0
              const enrolledB = b?.numEnrollees ?? 0
              return (enrolledA - enrolledB)
            })
            break;
          case 'degrees':
            res = res.sort((a, b) => {
              const degreesA = a?.degreesConferred ?? 0
              const degreesB = b?.degreesConferred ?? 0
              return (degreesA - degreesB)
            })
            break;
          case 'popIndex':
            res = res.sort((a, b) => {
              // use sizeIndex of programData obj
              const popIndexA = a?.sizeIndex ?? -Infinity
              const popIndexB = b?.sizeIndex ?? -Infinity
              return popIndexA - popIndexB
            });
            break;
          case 'earnings':
            res = res.sort((a, b) => {
              const earningsA = a?.mdn4yrEarnings ?? -Infinity
              const earningsB = b?.mdn4yrEarnings ?? -Infinity
              return (earningsA - earningsB)
            })
            break;
        }
      }

      setSorted(res)
    }

    if (programData.length > 0) {
      sortProgramData()
    }


  }, [sortBy, programData])

  const sortIconElement = () => {
    return (
      <img
        style={{ marginLeft: '5px' }}
        src={sortIcon}
        alt="sort-carat"
        className={!sortDirection ? 'dca-college-detail-major-table-sort-carat-desc' : 'dca-college-detail-major-table-sort-carat'}
      />
    );
  };

  const returnTH = (headerText, sortByParam) => {
    return <th scope="col">
      <div
        onClick={() => {

          if (sortBy === sortByParam) {
            setSortDirection(!sortDirection);
            setSorted([...sorted].reverse())
          }

          else {
            setSortBy(sortByParam);
            setSortDirection(true)
          }

        }}
        className="d-flex flex-row align-items-center"
        style={{
          cursor: 'pointer',
          letterSpacing: titleSpace,
          whiteSpace: 'nowrap'
        }}>
        {headerText} {sortBy == sortByParam && sortIconElement()}
      </div>
    </th>
  }

  let majorTable = (<div style={{ width: '100%', overflowX: 'auto' }}>
    <table className="table table-borderless table-sm table-responsive" style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr className="dca-font-M">
          {returnTH('MAJOR NAME', 'majorName')}

          {returnTH('AREA', 'area')}

          {returnTH(college?.majorSizeMetric == 1 ? 'DEGREES CONFERRED*' : 'STUDENTS ENROLLED*', college?.majorSizeMetric == 1 ? 'degrees' : 'enrolled')}

          {returnTH('POPULARITY INDEX', 'popIndex')}

          {returnTH('MEDIAN EARNINGS', 'earnings')}
        </tr>
      </thead>
      <tbody>

        {sorted.map((res, index) => (
          <tr key={index} className="dca-font-M" style={{ borderBottom: '1px solid rgba(207,207,207,1)', verticalAlign: 'middle' }}>
            <td scope='row' className="text-start dca-font-M">{res.major?.name}</td>

            <td>{returnAreaName(res?.major)}</td>

            {college?.majorSizeMetric == 1 ? <td>{res?.degreesConferred ? numberConverter(res?.degreesConferred) : '-'}</td> : <td>{res?.numEnrollees ? numberConverter(res?.numEnrollees) : '-'}</td>}

            <td><PopularityIndex index={res?.sizeIndex} /></td>

            <td>{res?.mdn4yrEarnings ? `$${numberConverter(res?.mdn4yrEarnings)}` : '-'}</td>
          </tr>
        ))}

      </tbody>
    </table>
    <div id='dca-college-details-major-table-note' style={{ marginTop: '40px' }}>*The major data displayed above is from {college?.majorSizeTimeframe ? college.majorSizeTimeframe : 'N/A'}.</div>
  </div>)

  const loadingElement = (<div className="d-flex justify-content-center" style={{ marginBottom: '40px' }}><LoadingIndicator text={'Loading Major Data...'} mt={'0px'} ml={'0px'} /></div>)

  return <div style={{ marginTop: '40px' }}>
    <div className='card'>
      {majorTab && <div style={{ margin: '40px 30px 0px 30px' }}>
        {(sorted?.length > 0) ? majorTable : loadingElement}
      </div>}
    </div>
  </div>
}

export { Majors }