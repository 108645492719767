import { ContentBlock } from "../ContentBlock"
import { currentYear, pageRoutes } from "../../dictionary"
import { useNavigate } from "react-router"
import IntendedMajorIcon from '../../../../custom_assets/dashboard/intended_major.png'
import wizardHome from '../../../../custom_assets/dashboard/wizardHome.png'
import { useEffect, useState, useRef } from "react"
import { handleLocalStorageData, URL } from "../../dictionary"
import axios from "axios"
import { returnProgressTrackerText } from "../ProgressTrackerText/IntendedMajor"
import { MultiSelect } from "../../MultiSelect"
import { RadioInput } from "../../RadioInputs"
import { scrollToWizard } from "../../../pages/Dashboard"
import { DashboardReactGAHandlers } from "../../ReactGAHandlers"

const IntendedMajor = ({ user, userCollegeInterest, collegeData, wizardActive, setWizardActive, status, expand, advisors, setUserprofileSelectedTab, majorData, programData, statusLabelMaxWidth }) => {

  const {
    handleGAEventCTAClick,
    handleGAEventWizardSkipClick,
    handleGAEventMajorWizardNextClick } = DashboardReactGAHandlers()

  let navigate = useNavigate()
  let blockClass = 'IntendedMajor'
  let title = 'Intended Majors'
  let gradYr = user?.hsGradYr ?? undefined
  let userMajors = user?.majorsInterest ?? undefined

  const intendedMajorPositionRef = useRef<HTMLDivElement>(null);

  let body = `You will need to select 1-3 intended major(s) as part of your college applications. We recommend for you to begin exploring and identifying candidate majors as early as the start of high school, as this should help guide decisions during high school - such as which classes to take and which extracurricular activities to pursue - towards the objective of having a thematically aligned application by senior year.`

  const [wizard, setWizard] = useState<any>({
    page: 1,
    response: undefined,
    params: undefined
  })
  let sortParams = `&sort=numColleges%2CDescending`
  const [majorSearchURL, setMajorSearchURL] = useState<any>(`${pageRoutes.majorSearch}?${sortParams}`)
  // http://localhost:3011/majors?fieldOfStudy=500506&sort=numColleges%2CDescending
  // {pageRoutes.majorSearch}?{paramsToString}&{sortParams}
  const [cipTax, setCipTax] = useState<any>([])
  const [fieldOptions, setFieldOptions] = useState<any>([])
  const [courseOptions, setCourseOptions] = useState<any>([])

  const [selectedField, setSelectedField] = useState<any>([]);
  const [areasSelects, setAreasSelects] = useState<any>({
    artsSelect: false,
    businessSelect: false,
    computerInformationSelect: false,
    educationSelect: false,
    engineeringSelect: false,
    healthMedicineSelect: false,
    humanitiesSelect: false,
    mathStatsSelect: false,
    physNatSelect: false,
    socialSciSelect: false,
    otherSelect: false
  })
  const [skipAreasSelects, setSkipAreasSelects] = useState<any>(undefined)
  const [selectedCourses, setSelectedCourses] = useState<any>([]);
  const [computerInfoSpan, setComputerInfoSpan] = useState('Computer & Information Sciences')

  useEffect(() => {
    const updateComputerInfoSpan = () => setComputerInfoSpan(window.innerWidth <= 450 ? 'Computer & Info. Sciences' : 'Computer & Information Sciences');
    window.addEventListener('resize', updateComputerInfoSpan);
    return () => window.removeEventListener('resize', updateComputerInfoSpan);
  }, []);

  const selectsMap = {
    artsSelect: 3,
    businessSelect: 9,
    computerInformationSelect: 1,
    educationSelect: 10,
    engineeringSelect: 5,
    healthMedicineSelect: 8,
    humanitiesSelect: 4,
    mathStatsSelect: 6,
    physNatSelect: 2,
    socialSciSelect: 7,
    otherSelect: 11
  };

  const checkCheckCase = (filterVal) => {
    return filterVal ? true : false
  }

  let areasArray = [
    {
      name: 'Arts', onChange: 'artsSelect', spanText: 'Arts', check: areasSelects.artsSelect
    },

    {
      name: 'Business', onChange: 'businessSelect', spanText: 'Business', check: areasSelects.businessSelect
    },

    {
      name: 'Computer & Information Sciences', onChange: 'computerInformationSelect', spanText: computerInfoSpan, check: areasSelects.computerInformationSelect
    },

    {
      name: 'Education', onChange: 'educationSelect', spanText: 'Education', check: areasSelects.educationSelect
    },

    {
      name: 'Engineering', onChange: 'engineeringSelect', spanText: 'Engineering', check: areasSelects.engineeringSelect
    },

    {
      name: 'Health & Medicine', onChange: 'healthMedicineSelect', spanText: 'Health & Medicine', check: areasSelects.healthMedicineSelect
    },

    {
      name: 'Humanities', onChange: 'humanitiesSelect', spanText: 'Humanities', check: areasSelects.humanitiesSelect
    },

    {
      name: 'Mathematics & Statistics', onChange: 'mathStatsSelect', spanText: 'Mathematics & Statistics', check: areasSelects.mathStatsSelect
    },

    {
      name: 'Physical & Natural Sciences', onChange: 'physNatSelect', spanText: 'Physical & Natural Sciences', check: areasSelects.physNatSelect
    },

    {
      name: 'Social Sciences', onChange: 'socialSciSelect', spanText: 'Social Sciences', check: areasSelects.socialSciSelect
    },

    {
      name: 'Other', onChange: 'otherSelect', spanText: 'Other', check: areasSelects.otherSelect
    },
  ]

  const handleFieldSelectChange = (selectedField) => {
    console.log('selectedField', selectedField)
    setSelectedField(selectedField)
    setWizard(prevState => ({
      ...prevState, params: selectedField
    }))
  };

  const handleCourseSelectChange = (selectedCourses) => {
    setSelectedCourses(selectedCourses)
    setWizard(prevState => ({
      ...prevState, params: selectedCourses
    }))
  }

  useEffect(() => {
    let converted = Object.keys(areasSelects)
      .filter((key) => areasSelects[key])
      ?.map((key) => selectsMap[key]);

    if (converted?.length > 0) {
      setSkipAreasSelects(false)
    }

    setWizard(prevState => ({
      ...prevState, params: converted
    }))
  }, [areasSelects])

  useEffect(() => {
    if (wizardActive.IntendedMajor === false) {
      setWizard({
        page: 1,
        response: undefined,
        params: undefined
      })

      setAreasSelects({
        artsSelect: false,
        businessSelect: false,
        computerInformationSelect: false,
        educationSelect: false,
        engineeringSelect: false,
        healthMedicineSelect: false,
        humanitiesSelect: false,
        mathStatsSelect: false,
        physNatSelect: false,
        socialSciSelect: false,
        otherSelect: false
      })

      setSkipAreasSelects(undefined)
    }

    else if (wizardActive.IntendedMajor && intendedMajorPositionRef.current) {
      scrollToWizard(intendedMajorPositionRef)
    }

  }, [wizardActive.IntendedMajor])

  useEffect(() => {
    if (wizard.page === 2 && wizard.response === true) {
      // since page 1 params is dependent on user selecting yes first, just set selectedField.length instead of directly accessing wizard.params. meanwhile in page 2 and 3 params can be set in any state of those pages
      if (selectedField.length > 0) {
        const fieldIDs = selectedField.map(obj => obj.value).join(',');
        setMajorSearchURL(`${pageRoutes.majorSearch}?fieldOfStudy=${fieldIDs}${sortParams}`)
      }
    }

    else if (wizard.page === 2 && wizard.response === false) {
      if (wizard.params?.length > 0) {
        const areaIDs = wizard.params?.map(obj => obj).join(',');
        setMajorSearchURL(`${pageRoutes.majorSearch}?areas=${areaIDs}&${sortParams}`)
      }
    }

    else if (wizard.page === 3) {
      if (selectedCourses.length > 0) {
        const courseIDs = selectedCourses.map(obj => obj.value).join(',');
        setMajorSearchURL(`${pageRoutes.majorSearch}?highSchoolCourses=${courseIDs}${sortParams}`)
      }
    }

  }, [wizard.params])

  useEffect(() => {
    if (wizard?.page === 3) {
      scrollToWizard(intendedMajorPositionRef)
    }
  }, [wizard.page])

  useEffect(() => {
    const setCIPTaxonomyData = async () => {
      const getCIPTaxonomyData = async () => {
        let res = await axios.get(`${URL}/cip_taxonomy/`)
        return res.data
      }
      let cipTaxCache: any = await handleLocalStorageData('dantech-cipTaxonomy', getCIPTaxonomyData)
      setCipTax(cipTaxCache)
    }

    const getCourseOptions = async () => {

      let res = await axios.get(`${URL}/interests/`)
      let courseRes: any = []
      res.data.forEach(course => {
        courseRes.push({ value: course.id, label: course.name })
      })

      setCourseOptions(courseRes)
    }

    setCIPTaxonomyData();
    getCourseOptions();
  }, [])

  useEffect(() => {
    // from major search
    const getFieldOptions = (cipTax, majorData) => {

      // Get unique cip codes list from cip codes in majors table first to trim down # of cip codes to work with on major search page
      let uniqueCips: any = []
      majorData?.map(major => {

        if (!uniqueCips.includes(major.cipt_code)) {
          uniqueCips.push(major.cipt_code);
        }

        major.secondaryCIPs?.forEach(secCip => {
          if (!uniqueCips.includes(secCip)) {
            uniqueCips.push(secCip)
          }
        })

      })
      uniqueCips = uniqueCips.sort((a, b) => a.localeCompare(b))

      let res: any = []
      // If the CIP code from cip tax is in the uniqueCIPs list created from the majors table, add to the field option codes list of objects to be passed to the filter component
      cipTax?.map((cip) => {
        if (uniqueCips.includes(cip.code)) {
          res.push({ value: cip.code, label: cip.name })
        }
      })

      res = res.sort((a, b) => a.label.localeCompare(b.label))
      setFieldOptions(res)
    }

    if (cipTax.length !== 0 && majorData.length !== 0) {
      getFieldOptions(cipTax, majorData)
    }
  }, [cipTax, majorData])

  if (gradYr) {
    let yearDif = gradYr - currentYear

    if (yearDif && yearDif > 6) {
      body = `No action required now, but keep in mind that you will eventually need to select 1-3 intended major(s) as part of your college applications. Therefore, we recommend for you to begin exploring and identifying candidate majors around the start of high school, as that can be helpful in guiding some decisions during high school - such as which classes to take and which extracurricular activities to pursue - towards the objective of having a thematically aligned application by senior year.`
    } else if (yearDif && (yearDif > 4) && (yearDif <= 6)) {
      body = `You will need to select 1-3 intended major(s) as part of your college applications. We recommend for you to begin exploring and identifying candidate majors now, as this should help guide decisions during high school - such as which classes to take and which extracurricular activities to pursue - towards the objective of having a thematically aligned application by senior year.`
    } else {
      body = body
    }
  }

  const button = <button
    onClick={() => {
      handleGAEventCTAClick(title);

      userMajors?.length > 0 ? navigate(pageRoutes.majorSearch) : setWizardActive(prevState => ({
        ...prevState,
        IntendedMajor: true
      }))
    }}
    className="dca-dashboard-button" style={{ marginTop: status === 'inactive' ? '34px' : '' }}>Explore Majors</button>

  const resources = [
    { navigate: pageRoutes.advisorNetwork, title: 'Advisors Network', description: 'Connect 1:1 with college students to learn insights on their majors' },
    { navigate: pageRoutes.personalizedExpertHelp, title: 'Expert Q&A', description: 'Get personalized help for strategically selecting majors' }
  ]

  const insights = [
    {
      navigate: pageRoutes.overviewAdmissionsAdviceWithHash('selecting-intended-major(s)'),
      text: 'Advice For Selecting Intended Major(s)'
    },
    {
      navigate: pageRoutes.overviewAdmissionsAdviceWithHash('identifying-core-application-theme'),
      text: 'Developing A Core Application Theme'
    },
    {
      external: true,
      navigate: 'https://blog.nextfour.ai/metasearch-for-college-majors/',
      text: 'Metasearch For College Majors'
    }
  ]

  const returnWizardQuestion = () => {
    switch (true) {
      case wizard.page == 1:
        return `Do you already know which field(s) of study you are interested in studying in college?`
      case wizard.page == 2 && wizard.response == true:
        return `Select the field(s) of study you are interested in.`
      case wizard.page == 2 && wizard.response == false:
        return `What high-level subject area(s) are most interesting to you?`
      case wizard.page == 3:
        return `What are your favorite classes in school so far?`
    }
  }

  const returnWizardResponseForm = () => {

    let page1 = (
      <div className='dca-dashboard-wizard-form-wrapper align-items-center'>

        <RadioInput
          selectedState={wizard.response === true}
          onClick={() => setWizard(prevState => ({
            ...prevState, response: true
          }))}
          name={"userType"}
          id={"qa_parent"}
          text={"Yes"} />

        <RadioInput
          selectedState={wizard.response === false}
          onClick={() => setWizard(prevState => ({
            ...prevState, response: false
          }))}
          name={"userType"}
          id={"qa_student"}
          text={"No"}
          mt={"15px"}
          mobileMT={"18px"} />

        <button
          className="dca-dashboard-wizard-button align-self-center"
          disabled={wizard?.response === undefined ? true : false}
          onClick={() => {
            handleGAEventMajorWizardNextClick('Fields1');
            setWizard(prevState => ({
              ...prevState, page: prevState.page + 1
            }))
          }
          }>Next</button>

      </div>)

    let page2True = <div className='dca-dashboard-wizard-form-wrapper' style={{ width: '100%' }}>
      <div className='mt-4 d-flex flex-column' style={{ width: '100%' }}>
        <div className='dca-aside-header dca-dashboard-select-label'>FIELDS OF STUDY</div>
        <MultiSelect options={fieldOptions} value={selectedField} onChange={handleFieldSelectChange} /></div>

      <button
        onClick={() => {
          handleGAEventMajorWizardNextClick('Fields2');
          navigate(majorSearchURL)
        }}
        className="dca-dashboard-wizard-button"
        disabled={wizard?.params?.length > 0 ? false : true}>Next</button>
    </div>

    let page2False = <div className='dca-dashboard-wizard-form-wrapper' id="dca-dashboard-wizard-form-wrapper-mobile">
      <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
        <div className='dca-aside-header' id="dca-dashboard-major-areas-label-mobile">MAJOR AREAS</div>
        <div className="checkbox-list d-flex flex-column" style={{ marginTop: '5px' }}>

          {areasArray?.map((res, index) => (
            <RadioInput
              selectedState={checkCheckCase(res.check)}
              onClick={() => {
                setAreasSelects(prevState => ({
                  ...prevState, [res.onChange]: !prevState[res.onChange]
                }))
              }}
              mt={index != 0 ? '10px' : ''}
              mobileMT={index != 0 ? '18px' : ''}
              name={res.name}
              id={res.name}
              text={res.spanText}
              box={true} />
          ))}

          <div
            onClick={() => {
              setAreasSelects({
                artsSelect: false,
                businessSelect: false,
                computerInformationSelect: false,
                educationSelect: false,
                engineeringSelect: false,
                healthMedicineSelect: false,
                humanitiesSelect: false,
                mathStatsSelect: false,
                physNatSelect: false,
                socialSciSelect: false,
                otherSelect: false
              });
              skipAreasSelects === undefined ? setSkipAreasSelects(true) : setSkipAreasSelects(!skipAreasSelects)
            }}
            className="dca-dashboard-intro-radio-custom-intended-majors-not-sure"
          >
            <input
              className={`form-check-input box`}
              type="radio"
              name={'skip'}
              style={{ cursor: 'pointer' }}
              checked={skipAreasSelects === true}
              id={'skip'}></input>I'm not sure
          </div>

        </div>
      </div>

      <button
        onClick={() => {
          handleGAEventMajorWizardNextClick('Subjects');

          skipAreasSelects === true ? setWizard(prevState => ({
            ...prevState, page: prevState.page + 1
          })) : navigate(majorSearchURL)
        }
        }
        className="dca-dashboard-wizard-button"
        disabled={(wizard.params?.length > 0 || skipAreasSelects === true) ? false : true}>Next</button>
    </div>

    let page3 = <div className='dca-dashboard-wizard-form-wrapper' style={{ width: '100%' }}>
      <div className='mt-4 d-flex flex-column' style={{ width: '100%' }}>
        <div className='dca-aside-header dca-dashboard-select-label'>HIGH SCHOOL COURSES</div>
        <MultiSelect options={courseOptions} value={selectedCourses} onChange={handleCourseSelectChange} />
      </div>

      <button
        onClick={() => {
          handleGAEventMajorWizardNextClick('Courses');
          navigate(majorSearchURL)
        }}
        className="dca-dashboard-wizard-button"
        disabled={wizard?.params?.length > 0 ? false : true}>Next</button>
    </div>

    switch (true) {
      case wizard.page == 1:
        return page1
      case wizard.page == 2 && wizard.response == true:
        return page2True
      case wizard.page == 2 && wizard.response == false:
        return page2False
      case wizard.page == 3:
        return page3
    }
  }

  const sharedWizardContent = <div className='dca-dashboard-wizard-header'>
    <div
      className="dca-intro-back-link"
      onClick={() => (wizard.page == 1 && wizard.response == true) ? setWizard(prevState => ({
        ...prevState, response: false
      })) : wizard.page > 1 ? setWizard(prevState => ({
        ...prevState, page: prevState.page - 1
      })) : setWizardActive(prevState => ({
        ...prevState, [blockClass]: false
      }))}><i className="fa-solid fa-arrow-left dca-intro-backArrow"></i>&nbsp;&nbsp;Back</div>

    <img className='dca-dashboard-wizard-home-icon' src={wizardHome} alt='home_icon' onClick={() => {
      setWizardActive(prevState => ({
        ...prevState,
        [blockClass]: false,
      }));
    }}></img>

    <div
      className="dca-intro-back-link"
      style={{ visibility: 'hidden' }}
    ><i className="fa-solid fa-arrow-left dca-intro-backArrow"></i>&nbsp;&nbsp;Back</div>
  </div>

  const wizardContent = (<div ref={intendedMajorPositionRef} className="d-flex flex-column justify-content-center">

    {sharedWizardContent}

    <h4 className="dca-introflow-question-text" id="dca-introflow-question-text-wizard-margin">{returnWizardQuestion()}</h4>

    {wizard.page == 1 && <span className="dca-dashboard-wizard-sub-question">Examples of fields of study: Computer Science, Economics, Neuroscience</span>}

    {[2, 3].includes(wizard.page) && <span className="dca-dashboard-wizard-select-multiple-note">You can select multiple</span>}

    {returnWizardResponseForm()}

    <span
      className="dca-dashboard-wizard-skip-wizard-link"
      onClick={() => {
        handleGAEventWizardSkipClick(title);

        navigate(pageRoutes.majorSearch)
      }}>Go straight to Majors Explorer</span>
  </div>
  );

  const handleProgressTrackerLink = () => {
    setUserprofileSelectedTab('Majors of Interest');
    navigate(pageRoutes.userProfileWithId(user?.user_id))
  }

  let progressTracker = {
    color: status,
    text: returnProgressTrackerText(userMajors, majorData, collegeData, programData, advisors, userCollegeInterest, gradYr),
    count: userMajors?.length ?? 0,
    subject: userMajors?.length === 1 ? `Major` : `Majors`,
    link: userMajors?.length > 0 ? handleProgressTrackerLink : undefined
  }

  let block = {
    class: blockClass,
    title: title,
    body: body,
    icon: IntendedMajorIcon,
    specialIconClass: 'dca-dashboard-intendedMajorIcon',
    button: button,
    resources: resources,
    insights: insights,
    wizardContent: wizardContent,
    status: status,
    expand: expand,
    progressTracker: progressTracker
  }

  return <ContentBlock block={block} wizardActive={wizardActive} setWizardActive={setWizardActive} statusLabelMaxWidth={statusLabelMaxWidth} />
}

export { IntendedMajor }