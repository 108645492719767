const PopularityIndex = ({ index }) => {

  if (typeof index !== 'number' || index > 10 || index < 0) {
    return (<div className='d-flex justify-content-center align-items-center fw-bold rounded' style={{ width: '30px', height: '30px' }}> - </div>)
  }

  else {

    const returnWidth = 15 + (10 * index)

    return (
      <div className='d-flex justify-content-center align-items-center align-self-center fw-bold rounded'
        style={{ backgroundColor: '#e0f8ff', width: `${returnWidth}px`, height: '30px' }}
      >
        {index}
      </div>
    );
  }
};

const PopularityIndexTable = ({ index }) => {

  if (typeof index !== 'number' || index > 10 || index < 0) {
    return (<div className='d-flex justify-content-center align-items-center fw-bold rounded' style={{ width: '30px', height: '30px' }}> - </div>)
  }

  else {

    return (
      <div className='d-flex justify-content-center align-items-center align-self-center fw-bold rounded'
        style={{ backgroundColor: '#e0f8ff', width: `30px`, height: '30px' }}
      >
        {index}
      </div>
    );
  }
};

export { PopularityIndex, PopularityIndexTable }