import { useEffect, useState } from "react"
import { useLocation } from "react-router"
import { TooltipComponent, TooltipComponentMobile } from "../../Tooltip"

const MajorAreasSelect = ({ setAreasSelect, majors, resetStateToggle, filteredMajorsFull, mobile = false }) => {

  const location = useLocation()

  const handleCheckChange = (event) => {
    let checkStatus = event.target.checked
    return checkStatus
  }

  const checkCheckCase = (filterVal) => {
    return filterVal ? true : false
  }

  const [artsSelect, setArtsSelect] = useState(false)
  const [businessSelect, setBusinessSelect] = useState(false)
  const [computerInformationSelect, setComputerInformationSelect] = useState(false)
  const [educationSelect, setEducationSelect] = useState(false)
  const [engineeringSelect, setEngineeringSelect] = useState(false)
  const [healthMedicineSelect, setHealthMedicineSelect] = useState(false)
  const [humanitiesSelect, setHumanitiesSelect] = useState(false)
  const [mathStatsSelect, setMathStatsSelect] = useState(false)
  const [physNatSelect, setPhysNatSelect] = useState(false)
  const [socialSciSelect, setSocialSciSelect] = useState(false)
  const [otherSelect, setOtherSelect] = useState(false)

  useEffect(() => {
    setArtsSelect(false);
    setBusinessSelect(false);
    setComputerInformationSelect(false);
    setEducationSelect(false);
    setEngineeringSelect(false);
    setHealthMedicineSelect(false);
    setHumanitiesSelect(false);
    setMathStatsSelect(false);
    setPhysNatSelect(false);
    setSocialSciSelect(false);
    setOtherSelect(false);
  }, [resetStateToggle])

  const selects = [artsSelect, businessSelect, computerInformationSelect, educationSelect, engineeringSelect, healthMedicineSelect, humanitiesSelect, mathStatsSelect, physNatSelect, socialSciSelect, otherSelect];

  const selectsMap = {
    artsSelect: 3,
    businessSelect: 9,
    computerInformationSelect: 1,
    educationSelect: 10,
    engineeringSelect: 5,
    healthMedicineSelect: 8,
    humanitiesSelect: 4,
    mathStatsSelect: 6,
    physNatSelect: 2,
    socialSciSelect: 7,
    otherSelect: 11
  };

  let artsCount: any = null
  let businessCount: any = null
  let computerInformationCount: any = null
  let educationCount: any = null
  let engineeringCount: any = null
  let healthMedicinceCount: any = null
  let humanitiesCount: any = null
  let mathStatsCount: any = null
  let physNatCount: any = null
  let socialSciCount: any = null
  let otherCount: any = null

  useEffect(() => {
    const returnAreasSelect = () => {

      let selectArray: any = []
      const keys = Object.keys(selectsMap);
      for (let i = 0; i < selects.length; i++) {
        if (selects[i]) {
          const key = keys[i]
          selectArray.push(selectsMap[key])
        }
      }

      selectArray = [...new Set(selectArray)]

      setAreasSelect(selectArray)
    };

    returnAreasSelect();

  }, [artsSelect, businessSelect, computerInformationSelect, educationSelect, engineeringSelect, healthMedicineSelect, humanitiesSelect, mathStatsSelect, physNatSelect, socialSciSelect, otherSelect]);

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let areasParam = params.get('areas');

    if (areasParam) {
      const areasParamSplit = areasParam.split(',').map(app => app)

      // reference const selectsMap = {
      //   artsSelect: 3,
      //   businessSelect: 9,
      //   computerInformationSelect: 1,
      //   educationSelect: 10,
      //   engineeringSelect: 5,
      //   healthMedicineSelect: 8,
      //   humanitiesSelect: 4,
      //   mathStatsSelect: 6,
      //   physNatSelect: 2,
      //   socialSciSelect: 7,
      //   otherSelect: 11
      // };

      areasParamSplit.map((app) => {
        switch (true) {
          case parseInt(app) === 3:
            setArtsSelect(true)
            break;
          case parseInt(app) === 9:
            setBusinessSelect(true)
            break;
          case parseInt(app) === 1:
            setComputerInformationSelect(true)
            break;
          case parseInt(app) === 10:
            setEducationSelect(true)
            break
          case parseInt(app) === 5:
            setEngineeringSelect(true)
            break
          case parseInt(app) === 8:
            setHealthMedicineSelect(true)
            break
          case parseInt(app) === 4:
            setHumanitiesSelect(true)
            break
          case parseInt(app) === 6:
            setMathStatsSelect(true)
            break
          case parseInt(app) === 2:
            setPhysNatSelect(true)
            break
          case parseInt(app) === 7:
            setSocialSciSelect(true)
            break
          case parseInt(app) === 11:
            setOtherSelect(true)
            break
        }
      })

      const returnAreasSelect = () => {

        let selectArray: any = []
        const keys = Object.keys(selectsMap);
        for (let i = 0; i < selects.length; i++) {
          if (selects[i]) {
            const key = keys[i]
            selectArray.push(selectsMap[key])
          }
        }

        selectArray = [...new Set(selectArray)]

        setAreasSelect(selectArray)
      };

      returnAreasSelect();

    }

  }, [location.search]);


  const getCount = (enumVal) => {
    return filteredMajorsFull.filter(item => item.primaryGroup == enumVal || item.secondaryGroup == enumVal).length
    // return majors.filter(item => item.primaryGroup == enumVal || item.secondaryGroup == enumVal).length
  }

  if (majors) {

    artsCount = getCount(selectsMap['artsSelect'])
    businessCount = getCount(selectsMap['businessSelect'])
    computerInformationCount = getCount(selectsMap['computerInformationSelect'])
    educationCount = getCount(selectsMap['educationSelect'])
    engineeringCount = getCount(selectsMap['engineeringSelect'])
    healthMedicinceCount = getCount(selectsMap['healthMedicineSelect'])
    humanitiesCount = getCount(selectsMap['humanitiesSelect'])
    mathStatsCount = getCount(selectsMap['mathStatsSelect'])
    physNatCount = getCount(selectsMap['physNatSelect'])
    socialSciCount = getCount(selectsMap['socialSciSelect'])
    otherCount = getCount(selectsMap['otherSelect'])

  }

  let areasArray = [
    { name: 'Arts', onChange: setArtsSelect, spanText: 'Arts', filterCount: artsCount, check: artsSelect },

    { name: 'Business', onChange: setBusinessSelect, spanText: 'Business', filterCount: businessCount, check: businessSelect },

    { name: 'Computer & Information Sciences', onChange: setComputerInformationSelect, spanText: 'Computer & Information Sciences', filterCount: computerInformationCount, check: computerInformationSelect },

    { name: 'Education', onChange: setEducationSelect, spanText: 'Education', filterCount: educationCount, check: educationSelect },

    { name: 'Engineering', onChange: setEngineeringSelect, spanText: 'Engineering', filterCount: engineeringCount, check: engineeringSelect },

    { name: 'Health & Medicine', onChange: setHealthMedicineSelect, spanText: 'Health & Medicine', filterCount: healthMedicinceCount, check: healthMedicineSelect },

    { name: 'Humanities', onChange: setHumanitiesSelect, spanText: 'Humanities', filterCount: humanitiesCount, check: humanitiesSelect },

    { name: 'Mathematics & Statistics', onChange: setMathStatsSelect, spanText: 'Mathematics & Statistics', filterCount: mathStatsCount, check: mathStatsSelect },

    { name: 'Physical & Natural Sciences', onChange: setPhysNatSelect, spanText: 'Physical & Natural Sciences', filterCount: physNatCount, check: physNatSelect },

    { name: 'Social Sciences', onChange: setSocialSciSelect, spanText: 'Social Sciences', filterCount: socialSciCount, check: socialSciSelect },

    { name: 'Other', onChange: setOtherSelect, spanText: 'Other', filterCount: otherCount, check: otherSelect },
  ]


  return (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className={`dca-aside-header ${mobile ? 'd-flex flex-row' : ''}`}>MAJOR AREAS {mobile ? <span className="ms-2"><TooltipComponentMobile content={"Use these controls to explore majors within broad subject areas."} /></span> : <TooltipComponent content={"Use these controls to explore majors within broad subject areas."} />}</div>
      <div className="checkbox-list d-flex flex-column" style={{ marginTop: '5px' }}>

        {areasArray.map((res, index) => (
          <label key={index} className="d-flex justify-content-between" style={{ cursor: 'pointer', marginTop: index !== 0 ? '4px' : '' }}>
            <div>
              <input
                type="checkbox"
                className="dca-checkbox"
                checked={checkCheckCase(res.check)}
                name={res.name}
                onChange={(event) => res.onChange((handleCheckChange(event)))}
              />
              <span className='ms-3 me-3'>{res.spanText}</span>
            </div>
            <span className='text-muted fs-7 fw-bolder'>{res.filterCount}</span>
          </label>
        ))}

      </div>
    </div>
  )
}

export { MajorAreasSelect }