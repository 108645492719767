import { sessionStorageStore, sessionStorageGet, currentYear } from "../dictionary"
import { handleUserProfilePatchIntroQuestions } from "../../pages/UserProfile"
import { getAuth } from "../../modules/auth"
import { useState, useEffect, Fragment } from "react"
import { Calendar } from 'primereact/calendar';
import "primereact/resources/themes/lara-light-indigo/theme.css"
import "primereact/resources/primereact.min.css"
import { RadioInput } from "../RadioInputs"
import { DashboardReactGAHandlers } from "../ReactGAHandlers";

// introQuestions
// a. Are you a parent or student
// Integer: 1 == Student ; 2 == Parent
// b. What year will you/your child graduate high school
// c. How familiar are you with the college admissions process 
// user?.familiarityWithProcess == integer field capturing user's response to how much they already know about the college admissions process
// 1 == "Just starting to learn about it"
// 2 == "Somewhat familiar"
// 3 == "Very familiar"

const IntroFlow = ({ user, setUser, introFlow, setIntroFlow, introQuestions, setIntroQuestions, introFlowCheck }) => {

  let apiToken: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
  }

  const {
    handleGAEventIntroNextClick,
    handleGAEventIntroSkipClick
  } = DashboardReactGAHandlers()

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  useEffect(() => {
    const updateScreenSize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', updateScreenSize);
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  useEffect(() => {
    if (introFlowCheck === null) {
      sessionStorageStore('introFlow', 'true')
      // console.log('show introForm')
    }
  }, [])

  const backArrow = (<i className="fa-solid fa-arrow-left dca-intro-backArrow"></i>)

  // Integer: 1 == Student ; 2 == Parent
  let questionBRes = introQuestions?.a ?? undefined

  // a. Are you a parent or student
  const questionA = (
    <div className="d-flex flex-column align-items-center">
      <h4 className="dca-introflow-question-text" style={{ marginBottom: '32px' }}>Are you a parent or student?</h4>

      <RadioInput selectedState={introQuestions.a === 2} onClick={() => {
        setIntroQuestions(prevState => ({
          ...prevState, a: 2
        }));
      }} name="userType" id="qa_parent" text={`I'm a parent`} maxWidth="280px" />

      <RadioInput selectedState={introQuestions.a === 1} onClick={() => {
        setIntroQuestions(prevState => ({
          ...prevState, a: 1
        }));
      }} name="userType" id="qa_student" text={`I'm a student`} mt="18px" mobileMT="18px" maxWidth="280px" />

    </div>
  )

  const minDate = new Date(currentYear, 0)
  const maxDate = new Date(currentYear + 10, 0)
  // b. What year will you/your child graduate high school
  const questionB = (
    <div className="d-flex flex-column align-items-center">
      <div className="dca-intro-question-wrapper">
        {isMobile == false && <div
          className="dca-intro-back-link"
          onClick={() => {
            setIntroFlow(prevState => ({
              ...prevState, page: introFlow.page - 1
            }))
          }}>{backArrow}&nbsp;&nbsp;Back</div>}

        <h4 className="dca-introflow-question-text" style={{ margin: 0 }}>What year will {questionBRes && questionBRes === 1 ? `you` : `your child`} graduate high school?</h4>

        {isMobile == false && <div
          className="dca-intro-back-link"
          style={{ visibility: 'hidden' }}
        >{backArrow}&nbsp;&nbsp;Back</div>}
      </div>

      <Calendar
        value={introQuestions?.b ? new Date(introQuestions.b, 0) : null}
        className={`dca-Calendar ${introQuestions?.b ? 'radio-selected' : ''}`}
        id="dca-introFlow-Calendar"
        view="year"
        dateFormat="yy"
        style={{ height: '49px', maxHeight: '49px' }}
        onChange={(e) => {
          setIntroQuestions((prevState) => ({
            ...prevState,
            b: e.value ? new Date(e.value).getFullYear() : null,
          }));
        }}
        showButtonBar
        minDate={minDate}
        maxDate={maxDate}
      />

    </div>
  )

  // c. How familiar are you with the college admissions process
  const questionC = (
    <div className="d-flex flex-column align-items-center">
      <div className="dca-intro-question-wrapper">
        {isMobile == false && <div
          className="dca-intro-back-link"
          onClick={() => {
            setIntroFlow(prevState => ({
              ...prevState, page: introFlow.page - 1
            }))
          }}>{backArrow}&nbsp;&nbsp;Back</div>}
        <h4 className="dca-introflow-question-text" style={{ margin: 0 }}>How much do you already know about the college admissions process?</h4>
        {isMobile == false && <div
          className="dca-intro-back-link"
          style={{ visibility: 'hidden' }}
        >{backArrow}&nbsp;&nbsp;Back</div>}
      </div>

      <RadioInput selectedState={introQuestions.c === 1} onClick={() => {
        setIntroQuestions(prevState => ({
          ...prevState, c: 1
        }));
      }} name="familiarityWithProcess" id="familiar1" text={`Not much`} maxWidth="280px" />

      <RadioInput selectedState={introQuestions.c === 2} onClick={() => {
        setIntroQuestions(prevState => ({
          ...prevState, c: 2
        }));
      }} name="familiarityWithProcess" id="familiar2" text={`Somewhat familiar`} mt="18px" mobileMT="18px" maxWidth="280px" />

      <RadioInput selectedState={introQuestions.c === 3} onClick={() => {
        setIntroQuestions(prevState => ({
          ...prevState, c: 3
        }));
      }} name="familiarityWithProcess" id="familiar3" text={`Very familiar`} mt="18px" mobileMT="18px" maxWidth="280px" />

    </div>
  )

  const returnIntroFlowProgressBarValue = (page) => {
    switch (page) {
      case 1:
        return 33
      case 2:
        return 66
      case 3:
        return 100
    }
  }

  const progressBar = (
    <div style={{ width: '35%' }}>
      <div
        className="progress"
        style={{ height: "13px", borderRadius: '0px' }}>
        <div
          className="progress-bar"
          role="progressbar"
          style={{
            width: `${returnIntroFlowProgressBarValue(introFlow.page)}%`,
            backgroundColor: '#00E282'
          }}
          aria-valuenow={returnIntroFlowProgressBarValue(introFlow.page)}
          aria-valuemin={0}
          aria-valuemax={100}
        >
        </div>
      </div>
    </div>
  )

  const returnIntroFlowQuestion = () => {

    switch (introFlow.page) {
      case 1:
        return questionA
      case 2:
        return questionB
      case 3:
        return questionC
    }
  }

  const returnNext = (page) => {
    if (page === 1 && introQuestions?.a) {
      return true
    }

    else if (page === 2 && introQuestions?.b) {
      return true
    }

    else if (page === 3 && introQuestions?.c) {
      return true
    }

    return false
  }

  const mobileStepElement = <div className="d-flex flex-row justify-content-between" style={{ width: '100%' }}>
    {introFlow.page != 1 && <div
      className="dca-intro-back-link"
      onClick={() => {
        setIntroFlow(prevState => ({
          ...prevState, page: introFlow.page - 1
        }))
      }}>{backArrow}&nbsp;&nbsp;Back</div>}

    <span className='dca-dashboard-introflow-step-text' style={{ margin: introFlow.page == 1 ? '0 auto 15px auto' : '0 0 15px 0' }}>STEP {introFlow.page} OF 3</span>

    {introFlow.page != 1 && <div
      className="dca-intro-back-link"
      style={{ visibility: 'hidden' }}
    >{backArrow}&nbsp;&nbsp;Back</div>}
  </div>

  const desktopStepElement = <span className="dca-dashboard-introflow-step-text" style={{ margin: '0 30px 0 0' }}>STEP {introFlow.page} OF 3</span>

  return <div id="dca-dashboard-intro-flow-wrapper">

    <div
      className={`d-flex justify-content-center align-items-center align-self-center ${isMobile ? `flex-column` : `flex-row`}`}
      style={{ marginBottom: '35px', width: isMobile ? '100%' : '45%', maxWidth: isMobile ? '' : '385px' }}>

      {isMobile ? mobileStepElement : desktopStepElement}

      {progressBar}
    </div>

    {returnIntroFlowQuestion()}

    {returnNext(introFlow.page) ? <button
      className="dca-dashboard-wizard-button"
      style={{ margin: '48px auto 0px auto' }}
      onClick={() => {
        handleGAEventIntroNextClick(introFlow.page);

        if (user?.user_id) {
          handleUserProfilePatchIntroQuestions(introQuestions, user, setUser, apiToken)
        };

        if (introQuestions?.c == 3) {
          sessionStorageStore('overviewIntroduction', 'true');
          sessionStorageStore('overviewCollegeApp', 'true');
        }

        (introFlow.page == 1 || introFlow.page == 2) ? setIntroFlow(prevState => ({
          ...prevState, page: introFlow.page + 1
        })) : setIntroFlow(prevState => ({
          ...prevState, passed: true
        }));
      }}>Next</button> : <button
        className="dca-dashboard-wizard-button"
        style={{ margin: '48px auto 0px auto' }}
        disabled>Next</button>}

    <button
      className="dca-dashboard-wizard-button"
      style={{ background: '#fafafa', marginTop: '22px' }}
      onClick={() => {
        handleGAEventIntroSkipClick(introFlow.page);

        setIntroFlow(prevState => ({
          ...prevState, passed: true
        }))
      }}>Skip Questions</button>
  </div>
}

export { IntroFlow }