import { ContentBlock } from "../ContentBlock"
import CollegeInterestIcon from '../../../../custom_assets/dashboard/college_list.png'
import { currentYear, pageRoutes, locationOptions, URL } from "../../dictionary"
import { useNavigate } from "react-router"
import { useState, useEffect, useRef } from "react"
import { returnProgressTrackerText } from "../ProgressTrackerText/CollegeInterestList"
import wizardHome from '../../../../custom_assets/dashboard/wizardHome.png'
import axios from "axios"
import scattergram_icon from '../../../../custom_assets/dashboard/scattergram.png'
import { MultiSelect } from "../../MultiSelect"
import { RadioInput } from "../../RadioInputs"
import { SingleSelect } from "../../SingleSelect"
import { scrollToWizard } from "../../../pages/Dashboard"
import { DashboardReactGAHandlers } from "../../ReactGAHandlers"

const checkCheckCase = (filterVal) => {
  return filterVal ? true : false
}

const CollegeInterestList = ({ user, userCollegeInterest, permissionGroups, wizardActive, setWizardActive, advisors, status, expand, setUserprofileSelectedTab, statusLabelMaxWidth }) => {

  const {
    handleGAEventCTAClick,
    handleGAEventWizardSkipClick,
    handleGAEventCollegeWizardNextClick } = DashboardReactGAHandlers()

  let navigate = useNavigate()
  let blockClass = 'CollegeInterestList'
  let title = 'Colleges'

  const collegeInterestPositionRef = useRef<HTMLDivElement>(null);

  let body = `An essential (and exciting!) step in the college planning process is to identity the set of schools you intend to apply for and it's important to thoughtfully research and consider your options with the objective of building a balanced college list. We recommend starting to deliberately think about your prospective college list by the middle of high school and have a goal of finalizing it by the summer before senior year.`

  let gradYr = user?.hsGradYr ?? undefined

  // response = [array of choices], activeCriteria = element from response that directs the sub page view, params = { dictionary of search params }
  const [wizard, setWizard] = useState<any>({
    page: 1,
    response: undefined,
    activeCriteria: undefined,
    params: undefined
  })
  const [criteriaSelects, setCriteriaSelects] = useState<any>({
    publicPrivate: false,
    location: false,
    setting: false,
    size: false,
    testPolicy: false,
    rank: false
  })
  const [formResponses, setFormResponses] = useState<any>({
    publicPrivate: undefined,
    location: undefined,
    setting: undefined,
    size: undefined,
    testPolicy: undefined,
    rank: undefined
  })
  const [collegeSetOptions, setCollegeSetOptions] = useState<any>([])

  let criterias = [
    {
      name: 'PublicPrivate', onChange: 'publicPrivate', spanText: 'Public/Private', check: criteriaSelects?.publicPrivate
    },

    {
      name: 'Location', onChange: 'location', spanText: 'Location', check: criteriaSelects?.location
    },

    {
      name: 'Campus Setting', onChange: 'setting', spanText: 'Campus Setting', check: criteriaSelects?.setting
    },

    {
      name: 'Size', onChange: 'size', spanText: 'Size', check: criteriaSelects?.size
    },

    {
      name: 'TestPolicy', onChange: 'testPolicy', spanText: 'Test Policy', check: criteriaSelects?.testPolicy
    },

    {
      name: 'Rank', onChange: 'rank', spanText: 'Rank', check: criteriaSelects?.rank
    }
  ]

  let sortParams = `?show=25&sort=metaRank%2CAscending`
  const [collegeSearchURL, setCollegeSearchURL] = useState<any>(`${pageRoutes.collegeSearch}?${sortParams}`)

  useEffect(() => {
    setFormResponses({
      publicPrivate: undefined,
      location: undefined,
      setting: undefined,
      size: undefined,
      testPolicy: undefined,
      rank: undefined
    })
  }, [wizard.page])

  useEffect(() => {
    if (wizardActive.CollegeInterestList == false) {
      setWizard({
        page: 1,
        response: undefined,
        activeCriteria: undefined,
        params: undefined
      });

      setCriteriaSelects({
        publicPrivate: false,
        location: false,
        setting: false,
        size: false,
        testPolicy: false,
        rank: false
      });

      setFormResponses({
        publicPrivate: undefined,
        location: undefined,
        setting: undefined,
        size: undefined,
        testPolicy: undefined,
        rank: undefined
      })
    }

    else if (wizardActive?.CollegeInterestList && collegeInterestPositionRef.current) {
      scrollToWizard(collegeInterestPositionRef)
    }
  }, [wizardActive.CollegeInterestList])

  useEffect(() => {
    let res = ''

    if ([1, 2].includes(formResponses?.publicPrivate)) {
      res = res + `&type=${formResponses?.publicPrivate}`
    }

    if (formResponses.location) {
      let locationArr = formResponses.location.map(obj => obj.value.flat()).join(',')
      res = res + `&location=${locationArr}`
    }

    if (formResponses.setting && formResponses.setting != 'All') {
      res = res + `&setting=${formResponses.setting}`
    }

    if (formResponses.size && formResponses.size != 'All') {
      res = res + `&size=${formResponses.size}`
    }

    if (formResponses.testPolicy && formResponses.testPolicy != 'All') {
      res = res + `&testPolicy=${formResponses.testPolicy}`
    }

    if (formResponses?.rank?.value && formResponses?.rank?.value != 'All') {
      let collegeSet = formResponses?.rank?.value.flat()
      res = res + `&collegeSet=${collegeSet}`
    }

    setCollegeSearchURL(`${pageRoutes.collegeSearch}${sortParams}${res}`)
  }, [formResponses])

  useEffect(() => {
    const getCollegeSet = async () => {
      const collegeSetAxios = await axios.get(`${URL}/collegeset/`)

      let collegeSetRes: any = []
      const handleLabel = (collegeArr) => {
        let len = collegeArr?.length

        if (5 <= len && len <= 50 && len != 8) {
          return `Top ${len}`
        }

        else {
          return `Top 100`
        }
      }
      collegeSetAxios.data.forEach((res) => {
        if (![8, 15].includes(res?.colleges?.length)) {
          collegeSetRes.push({ value: res.colleges, label: handleLabel(res.colleges) })
        }
      });

      collegeSetRes.push({ value: 'All', label: 'Top 100' })

      setCollegeSetOptions(collegeSetRes)
    }

    getCollegeSet()
  }, [])

  if (gradYr) {
    let yearDif = gradYr - currentYear

    if (yearDif && yearDif > 4) {
      body = `An integral step in the college planning process is to identity the set of schools you intend to apply for and it's important to thoughtfully research and consider your options with the objective of building a balanced college list. No action required now as we recommend waiting until high school before diving into active college research.`
    }
  }

  const button = <button
    onClick={() => {

      handleGAEventCTAClick(title);

      userCollegeInterest?.length > 0 ? navigate(pageRoutes.collegeSearch) : setWizardActive(prevState => ({
        ...prevState, CollegeInterestList: true
      }))
    }}
    className="dca-dashboard-button" style={{ marginTop: status === 'inactive' ? '34px' : '' }}>Explore Colleges</button>


  const pca = [
    {
      navigate: pageRoutes.premiumSpecializedRankings,
      title: 'Specialized Rankings',
      description: 'Explore college rankings based on tangible success metrics'
    },
    {
      navigate: pageRoutes.premiumRDSpend,
      title: 'R&D Spend Insights',
      description: 'Explore how the top colleges compare in strength of research by academic field'
    },
    {
      navigate: pageRoutes.premiumCrimeSafety,
      title: 'Crime & Safety Insights',
      description: 'Easily incorporate comprehehensive safety data into the college research process'
    }
  ]

  const resources = [
    {
      navigate: pageRoutes.collegeDataVisualization,
      title: 'College Data Scattergrams',
      description: `Visualize benchmark values for key college admissions criteria`,
      icon: scattergram_icon
    },
    {
      navigate: pageRoutes.advisorNetwork,
      title: 'Advisors Network',
      description: `Get the real talk on academics and student life at the colleges you're considering`
    },
    {
      navigate: pageRoutes.personalizedExpertHelp,
      title: 'Expert Q&A',
      description: 'Get personalized help developing a balanced college list that best fits your academic and personal interests'
    }
  ]

  const insights = [
    {
      navigate: pageRoutes.overviewAdmissionsAdviceWithHash('creating-a-college-interest-list'),
      text: 'How To Create A Balanced College Interest List'
    },
    {
      external: true,
      navigate: `https://blog.nextfour.ai/deep-dive-on-data-to-build-a-balanced-college-list/`,
      text: 'Deep-Dive On Data To Build A Balanced College List'
    }
  ]

  const returnWizardQuestion = () => {
    switch (true) {
      case wizard.page == 1:
        return `What are your most important criteria for considering colleges?`
      case wizard.page == 2 && wizard.activeCriteria == 'publicPrivate':
        return `What institution type do you prefer?`
      case wizard.page == 2 && wizard.activeCriteria == 'location':
        return `Which state(s) do you prefer for college location?`
      case wizard.page == 2 && wizard.activeCriteria == 'setting':
        return `What is your preference for campus setting?`
      case wizard.page == 2 && wizard.activeCriteria == 'size':
        return `What is your preference for college size (number of undergrad students)?`
      case wizard.page == 2 && wizard.activeCriteria == 'testPolicy':
        return `What is your preference for policy on standardized testing?`
      case wizard.page == 2 && wizard.activeCriteria == 'rank':
        return `What is your target college ranking tier?`
    }
  }

  const handleNextActiveCriteria = () => {
    let len = wizard?.response.length
    let currentPage = wizard?.response.indexOf(wizard?.activeCriteria)

    if (currentPage < len - 1) {
      setWizard(prevState => ({
        ...prevState, activeCriteria: wizard?.response[currentPage + 1]
      }))
    }

    else {
      navigate(collegeSearchURL)
    }
  }

  const handleBackActiveCriteria = () => {
    let currentPage = wizard?.response.indexOf(wizard?.activeCriteria)

    if (currentPage > 0) {
      setWizard(prevState => ({
        ...prevState, activeCriteria: wizard?.response[currentPage - 1]
      }))
    }

    else {
      setWizard(prevState => ({
        ...prevState, page: wizard?.page - 1
      }))
    }
  }

  const handleLocationSelectChange = (selectedLocations) => {
    setFormResponses(prevState => ({
      ...prevState, location: selectedLocations
    }))
  }

  const handleRankChange = (selectedRank) => {
    setFormResponses(prevState => ({
      ...prevState, rank: selectedRank
    }))
  }

  let publicPrivateArr = [{
    text: 'Public',
    value: 1
  }, {
    text: 'Private',
    value: 2
  }, {
    text: 'All',
    value: 0
  }]
  const publicPrivateForm = (
    <div>
      {publicPrivateArr.map((res, index) => (
        <RadioInput
          selectedState={formResponses.publicPrivate === res.value}
          onClick={() => {
            setFormResponses(prevState => ({
              ...prevState, publicPrivate: res.value
            }))
          }}
          text={res.text}
          name={"publicPrivate"}
          id={`form_publicPrivate_${res.text}`}
          mt={index != 0 ? '10px' : ''}
          mobileMT={index != 0 ? '18px' : ''} />
      ))}
    </div>
  )

  const locationForm = (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px', zIndex: 4 }}>
      <div className='dca-aside-header dca-dashboard-select-label'>LOCATIONS</div>
      <MultiSelect options={locationOptions} value={formResponses.location} onChange={handleLocationSelectChange} />
    </div>
  )

  let campusArr = [{
    text: 'City',
    value: 'City'
  }, {
    text: 'Suburb',
    value: 'Suburb'
  }, {
    text: 'Town',
    value: 'Town'
  }]
  const campusSettingForm = (
    <div>

      {campusArr.map((res, index) => (
        <RadioInput selectedState={formResponses.setting === res.value} onClick={() => {
          setFormResponses(prevState => ({
            ...prevState, setting: res.value
          }));
        }} text={res.text} name={"setting"} id={`form_setting_${res.text}`} mt={index != 0 ? '10px' : ''} mobileMT={index != 0 ? '18px' : ''} />
      ))}

      <RadioInput selectedState={formResponses.setting === 'All'} onClick={() => {
        setFormResponses(prevState => ({
          ...prevState, setting: 'All'
        }));
      }} text={'All'} name={"setting"} id={`form_setting_all`} mt={'10px'} mobileMT={'18px'} />

    </div>
  )

  let sizeArr = [{
    class: 'small',
    text: 'Small (Up to 5K)',
    value: 'Small'
  }, {
    class: 'medium',
    text: 'Medium (5K - 15K)',
    value: 'Medium'
  }, {
    class: 'large',
    text: 'Large (15K - 30K)',
    value: 'Large'
  }, {
    class: 'veryLarge',
    text: 'Very Large (Over 30K)',
    value: 'Very+Large'
  }]
  const sizeForm = (
    <div>
      {sizeArr.map((res, index) => (
        <RadioInput selectedState={formResponses.size === res.value} onClick={() => {
          setFormResponses(prevState => ({
            ...prevState, size: res.value
          }));
        }} text={res.text} name={"size"} id={`form_size_${res.class}`} mt={index != 0 ? '10px' : ''} mobileMT={index != 0 ? '18px' : ''} />
      ))}

      <RadioInput selectedState={formResponses.size === 'All'} onClick={() => {
        setFormResponses(prevState => ({
          ...prevState, size: 'All'
        }));
      }} text={'All'} name={"size"} id={`form_size_all`} mt={'10px'} mobileMT={'18px'} />
    </div>
  )

  let testPolicyArr = [{
    text: 'Required',
    value: 'Required',
  }, {
    text: 'Optional',
    value: 'Optional',
  },
  {
    text: 'Blind',
    value: 'Blind',
  }]
  const testPolicyForm = (
    <div>

      {testPolicyArr.map((res, index) => (
        <RadioInput selectedState={formResponses.testPolicy === res.value} onClick={() => {
          setFormResponses(prevState => ({
            ...prevState, testPolicy: res.value
          }));
        }} text={res.text} name={"testPolicy"} id={`form_testPolicy_${res.text}`} mt={index != 0 ? '10px' : ''} mobileMT={index != 0 ? '18px' : ''} />
      ))}

      <RadioInput selectedState={formResponses.testPolicy === 'All'} onClick={() => {
        setFormResponses(prevState => ({
          ...prevState, testPolicy: 'All'
        }));
      }} text={'All'} name={"testPolicy"} id={`form_testPolicy_all`} mt={'10px'} mobileMT={'18px'} />

    </div>
  )

  const rankForm = (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px', zIndex: 4, width: '100%' }}>
      <div className="dca-aside-header dca-dashboard-select-label">RANK</div>
      <SingleSelect options={collegeSetOptions} value={formResponses.rank} onChange={handleRankChange} />
    </div>
  )

  const checkPage2Disabled = (activeCriteria) => {
    switch (activeCriteria) {
      case 'publicPrivate':
        if (formResponses?.publicPrivate === undefined) {
          return true
        }
        return false
      case 'location':
        if (formResponses?.location === undefined || formResponses?.location.length === 0) {
          return true
        }
        return false
      case 'setting':
        if (formResponses?.setting === undefined) {
          return true
        }
        return false
      case 'size':
        if (formResponses?.size === undefined) {
          return true
        }
        return false
      case 'testPolicy':
        if (formResponses?.testPolicy === undefined) {
          return true
        }
        return false
      case 'rank':
        if (formResponses?.rank === undefined || !formResponses?.rank?.value) {
          return true
        }
        return false
    }
  }

  const returnWizardResponseForm = () => {

    let page1 = <div className='dca-dashboard-wizard-form-wrapper'>
      <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
        <div className="checkbox-list d-flex flex-column" style={{ marginTop: '5px' }}>

          {criterias?.map((res, index) => (
            <RadioInput box={true} selectedState={checkCheckCase(res.check)} onClick={() => {
              setCriteriaSelects(prevState => ({
                ...prevState,
                [res.onChange]: !prevState[res.onChange],
              }))
            }} name={res.name} id={res.name} text={res.spanText} mt={index != 0 ? '10px' : ''} mobileMT={index != 0 ? '18px' : ''} />
          ))}

        </div>
      </div>

      <button
        onClick={() => {
          handleGAEventCollegeWizardNextClick('Criterias');
          setWizard(prevState => ({
            ...prevState, page: wizard.page + 1, response: Object.keys(criteriaSelects).filter(key => criteriaSelects[key] == true),
            activeCriteria: Object.keys(criteriaSelects).filter(key => criteriaSelects[key] == true)[0]
          }))
        }}
        className="dca-dashboard-wizard-button"
        disabled={Object.values(criteriaSelects).filter(val => val == true).length > 1 ? false : true}>Next</button>
    </div>

    const returnSelectedCriteriaForm = () => {
      switch (wizard.activeCriteria) {
        case 'publicPrivate':
          return publicPrivateForm
        case 'location':
          return locationForm
        case 'setting':
          return campusSettingForm
        case 'size':
          return sizeForm
        case 'testPolicy':
          return testPolicyForm
        case 'rank':
          return rankForm
      }
    }

    const returnWidthChange = () => {
      if (wizard.activeCriteria == 'location' || wizard.activeCriteria == 'rank') {
        return true
      }

      return false
    }

    let page2 = <div className='dca-dashboard-wizard-form-wrapper'
      style={{ width: returnWidthChange() ? '100%' : '' }}
    >
      {returnSelectedCriteriaForm()}

      <button
        onClick={() => {
          handleGAEventCollegeWizardNextClick(wizard?.activeCriteria);
          handleNextActiveCriteria()
        }}
        disabled={checkPage2Disabled(wizard?.activeCriteria)}
        className="dca-dashboard-wizard-button"
      >Next</button>

    </div>

    switch (wizard.page) {
      case 1:
        return page1
      case 2:
        return page2
    }
  }

  const sharedWizardContent = <div className='dca-dashboard-wizard-header'>
    <div
      className="dca-intro-back-link"
      onClick={() => wizard.page > 1 ? handleBackActiveCriteria() : setWizardActive(prevState => ({
        ...prevState, [blockClass]: false
      }))}
    ><i className="fa-solid fa-arrow-left dca-intro-backArrow"></i>&nbsp;&nbsp;Back</div>

    <img className='dca-dashboard-wizard-home-icon' src={wizardHome} alt='home_icon' onClick={() => {
      setWizardActive(prevState => ({
        ...prevState,
        [blockClass]: false,
      }));
    }}></img>

    <div
      className="dca-intro-back-link"
      style={{ visibility: 'hidden' }}
    ><i className="fa-solid fa-arrow-left dca-intro-backArrow"></i>&nbsp;&nbsp;Back</div>
  </div>

  const wizardContent = (<div ref={collegeInterestPositionRef} className="d-flex flex-column justify-content-center">

    {sharedWizardContent}

    <h4 id="dca-introflow-question-text-wizard-margin" className="dca-introflow-question-text">{returnWizardQuestion()}</h4>
    {wizard.page == 1 && <span className="dca-dashboard-wizard-select-multiple-note">Please select 2 or more options</span>}

    {returnWizardResponseForm()}

    <span
      className="dca-dashboard-wizard-skip-wizard-link"
      onClick={() => {
        handleGAEventWizardSkipClick(title);

        if (wizard.page === 2 && collegeSearchURL != `${pageRoutes.collegeSearch}?${sortParams}`) {
          navigate(collegeSearchURL)
        } else {
          navigate(pageRoutes.collegeSearch)
        }
      }}>Go straight to Colleges Explorer</span>
  </div>
  );

  const handleProgressTrackerLink = () => {
    setUserprofileSelectedTab('Colleges of Interest');
    navigate(pageRoutes.userProfileWithId(user?.user_id))
  }

  const progressTracker = {
    color: status,
    text: returnProgressTrackerText(user, userCollegeInterest, permissionGroups, advisors, gradYr),
    count: userCollegeInterest?.length ?? 0,
    subject: userCollegeInterest?.length === 1 ? `College` : `Colleges`,
    link: userCollegeInterest?.length > 0 ? handleProgressTrackerLink : undefined
  }

  const block = {
    class: blockClass,
    title: title,
    body: body,
    icon: CollegeInterestIcon,
    specialIconClass: 'dca-dashboard-collegeInterestIcon',
    button: button,
    resources: resources,
    insights: insights,
    wizardContent: wizardContent,
    status: status,
    expand: expand,
    progressTracker: status != 'inactive' ? progressTracker : undefined,
    pca: pca
  }

  return <ContentBlock block={block} wizardActive={wizardActive} setWizardActive={setWizardActive} statusLabelMaxWidth={statusLabelMaxWidth} />
}

export { CollegeInterestList }