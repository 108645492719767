import { currentYear, specializedRankingsAccessCode, rdSpendAccessCode, safetyAccessCode } from "../../dictionary"
// sentence_savedColleges

export const sentence1_colleges0 = `Read the Insights referenced below for additional context and then proceed to explore colleges and begin building your interest list.`

export const sentence1_colleges1_4 = `Good start, but please consider additional colleges so that you have a healthy mix of reach, target, and safety schools.`

export const sentence1_colleges5 = `Nice job with building out your college list.  Please continue to update and finalize your list by the fall of senior year.`

export const sentence2_colleges5a = `Colleges should be categorized as Reach, Target or Safety to ensure the list is balanced; follow the link below to review categories for your selected colleges.`

export const sentence2_colleges5b = `We advise to add more Target and/or Safety schools to help ensure a balanced college interest list.`

export const sentence3_colleges1 = `Review the resources below to access exclusive data and rankings that may help identify the best colleges for your specific interests. `

export const sentence4_colleges1 = `You should also consider engaging with Advisors who currently attend colleges you're considering to learn from their first-hand experience of getting admitted and being on campus.`

export const returnProgressTrackerText = (user, userCollegeInterest, permissionGroups, advisors, gradYr) => {
  let capValue = 4
  let len = userCollegeInterest?.length ?? undefined
  let yearDif = gradYr ? gradYr - currentYear : undefined
  let concat: any = []

  // SENTENCE 1
  if (len === 0) {
    return sentence1_colleges0
  }

  else if (1 <= len && len <= 4) {
    concat.push(sentence1_colleges1_4)
  }

  else if (len >= 5) {
    concat.push(sentence1_colleges5)
  }

  // SENTENCE 2
  if (len >= 5 && userCollegeInterest?.length > 0) {
    let safetyCount = userCollegeInterest?.filter(res => res.fit === 4).length ?? 0;
    let targetCount = userCollegeInterest?.filter(res => res.fit === 3).length ?? 0;
    const anyUserCollegeNotCategorized = userCollegeInterest?.some((college) => college.fit == 1) ?? undefined

    // If 1+ college hasn't been categorized as {reach/safety/target}
    if (anyUserCollegeNotCategorized == true) {
      concat.push(sentence2_colleges5a)
    }

    // If all colleges have been categorized as {reach/safety/target} and (number of safety schools < 2 or number of target schools is <2)
    if (anyUserCollegeNotCategorized == false && (safetyCount < 2 || targetCount < 2)) {
      concat.push(sentence2_colleges5b)
    }
  }

  // SENTENCE 3
  // if user has not already purchased all of the premium college reports (i.e. Specialized Rankings, R&D Spend, Crime & Safety, etc.)
  if (len >= 1 && [specializedRankingsAccessCode, rdSpendAccessCode, safetyAccessCode].some(code => !permissionGroups.includes(code))) {
    concat.push(sentence3_colleges1)
  }

  // SENTENCE 4
  // 1 or more saved college intersects with 1 or more Advisor profile AND yearDif < 2
  if (advisors.some((advisor) => userCollegeInterest?.some((college) => college.college_id == advisor.college_id)) && yearDif != undefined && yearDif < 2) {
    concat.push(sentence4_colleges1)
  }

  // if multiple sentences, join with single space
  if (concat?.length > 0) {
    concat = concat.join(" ")
    return concat
  }

  return sentence1_colleges0

}