import { Introduction } from "../components/Dashboard/Blocks/Introduction"
import { IntendedMajor } from "../components/Dashboard/Blocks/IntendedMajor"
import { Extracurriculars } from "../components/Dashboard/Blocks/Extracurriculars"
import { CollegeInterestList } from "../components/Dashboard/Blocks/CollegeInterestList"
import { StandardizedTesting } from "../components/Dashboard/Blocks/StandardizedTesting"
import { CollegeApplications } from "../components/Dashboard/Blocks/CollegeApplications"
import { useEffect, useState, useRef } from "react"
import { IntroFlow } from "../components/Dashboard/IntroFlow"
import { currentYear, sessionStorageGet, URL, handleLocalStorageData, pageRoutes, sessionStorageStore, updateTitleAndDescription } from "../components/dictionary"
import { getAuth } from "../modules/auth"
import axios from "axios"
import { useNavigate } from "react-router"
import tipSymbol from '../../custom_assets/dashboard/tip.png'

export const scrollToWizard = (wizardPositionRef) => {
  const element = wizardPositionRef?.current;
  const yOffset = element?.getBoundingClientRect().top + window.scrollY - 150;
  window.scrollTo({ top: yOffset, behavior: "smooth" });
}

const Dashboard = ({ user, setUser, userCollegeInterest, setUserCollegeInterest, setPrompt, setNavigateTo, introQuestions, setIntroQuestions, setUserprofileSelectedTab, collegesId, setOnlyInterestsSelect, overrideTestingWizard, setOverrideTestingWizard }) => {

  let apiToken: any = undefined
  let permissionGroups: any = undefined
  let navigate = useNavigate()

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
    permissionGroups = getAuth()?.groups
  }
  // initialize variables
  const [wizardActive, setWizardActive] = useState<any>({
    IntendedMajor: false,
    Extracurriculars: false,
    CollegeInterestList: false,
    StandardizedTesting: false
  })
  const [statusController, setStatusController] = useState<any>({
    Introduction: undefined,
    IntendedMajor: undefined,
    Extracurriculars: undefined,
    CollegeInterestList: undefined,
    StandardizedTesting: undefined,
    CollegeApplications: undefined
  })
  const [statusLabelMaxWidth, setStatusLabelMaxWidth] = useState(0);

  let allStatusAreSet = Object.values(statusController).every((value) => value !== undefined);
  const [firstExpand, setFirstExpand] = useState<any>(undefined)
  const [collegeData, setCollegeData] = useState<any>([])
  const [programData, setProgramData] = useState<any>([])
  const [majorData, setMajorData] = useState<any>([])
  const [ecData, setECData] = useState<any>([])
  const [advisors, setAdvisors] = useState<any>([])

  const userPassesIntroFlow = () => {
    let studentParent = (user?.userType || introQuestions?.a) ?? undefined
    let hsGradYr = (user?.hsGradYr || introQuestions?.b) ?? undefined
    let familiarityWithProcess = (user?.familiarityWithProcess || introQuestions?.c) ?? undefined

    if (studentParent != undefined && hsGradYr != undefined && familiarityWithProcess != undefined) {
      sessionStorageStore('introFlow', 'true');
      return true
    }

    return false
  }
  let introFlowCheck = sessionStorageGet('introFlow')
  const [introFlow, setIntroFlow] = useState<any>({
    page: 1,
    passed: (introFlowCheck != null || userPassesIntroFlow()) ? true : false
  })

  const initializeGradYr = () => {
    if (user?.hsGradYr != null) {
      return user?.hsGradYr
    }

    else if (introQuestions.b != null) {
      return introQuestions.b
    }

    return undefined
  }

  useEffect(() => {
    const getAdvisors = async () => {
      let res = await axios.get(`${URL}/advisor_profiles/`);
      res = res.data.filter((item) => item.displayState == 1)
      setAdvisors(res)
    }

    const getMajorData = async () => {
      const getMajorsData = async () => {
        let res = await axios.get(`${URL}/majors/`);
        return res.data
      }
      let majorCache: any = await handleLocalStorageData('dantech-majors', getMajorsData)

      setMajorData(majorCache)
    }

    const getProgramsData = async () => {
      const getProgramData = async () => {
        let programRes = await axios.get(`${URL}/program_data/`)
        return programRes.data
      }

      let programCache: any = await handleLocalStorageData('dantech-programData', getProgramData)
      setProgramData(programCache)
    }

    const getECData = async () => {
      const getECValid = async () => {
        let res = await axios.get(`${URL}/ec_activity_valid/`)
        return res.data
      }

      let ecCache: any = await handleLocalStorageData('dantech-ec', getECValid)

      setECData(ecCache)
    }

    updateTitleAndDescription(pageRoutes.dashboard)

    getAdvisors();
    getMajorData();
    getProgramsData();
    getECData();

  }, [])

  useEffect(() => {

    const getCollegesData = async () => {
      let res = await axios.get(`${URL}/colleges/${0}-to-${collegesId.length}?sortKey=metaRank`)
      return res.data
    }

    const getColleges = async () => {

      let collegeCache: any = await handleLocalStorageData('dantech-colleges', getCollegesData)

      setCollegeData(collegeCache)
    }

    getColleges();
  }, [collegesId])

  useEffect(() => {

    setFirstExpand(undefined);

    if (!user?.user_id) {
      setStatusController({
        Introduction: undefined,
        IntendedMajor: undefined,
        Extracurriculars: undefined,
        CollegeInterestList: undefined,
        StandardizedTesting: undefined,
        CollegeApplications: undefined
      });

      setOverrideTestingWizard(false);

      setWizardActive({
        IntendedMajor: false,
        Extracurriculars: false,
        CollegeInterestList: false,
        StandardizedTesting: false
      })
    }

    // #972 handle close on wizard if user just needs to enter test scores, already has colleges saved
    // if (user?.user_id) {
    //   let testScores = false
    //   let len = userCollegeInterest?.length ?? undefined
    //   let sat = (user?.satMath != null && user?.satVerbal != null);
    //   let act = !!user?.actComposite;

    //   if (sat || act) {
    //     testScores = true
    //   }

    //   if (len >= 5 && testScores && wizardActive?.StandardizedTesting == true) {
    //     setWizardActive(prevState => ({
    //       ...prevState, StandardizedTesting: false
    //     }))
    //   }
    // }

    const handleOverviewStatus = () => {
      return new Promise<void>((resolve) => {
        const setFirstStatus = () => {
          let overviewIntroductionKey = sessionStorageGet('overviewIntroduction')
          let overviewCollegeAppKey = sessionStorageGet('overviewCollegeApp')

          if ((user?.overviewIntroductionVisit == true && user?.overviewCollegeAppVisit == true) || (overviewIntroductionKey != null && overviewCollegeAppKey != null)) {
            return 3
          }

          else if (user?.familiarityWithProcess != null || user?.familiarityWithProcess != undefined) {
            return user?.familiarityWithProcess
          }

          else if (introQuestions.c != undefined) {
            return introQuestions.c
          }

          return 'red'
        }

        let status: any = setFirstStatus()

        if (status != 'red') {
          // 1 == "Just starting to learn about it"
          // 2 == "Somewhat familiar"
          // 3 == "Very familiar"
          switch (status) {
            case 3:
              status = 'green'
              break;
            case 2:
              status = 'yellow'
              break;
            case 1:
              status = 'red'
              break
          }
        }

        setStatusController(prevState => ({
          ...prevState, Introduction: status
        }))

        resolve();
      });
    };

    const handleIntendedMajorStatus = () => {
      return new Promise<void>((resolve) => {
        let gradYr = initializeGradYr()
        let userMajors = user?.majorsInterest ?? undefined

        const returnStatus = () => {
          if (gradYr) {
            let yearDif = gradYr - currentYear

            if (yearDif && yearDif > 6) {
              return 'inactive'
            }
          }

          if (userMajors) {
            let len = userMajors.length
            switch (true) {
              case len == 0:
                return 'red';
              case len == 1:
                return 'yellow'
              case len >= 2:
                // Initializing an empty array to hold the colleges in the userCollegeInterest array that have some programData
                let userCollegesWithProgramData: any = []
                if (userCollegeInterest?.length > 0 && programData?.length > 0) {
                  userCollegeInterest.map((college) => {
                    let found = programData.find((program) => parseInt(program.college_id) === parseInt(college.college_id))
                    if (found) {
                      userCollegesWithProgramData.push(college)
                    }
                  })
                }

                // If there are items in the userCollegesWithProgramData, check to see if every college object has a program in programData where the college_id matches and some major id in userMajors is the program's major_id
                const collegesWithProgramDataFoundMatchingMajor = () => {
                  if (userCollegesWithProgramData.length > 0 && userMajors?.length > 0) {
                    // Returns true if for every user saved college with program data, there is a program that has a college_id matching the college_id and the program's major_id matches some major id in the userMajors array
                    let check = userCollegesWithProgramData?.every(college => userMajors.some(majorID => programData?.find(program => parseInt(program.major_id) === majorID && parseInt(program.college_id) === parseInt(college.college_id))))

                    // If it is true, we want to skip the concat push block, so we set to false. Otherwise, if false, that is our trigger to add the sentence2_majors2
                    return !check
                  }

                  // By default, skip the concat push since the user may not have saved colleges or majors
                  return false
                }

                if (collegesWithProgramDataFoundMatchingMajor()) {
                  return 'yellow'
                }

                return 'green'
            }
          }

          return 'red'
        }

        setStatusController(prevState => ({
          ...prevState, IntendedMajor: returnStatus()
        }))

        resolve();
      });
    };

    const handleExtracurricularsStatus = () => {
      return new Promise<void>((resolve) => {
        let userECs = user?.activitiesInterest ?? undefined

        const returnStatus = () => {
          if (userECs) {
            let len = userECs.length
            switch (true) {
              case len == 0:
                return 'red'
              case [1, 2, 3].includes(len):
                return 'yellow'
              case len >= 4:
                // impact = best === parseInt(impactIndex) == 3
                let anyImpactBest = userECs?.some((userEC) => ecData.find((res) => userEC === res.id && parseInt(res?.impactIndex) == 3)) ?? undefined

                // activityType = Independent === activityType == 8
                let noneIndependent = userECs?.every((userEC) => ecData.find((res) => userEC === res.id && res?.activityType != 8)) ?? undefined

                if (userECs?.length > 0 && anyImpactBest == false) {
                  return 'yellow'
                }

                else if (userECs?.length > 0 && anyImpactBest == true && noneIndependent == true) {
                  // concat.push(sentence3_ec4b)
                  return 'yellow'
                }

                return 'green'
              default:
                return 'red'
            }
          }

          return 'red'
        }

        setStatusController(prevState => ({
          ...prevState, Extracurriculars: returnStatus()
        }))

        resolve();
      });
    }

    const handleCollegeInterestListStatus = () => {
      return new Promise<void>((resolve) => {
        let gradYr = initializeGradYr()

        const returnStatus = () => {
          if (gradYr) {
            let yearDif = gradYr - currentYear

            if (yearDif && yearDif > 4) {
              return 'inactive'
            }
          }

          if (userCollegeInterest) {
            let len = userCollegeInterest.length
            switch (true) {
              case len == 0:
                return 'red'
              case [1, 2, 3, 4].includes(len):
                return 'yellow'
              case len >= 5:
                let safetyCount = userCollegeInterest?.filter(res => res.fit === 4).length ?? 0;
                let targetCount = userCollegeInterest?.filter(res => res.fit === 3).length ?? 0;
                const anyUserCollegeNotCategorized = userCollegeInterest?.some((college) => college.fit == 1) ?? undefined

                // If 1+ college hasn't been categorized as {reach/safety/target}
                if (anyUserCollegeNotCategorized == true) {
                  return 'yellow'
                }

                // If all colleges have been categorized as {reach/safety/target} and (number of safety schools < 2 or number of target schools is <2)
                if (anyUserCollegeNotCategorized == false && (safetyCount < 2 || targetCount < 2)) {
                  // concat.push(sentence2_colleges5b)
                  return 'yellow'
                }
                return 'green'
            }
          }

          return 'red'

        }

        setStatusController(prevState => ({
          ...prevState, CollegeInterestList: returnStatus()
        }))

        resolve();
      });
    }

    const handleStandardizedTestingStatus = () => {
      return new Promise<void>((resolve) => {
        let gradYr = initializeGradYr()
        let testScores = false

        const returnStatus = () => {
          if (gradYr) {
            let yearDif = gradYr - currentYear

            if (yearDif > 3) {
              return 'inactive'
            }
          }

          if (user) {
            let sat = (user?.satMath != null && user?.satVerbal != null);
            let act = !!user?.actComposite;

            if (sat || act) {
              testScores = true
            }

            if (testScores == true && userCollegeInterest?.length > 0) {
              const userCollegeIds = new Set(userCollegeInterest?.map((college) => college.college_id));
              let userCollegesThatRequireTests = collegeData?.filter(
                (res) => userCollegeIds?.has(res.id) && res.testPolicy == 1
              );
              let userCollegesOptionalTests = collegeData?.filter(
                (res) => userCollegeIds?.has(res.id) && res.testPolicy == 2
              );
              // SENTENCE 2
              const checkTestScoresAgainstMedians = (userCollegesThatRequireTests) => {

                const returnUserSATScoreOutputLowerThan50 = (userSATMath, userSATVerbal, res: any) => {
                  const returnOptionBSATComposite25 = (cds) => {
                    if (cds.satEvidence25 && cds.satMath25) {
                      return cds.satEvidence25 + cds.satMath25
                    }

                    else {
                      return null
                    }
                  }

                  const returnOptionBSATComposite75 = (cds) => {
                    if (cds.satEvidence75 + cds.satMath75) {
                      return cds.satEvidence75 + cds.satMath75
                    }

                    else {
                      return null
                    }
                  }

                  const returnOptionBSATComposite50 = (cds) => {

                    if (cds.satEvidence50 && cds.satMath50) {
                      return cds.satEvidence50 + cds.satMath50
                    }

                    else if (cds.satEvidence25 && cds.satEvidence75 && cds.satMath25 && cds.satMath75) {
                      return ((cds.satEvidence25 + cds.satEvidence75) / 2) + ((cds.satMath25 + cds.satMath75) / 2)
                    }

                    else {
                      return null
                    }
                  }

                  if (res && res.college_cds) {

                    const cds = res.college_cds[0]

                    const caseA = (cds.satComposite25 == null || cds.satComposite25 == undefined) || (cds.satComposite75 == null || cds.satComposite75 == undefined)

                    const caseB = [cds.satEvidence25, cds.satEvidence75, cds.satMath25, cds.satMath75].some((sat) => sat == null || sat == undefined)

                    if (caseA && caseB) {
                      return false
                    }

                    else if ((userSATMath == null || userSATMath == undefined) || (userSATVerbal == null || userSATVerbal == undefined)) {
                      return false
                    }

                    else {

                      let satSuperScore = userSATMath + userSATVerbal
                      let satComposite50: any = cds.satComposite50 ? cds.satComposite50 : null
                      let satComposite25: any = cds.satComposite25 ? cds.satComposite25 : null
                      let satComposite75: any = cds.satComposite75 ? cds.satComposite75 : null

                      let above25: boolean = false
                      let above50: boolean = false
                      let above75: boolean = false

                      if (cds.satComposite25 !== null && cds.satComposite75 !== null) {
                        satComposite25 = cds.satComposite25;
                        satComposite75 = cds.satComposite75;
                        cds.satComposite50 ? satComposite50 = cds.satComposite50 : satComposite50 = ((satComposite25 + satComposite75) / 2);
                      }

                      else if (cds.satComposite25 === null && cds.satComposite75 === null) {
                        satComposite25 = returnOptionBSATComposite25(cds);
                        satComposite50 = returnOptionBSATComposite50(cds);
                        satComposite75 = returnOptionBSATComposite75(cds);
                      }

                      if (satSuperScore >= satComposite25) {
                        above25 = true
                      }

                      if (satSuperScore >= satComposite75) {
                        above75 = true
                      }

                      if (satSuperScore >= satComposite50) {
                        above50 = true
                      }

                      switch (true) {
                        case above25 == false:
                          return ((((satSuperScore / satComposite25)) * 25) < 50) ? true : false
                        case above25 == true && above50 == false:
                          return ((((satSuperScore - satComposite25) / (satComposite50 - satComposite25)) * 25) + 25) < 50 ? true : false
                        case above50 == true && above75 == false:
                          return ((((satSuperScore - satComposite50) / (satComposite75 - satComposite50)) * 25) + 50) < 50 ? true : false
                        default:
                          return ((((satSuperScore - satComposite75) / (1600 - satComposite75)) * 25) + 75) < 50 ? true : false
                      }
                    }
                  }

                  else {
                    return false
                  }

                }

                const returnUserACTScoreOutputLowerThan50 = (userACTBestScore, res: any) => {

                  let actReference50: any = 0
                  let above25: any = false
                  let above50: any = false
                  let above75: any = false

                  if (res && res.college_cds) {

                    const cds = res.college_cds[0]

                    // If either actComposite25 or actComposite75 are null, set ACT score output to -2
                    if ((cds.actComposite25 == null || cds.actComposite25 == undefined) || (cds.actComposite75 == null || cds.actComposite75 == undefined)) {
                      return false
                    }

                    else if (userACTBestScore == null || userACTBestScore == undefined) {
                      return false
                    }

                    // Set act reference 50. If it exists set it to actComposite50 otherwise set it to the average of actComposite25 and actComposite75
                    actReference50 = cds.actComposite50 ? cds.actComposite50 : ((cds.actComposite25 + cds.actComposite75) / 2)

                    const setBooleans = () => {
                      if (userACTBestScore >= cds.actComposite25) {
                        above25 = true
                      }

                      if (userACTBestScore >= actReference50) {
                        above50 = true
                      }

                      if (userACTBestScore >= cds.actComposite75) {
                        above75 = true
                      }
                    }
                    setBooleans();


                    if (above25 === false) {
                      return ((((userACTBestScore / cds.actComposite25)) * 25)) < 50 ? true : false
                    }

                    else if (above25 === true && above50 === false) {
                      return ((((userACTBestScore - cds.actComposite25) / (actReference50 - cds.actComposite25)) * 25) + 25) < 50 ? true : false
                    }

                    else if (above50 === true && above75 === false) {

                      if ((cds.actComposite25 == actReference50) && (userACTBestScore == cds.actComposite25)) {
                        return ((50 + 25) / 2) < 50 ? true : false
                      }

                      return ((((userACTBestScore - actReference50) / (cds.actComposite75 - actReference50)) * 25) + 50) < 50 ? true : false
                    }

                    else {
                      if (userACTBestScore == 36 && cds.actComposite75 == 36) {
                        return false
                      }

                      if ((cds.actComposite75 == actReference50) && (userACTBestScore == cds.actComposite75)) {
                        return false
                      }

                      return ((((userACTBestScore - cds.actComposite75) / (36 - cds.actComposite75)) * 25) + 75) < 50 ? true : false
                    }

                  }

                  else {
                    // fallback, if college cds is not available
                    return false
                  }


                }

                let belowArr: any = []
                let satTaken = (user?.satMath && user?.satVerbal);
                let actTaken = !!user?.actComposite;
                let test: any = []

                if (satTaken) {
                  let satMath = user?.satMath
                  let satVerbal = user?.satVerbal

                  userCollegesThatRequireTests.map((college) => {
                    if (returnUserSATScoreOutputLowerThan50(satMath, satVerbal, college)) {
                      !belowArr.includes(college) && belowArr.push(college)
                      !test.includes('SAT') && test.push('SAT')
                    }
                  })
                }

                if (actTaken) {
                  let actBest = user?.actComposite

                  userCollegesThatRequireTests.map((college) => {
                    if (returnUserACTScoreOutputLowerThan50(actBest, college)) {
                      !belowArr.includes(college) && belowArr.push(college)
                      !test.includes('ACT') && test.push('ACT')
                    }
                  })

                }

                const returnTestValue = (test) => {
                  if (test.includes('SAT') && test.includes('ACT')) {
                    return `SAT and ACT scores are`
                  }

                  else if (test.includes('SAT') && !test.includes('ACT')) {
                    return `SAT score is`
                  }

                  else if (!test.includes('SAT') && test.includes('ACT')) {
                    return `ACT score is`
                  }

                  return `SAT and ACT scores are`
                }

                return {
                  length: belowArr?.length > 0 ? belowArr?.length : undefined,
                  test: returnTestValue(test),
                  college1: belowArr[0]?.name ?? undefined,
                  college2: belowArr[1]?.name ?? undefined
                }

              }

              let collegesBelow = checkTestScoresAgainstMedians(userCollegesThatRequireTests)

              if (collegesBelow?.length > 0) {
                return 'yellow'
              }

              // SENTENCE 3
              const checkDoNotSubmitCount = (userCollegesOptionalTests) => {
                let doNotSubmitArr: any = []
                let satMath = user?.satMath ?? undefined
                let satVerbal = user?.satVerbal ?? undefined
                let actBest = user?.actComposite ?? undefined

                const returnOptionBSATComposite25 = (cds) => {
                  if (cds.satEvidence25 && cds.satMath25) {
                    return cds.satEvidence25 + cds.satMath25
                  }

                  else {
                    return null
                  }
                }

                const returnOptionBSATComposite75 = (cds) => {
                  if (cds.satEvidence75 + cds.satMath75) {
                    return cds.satEvidence75 + cds.satMath75
                  }

                  else {
                    return null
                  }
                }

                const returnOptionBSATComposite50 = (cds) => {

                  if (cds.satEvidence50 && cds.satMath50) {
                    return cds.satEvidence50 + cds.satMath50
                  }

                  else if (cds.satEvidence25 && cds.satEvidence75 && cds.satMath25 && cds.satMath75) {
                    return ((cds.satEvidence25 + cds.satEvidence75) / 2) + ((cds.satMath25 + cds.satMath75) / 2)
                  }

                  else {
                    return null
                  }
                }

                const returnUserSATScoreOutput = (userSATMath, userSATVerbal, res: any) => {

                  if (res && res.college_cds) {

                    const cds = res.college_cds[0]

                    const caseA = (cds.satComposite25 == null || cds.satComposite25 == undefined) || (cds.satComposite75 == null || cds.satComposite75 == undefined)

                    const caseB = [cds.satEvidence25, cds.satEvidence75, cds.satMath25, cds.satMath75].some((sat) => sat == null || sat == undefined)

                    if (caseA && caseB) {
                      return -2
                    }

                    else if ((userSATMath == null || userSATMath == undefined) || (userSATVerbal == null || userSATVerbal == undefined)) {
                      return -1
                    }

                    else {

                      let satSuperScore = userSATMath + userSATVerbal
                      let satComposite50: any = cds.satComposite50 ? cds.satComposite50 : null
                      let satComposite25: any = cds.satComposite25 ? cds.satComposite25 : null
                      let satComposite75: any = cds.satComposite75 ? cds.satComposite75 : null

                      let above25: boolean = false
                      let above50: boolean = false
                      let above75: boolean = false

                      if (cds.satComposite25 !== null && cds.satComposite75 !== null) {
                        satComposite25 = cds.satComposite25;
                        satComposite75 = cds.satComposite75;
                        cds.satComposite50 ? satComposite50 = cds.satComposite50 : satComposite50 = ((satComposite25 + satComposite75) / 2);
                      }

                      else if (cds.satComposite25 === null && cds.satComposite75 === null) {
                        satComposite25 = returnOptionBSATComposite25(cds);
                        satComposite50 = returnOptionBSATComposite50(cds);
                        satComposite75 = returnOptionBSATComposite75(cds);
                      }

                      if (satSuperScore >= satComposite25) {
                        above25 = true
                      }

                      if (satSuperScore >= satComposite75) {
                        above75 = true
                      }

                      if (satSuperScore >= satComposite50) {
                        above50 = true
                      }

                      switch (true) {
                        case above25 == false:
                          return (((satSuperScore / satComposite25)) * 25)
                        case above25 == true && above50 == false:
                          return (((satSuperScore - satComposite25) / (satComposite50 - satComposite25)) * 25) + 25
                        case above50 == true && above75 == false:
                          return (((satSuperScore - satComposite50) / (satComposite75 - satComposite50)) * 25) + 50
                        default:
                          return (((satSuperScore - satComposite75) / (1600 - satComposite75)) * 25) + 75
                      }
                    }
                  }

                  else {
                    return -2
                  }

                }

                const returnUserACTScoreOutput = (userACTBestScore, res: any) => {

                  let actReference50: any = 0
                  let above25: any = false
                  let above50: any = false
                  let above75: any = false

                  if (res && res.college_cds) {

                    const cds = res.college_cds[0]

                    // If either actComposite25 or actComposite75 are null, set ACT score output to -2
                    if ((cds.actComposite25 == null || cds.actComposite25 == undefined) || (cds.actComposite75 == null || cds.actComposite75 == undefined)) {
                      return -2
                    }

                    else if (userACTBestScore == null || userACTBestScore == undefined) {
                      return -1
                    }

                    // Set act reference 50. If it exists set it to actComposite50 otherwise set it to the average of actComposite25 and actComposite75
                    actReference50 = cds.actComposite50 ? cds.actComposite50 : ((cds.actComposite25 + cds.actComposite75) / 2)

                    const setBooleans = () => {
                      if (userACTBestScore >= cds.actComposite25) {
                        above25 = true
                      }

                      if (userACTBestScore >= actReference50) {
                        above50 = true
                      }

                      if (userACTBestScore >= cds.actComposite75) {
                        above75 = true
                      }
                    }
                    setBooleans();


                    if (above25 === false) {
                      return ((((userACTBestScore / cds.actComposite25)) * 25))
                    }

                    else if (above25 === true && above50 === false) {
                      return ((((userACTBestScore - cds.actComposite25) / (actReference50 - cds.actComposite25)) * 25) + 25)
                    }

                    else if (above50 === true && above75 === false) {

                      if ((cds.actComposite25 == actReference50) && (userACTBestScore == cds.actComposite25)) {
                        return ((50 + 25) / 2)
                      }

                      return ((((userACTBestScore - actReference50) / (cds.actComposite75 - actReference50)) * 25) + 50)
                    }

                    else {
                      if (userACTBestScore == 36 && cds.actComposite75 == 36) {
                        return 100
                      }

                      if ((cds.actComposite75 == actReference50) && (userACTBestScore == cds.actComposite75)) {
                        return ((50 + 75) / 2)
                      }

                      return ((((userACTBestScore - cds.actComposite75) / (36 - cds.actComposite75)) * 25) + 75)
                    }

                  }

                  else {
                    // fallback, if college cds is not available
                    return -2
                  }


                }

                const recommendationLogic = (userSATScoreOutput, userACTScoreOutput, res) => {

                  if (res.testPolicy == 3) {
                    // Do not submit
                    return true
                  }

                  // CTA to enter score data
                  if (userSATScoreOutput === -1 && userACTScoreOutput === -1) {
                    return false
                  }

                  // Insufficient college data
                  if (userSATScoreOutput === -2 && userACTScoreOutput === -2) {
                    return false
                  }

                  if (userSATScoreOutput === -2 && userACTScoreOutput === -1) {
                    return false
                  }

                  if (userSATScoreOutput === -1 && userACTScoreOutput === -2) {
                    return false
                  }

                  // Testpolicy 1 vs 2:
                  switch (true) {
                    case res.testPolicy == 1:
                      if (userSATScoreOutput > userACTScoreOutput) {
                        //Submit SAT
                        return false
                      }
                      else if (userSATScoreOutput < userACTScoreOutput) {
                        //Submit ACT
                        return false
                      }
                      else {
                        //Submit either
                        return false
                      }
                    case res.testPolicy == 2:
                      if (userSATScoreOutput > userACTScoreOutput) {
                        if (userSATScoreOutput >= 60) {
                          //Submit SAT
                          return false
                        }
                        else if ((40 <= userSATScoreOutput) && (userSATScoreOutput < 60)) {
                          //Borderline
                          return false
                        }
                        else if (userSATScoreOutput < 40) {
                          // Do not submit
                          return true
                        }
                      }

                      if (userSATScoreOutput < userACTScoreOutput) {
                        if (userACTScoreOutput >= 60) {
                          // Submit ACT 
                          return false
                        }
                        else if ((40 <= userACTScoreOutput) && (userACTScoreOutput < 60)) {
                          // Borderline
                          return false
                        }
                        else if (userACTScoreOutput < 40) {
                          //Do not submit
                          return true
                        }
                      }

                      if (userSATScoreOutput === userACTScoreOutput) {
                        if (userSATScoreOutput >= 60) {
                          if (res.college_cds[0].submitSATPercent >= res.college_cds[0].submitACTPercent) {
                            // Submit SAT
                            return false
                          }
                          else if (res.college_cds[0].submitSATPercent < res.college_cds[0].submitACTPercent) {
                            // Submit ACT
                            return false
                          }
                        }
                        if (40 <= userSATScoreOutput && userSATScoreOutput < 60) {
                          //Borderline
                          return false
                        }
                        if (userSATScoreOutput < 40) {
                          // Do not submit
                          return true
                        }
                      }
                  }
                }

                userCollegesOptionalTests.map((college) => {
                  let satOutput = returnUserSATScoreOutput(satMath, satVerbal, college)
                  let actOutput = returnUserACTScoreOutput(actBest, college)

                  if (recommendationLogic(satOutput, actOutput, college)) {
                    doNotSubmitArr.push(college)
                  }
                })

                return doNotSubmitArr

              }

              let doNotSubmit = checkDoNotSubmitCount(userCollegesOptionalTests)

              if (doNotSubmit?.length > 0) {
                return 'yellow'
              }

              return 'green'
            }

            else if (testScores == true && userCollegeInterest?.length == 0) {
              return 'yellow'
            }

          }

          return 'red'
        }

        setStatusController(prevState => ({
          ...prevState, StandardizedTesting: returnStatus()
        }))

        resolve();
      });
    }

    const handleCollegeApplicationsStatus = () => {
      return new Promise<void>((resolve) => {

        let status = 'inactive'

        setStatusController(prevState => ({
          ...prevState, CollegeApplications: status
        }))

        resolve();
      });
    }

    const handleAll = async () => {

      await handleOverviewStatus();
      await handleIntendedMajorStatus();
      await handleExtracurricularsStatus();
      await handleCollegeInterestListStatus();
      await handleStandardizedTestingStatus();
      await handleCollegeApplicationsStatus();

    };

    handleAll();

    // if (!user?.user_id) {
    //   setStatusController({
    //     Overview: undefined,
    //     IntendedMajor: undefined,
    //     Extracurriculars: undefined,
    //     CollegeInterestList: undefined,
    //     StandardizedTesting: undefined,
    //     CollegeApplications: undefined
    //   });

    //   setOverrideTestingWizard(false);

    //   setWizardActive({
    //     IntendedMajor: false,
    //     Extracurriculars: false,
    //     CollegeInterestList: false,
    //     StandardizedTesting: false
    //   })
    // }

    // if (wizardActive?.StandardizedTesting == false) {
    //   setFirstExpand(undefined);

    //   // #972 handle close on wizard if user just needs to enter test scores, already has colleges saved
    //   // if (user?.user_id) {
    //   //   let testScores = false
    //   //   let len = userCollegeInterest?.length ?? undefined
    //   //   let sat = (user?.satMath != null && user?.satVerbal != null);
    //   //   let act = !!user?.actComposite;

    //   //   if (sat || act) {
    //   //     testScores = true
    //   //   }

    //   //   if (len >= 5 && testScores && wizardActive?.StandardizedTesting == true) {
    //   //     setWizardActive(prevState => ({
    //   //       ...prevState, StandardizedTesting: false
    //   //     }))
    //   //   }
    //   // }

    //   const handleOverviewStatus = () => {
    //     return new Promise<void>((resolve) => {
    //       const setFirstStatus = () => {
    //         let overviewIntroductionKey = sessionStorageGet('overviewIntroduction')
    //         let overviewCollegeAppKey = sessionStorageGet('overviewCollegeApp')

    //         if ((user?.overviewIntroductionVisit == true && user?.overviewCollegeAppVisit == true) || (overviewIntroductionKey != null && overviewCollegeAppKey != null)) {
    //           return 3
    //         }

    //         else if (user?.familiarityWithProcess != null || user?.familiarityWithProcess != undefined) {
    //           return user?.familiarityWithProcess
    //         }

    //         else if (introQuestions.c != undefined) {
    //           return introQuestions.c
    //         }

    //         return 'red'
    //       }

    //       let status: any = setFirstStatus()

    //       if (status != 'red') {
    //         // 1 == "Just starting to learn about it"
    //         // 2 == "Somewhat familiar"
    //         // 3 == "Very familiar"
    //         switch (status) {
    //           case 3:
    //             status = 'green'
    //             break;
    //           case 2:
    //             status = 'yellow'
    //             break;
    //           case 1:
    //             status = 'red'
    //             break
    //         }
    //       }

    //       setStatusController(prevState => ({
    //         ...prevState, Overview: status
    //       }))

    //       resolve();
    //     });
    //   };

    //   const handleIntendedMajorStatus = () => {
    //     return new Promise<void>((resolve) => {
    //       let gradYr = initializeGradYr()
    //       let userMajors = user?.majorsInterest ?? undefined

    //       const returnStatus = () => {
    //         if (gradYr) {
    //           let yearDif = gradYr - currentYear

    //           if (yearDif && yearDif > 6) {
    //             return 'inactive'
    //           }
    //         }

    //         if (userMajors) {
    //           let len = userMajors.length
    //           switch (true) {
    //             case len == 0:
    //               return 'red';
    //             case len == 1:
    //               return 'yellow'
    //             case len >= 2:
    //               // Initializing an empty array to hold the colleges in the userCollegeInterest array that have some programData
    //               let userCollegesWithProgramData: any = []
    //               if (userCollegeInterest?.length > 0 && programData?.length > 0) {
    //                 userCollegeInterest.map((college) => {
    //                   let found = programData.find((program) => parseInt(program.college_id) === parseInt(college.college_id))
    //                   if (found) {
    //                     userCollegesWithProgramData.push(college)
    //                   }
    //                 })
    //               }

    //               // If there are items in the userCollegesWithProgramData, check to see if every college object has a program in programData where the college_id matches and some major id in userMajors is the program's major_id
    //               const collegesWithProgramDataFoundMatchingMajor = () => {
    //                 if (userCollegesWithProgramData.length > 0 && userMajors?.length > 0) {
    //                   // Returns true if for every user saved college with program data, there is a program that has a college_id matching the college_id and the program's major_id matches some major id in the userMajors array
    //                   let check = userCollegesWithProgramData?.every(college => userMajors.some(majorID => programData?.find(program => parseInt(program.major_id) === majorID && parseInt(program.college_id) === parseInt(college.college_id))))

    //                   // If it is true, we want to skip the concat push block, so we set to false. Otherwise, if false, that is our trigger to add the sentence2_majors2
    //                   return !check
    //                 }

    //                 // By default, skip the concat push since the user may not have saved colleges or majors
    //                 return false
    //               }

    //               if (collegesWithProgramDataFoundMatchingMajor()) {
    //                 return 'yellow'
    //               }

    //               return 'green'
    //           }
    //         }

    //         return 'red'
    //       }

    //       setStatusController(prevState => ({
    //         ...prevState, IntendedMajor: returnStatus()
    //       }))

    //       resolve();
    //     });
    //   };

    //   const handleExtracurricularsStatus = () => {
    //     return new Promise<void>((resolve) => {
    //       let userECs = user?.activitiesInterest ?? undefined

    //       const returnStatus = () => {
    //         if (userECs) {
    //           let len = userECs.length
    //           switch (true) {
    //             case len == 0:
    //               return 'red'
    //             case [1, 2, 3].includes(len):
    //               return 'yellow'
    //             case len >= 4:
    //               // impact = best === parseInt(impactIndex) == 3
    //               let anyImpactBest = userECs?.some((userEC) => ecData.find((res) => userEC === res.id && parseInt(res?.impactIndex) == 3)) ?? undefined

    //               // activityType = Independent === activityType == 8
    //               let noneIndependent = userECs?.every((userEC) => ecData.find((res) => userEC === res.id && res?.activityType != 8)) ?? undefined

    //               if (userECs?.length > 0 && anyImpactBest == false) {
    //                 return 'yellow'
    //               }

    //               else if (userECs?.length > 0 && anyImpactBest == true && noneIndependent == true) {
    //                 // concat.push(sentence3_ec4b)
    //                 return 'yellow'
    //               }

    //               return 'green'
    //             default:
    //               return 'red'
    //           }
    //         }

    //         return 'red'
    //       }

    //       setStatusController(prevState => ({
    //         ...prevState, Extracurriculars: returnStatus()
    //       }))

    //       resolve();
    //     });
    //   }

    //   const handleCollegeInterestListStatus = () => {
    //     return new Promise<void>((resolve) => {
    //       let gradYr = initializeGradYr()

    //       const returnStatus = () => {
    //         if (gradYr) {
    //           let yearDif = gradYr - currentYear

    //           if (yearDif && yearDif > 4) {
    //             return 'inactive'
    //           }
    //         }

    //         if (userCollegeInterest) {
    //           let len = userCollegeInterest.length
    //           switch (true) {
    //             case len == 0:
    //               return 'red'
    //             case [1, 2, 3, 4].includes(len):
    //               return 'yellow'
    //             case len >= 5:
    //               let safetyCount = userCollegeInterest?.filter(res => res.fit === 4).length ?? 0;
    //               let targetCount = userCollegeInterest?.filter(res => res.fit === 3).length ?? 0;
    //               const anyUserCollegeNotCategorized = userCollegeInterest?.some((college) => college.fit == 1) ?? undefined

    //               // If 1+ college hasn't been categorized as {reach/safety/target}
    //               if (anyUserCollegeNotCategorized == true) {
    //                 return 'yellow'
    //               }

    //               // If all colleges have been categorized as {reach/safety/target} and (number of safety schools < 2 or number of target schools is <2)
    //               if (anyUserCollegeNotCategorized == false && (safetyCount < 2 || targetCount < 2)) {
    //                 // concat.push(sentence2_colleges5b)
    //                 return 'yellow'
    //               }
    //               return 'green'
    //           }
    //         }

    //         return 'red'

    //       }

    //       setStatusController(prevState => ({
    //         ...prevState, CollegeInterestList: returnStatus()
    //       }))

    //       resolve();
    //     });
    //   }

    //   const handleStandardizedTestingStatus = () => {
    //     return new Promise<void>((resolve) => {
    //       let gradYr = initializeGradYr()
    //       let testScores = false

    //       const returnStatus = () => {
    //         if (gradYr) {
    //           let yearDif = gradYr - currentYear

    //           if (yearDif > 3) {
    //             return 'inactive'
    //           }
    //         }

    //         if (user) {
    //           let sat = (user?.satMath != null && user?.satVerbal != null);
    //           let act = !!user?.actComposite;

    //           if (sat || act) {
    //             testScores = true
    //           }

    //           if (testScores == true && userCollegeInterest?.length > 0) {
    //             const userCollegeIds = new Set(userCollegeInterest?.map((college) => college.college_id));
    //             let userCollegesThatRequireTests = collegeData?.filter(
    //               (res) => userCollegeIds?.has(res.id) && res.testPolicy == 1
    //             );
    //             let userCollegesOptionalTests = collegeData?.filter(
    //               (res) => userCollegeIds?.has(res.id) && res.testPolicy == 2
    //             );
    //             // SENTENCE 2
    //             const checkTestScoresAgainstMedians = (userCollegesThatRequireTests) => {

    //               const returnUserSATScoreOutputLowerThan50 = (userSATMath, userSATVerbal, res: any) => {
    //                 const returnOptionBSATComposite25 = (cds) => {
    //                   if (cds.satEvidence25 && cds.satMath25) {
    //                     return cds.satEvidence25 + cds.satMath25
    //                   }

    //                   else {
    //                     return null
    //                   }
    //                 }

    //                 const returnOptionBSATComposite75 = (cds) => {
    //                   if (cds.satEvidence75 + cds.satMath75) {
    //                     return cds.satEvidence75 + cds.satMath75
    //                   }

    //                   else {
    //                     return null
    //                   }
    //                 }

    //                 const returnOptionBSATComposite50 = (cds) => {

    //                   if (cds.satEvidence50 && cds.satMath50) {
    //                     return cds.satEvidence50 + cds.satMath50
    //                   }

    //                   else if (cds.satEvidence25 && cds.satEvidence75 && cds.satMath25 && cds.satMath75) {
    //                     return ((cds.satEvidence25 + cds.satEvidence75) / 2) + ((cds.satMath25 + cds.satMath75) / 2)
    //                   }

    //                   else {
    //                     return null
    //                   }
    //                 }

    //                 if (res && res.college_cds) {

    //                   const cds = res.college_cds[0]

    //                   const caseA = (cds.satComposite25 == null || cds.satComposite25 == undefined) || (cds.satComposite75 == null || cds.satComposite75 == undefined)

    //                   const caseB = [cds.satEvidence25, cds.satEvidence75, cds.satMath25, cds.satMath75].some((sat) => sat == null || sat == undefined)

    //                   if (caseA && caseB) {
    //                     return false
    //                   }

    //                   else if ((userSATMath == null || userSATMath == undefined) || (userSATVerbal == null || userSATVerbal == undefined)) {
    //                     return false
    //                   }

    //                   else {

    //                     let satSuperScore = userSATMath + userSATVerbal
    //                     let satComposite50: any = cds.satComposite50 ? cds.satComposite50 : null
    //                     let satComposite25: any = cds.satComposite25 ? cds.satComposite25 : null
    //                     let satComposite75: any = cds.satComposite75 ? cds.satComposite75 : null

    //                     let above25: boolean = false
    //                     let above50: boolean = false
    //                     let above75: boolean = false

    //                     if (cds.satComposite25 !== null && cds.satComposite75 !== null) {
    //                       satComposite25 = cds.satComposite25;
    //                       satComposite75 = cds.satComposite75;
    //                       cds.satComposite50 ? satComposite50 = cds.satComposite50 : satComposite50 = ((satComposite25 + satComposite75) / 2);
    //                     }

    //                     else if (cds.satComposite25 === null && cds.satComposite75 === null) {
    //                       satComposite25 = returnOptionBSATComposite25(cds);
    //                       satComposite50 = returnOptionBSATComposite50(cds);
    //                       satComposite75 = returnOptionBSATComposite75(cds);
    //                     }

    //                     if (satSuperScore >= satComposite25) {
    //                       above25 = true
    //                     }

    //                     if (satSuperScore >= satComposite75) {
    //                       above75 = true
    //                     }

    //                     if (satSuperScore >= satComposite50) {
    //                       above50 = true
    //                     }

    //                     switch (true) {
    //                       case above25 == false:
    //                         return ((((satSuperScore / satComposite25)) * 25) < 50) ? true : false
    //                       case above25 == true && above50 == false:
    //                         return ((((satSuperScore - satComposite25) / (satComposite50 - satComposite25)) * 25) + 25) < 50 ? true : false
    //                       case above50 == true && above75 == false:
    //                         return ((((satSuperScore - satComposite50) / (satComposite75 - satComposite50)) * 25) + 50) < 50 ? true : false
    //                       default:
    //                         return ((((satSuperScore - satComposite75) / (1600 - satComposite75)) * 25) + 75) < 50 ? true : false
    //                     }
    //                   }
    //                 }

    //                 else {
    //                   return false
    //                 }

    //               }

    //               const returnUserACTScoreOutputLowerThan50 = (userACTBestScore, res: any) => {

    //                 let actReference50: any = 0
    //                 let above25: any = false
    //                 let above50: any = false
    //                 let above75: any = false

    //                 if (res && res.college_cds) {

    //                   const cds = res.college_cds[0]

    //                   // If either actComposite25 or actComposite75 are null, set ACT score output to -2
    //                   if ((cds.actComposite25 == null || cds.actComposite25 == undefined) || (cds.actComposite75 == null || cds.actComposite75 == undefined)) {
    //                     return false
    //                   }

    //                   else if (userACTBestScore == null || userACTBestScore == undefined) {
    //                     return false
    //                   }

    //                   // Set act reference 50. If it exists set it to actComposite50 otherwise set it to the average of actComposite25 and actComposite75
    //                   actReference50 = cds.actComposite50 ? cds.actComposite50 : ((cds.actComposite25 + cds.actComposite75) / 2)

    //                   const setBooleans = () => {
    //                     if (userACTBestScore >= cds.actComposite25) {
    //                       above25 = true
    //                     }

    //                     if (userACTBestScore >= actReference50) {
    //                       above50 = true
    //                     }

    //                     if (userACTBestScore >= cds.actComposite75) {
    //                       above75 = true
    //                     }
    //                   }
    //                   setBooleans();


    //                   if (above25 === false) {
    //                     return ((((userACTBestScore / cds.actComposite25)) * 25)) < 50 ? true : false
    //                   }

    //                   else if (above25 === true && above50 === false) {
    //                     return ((((userACTBestScore - cds.actComposite25) / (actReference50 - cds.actComposite25)) * 25) + 25) < 50 ? true : false
    //                   }

    //                   else if (above50 === true && above75 === false) {

    //                     if ((cds.actComposite25 == actReference50) && (userACTBestScore == cds.actComposite25)) {
    //                       return ((50 + 25) / 2) < 50 ? true : false
    //                     }

    //                     return ((((userACTBestScore - actReference50) / (cds.actComposite75 - actReference50)) * 25) + 50) < 50 ? true : false
    //                   }

    //                   else {
    //                     if (userACTBestScore == 36 && cds.actComposite75 == 36) {
    //                       return false
    //                     }

    //                     if ((cds.actComposite75 == actReference50) && (userACTBestScore == cds.actComposite75)) {
    //                       return false
    //                     }

    //                     return ((((userACTBestScore - cds.actComposite75) / (36 - cds.actComposite75)) * 25) + 75) < 50 ? true : false
    //                   }

    //                 }

    //                 else {
    //                   // fallback, if college cds is not available
    //                   return false
    //                 }


    //               }

    //               let belowArr: any = []
    //               let satTaken = (user?.satMath && user?.satVerbal);
    //               let actTaken = !!user?.actComposite;
    //               let test: any = []

    //               if (satTaken) {
    //                 let satMath = user?.satMath
    //                 let satVerbal = user?.satVerbal

    //                 userCollegesThatRequireTests.map((college) => {
    //                   if (returnUserSATScoreOutputLowerThan50(satMath, satVerbal, college)) {
    //                     !belowArr.includes(college) && belowArr.push(college)
    //                     !test.includes('SAT') && test.push('SAT')
    //                   }
    //                 })
    //               }

    //               if (actTaken) {
    //                 let actBest = user?.actComposite

    //                 userCollegesThatRequireTests.map((college) => {
    //                   if (returnUserACTScoreOutputLowerThan50(actBest, college)) {
    //                     !belowArr.includes(college) && belowArr.push(college)
    //                     !test.includes('ACT') && test.push('ACT')
    //                   }
    //                 })

    //               }

    //               const returnTestValue = (test) => {
    //                 if (test.includes('SAT') && test.includes('ACT')) {
    //                   return `SAT and ACT scores are`
    //                 }

    //                 else if (test.includes('SAT') && !test.includes('ACT')) {
    //                   return `SAT score is`
    //                 }

    //                 else if (!test.includes('SAT') && test.includes('ACT')) {
    //                   return `ACT score is`
    //                 }

    //                 return `SAT and ACT scores are`
    //               }

    //               return {
    //                 length: belowArr?.length > 0 ? belowArr?.length : undefined,
    //                 test: returnTestValue(test),
    //                 college1: belowArr[0]?.name ?? undefined,
    //                 college2: belowArr[1]?.name ?? undefined
    //               }

    //             }

    //             let collegesBelow = checkTestScoresAgainstMedians(userCollegesThatRequireTests)

    //             if (collegesBelow?.length > 0) {
    //               return 'yellow'
    //             }

    //             // SENTENCE 3
    //             const checkDoNotSubmitCount = (userCollegesOptionalTests) => {
    //               let doNotSubmitArr: any = []
    //               let satMath = user?.satMath ?? undefined
    //               let satVerbal = user?.satVerbal ?? undefined
    //               let actBest = user?.actComposite ?? undefined

    //               const returnOptionBSATComposite25 = (cds) => {
    //                 if (cds.satEvidence25 && cds.satMath25) {
    //                   return cds.satEvidence25 + cds.satMath25
    //                 }

    //                 else {
    //                   return null
    //                 }
    //               }

    //               const returnOptionBSATComposite75 = (cds) => {
    //                 if (cds.satEvidence75 + cds.satMath75) {
    //                   return cds.satEvidence75 + cds.satMath75
    //                 }

    //                 else {
    //                   return null
    //                 }
    //               }

    //               const returnOptionBSATComposite50 = (cds) => {

    //                 if (cds.satEvidence50 && cds.satMath50) {
    //                   return cds.satEvidence50 + cds.satMath50
    //                 }

    //                 else if (cds.satEvidence25 && cds.satEvidence75 && cds.satMath25 && cds.satMath75) {
    //                   return ((cds.satEvidence25 + cds.satEvidence75) / 2) + ((cds.satMath25 + cds.satMath75) / 2)
    //                 }

    //                 else {
    //                   return null
    //                 }
    //               }

    //               const returnUserSATScoreOutput = (userSATMath, userSATVerbal, res: any) => {

    //                 if (res && res.college_cds) {

    //                   const cds = res.college_cds[0]

    //                   const caseA = (cds.satComposite25 == null || cds.satComposite25 == undefined) || (cds.satComposite75 == null || cds.satComposite75 == undefined)

    //                   const caseB = [cds.satEvidence25, cds.satEvidence75, cds.satMath25, cds.satMath75].some((sat) => sat == null || sat == undefined)

    //                   if (caseA && caseB) {
    //                     return -2
    //                   }

    //                   else if ((userSATMath == null || userSATMath == undefined) || (userSATVerbal == null || userSATVerbal == undefined)) {
    //                     return -1
    //                   }

    //                   else {

    //                     let satSuperScore = userSATMath + userSATVerbal
    //                     let satComposite50: any = cds.satComposite50 ? cds.satComposite50 : null
    //                     let satComposite25: any = cds.satComposite25 ? cds.satComposite25 : null
    //                     let satComposite75: any = cds.satComposite75 ? cds.satComposite75 : null

    //                     let above25: boolean = false
    //                     let above50: boolean = false
    //                     let above75: boolean = false

    //                     if (cds.satComposite25 !== null && cds.satComposite75 !== null) {
    //                       satComposite25 = cds.satComposite25;
    //                       satComposite75 = cds.satComposite75;
    //                       cds.satComposite50 ? satComposite50 = cds.satComposite50 : satComposite50 = ((satComposite25 + satComposite75) / 2);
    //                     }

    //                     else if (cds.satComposite25 === null && cds.satComposite75 === null) {
    //                       satComposite25 = returnOptionBSATComposite25(cds);
    //                       satComposite50 = returnOptionBSATComposite50(cds);
    //                       satComposite75 = returnOptionBSATComposite75(cds);
    //                     }

    //                     if (satSuperScore >= satComposite25) {
    //                       above25 = true
    //                     }

    //                     if (satSuperScore >= satComposite75) {
    //                       above75 = true
    //                     }

    //                     if (satSuperScore >= satComposite50) {
    //                       above50 = true
    //                     }

    //                     switch (true) {
    //                       case above25 == false:
    //                         return (((satSuperScore / satComposite25)) * 25)
    //                       case above25 == true && above50 == false:
    //                         return (((satSuperScore - satComposite25) / (satComposite50 - satComposite25)) * 25) + 25
    //                       case above50 == true && above75 == false:
    //                         return (((satSuperScore - satComposite50) / (satComposite75 - satComposite50)) * 25) + 50
    //                       default:
    //                         return (((satSuperScore - satComposite75) / (1600 - satComposite75)) * 25) + 75
    //                     }
    //                   }
    //                 }

    //                 else {
    //                   return -2
    //                 }

    //               }

    //               const returnUserACTScoreOutput = (userACTBestScore, res: any) => {

    //                 let actReference50: any = 0
    //                 let above25: any = false
    //                 let above50: any = false
    //                 let above75: any = false

    //                 if (res && res.college_cds) {

    //                   const cds = res.college_cds[0]

    //                   // If either actComposite25 or actComposite75 are null, set ACT score output to -2
    //                   if ((cds.actComposite25 == null || cds.actComposite25 == undefined) || (cds.actComposite75 == null || cds.actComposite75 == undefined)) {
    //                     return -2
    //                   }

    //                   else if (userACTBestScore == null || userACTBestScore == undefined) {
    //                     return -1
    //                   }

    //                   // Set act reference 50. If it exists set it to actComposite50 otherwise set it to the average of actComposite25 and actComposite75
    //                   actReference50 = cds.actComposite50 ? cds.actComposite50 : ((cds.actComposite25 + cds.actComposite75) / 2)

    //                   const setBooleans = () => {
    //                     if (userACTBestScore >= cds.actComposite25) {
    //                       above25 = true
    //                     }

    //                     if (userACTBestScore >= actReference50) {
    //                       above50 = true
    //                     }

    //                     if (userACTBestScore >= cds.actComposite75) {
    //                       above75 = true
    //                     }
    //                   }
    //                   setBooleans();


    //                   if (above25 === false) {
    //                     return ((((userACTBestScore / cds.actComposite25)) * 25))
    //                   }

    //                   else if (above25 === true && above50 === false) {
    //                     return ((((userACTBestScore - cds.actComposite25) / (actReference50 - cds.actComposite25)) * 25) + 25)
    //                   }

    //                   else if (above50 === true && above75 === false) {

    //                     if ((cds.actComposite25 == actReference50) && (userACTBestScore == cds.actComposite25)) {
    //                       return ((50 + 25) / 2)
    //                     }

    //                     return ((((userACTBestScore - actReference50) / (cds.actComposite75 - actReference50)) * 25) + 50)
    //                   }

    //                   else {
    //                     if (userACTBestScore == 36 && cds.actComposite75 == 36) {
    //                       return 100
    //                     }

    //                     if ((cds.actComposite75 == actReference50) && (userACTBestScore == cds.actComposite75)) {
    //                       return ((50 + 75) / 2)
    //                     }

    //                     return ((((userACTBestScore - cds.actComposite75) / (36 - cds.actComposite75)) * 25) + 75)
    //                   }

    //                 }

    //                 else {
    //                   // fallback, if college cds is not available
    //                   return -2
    //                 }


    //               }

    //               const recommendationLogic = (userSATScoreOutput, userACTScoreOutput, res) => {

    //                 if (res.testPolicy == 3) {
    //                   // Do not submit
    //                   return true
    //                 }

    //                 // CTA to enter score data
    //                 if (userSATScoreOutput === -1 && userACTScoreOutput === -1) {
    //                   return false
    //                 }

    //                 // Insufficient college data
    //                 if (userSATScoreOutput === -2 && userACTScoreOutput === -2) {
    //                   return false
    //                 }

    //                 if (userSATScoreOutput === -2 && userACTScoreOutput === -1) {
    //                   return false
    //                 }

    //                 if (userSATScoreOutput === -1 && userACTScoreOutput === -2) {
    //                   return false
    //                 }

    //                 // Testpolicy 1 vs 2:
    //                 switch (true) {
    //                   case res.testPolicy == 1:
    //                     if (userSATScoreOutput > userACTScoreOutput) {
    //                       //Submit SAT
    //                       return false
    //                     }
    //                     else if (userSATScoreOutput < userACTScoreOutput) {
    //                       //Submit ACT
    //                       return false
    //                     }
    //                     else {
    //                       //Submit either
    //                       return false
    //                     }
    //                   case res.testPolicy == 2:
    //                     if (userSATScoreOutput > userACTScoreOutput) {
    //                       if (userSATScoreOutput >= 60) {
    //                         //Submit SAT
    //                         return false
    //                       }
    //                       else if ((40 <= userSATScoreOutput) && (userSATScoreOutput < 60)) {
    //                         //Borderline
    //                         return false
    //                       }
    //                       else if (userSATScoreOutput < 40) {
    //                         // Do not submit
    //                         return true
    //                       }
    //                     }

    //                     if (userSATScoreOutput < userACTScoreOutput) {
    //                       if (userACTScoreOutput >= 60) {
    //                         // Submit ACT 
    //                         return false
    //                       }
    //                       else if ((40 <= userACTScoreOutput) && (userACTScoreOutput < 60)) {
    //                         // Borderline
    //                         return false
    //                       }
    //                       else if (userACTScoreOutput < 40) {
    //                         //Do not submit
    //                         return true
    //                       }
    //                     }

    //                     if (userSATScoreOutput === userACTScoreOutput) {
    //                       if (userSATScoreOutput >= 60) {
    //                         if (res.college_cds[0].submitSATPercent >= res.college_cds[0].submitACTPercent) {
    //                           // Submit SAT
    //                           return false
    //                         }
    //                         else if (res.college_cds[0].submitSATPercent < res.college_cds[0].submitACTPercent) {
    //                           // Submit ACT
    //                           return false
    //                         }
    //                       }
    //                       if (40 <= userSATScoreOutput && userSATScoreOutput < 60) {
    //                         //Borderline
    //                         return false
    //                       }
    //                       if (userSATScoreOutput < 40) {
    //                         // Do not submit
    //                         return true
    //                       }
    //                     }
    //                 }
    //               }

    //               userCollegesOptionalTests.map((college) => {
    //                 let satOutput = returnUserSATScoreOutput(satMath, satVerbal, college)
    //                 let actOutput = returnUserACTScoreOutput(actBest, college)

    //                 if (recommendationLogic(satOutput, actOutput, college)) {
    //                   doNotSubmitArr.push(college)
    //                 }
    //               })

    //               return doNotSubmitArr

    //             }

    //             let doNotSubmit = checkDoNotSubmitCount(userCollegesOptionalTests)

    //             if (doNotSubmit?.length > 0) {
    //               return 'yellow'
    //             }

    //             return 'green'
    //           }

    //           else if (testScores == true && userCollegeInterest?.length == 0) {
    //             return 'yellow'
    //           }

    //         }

    //         return 'red'
    //       }

    //       setStatusController(prevState => ({
    //         ...prevState, StandardizedTesting: returnStatus()
    //       }))

    //       resolve();
    //     });
    //   }

    //   const handleCollegeApplicationsStatus = () => {
    //     return new Promise<void>((resolve) => {

    //       let status = 'inactive'

    //       setStatusController(prevState => ({
    //         ...prevState, CollegeApplications: status
    //       }))

    //       resolve();
    //     });
    //   }

    //   const handleAll = async () => {

    //     await handleOverviewStatus();
    //     await handleIntendedMajorStatus();
    //     await handleExtracurricularsStatus();
    //     await handleCollegeInterestListStatus();
    //     await handleStandardizedTestingStatus();
    //     await handleCollegeApplicationsStatus();

    //   };

    //   handleAll();
    // }

  }, [user, userCollegeInterest, introQuestions, collegeData])

  useEffect(() => {

    const handleSetFirstExpand = async (statusController) => {
      const orderedBlocks = [
        "Introduction",
        "IntendedMajor",
        "Extracurriculars",
        "CollegeInterestList",
        "StandardizedTesting",
        "CollegeApplications",
      ];

      const findFirstXKey = (statusController: any, status: string): Promise<string | null> => {
        return new Promise((resolve) => {
          for (let block of orderedBlocks) {
            if (statusController[block] === status) {
              resolve(block);
              return;
            }
          }
          resolve(null);
        });
      };

      let firstRed: any = await findFirstXKey(statusController, 'red')
      let firstYellow: any = await findFirstXKey(statusController, 'yellow')
      let firstInactive: any = await findFirstXKey(statusController, 'inactive')

      if (firstRed) {
        return setFirstExpand(firstRed)
      }

      else if (firstYellow) {
        return setFirstExpand(firstYellow)
      }

      else if (firstInactive) {
        return setFirstExpand(firstInactive)
      }

      else {
        return setFirstExpand(undefined)
      }

    }

    if (allStatusAreSet && firstExpand === undefined) {
      handleSetFirstExpand(statusController)
    }

  }, [statusController])

  // Move status label width adjust to separate useEffect, trigger on statusController changes + introFlow passed boolean update; #989
  useEffect(() => {

    const labels = document.querySelectorAll(".dca-dashboard-status-label");

    if (labels.length > 0) {
      const widths = Array.from(labels).map(label => (label as HTMLElement).offsetWidth);

      let maxWidth = Math.max(...widths);

      labels.forEach(label => {
        (label as HTMLElement).style.width = `${maxWidth}px`;
        (label as HTMLElement).style.minWidth = `${maxWidth}px`;
      });

      setStatusLabelMaxWidth(maxWidth);
    }
  }, [statusController, introFlow?.passed])

  useEffect(() => {
    if (overrideTestingWizard === true && statusController.StandardizedTesting != 'green') {
      setOverrideTestingWizard(false);
      setWizardActive(prevState => ({
        ...prevState, StandardizedTesting: true
      }))
    }

    if (overrideTestingWizard === true && statusController.StandardizedTesting === 'green' && user?.user_id) {
      setOverrideTestingWizard(false);
      navigate(pageRoutes.testScoreStrategy)
    }

  }, [overrideTestingWizard, user, statusController])

  const sharedContent = (
    <div id="dca-dashboard-title-wrapper">
      <h3 id="dca-dashboard-title">Dashboard</h3>
      <span id="dca-dashboard-description">
        {(introFlow.passed) ? `Check here for current status and recommended next steps for core aspects of the college admissions journey.` : `Please answer these quick questions so we can personalize your dashboard experience.`}
      </span>
    </div>
  );

  const introFlowReturn = <IntroFlow user={user} setUser={setUser} introFlow={introFlow} setIntroFlow={setIntroFlow} introQuestions={introQuestions} setIntroQuestions={setIntroQuestions} introFlowCheck={introFlowCheck} />

  // const signInReminder = (
  //   <div id="dashboard-signin-wrapper">
  //     <div className="dca-dashboard-signin-reminder">
  //       <span className="dca-dashboard-signin-reminder-text">Tip: <span
  //         onClick={() => {
  //           setNavigateTo('');
  //           setOverrideTestingWizard(false)
  //         }}
  //         data-bs-toggle="modal"
  //         data-bs-target="#login_modal"
  //         style={{ textDecoration: 'underline', cursor: 'pointer' }}>Sign in</span> to save your personalized dashboard.</span>
  //     </div>
  //   </div>)

  const signInReminder = (
    <div id="dashboard-signin-wrapper">
      <div className="dca-dashboard-signin-reminder">

        <div className="dca-dashboard-signin-reminder-tip"><img src={tipSymbol} alt="tip_symbol" className="dca-dashboard-signin-tip-image"></img>TIP</div>

        <span className="dca-dashboard-signin-reminder-text"><span
          onClick={() => {
            setNavigateTo('');
            setOverrideTestingWizard(false)
          }}
          data-bs-toggle="modal"
          data-bs-target="#login_modal"
          className="dca-font-M dca-dashboard-signin-reminder-hover"
          style={{ cursor: 'pointer' }}>Sign in</span>&nbsp;to save/view your <br id="dca-word-break-trigger-dashboard"></br>personalized dashboard!</span>
      </div>
    </div>)

  const contentBlocksReturn = allStatusAreSet ? (
    <div id="dca-dashboard-content-blocks-wrapper">

      {/* <button
        className='dca-green-button'
        style={{ height: '53px', width: '60%', margin: '20px auto' }}
        onClick={() => {
          setIntroFlow(prevState => ({ ...prevState, passed: false }));
        }}>Show Intro Flow Again</button>

      <button
        className='dca-green-button'
        style={{ height: '53px', width: '60%', margin: '20px auto' }}
        onClick={() => {
          sessionStorage.removeItem('introFlow')
        }}>Show Intro Flow On Reload</button>

      <button onClick={() => {
        console.log(introQuestions);
      }}>Show states</button> */}

      {!user && signInReminder}

      <Introduction
        user={user}
        wizardActive={wizardActive}
        setWizardActive={setWizardActive}
        status={statusController.Introduction}
        expand={firstExpand === 'Introduction'}
        introQuestions={introQuestions}
        statusLabelMaxWidth={statusLabelMaxWidth} />
      <IntendedMajor
        user={user}
        userCollegeInterest={userCollegeInterest}
        collegeData={collegeData}
        majorData={majorData}
        programData={programData}
        wizardActive={wizardActive}
        setWizardActive={setWizardActive}
        advisors={advisors}
        status={statusController.IntendedMajor}
        expand={firstExpand === 'IntendedMajor'}
        setUserprofileSelectedTab={setUserprofileSelectedTab}
        statusLabelMaxWidth={statusLabelMaxWidth} />
      <Extracurriculars
        user={user}
        wizardActive={wizardActive}
        setWizardActive={setWizardActive}
        permissionGroups={permissionGroups}
        majorData={majorData}
        ecData={ecData}
        advisors={advisors}
        status={statusController.Extracurriculars}
        expand={firstExpand === 'Extracurriculars'}
        setUserprofileSelectedTab={setUserprofileSelectedTab}
        statusLabelMaxWidth={statusLabelMaxWidth} />
      <CollegeInterestList
        user={user}
        userCollegeInterest={userCollegeInterest}
        permissionGroups={permissionGroups}
        wizardActive={wizardActive}
        setWizardActive={setWizardActive}
        advisors={advisors}
        status={statusController.CollegeInterestList}
        expand={firstExpand === 'CollegeInterestList'}
        setUserprofileSelectedTab={setUserprofileSelectedTab}
        statusLabelMaxWidth={statusLabelMaxWidth} />
      <StandardizedTesting
        user={user}
        setUser={setUser}
        setPrompt={setPrompt}
        setNavigateTo={setNavigateTo}
        wizardActive={wizardActive}
        setWizardActive={setWizardActive}
        userCollegeInterest={userCollegeInterest}
        setUserCollegeInterest={setUserCollegeInterest}
        collegesId={collegesId}
        collegeData={collegeData}
        setOnlyInterestsSelect={setOnlyInterestsSelect}
        status={statusController.StandardizedTesting}
        expand={firstExpand === 'StandardizedTesting'}
        statusLabelMaxWidth={statusLabelMaxWidth} />
      <CollegeApplications
        user={user}
        wizardActive={wizardActive}
        setWizardActive={setWizardActive}
        status={statusController.CollegeApplications}
        expand={firstExpand === 'CollegeApplications'}
        statusLabelMaxWidth={statusLabelMaxWidth} />

    </div>) : (<div></div>)

  // move to intro flow components ?
  useEffect(() => {

    let finalPage = 1

    if (!user) {
      setIntroFlow(prevState => ({ ...prevState, page: finalPage }))
    }

    else {
      let a = user?.userType || undefined
      let b = user?.hsGradYr || undefined
      let c = user?.familiarityWithProcess || undefined

      if (a) {
        finalPage = finalPage + 1
      }

      if (b) {
        finalPage = finalPage + 1
      }

      setIntroFlow(prevState => ({ ...prevState, page: finalPage }))
    }

  }, [user, introFlow.passed])

  return (
    <div id="dca-dashboard-page">
      {sharedContent}
      {(introFlow.passed) ? contentBlocksReturn : introFlowReturn}
    </div>
  )
}

export { Dashboard }