export const collegeMap = [
    {
        "id": "166683",
        "name": "Massachusetts Institute of Technology",
        "hyphenated": "massachusetts-institute-of-technology"
    },
    {
        "id": "243744",
        "name": "Stanford University",
        "hyphenated": "stanford-university"
    },
    {
        "id": "166027",
        "name": "Harvard University",
        "hyphenated": "harvard-university"
    },
    {
        "id": "186131",
        "name": "Princeton University",
        "hyphenated": "princeton-university"
    },
    {
        "id": "130794",
        "name": "Yale University",
        "hyphenated": "yale-university"
    },
    {
        "id": "215062",
        "name": "University of Pennsylvania",
        "hyphenated": "university-of-pennsylvania"
    },
    {
        "id": "190150",
        "name": "Columbia University",
        "hyphenated": "columbia-university"
    },
    {
        "id": "190415",
        "name": "Cornell University",
        "hyphenated": "cornell-university"
    },
    {
        "id": "198419",
        "name": "Duke University",
        "hyphenated": "duke-university"
    },
    {
        "id": "110635",
        "name": "University of California-Berkeley",
        "hyphenated": "university-of-california-berkeley"
    },
    {
        "id": "110404",
        "name": "California Institute of Technology",
        "hyphenated": "california-institute-of-technology"
    },
    {
        "id": "147767",
        "name": "Northwestern University",
        "hyphenated": "northwestern-university"
    },
    {
        "id": "144050",
        "name": "University of Chicago",
        "hyphenated": "university-of-chicago"
    },
    {
        "id": "170976",
        "name": "University of Michigan",
        "hyphenated": "university-of-michigan"
    },
    {
        "id": "110662",
        "name": "University of California-Los Angeles",
        "hyphenated": "university-of-california-los-angeles"
    },
    {
        "id": "162928",
        "name": "Johns Hopkins University",
        "hyphenated": "johns-hopkins-university"
    },
    {
        "id": "168342",
        "name": "Williams College",
        "hyphenated": "williams-college"
    },
    {
        "id": "221999",
        "name": "Vanderbilt University",
        "hyphenated": "vanderbilt-university"
    },
    {
        "id": "112260",
        "name": "Claremont McKenna College",
        "hyphenated": "claremont-mckenna-college"
    },
    {
        "id": "179867",
        "name": "Washington University in St Louis",
        "hyphenated": "washington-university-in-st-louis"
    },
    {
        "id": "123961",
        "name": "University of Southern California",
        "hyphenated": "university-of-southern-california"
    },
    {
        "id": "217156",
        "name": "Brown University",
        "hyphenated": "brown-university"
    },
    {
        "id": "145637",
        "name": "University of Illinois Urbana-Champaign",
        "hyphenated": "university-of-illinois-urbana-champaign"
    },
    {
        "id": "134130",
        "name": "University of Florida",
        "hyphenated": "university-of-florida"
    },
    {
        "id": "182670",
        "name": "Dartmouth College",
        "hyphenated": "dartmouth-college"
    },
    {
        "id": "121345",
        "name": "Pomona College",
        "hyphenated": "pomona-college"
    },
    {
        "id": "199120",
        "name": "University of North Carolina at Chapel Hill",
        "hyphenated": "university-of-north-carolina-at-chapel-hill"
    },
    {
        "id": "211440",
        "name": "Carnegie Mellon University",
        "hyphenated": "carnegie-mellon-university"
    },
    {
        "id": "131496",
        "name": "Georgetown University",
        "hyphenated": "georgetown-university"
    },
    {
        "id": "110680",
        "name": "University of California-San Diego",
        "hyphenated": "university-of-california-san-diego"
    },
    {
        "id": "240444",
        "name": "University of Wisconsin-Madison",
        "hyphenated": "university-of-wisconsin-madison"
    },
    {
        "id": "139755",
        "name": "Georgia Institute of Technology",
        "hyphenated": "georgia-institute-of-technology"
    },
    {
        "id": "198385",
        "name": "Davidson College",
        "hyphenated": "davidson-college"
    },
    {
        "id": "227757",
        "name": "Rice University",
        "hyphenated": "rice-university"
    },
    {
        "id": "236948",
        "name": "University of Washington",
        "hyphenated": "university-of-washington"
    },
    {
        "id": "115409",
        "name": "Harvey Mudd College",
        "hyphenated": "harvey-mudd-college"
    },
    {
        "id": "139658",
        "name": "Emory University",
        "hyphenated": "emory-university"
    },
    {
        "id": "152080",
        "name": "University of Notre Dame",
        "hyphenated": "university-of-notre-dame"
    },
    {
        "id": "110644",
        "name": "University of California-Davis",
        "hyphenated": "university-of-california-davis"
    },
    {
        "id": "234076",
        "name": "University of Virginia",
        "hyphenated": "university-of-virginia"
    },
    {
        "id": "234207",
        "name": "Washington and Lee University",
        "hyphenated": "washington-and-lee-university"
    },
    {
        "id": "228778",
        "name": "The University of Texas at Austin",
        "hyphenated": "the-university-of-texas-at-austin"
    },
    {
        "id": "228723",
        "name": "Texas A & M University",
        "hyphenated": "texas-a-&-m-university"
    },
    {
        "id": "110705",
        "name": "University of California-Santa Barbara",
        "hyphenated": "university-of-california-santa-barbara"
    },
    {
        "id": "161004",
        "name": "Bowdoin College",
        "hyphenated": "bowdoin-college"
    },
    {
        "id": "190099",
        "name": "Colgate University",
        "hyphenated": "colgate-university"
    },
    {
        "id": "171100",
        "name": "Michigan State University",
        "hyphenated": "michigan-state-university"
    },
    {
        "id": "243780",
        "name": "Purdue University",
        "hyphenated": "purdue-university"
    },
    {
        "id": "193900",
        "name": "New York University",
        "hyphenated": "new-york-university"
    },
    {
        "id": "110653",
        "name": "University of California-Irvine",
        "hyphenated": "university-of-california-irvine"
    },
    {
        "id": "212911",
        "name": "Haverford College",
        "hyphenated": "haverford-college"
    },
    {
        "id": "164465",
        "name": "Amherst College",
        "hyphenated": "amherst-college"
    },
    {
        "id": "233921",
        "name": "Virginia Tech",
        "hyphenated": "virginia-tech"
    },
    {
        "id": "163286",
        "name": "University of Maryland",
        "hyphenated": "university-of-maryland"
    },
    {
        "id": "164988",
        "name": "Boston University",
        "hyphenated": "boston-university"
    },
    {
        "id": "164580",
        "name": "Babson College",
        "hyphenated": "babson-college"
    },
    {
        "id": "213543",
        "name": "Lehigh University",
        "hyphenated": "lehigh-university"
    },
    {
        "id": "174066",
        "name": "University of Minnesota-Twin Cities",
        "hyphenated": "university-of-minnesota-twin-cities"
    },
    {
        "id": "131469",
        "name": "George Washington University",
        "hyphenated": "george-washington-university"
    },
    {
        "id": "134097",
        "name": "Florida State University",
        "hyphenated": "florida-state-university"
    },
    {
        "id": "230038",
        "name": "Brigham Young University",
        "hyphenated": "brigham-young-university"
    },
    {
        "id": "164924",
        "name": "Boston College",
        "hyphenated": "boston-college"
    },
    {
        "id": "191515",
        "name": "Hamilton College",
        "hyphenated": "hamilton-college"
    },
    {
        "id": "104151",
        "name": "Arizona State University",
        "hyphenated": "arizona-state-university"
    },
    {
        "id": "168218",
        "name": "Wellesley College",
        "hyphenated": "wellesley-college"
    },
    {
        "id": "186380",
        "name": "Rutgers University",
        "hyphenated": "rutgers-university"
    },
    {
        "id": "216287",
        "name": "Swarthmore College",
        "hyphenated": "swarthmore-college"
    },
    {
        "id": "204796",
        "name": "Ohio State University",
        "hyphenated": "ohio-state-university"
    },
    {
        "id": "161086",
        "name": "Colby College",
        "hyphenated": "colby-college"
    },
    {
        "id": "199193",
        "name": "North Carolina State University at Raleigh",
        "hyphenated": "north-carolina-state-university-at-raleigh"
    },
    {
        "id": "135726",
        "name": "University of Miami",
        "hyphenated": "university-of-miami"
    },
    {
        "id": "230959",
        "name": "Middlebury College",
        "hyphenated": "middlebury-college"
    },
    {
        "id": "230764",
        "name": "University of Utah",
        "hyphenated": "university-of-utah"
    },
    {
        "id": "199847",
        "name": "Wake Forest University",
        "hyphenated": "wake-forest-university"
    },
    {
        "id": "130697",
        "name": "Wesleyan University",
        "hyphenated": "wesleyan-university"
    },
    {
        "id": "151351",
        "name": "Indiana University",
        "hyphenated": "indiana-university"
    },
    {
        "id": "129020",
        "name": "University of Connecticut",
        "hyphenated": "university-of-connecticut"
    },
    {
        "id": "167358",
        "name": "Northeastern University",
        "hyphenated": "northeastern-university"
    },
    {
        "id": "195030",
        "name": "University of Rochester",
        "hyphenated": "university-of-rochester"
    },
    {
        "id": "139959",
        "name": "University of Georgia",
        "hyphenated": "university-of-georgia"
    },
    {
        "id": "216597",
        "name": "Villanova University",
        "hyphenated": "villanova-university"
    },
    {
        "id": "168148",
        "name": "Tufts University",
        "hyphenated": "tufts-university"
    },
    {
        "id": "145600",
        "name": "University of Illinois Chicago",
        "hyphenated": "university-of-illinois-chicago"
    },
    {
        "id": "166629",
        "name": "University of Massachusetts-Amherst",
        "hyphenated": "university-of-massachusetts-amherst"
    },
    {
        "id": "194824",
        "name": "Rensselaer Polytechnic Institute",
        "hyphenated": "rensselaer-polytechnic-institute"
    },
    {
        "id": "166124",
        "name": "College of the Holy Cross",
        "hyphenated": "college-of-the-holy-cross"
    },
    {
        "id": "137351",
        "name": "University of South Florida",
        "hyphenated": "university-of-south-florida"
    },
    {
        "id": "104179",
        "name": "University of Arizona",
        "hyphenated": "university-of-arizona"
    },
    {
        "id": "133951",
        "name": "Florida International University",
        "hyphenated": "florida-international-university"
    },
    {
        "id": "152318",
        "name": "Rose-Hulman Institute of Technology",
        "hyphenated": "rose-hulman-institute-of-technology"
    },
    {
        "id": "201645",
        "name": "Case Western Reserve University",
        "hyphenated": "case-western-reserve-university"
    },
    {
        "id": "231624",
        "name": "William & Mary",
        "hyphenated": "william-&-mary"
    },
    {
        "id": "132903",
        "name": "University of Central Florida",
        "hyphenated": "university-of-central-florida"
    },
    {
        "id": "130943",
        "name": "University of Delaware",
        "hyphenated": "university-of-delaware"
    },
    {
        "id": "126775",
        "name": "Colorado School of Mines",
        "hyphenated": "colorado-school-of-mines"
    },
    {
        "id": "110671",
        "name": "University of California-Riverside",
        "hyphenated": "university-of-california-riverside"
    },
    {
        "id": "160977",
        "name": "Bates College",
        "hyphenated": "bates-college"
    },
    {
        "id": "189097",
        "name": "Barnard College",
        "hyphenated": "barnard-college"
    },
    {
        "id": "173258",
        "name": "Carleton College",
        "hyphenated": "carleton-college"
    },
    {
        "id": "160755",
        "name": "Tulane University of Louisiana",
        "hyphenated": "tulane-university-of-louisiana"
    }
];
