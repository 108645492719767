import { closeModalIcon, numberConverter, simpleCloseModalIcon } from "../../../dictionary"
import { useState, useEffect } from "react"
import { RankIndicator } from "../../../RankIndicator"
import LoadingIndicator from '../../../../../custom_assets/dca-loading-indicator.gif'

const RnDSpendModal = ({ collegeData, dataLoaded, modalTitle }) => {

  const [tableData, setTableData] = useState<any>([])
  // scroll / scrollTo post modalTitle change

  useEffect(() => {

    const createTableData = (collegeData, field) => {
      let res = collegeData
      let tableData: any = []

      res?.forEach((college) => {
        let rndAverage = college?.rnd_average ? college?.rnd_average : null
        let rndRank = college?.rnd_rank ? college?.rnd_rank : null
        // let rndAnnual = college.rnd_annual ? college.rnd_annual : null

        // const returnData = (field) => {
        //   return { name: college.name, average: rndAverage ? rndAverage[field] : null, annual0: rndAnnual[0] ? rndAnnual[0][field] : null, annual1: rndAnnual[1] ? rndAnnual[1][field] : null, annual2: rndAnnual[2] ? rndAnnual[2][field] : null, rank: rndRank ? rndRank[field] : null }
        // }

        // const returnData = (field) => {
        //   return { name: college.name, average: rndAverage ? rndAverage[field] : null, rank: rndRank ? rndRank[field] : null }
        // }

        // tableData.push(returnData(field))

        tableData?.push({ name: college?.name, average: rndAverage ? rndAverage[field] : null, rank: rndRank ? rndRank[field] : null })

      })

      tableData = tableData?.sort((a, b) => {
        if (a.rank === '-' && b.rank === '-') {
          return a.name.localeCompare(b.name);
        } else if (a.rank === '-') {
          return 1;
        } else if (b.rank === '-') {
          return -1;
        } else {
          const rankA = parseFloat(a.rank);
          const rankB = parseFloat(b.rank);
          if (rankA === rankB) {
            return a.name.localeCompare(b.name);
          }
          return rankA - rankB;
        }
      });

      return tableData

    }

    if (collegeData?.length > 0) {

      switch (true) {

        case modalTitle === 'Science':
          setTableData(createTableData(collegeData, 'science'))
          break;

        case modalTitle === 'Computer and information sciences':
          setTableData(createTableData(collegeData, 'computerInfo'))
          break;

        case modalTitle === 'Geosciences, atmospheric sciences, and ocean sciences':
          setTableData(createTableData(collegeData, 'geoscienceAtmosphericOcean'))
          break;

        case modalTitle === 'Atmospheric science and meteorology':
          setTableData(createTableData(collegeData, 'atmospheric'))
          break;

        case modalTitle === 'Geological and earth sciences':
          setTableData(createTableData(collegeData, 'geologicalEarth'))
          break;

        case modalTitle === 'Ocean sciences and marine sciences':
          setTableData(createTableData(collegeData, 'oceanMarine'))
          break;

        case modalTitle === 'Geosciences, atmospheric sciences, and ocean sciences, not elsewhere classified':
          setTableData(createTableData(collegeData, 'geoAtmoOceanNotClassfied'))
          break;

        case modalTitle === 'Life Sciences':
          setTableData(createTableData(collegeData, 'lifeSciences'))
          break;

        case modalTitle === 'Agricultural sciences':
          setTableData(createTableData(collegeData, 'agriculturalSciences'))
          break;

        case modalTitle === 'Biological and biomedical sciences':
          setTableData(createTableData(collegeData, 'biologicalBiomedical'))
          break;

        case modalTitle === 'Health sciences':
          setTableData(createTableData(collegeData, 'healthSciences'))
          break;

        case modalTitle === 'Natural resources and conservation':
          setTableData(createTableData(collegeData, 'naturalResources'))
          break;

        case modalTitle === 'Life sciences, not elsewhere classified':
          setTableData(createTableData(collegeData, 'lifeSciencesNotClassified'))
          break;

        case modalTitle === 'Math and statistics':
          setTableData(createTableData(collegeData, 'mathStatistics'))
          break;

        case modalTitle === 'Physical sciences':
          setTableData(createTableData(collegeData, 'physicalSciences'))
          break;

        case modalTitle === 'Astronomy and astrophysics':
          setTableData(createTableData(collegeData, 'astronomyAstro'))
          break;

        case modalTitle === 'Chemistry':
          setTableData(createTableData(collegeData, 'chemistry'))
          break;

        case modalTitle === 'Materials Science':
          setTableData(createTableData(collegeData, 'materialsScience'))
          break;

        case modalTitle === 'Physics':
          setTableData(createTableData(collegeData, 'physics'))
          break;

        case modalTitle === 'Physical sciences, not elsewhere classified':
          setTableData(createTableData(collegeData, 'physicalSciencesNotClassified'))
          break;

        case modalTitle === 'Psychology':
          setTableData(createTableData(collegeData, 'psychology'))
          break;

        case modalTitle === 'Social sciences':
          setTableData(createTableData(collegeData, 'socialSciences'))
          break;

        case modalTitle === 'Anthropology':
          setTableData(createTableData(collegeData, 'anthropology'))
          break;

        case modalTitle === 'Economics':
          setTableData(createTableData(collegeData, 'economics'))
          break;

        case modalTitle === 'Political science and government':
          setTableData(createTableData(collegeData, 'politicalSciGovernment'))
          break;

        case modalTitle === 'Sociology, demography, and population studies':
          setTableData(createTableData(collegeData, 'sociology'))
          break;

        case modalTitle === 'Social sciences, not elsewhere classified':
          setTableData(createTableData(collegeData, 'socialSciencesNotClassified'))
          break;

        case modalTitle === 'Sciences, not elsewhere classified':
          setTableData(createTableData(collegeData, 'sciencesNotClassified'))
          break;

        case modalTitle === 'Engineering':
          setTableData(createTableData(collegeData, 'engineering'))
          break;

        case modalTitle === 'Aerospace, aeronautical, and astronautical engineering':
          setTableData(createTableData(collegeData, 'aerospace'))
          break;

        case modalTitle === 'Bioengineering and biomedical engineering':
          setTableData(createTableData(collegeData, 'bioengineeringBiomed'))
          break;

        case modalTitle === 'Chemical engineering':
          setTableData(createTableData(collegeData, 'chemicalEng'))
          break;

        case modalTitle === 'Civil engineering':
          setTableData(createTableData(collegeData, 'civilEng'))
          break;

        case modalTitle === 'Electrical, electronic, and communications engineering':
          setTableData(createTableData(collegeData, 'electricalEng'))
          break;

        case modalTitle === 'Industrial and manufacturing engineering':
          setTableData(createTableData(collegeData, 'industrialManufEng'))
          break;

        case modalTitle === 'Mechanical engineering':
          setTableData(createTableData(collegeData, 'mechanicalEng'))
          break;

        case modalTitle === 'Metallurgical and materials engineering':
          setTableData(createTableData(collegeData, 'metallurgicalEng'))
          break;

        case modalTitle === 'Engineering not elsewhere classified':
          setTableData(createTableData(collegeData, 'engineeringNotClassified'))
          break;

        case modalTitle === 'Non-S&E':
          setTableData(createTableData(collegeData, 'nonScienceEng'))
          break;

        case modalTitle === 'Business management and business administration':
          setTableData(createTableData(collegeData, 'business'))
          break;

        case modalTitle === 'Communication and communications technologies':
          setTableData(createTableData(collegeData, 'communication'))
          break;

        case modalTitle === 'Education':
          setTableData(createTableData(collegeData, 'education'))
          break;

        case modalTitle === 'Humanities':
          setTableData(createTableData(collegeData, 'humanities'))
          break;

        case modalTitle === 'Law':
          setTableData(createTableData(collegeData, 'law'))
          break;

        case modalTitle === 'Social work':
          setTableData(createTableData(collegeData, 'socialWork'))
          break;

        case modalTitle === 'Visual and performing arts':
          setTableData(createTableData(collegeData, 'visualPerformingArts'))
          break;

        case modalTitle === 'Non-S&E, not elsewhere classified':
          setTableData(createTableData(collegeData, 'nonScienceEngNotClassified'))
          break;

        default:
          setTableData(tableData)
      }

    }

    console.log('college data', collegeData)

    console.log('tableData', tableData)

  }, [collegeData, modalTitle])

  // Table Content

  const tableContent = (
    <div>
      <table className="table table-borderless table-sm table-responsive ms-5 me-5" id='dca-mobile-modal-overflow'>
        <thead>
          <tr className="text-center">
            <th className='text-start dca-header-spacing-B' colSpan={3} scope="col">COLLEGE</th>
            <th className="dca-header-spacing-B" colSpan={3} scope="col" style={{ minWidth: 'fit-content', whiteSpace: 'nowrap', verticalAlign: 'middle' }}>3-YEAR AVG SPEND ($000s)</th>
            {/* <th className='fw-bold' colSpan={3} scope="col">{year0} SPEND ($000s)</th>
            <th className='fw-bold' colSpan={3} scope="col">{year1} SPEND ($000s)</th>
            <th className='fw-bold' colSpan={3} scope="col">{year2} SPEND ($000s)</th> */}
            <th className="dca-header-spacing-B" colSpan={3} scope="col">RANK</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((res, index) => (
            <tr key={index} className="text-center align-middle">
              <th scope='row' colSpan={3} className="text-start" style={{ minWidth: 'fit-content', whiteSpace: 'nowrap', verticalAlign: 'middle' }}>{res.name ? res.name : '-'}</th>
              <td colSpan={3} style={{ minWidth: 'fit-content', whiteSpace: 'nowrap', verticalAlign: 'middle' }}>{res.average ? `$${numberConverter(res.average)}` : '-'}</td>
              {/* <td colSpan={3}>{res.annual0 ? `$${numberConverter(res.annual0)}` : '-'}</td>
              <td colSpan={3}>{res.annual1 ? `$${numberConverter(res.annual1)}` : '-'}</td>
              <td colSpan={3}>{res.annual2 ? `$${numberConverter(res.annual2)}` : '-'}</td> */}
              <td colSpan={3} style={{ verticalAlign: 'middle' }}><div style={{ marginLeft: '8px' }}><RankIndicator rank={res.rank} modal={true} /></div></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )

  return dataLoaded && collegeData.length > 0 ? (
    <div className="modal fade" id="view_rd_spend_rankings_modal">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content dca-font-M dca-rdspend-modal">
          <div className="d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '15px' }} id='dca-rdspend-modal-content'>

            <div className="d-flex" style={{ margin: '5px 0 40px 0', fontSize: 'calc(1.26rem + 0.12vw)' }}>{modalTitle}</div>
            {closeModalIcon('')}

            {tableContent}

          </div>

        </div>
      </div>
    </div>
  ) : (
    <div className="modal fade" id="view_rd_spend_rankings_modal">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content dca-font-M dca-college-premium-mobile-modal" style={{ minWidth: '75vw', maxWidth: '75vw', minHeight: '85vh', maxHeight: '85vh', padding: '60px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', overflowY: 'auto' }}>

          <div className="d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '15px' }}>

            <div className="d-flex" style={{ margin: '5px 0 80px 0', fontSize: 'calc(1.26rem + 0.12vw)' }}>{modalTitle}</div>
            {closeModalIcon('')}


            <img src={LoadingIndicator} alt='LoadingIndicator' />
            <div className='mt-5'>Getting Colleges Data ... </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export { RnDSpendModal }