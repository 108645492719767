import { pageRoutes, sessionStorageGet } from './dictionary';
import { useNavigate, useLocation } from 'react-router'
import { getAuth, useAuth } from '../modules/auth';
import { useEffect, useState, useRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import { BiChevronDown } from 'react-icons/bi'
import { clearModal, setTestScoreStrategyModal } from '../components/Auth/setPrompts'
import { TooltipDashboard, TooltipDashboardMobile } from './Tooltip';
import avatar from '../../custom_assets/avatar.png'
import { AuthReactGAHandlers } from './ReactGAHandlers';
import arrowUpRight from '../../custom_assets/arrow-up-right-short.png'
import navHamburger from '../../custom_assets/hamburger.png'

const Header = ({ setPrompt, setNavigateTo, setUserprofileSelectedTab }) => {

  const {
    handleGAEventSignInModalOpen,
    handleGAEventSignUpModalOpen
  } = AuthReactGAHandlers()
  const { currentUser, setCurrentUser, saveAuth } = useAuth()
  let navigate = useNavigate()
  let location = useLocation()
  let apiToken: any = undefined

  const desktopDashboardRef = useRef<HTMLDivElement>(null);
  const mobileDashboardRef = useRef<HTMLDivElement>(null);

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
  }

  const [sidebarActive, setSidebarActive] = useState(false)
  const [showDropdownOverview, setShowDropdownOverview] = useState(false)
  const [showDropdownToolsResources, setShowDropdownToolsResources] = useState(false)

  const [onDashboardPage, setOnDashboardPage] = useState(false)
  const [showDashboardAlert, setShowDashboardAlert] = useState(false)

  const [desktopDashboardAlertPosition, setDesktopDashboardAlertPosition] = useState<{ top: number; left: number } | null>(null);
  const [mobileDashboardAlertPosition, setMobileDashboardAlertPosition] = useState<{ top: number; left: number } | null>(null);

  // const currentUserFalse = (
  //   <div
  //     className='d-flex align-items-stretch'
  //     style={{ marginRight: '25px', padding: '11.075px 0 11.075px 20.5px', whiteSpace: 'nowrap', maxWidth: '100%' }}
  //   // style={{ paddingLeft: '20.5px', maxWidth: '100%', margin: 'auto 25px auto 0', whiteSpace: 'nowrap' }}
  //   >
  //     <a
  //       className="dca-header-sign-in-up"
  //       data-bs-toggle="modal"
  //       href="#signup_modal"
  //       onClick={() => {
  //         clearModal(setPrompt, setNavigateTo);
  //         handleGAEventSignUpModalOpen()
  //       }}
  //     >SIGN UP</a>

  //     <div
  //       className="align-self-center dca-header-signin-signup-spacer">|</div>

  //     <a
  //       className="dca-header-sign-in-up"
  //       data-bs-toggle="modal"
  //       href="#login_modal"
  //       onClick={() => {
  //         clearModal(setPrompt, setNavigateTo);
  //         handleGAEventSignInModalOpen('')
  //       }}
  //     >SIGN IN</a>
  //   </div>
  // )

  const currentUserFalse = (
    <div id="dca-signin-signup-mobile-aligner">
      <Dropdown>
        <Dropdown.Toggle
          className='dca-header-item dca-header-signup-link'
          id="dropdown-tools-resources">
          <a
            className="dca-header-sign-in-up"
            data-bs-toggle="modal"
            href="#signup_modal"
            onClick={() => {
              clearModal(setPrompt, setNavigateTo);
              handleGAEventSignUpModalOpen()
            }}
          >SIGN UP</a>
        </Dropdown.Toggle>
      </Dropdown>

      <div className="align-self-center dca-header-signin-signup-spacer">|</div>

      <Dropdown>
        <Dropdown.Toggle
          className='dca-header-item dca-header-signin-link'
          id="dropdown-tools-resources">
          <a
            className="dca-header-sign-in-up"
            data-bs-toggle="modal"
            href="#login_modal"
            onClick={() => {
              clearModal(setPrompt, setNavigateTo);
              handleGAEventSignInModalOpen('')
            }}
          >SIGN IN</a>
        </Dropdown.Toggle>
      </Dropdown>
    </div>
  )

  const currentUserTrue = currentUser && currentUser.emailAddress && (
    <div className='topbar d-flex align-items-stretch flex-shrink-0' style={{ marginRight: '25px' }}>
      <div className='symbol symbol-30px symbol-md-40px align-self-center ms-5'>
        <img
          className='rounded-circle'
          src={avatar}
          alt='avatar'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setUserprofileSelectedTab('Profile Info');
            navigate(pageRoutes.userProfileWithId(currentUser?.user_id))
          }} />
      </div>
    </div>
  )

  const handleMouseEnterToolsResources = () => {
    if (showDropdownToolsResources == false) {
      showDropdownOverview && setShowDropdownOverview(false);
      setShowDropdownToolsResources(true)
    }
  }

  const handleMouseLeaveToolsResources = () => {
    setTimeout(() => {
      if (showDropdownToolsResources == true) {
        setShowDropdownToolsResources(false);
      }
    }, 600);
  }

  const handleClickOffcanvas = () => {
    setSidebarActive(!sidebarActive)
  }

  useEffect(() => {
    showDropdownToolsResources && setShowDropdownToolsResources(false)
    showDropdownOverview && setShowDropdownOverview(false)

    if (location.pathname == pageRoutes.dashboard) {
      setOnDashboardPage(true)
    } else if (onDashboardPage) {
      let alreadyShown = sessionStorageGet('dashboardAlertShown')
      let userSeen = currentUser?.dashboardAlertShown ?? false

      setOnDashboardPage(false);

      if (alreadyShown == null && userSeen == false) {
        setShowDashboardAlert(true)
      }
    }
  }, [location.pathname])

  useEffect(() => {
    const updatePosition = () => {
      if (desktopDashboardRef.current) {
        const rect = desktopDashboardRef.current.getBoundingClientRect();
        setDesktopDashboardAlertPosition({
          top: rect.bottom + 15,
          left: rect.left
        });
      }

      if (mobileDashboardRef.current) {
        const rect = mobileDashboardRef.current.getBoundingClientRect();
        setMobileDashboardAlertPosition({
          top: rect.bottom,
          left: rect.left
        });
      }
    };

    updatePosition();
    window.addEventListener("resize", updatePosition);

    return () => window.removeEventListener("resize", updatePosition);
  }, [desktopDashboardRef, mobileDashboardRef]);

  const sidebar = (
    <div className={`offcanvas offcanvas-start ${sidebarActive ? 'show' : ''}`} tabIndex={-1} id="offcanvas" aria-labelledby="offcanvasLabel">
      <div className="offcanvas-header dca-header-sidebar-title">
        <div
          className="offcanvas-title dca-header-sidebar-logo"
          id="offcanvasLabel"
          onClick={() => {
            setSidebarActive(!sidebarActive);
            navigate(pageRoutes.home)
          }}>
          NEXTFOUR
        </div>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => setSidebarActive(!sidebarActive)}></button>
      </div>
      <div className="offcanvas-body">

        <div className='dca-header-sidebar-navgroup'>
          <div
            className='dca-header-sidebar-navitem'
            style={{
              background: '#D5FBAB',
              marginBottom: '52.5px',
              marginLeft: '7px',
              lineHeight: 'normal',
              padding: '1px',
              maxHeight: '21px'
            }}
            onClick={() => {
              setSidebarActive(!sidebarActive);
              navigate(pageRoutes.dashboard)
            }}>My Dashboard
            <img src={arrowUpRight} alt="Arrow Up Right" className="dca-header-sidebar-arrow-up-right-mydashboard" />
          </div>
          <span className='dca-header-sidebar-item'>TOOLS & RESOURCES</span>
          <div
            className='dca-header-sidebar-navitem'
            onClick={() => {
              setSidebarActive(!sidebarActive);
              navigate(pageRoutes.overviewIntroduction)
            }}>Introduction & Advice<img src={arrowUpRight} alt="Arrow Up Right" className="dca-header-sidebar-arrow-up-right" /></div>
          <div className='dca-header-sidebar-navitem' style={{ cursor: 'default' }}>
            Colleges
          </div>
          <div
            className='dca-header-sidebar-navitem-b'
            onClick={() => {
              setSidebarActive(!sidebarActive);
              navigate(pageRoutes.collegeSearch)
            }}>Explorer <img src={arrowUpRight} alt="Arrow Up Right" className="dca-header-sidebar-arrow-up-right" /></div>
          <div
            className='dca-header-sidebar-navitem-b'
            onClick={() => {
              setSidebarActive(!sidebarActive);
              navigate(pageRoutes.collegeDataVisualization)
            }}>Scattergrams <img src={arrowUpRight} alt="Arrow Up Right" className="dca-header-sidebar-arrow-up-right" /></div>
          <div
            className='dca-header-sidebar-navitem-b'
            onClick={() => {
              setSidebarActive(!sidebarActive);
              navigate(pageRoutes.premiumSpecializedRankings)
            }}>Specialized Rankings <img src={arrowUpRight} alt="Arrow Up Right" className="dca-header-sidebar-arrow-up-right" /></div>
          <div
            className='dca-header-sidebar-navitem-b'
            onClick={() => {
              setSidebarActive(!sidebarActive);
              navigate(pageRoutes.premiumRDSpend)
            }}>R&D Spend <img src={arrowUpRight} alt="Arrow Up Right" className="dca-header-sidebar-arrow-up-right" /></div>
          <div
            className='dca-header-sidebar-navitem-b'
            onClick={() => {
              setSidebarActive(!sidebarActive);
              navigate(pageRoutes.premiumCrimeSafety)
            }}>Crime & Safety <img src={arrowUpRight} alt="Arrow Up Right" className="dca-header-sidebar-arrow-up-right" /></div>
          <div
            className='dca-header-sidebar-navitem'
            onClick={() => {
              setSidebarActive(!sidebarActive);
              navigate(pageRoutes.majorSearch)
            }}>Majors Explorer<img src={arrowUpRight} alt="Arrow Up Right" className="dca-header-sidebar-arrow-up-right" /></div>
          <div className='dca-header-sidebar-navitem' style={{ cursor: 'default' }}>Extracurriculars</div>
          <div
            className='dca-header-sidebar-navitem-b'
            onClick={() => {
              setSidebarActive(!sidebarActive);
              navigate(pageRoutes.ecSearch)
            }}>Explorer <img src={arrowUpRight} alt="Arrow Up Right" className="dca-header-sidebar-arrow-up-right" /></div>
          <div
            className='dca-header-sidebar-navitem-b'
            onClick={() => {
              setSidebarActive(!sidebarActive);
              navigate(pageRoutes.ecPremiumInsightsBase)
            }}>EC Insights <img src={arrowUpRight} alt="Arrow Up Right" className="dca-header-sidebar-arrow-up-right" /></div>
          <div
            className='dca-header-sidebar-navitem'
          ><a
            data-bs-toggle={apiToken == undefined ? "modal" : ""}
            href={apiToken != undefined ? "" : "#login_modal"}
            style={{ color: 'black' }}
            onClick={() => {
              setSidebarActive(!sidebarActive);
              setTestScoreStrategyModal(apiToken, navigate, setPrompt, setNavigateTo);

              apiToken == undefined && handleGAEventSignInModalOpen('forced')
            }}
          >Test Score Guidance <img src={arrowUpRight} alt="Arrow Up Right" className="dca-header-sidebar-arrow-up-right" /></a></div>
          <div
            className='dca-header-sidebar-navitem'
            onClick={() => {
              setSidebarActive(!sidebarActive);
              navigate(pageRoutes.advisorNetwork)
            }}>Advisors Network <img src={arrowUpRight} alt="Arrow Up Right" className="dca-header-sidebar-arrow-up-right" /></div>
          <div
            className='dca-header-sidebar-navitem'
            onClick={() => {
              setSidebarActive(!sidebarActive);
              navigate(pageRoutes.personalizedExpertHelp)
            }}>Expert Help <img src={arrowUpRight} alt="Arrow Up Right" className="dca-header-sidebar-arrow-up-right" /></div>
        </div>

        <a
          className='dca-header-sidebar-navgroup dca-header-sidebar-blog'
          href={pageRoutes.blog}
          rel="noopener noreferrer"
        >
          Blog <img src={arrowUpRight} alt="Arrow Up Right" className="dca-header-sidebar-arrow-up-right" />
        </a>

      </div>
    </div>
  )

  return (
    <div className='dca-header-wrapper'>

      <div className='d-flex d-md-none'>
        <div className='dca-header-menu-toggle d-flex d-lg-none align-items-center' title='Show header menu' ref={mobileDashboardRef} onClick={() => { handleClickOffcanvas() }}>
          <button
            className='btn btn-icon btn-active-light-primary'
            id='kt_header_menu_mobile_toggle'
          >
            <img style={{ height: '7.29px', width: '16px' }} alt='dca-hamburger-nav-icon' src={navHamburger}></img>
          </button>
        </div>

        {showDashboardAlert && mobileDashboardAlertPosition &&
          <TooltipDashboardMobile
            position={mobileDashboardAlertPosition}
            user={currentUser}
            setUser={setCurrentUser}
            apiToken={apiToken}
            show={showDashboardAlert}
            setShowDashboardAlert={setShowDashboardAlert} />}
      </div>

      <div
        className='dca-header-logo'
        onClick={() => navigate(pageRoutes.home)}>
        NEXTFOUR
      </div>

      <div className='dca-header-container'>
        <div className='dca-header-dropdowns-container'>
          <Dropdown>
            <Dropdown.Toggle
              onClick={() => navigate(pageRoutes.dashboard)}
              className='dca-header-item dca-header-my-dashboard'
              id="dropdown-tools-resources">
              <span ref={desktopDashboardRef} style={{ background: '#D5FBAB', padding: '1px' }}>MY DASHBOARD</span>
            </Dropdown.Toggle>
          </Dropdown>

          <Dropdown
            show={showDropdownToolsResources}
            onMouseEnter={handleMouseEnterToolsResources}
            onMouseLeave={handleMouseLeaveToolsResources}
          >
            <Dropdown.Toggle
              className='dca-header-item dca-header-tools-resources'
              id="dropdown-tools-resources">
              TOOLS & RESOURCES <BiChevronDown />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div className='dca-header-tools-resources-block'>

                <div className='dca-header-tools-resources-colleges-items'>
                  <Dropdown.Item
                    className='dca-group-header-clickable'
                    onClick={() => navigate(pageRoutes.overviewIntroduction)}
                    style={{ color: 'black' }}>Introduction & Advice</Dropdown.Item>
                  <Dropdown.Item
                    className='dca-header-group-header'>Colleges</Dropdown.Item>
                  <Dropdown.Item
                    className='dca-header-dropdown-item-b'
                    onClick={() => navigate(pageRoutes.collegeSearch)}
                    style={{ color: 'black' }}>Explorer</Dropdown.Item>
                  <Dropdown.Item
                    className='dca-header-dropdown-item-b'
                    onClick={() => navigate(pageRoutes.collegeDataVisualization)}
                    style={{ color: 'black' }}>Scattergrams</Dropdown.Item>
                  <Dropdown.Item
                    className='dca-header-dropdown-item-b'
                    onClick={() => navigate(pageRoutes.premiumSpecializedRankings)}
                    style={{ color: 'black' }}>Specialized Rankings</Dropdown.Item>
                  <Dropdown.Item
                    className='dca-header-dropdown-item-b'
                    onClick={() => navigate(pageRoutes.premiumRDSpend)}
                    style={{ color: 'black' }}>R&D Spend</Dropdown.Item>
                  <Dropdown.Item
                    className='dca-header-dropdown-item-b'
                    onClick={() => navigate(pageRoutes.premiumCrimeSafety)}
                    style={{ color: 'black' }}>Crime & Safety</Dropdown.Item>
                </div>

                <div className='dca-header-tools-resources-other-items'>
                  <Dropdown.Item
                    className='dca-group-header-clickable'
                    onClick={() => navigate(pageRoutes.majorSearch)}
                    style={{ color: 'black' }}>Majors Explorer</Dropdown.Item>
                  <Dropdown.Item
                    className='dca-header-group-header'>Extracurriculars</Dropdown.Item>
                  <Dropdown.Item
                    className='dca-header-dropdown-item-b'
                    onClick={() => navigate(pageRoutes.ecSearch)}
                    style={{ color: 'black' }}>Explorer</Dropdown.Item>
                  <Dropdown.Item
                    className='dca-header-dropdown-item-b'
                    onClick={() => navigate(pageRoutes.ecPremiumInsightsBase)}
                    style={{ color: 'black' }}>EC Insights</Dropdown.Item>
                  <Dropdown.Item
                    className='dca-group-header-clickable'
                    data-bs-toggle={apiToken == undefined ? "modal" : ""}
                    href={apiToken != undefined ? "" : "#login_modal"}
                    onClick={() => {
                      setTestScoreStrategyModal(apiToken, navigate, setPrompt, setNavigateTo);
                      apiToken == undefined && handleGAEventSignInModalOpen('forced')
                    }}
                    style={{ color: 'black' }}>Test Score Guidance</Dropdown.Item>
                  <Dropdown.Item
                    className='dca-group-header-clickable'
                    onClick={() => navigate(pageRoutes.advisorNetwork)}
                    style={{ color: 'black' }}>Advisors Network</Dropdown.Item>
                  <Dropdown.Item
                    className='dca-group-header-clickable'
                    onClick={() => navigate(pageRoutes.personalizedExpertHelp)}
                    style={{ color: 'black' }}>Expert Help</Dropdown.Item>
                </div>

              </div>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown>
            <Dropdown.Toggle
              className='dca-header-item dca-header-blog'
              id="dropdown-tools-resources">
              <a
                href={pageRoutes.blog}
                rel="noopener noreferrer"
                style={{ color: 'black', cursor: 'pointer' }}>BLOG</a>
            </Dropdown.Toggle>
          </Dropdown>
        </div>

        {showDashboardAlert && desktopDashboardAlertPosition && <TooltipDashboard
          position={desktopDashboardAlertPosition}
          user={currentUser}
          setUser={setCurrentUser}
          apiToken={apiToken}
          show={showDashboardAlert}
          setShowDashboardAlert={setShowDashboardAlert} />}
      </div>

      {currentUser && currentUser.emailAddress ? currentUserTrue : currentUserFalse}

      <div className='dca-search-aside'>
        {sidebar}
      </div>

    </div>
  )
}

export { Header }