import Select from 'react-select'
import { useState, useEffect } from "react";
import { matchSorter } from 'match-sorter'

export const MultiSelect = ({ options, value, onChange }) => {

  // multiselect under wizard
  // .dca-dashboard-wizard-form-wrapper .css-b62m3t-container {
  //   width: 563px;
  //   max-width: 90%;
  //   margin: 0 auto;
  //   align-self: center;
  // }

  return <Select
    options={options}
    styles={{
      control: (baseStyles, state) => ({
        ...baseStyles,
        border: 'transparent',
        backgroundColor: '#F9F9F9',
        borderRadius: '13px'
      }),
      menu: (baseStyles) => ({
        ...baseStyles,
        zIndex: 100
      })
    }}
    placeholder={false}
    isMulti
    value={value}
    onChange={onChange}
  />
}

export const MatchSortSelect = ({ options, value, onChange }) => {

  // Updated from now closed feature request. Latest version of react select doesn't allow return of the options in a sorted/filtered order. Now filterOption just returns whether the option itself should be shown or not. Create similar functionality from last shared component here:
  // https://github.com/JedWatson/react-select/issues/2677

  const [sortedOptions, setSortedOptions] = useState<any>(options)

  useEffect(() => {
    setSortedOptions(options);
  }, [options]);

  const onInputChange = (value, { action }) => {
    if (action === 'input-change') {
      let filteredOptions = matchSorter(options, value, { keys: ['label'] });
      setSortedOptions(filteredOptions);

    } else if (action === 'menu-close') {
      setSortedOptions(options);
    }
  }

  return <Select
    options={sortedOptions}
    onInputChange={onInputChange}
    styles={{
      control: (baseStyles, state) => ({
        ...baseStyles,
        border: 'transparent',
        backgroundColor: '#F9F9F9'
      }),
      menu: (baseStyles) => ({
        ...baseStyles,
        zIndex: 100
      })
    }}
    isMulti
    value={value}
    onChange={onChange}
  />
}