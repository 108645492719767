import { useNavigate } from "react-router"
import { getAuth } from "../modules/auth"
import { pageRoutes } from "./dictionary"
import { setTestScoreStrategyModal } from "./Auth/setPrompts"
import { AuthReactGAHandlers } from "./ReactGAHandlers"

const Footer = ({ setPrompt, setNavigateTo }) => {

  const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()

  let apiToken: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
  }

  let navigate = useNavigate()

  const toolsResources = (
    <div id="dca-footer-tools-resources-wrapper">
      <div className="dca-footer-group-title">TOOLS & RESOURCES</div>
      <div className="dca-footer-tools-resources-links">
        <div className="dca-footer-link-column">
          <div
            className="dca-footer-link"
            onClick={() => {
              navigate(pageRoutes.dashboard)
            }}
          >Dashboard</div>

          <div
            className="dca-footer-link"
            onClick={() => {
              navigate(pageRoutes.overviewIntroduction)
            }}
          >Introduction & Advice</div>

          <div className="dca-footer-link-group-header">Colleges</div>

          <div
            className="dca-footer-sublink"
            onClick={() => {
              navigate(pageRoutes.collegeSearch)
            }}>Explorer</div>

          <div
            className="dca-footer-sublink"
            onClick={() => {
              navigate(pageRoutes.collegeDataVisualization)
            }}
          >Scattergrams</div>

          <div
            className="dca-footer-sublink"
            onClick={() => {
              navigate(pageRoutes.premiumSpecializedRankings)
            }}
          >Specialized Rankings</div>

          <div
            className="dca-footer-sublink"
            onClick={() => {
              navigate(pageRoutes.premiumRDSpend)
            }}
          >R&D Spend</div>

          <div
            className="dca-footer-sublink"
            onClick={() => {
              navigate(pageRoutes.premiumCrimeSafety)
            }}
          >Crime & Safety</div>
        </div>

        <div className="dca-footer-link-column">
          <div
            className="dca-footer-link"
            onClick={() => {
              navigate(pageRoutes.majorSearch)
            }}
          >Majors Explorer</div>

          <div className="dca-footer-link-group-header"
          >Extracurriculars</div>

          <div
            className="dca-footer-sublink"
            onClick={() => {
              navigate(pageRoutes.ecSearch)
            }}
          >Explorer</div>

          <div
            className="dca-footer-sublink"
            onClick={() => {
              navigate(pageRoutes.ecPremiumInsightsBase)
            }}
          >EC Insights</div>

          {(apiToken != undefined) ? <div
            className="dca-footer-link"
            onClick={() => {
              navigate(pageRoutes.testScoreStrategy)
            }}
          >Test Score Guidance</div> : <a
            className="dca-footer-link"
            style={{ color: 'black' }}
            data-bs-toggle={"modal"}
            href={"#login_modal"}
            onClick={() => {
              setTestScoreStrategyModal(apiToken, navigate, setPrompt, setNavigateTo);
              apiToken == undefined && handleGAEventSignInModalOpen('forced')
            }}
          >Test Score Guidance</a>}

          <div
            className="dca-footer-link"
            onClick={() => {
              navigate(pageRoutes.advisorNetwork)
            }}
          >Advisors Network</div>

          <div
            className="dca-footer-link"
            onClick={() => {
              navigate(pageRoutes.personalizedExpertHelp)
            }}
          >Expert Help</div>
        </div>
      </div>
    </div>
  )

  const company = (
    <div id="dca-footer-company-wrapper">
      <div className="dca-footer-group-title">COMPANY</div>
      <div className="dca-footer-company-links">
        <div className="d-flex flex-column">
          <div
            className="dca-footer-link"
            onClick={() => {
              navigate(pageRoutes.about)
            }}
          >About</div>

          <a
            className="dca-footer-link"
            href={pageRoutes.blog}
          >Blog</a>

          <div
            className="dca-footer-link"
            onClick={() => {
              navigate(pageRoutes.faq)
            }}
          >FAQ</div>

          <div
            className="dca-footer-link"
            onClick={() => {
              navigate(pageRoutes.contact)
            }}
          >Contact</div>

          <div
            className="dca-footer-link"
            onClick={() => {
              navigate(pageRoutes.privacyPolicy)
            }}
          >Privacy Policy</div>

          <div
            className="dca-footer-link"
            onClick={() => {
              navigate(pageRoutes.termsOfService)
            }}
          >Terms</div>
        </div>
      </div>
    </div>
  )

  return (
    <div className="dca-footer-wrapper">

      <div className="dca-footer-links-wrapper">
        {toolsResources}
        {company}
      </div>

      <div className="dca-footer-copyright">{new Date().getFullYear()} &copy; NEXTFOUR.AI</div>
    </div>
  )
}

export { Footer }