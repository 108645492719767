import { currentYear } from "../../dictionary"
import { collegeMap } from "../../collegeMap"

export const sentence1_majors0 = `Consider reviewing the topical Insights linked below. Then start exploring majors and select potential intended major(s) to help anchor your application strategy.`

export const sentence1_majors1 = `Good start, but please consider identifying additional major(s) especially if you plan on indicating multiple intended majors on your applications (recommended).`

export const sentence1_majors2 = `Nice work identifying some prospective majors, please continue to update and finalize by senior year.`

export const sentence2_majors2 = (college1, college2 = undefined) => {
  return `Please ensure you have identified majors for all the colleges you will be applying to (i.e. ${college2 ? `${college1} and ${college2} don't` : `${college1} doesn't`} offer the majors saved thus far.)`
}

export const sentence3_majors2 = `Considering the popularity of your selected majors, you way wish to consider some adjacent but less popular majors from a strategic perspective.`

export const sentence4_majors2 = `You should consider engaging with Advisors majoring in your fields of interest to help confirm your choices and prepare for applications.`

export const returnProgressTrackerText = (userMajors, majorData, collegeData, programData, advisors, userCollegeInterest, gradYr) => {
  let capValue = 4
  let len = userMajors?.length ?? undefined
  let yearDif: any = gradYr ? gradYr - currentYear : undefined
  let concat: any = []

  if (len === 0) {
    return sentence1_majors0
  }

  else if (len === 1) {
    return sentence1_majors1
  }

  else if (len >= 2) {
    concat.push(sentence1_majors2);

    // Initializing an empty array to hold the colleges in the userCollegeInterest array that have some programData
    let userCollegesWithProgramData: any = []
    if (userCollegeInterest?.length > 0 && programData?.length > 0) {
      userCollegeInterest.map((college) => {
        let found = programData.find((program) => parseInt(program.college_id) === parseInt(college.college_id))
        if (found) {
          userCollegesWithProgramData.push(college)
        }
      })
    }

    // If there are items in the userCollegesWithProgramData, check to see if every college object has a program in programData where the college_id matches and some major id in userMajors is the program's major_id
    const collegesWithProgramDataFoundMatchingMajor = () => {
      if (userCollegesWithProgramData.length > 0 && userMajors?.length > 0) {
        // Returns true if for every user saved college with program data, there is a program that has a college_id matching the college_id and the program's major_id matches some major id in the userMajors array
        let check = userCollegesWithProgramData?.every(college => userMajors.some(majorID => programData?.find(program => parseInt(program.major_id) === majorID && parseInt(program.college_id) === parseInt(college.college_id))))

        // If it is true, we want to skip the concat push block, so we set to false. Otherwise, if false, that is our trigger to add the sentence2_majors2
        return !check
      }

      // By default, skip the concat push since the user may not have saved colleges or majors
      return false
    }

    if (collegeData?.length > 0 && collegesWithProgramDataFoundMatchingMajor()) {

      let missingProgramColleges: any = []

      missingProgramColleges = userCollegesWithProgramData?.filter(college =>
        !userMajors.some(majorID =>
          programData.some(program =>
            parseInt(program.major_id) === majorID && parseInt(program.college_id) === college)));

      const metaRankMap = programData.reduce((map, item) => {
        map[item.college_id] = item.metaRank;
        return map;
      }, {} as Record<number, number>);

      missingProgramColleges.sort((a, b) => {
        const rankA = metaRankMap[a.college_id] || Infinity;
        const rankB = metaRankMap[b.college_id] || Infinity;
        return rankA - rankB;
      });

      let sortedCollege1 = missingProgramColleges[0] ? collegeData.find((college) => college.id == missingProgramColleges[0].college_id)?.id : undefined
      let sortedCollege2 = missingProgramColleges[1] ? collegeData.find((college) => college.id == missingProgramColleges[1].college_id)?.id : undefined

      sortedCollege1 = collegeMap.find(college => college.id === `${sortedCollege1}`)?.name ?? undefined
      sortedCollege2 = collegeMap.find(college => college.id === `${sortedCollege2}`)?.name ?? undefined

      concat.push(sentence2_majors2(sortedCollege1, sortedCollege2))
    }

    // if all user majors have a pop index >= 8
    if (userMajors?.length > 0 && userMajors?.every((major) => majorData.find((majorObj) => majorObj.id == major)?.popularityIndex >= 8)) {
      concat.push(sentence3_majors2)
    }

    // if at least one user major contains the major id within the advisors major_id array(s)
    if (advisors?.some((advisor) =>
      advisor.major_id?.some((major) => userMajors?.includes(major))) && [0, 1, 2].includes(yearDif)) {
      concat.push(sentence4_majors2)
    }

    // if multiple sentences, join with single space
    if (concat?.length > 0) {
      concat = concat.join(" ")
    }

    // otherwise set concat to the string within the single item array
    else {
      concat = concat[0]
    }

    return concat
  }

  // default
  return sentence1_majors0

}