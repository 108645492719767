export const extracurricularMap = [
    {
        "id": "1",
        "name": "AAPT High School Physics Photo Contest",
        "hyphenated": "aapt-high-school-physics-photo-contest"
    },
    {
        "id": "2",
        "name": "Academic Decathlon National Championship",
        "hyphenated": "academic-decathlon-national-championship"
    },
    {
        "id": "3",
        "name": "Academic Talent Development Program at UC Berkeley",
        "hyphenated": "academic-talent-development-program-at-uc-berkeley"
    },
    {
        "id": "4",
        "name": "Adler Planetarium Summer High School Internship",
        "hyphenated": "adler-planetarium-summer-high-school-internship"
    },
    {
        "id": "5",
        "name": "Advocate For An Important Cause",
        "hyphenated": "advocate-for-an-important-cause"
    },
    {
        "id": "6",
        "name": "Aerospace Robotics Competition",
        "hyphenated": "aerospace-robotics-competition"
    },
    {
        "id": "7",
        "name": "AI Adventurers",
        "hyphenated": "ai-adventurers"
    },
    {
        "id": "8",
        "name": "AI Creators",
        "hyphenated": "ai-creators"
    },
    {
        "id": "9",
        "name": "AI Pioneers",
        "hyphenated": "ai-pioneers"
    },
    {
        "id": "10",
        "name": "AI Scholars Live Online",
        "hyphenated": "ai-scholars-live-online"
    },
    {
        "id": "11",
        "name": "Air Force Research Laboratory (AFRL) Scholars Program",
        "hyphenated": "air-force-research-laboratory-(afrl)-scholars-program"
    },
    {
        "id": "12",
        "name": "American Computer Science League",
        "hyphenated": "american-computer-science-league"
    },
    {
        "id": "13",
        "name": "American Foreign Service Association Essay Contest",
        "hyphenated": "american-foreign-service-association-essay-contest"
    },
    {
        "id": "14",
        "name": "American Invitational Mathematics Examination (AIME)",
        "hyphenated": "american-invitational-mathematics-examination-(aime)"
    },
    {
        "id": "15",
        "name": "American Legion Auxiliary Scholarships",
        "hyphenated": "american-legion-auxiliary-scholarships"
    },
    {
        "id": "16",
        "name": "American Regions Mathematics League",
        "hyphenated": "american-regions-mathematics-league"
    },
    {
        "id": "17",
        "name": "American Rocketry Challenge",
        "hyphenated": "american-rocketry-challenge"
    },
    {
        "id": "18",
        "name": "Annenberg Youth Academy for Media and Civic Engagement",
        "hyphenated": "annenberg-youth-academy-for-media-and-civic-engagement"
    },
    {
        "id": "19",
        "name": "Apprentice Writer",
        "hyphenated": "apprentice-writer"
    },
    {
        "id": "20",
        "name": "Architecture at Zero Competition",
        "hyphenated": "architecture-at-zero-competition"
    },
    {
        "id": "21",
        "name": "ARISE UWCCC Summer High School Cancer Research Internship Program",
        "hyphenated": "arise-uwccc-summer-high-school-cancer-research-internship-program"
    },
    {
        "id": "22",
        "name": "arXiv",
        "hyphenated": "arxiv"
    },
    {
        "id": "23",
        "name": "ASA Statistics Project Competition",
        "hyphenated": "asa-statistics-project-competition"
    },
    {
        "id": "24",
        "name": "ASHG Annual DNA Day Essay Contest",
        "hyphenated": "ashg-annual-dna-day-essay-contest"
    },
    {
        "id": "25",
        "name": "Aspiring Scholars Directed Research Program (ASDRP)",
        "hyphenated": "aspiring-scholars-directed-research-program-(asdrp)"
    },
    {
        "id": "26",
        "name": "Aspiring Scientists Summer Internship Program (ASSIP)",
        "hyphenated": "aspiring-scientists-summer-internship-program-(assip)"
    },
    {
        "id": "27",
        "name": "Aspiring Young Journalist",
        "hyphenated": "aspiring-young-journalist"
    },
    {
        "id": "28",
        "name": "Aspirnaut Summer Research Internships",
        "hyphenated": "aspirnaut-summer-research-internships"
    },
    {
        "id": "29",
        "name": "Audubon Photography Awards",
        "hyphenated": "audubon-photography-awards"
    },
    {
        "id": "30",
        "name": "AwesomeMath Academy",
        "hyphenated": "awesomemath-academy"
    },
    {
        "id": "31",
        "name": "AwesomeMath Summer Program",
        "hyphenated": "awesomemath-summer-program"
    },
    {
        "id": "32",
        "name": "Ayn Rand Essay Contest",
        "hyphenated": "ayn-rand-essay-contest"
    },
    {
        "id": "33",
        "name": "Bank of America Student Leaders",
        "hyphenated": "bank-of-america-student-leaders"
    },
    {
        "id": "34",
        "name": "Bard College at Simon's Rock Young Writers Workshop",
        "hyphenated": "bard-college-at-simon's-rock-young-writers-workshop"
    },
    {
        "id": "35",
        "name": "Beaver Works Summer Institute (BWSI)",
        "hyphenated": "beaver-works-summer-institute-(bwsi)"
    },
    {
        "id": "36",
        "name": "Bennington Young Writers Awards",
        "hyphenated": "bennington-young-writers-awards"
    },
    {
        "id": "37",
        "name": "Berkeley Business Academy for Youth High School Entrepreneurship Program",
        "hyphenated": "berkeley-business-academy-for-youth-high-school-entrepreneurship-program"
    },
    {
        "id": "38",
        "name": "Berkeley Math Circle",
        "hyphenated": "berkeley-math-circle"
    },
    {
        "id": "39",
        "name": "Best Robotics Competition",
        "hyphenated": "best-robotics-competition"
    },
    {
        "id": "40",
        "name": "Bill of Rights Institute Student Fellowship",
        "hyphenated": "bill-of-rights-institute-student-fellowship"
    },
    {
        "id": "41",
        "name": "BioGENEius Challenge",
        "hyphenated": "biogeneius-challenge"
    },
    {
        "id": "42",
        "name": "Biophysics Research for Baltimore Teens",
        "hyphenated": "biophysics-research-for-baltimore-teens"
    },
    {
        "id": "43",
        "name": "bioRxiv",
        "hyphenated": "biorxiv"
    },
    {
        "id": "44",
        "name": "Blue Ocean Competition",
        "hyphenated": "blue-ocean-competition"
    },
    {
        "id": "45",
        "name": "Boston College Experience",
        "hyphenated": "boston-college-experience"
    },
    {
        "id": "46",
        "name": "Boston Leadership Institute",
        "hyphenated": "boston-leadership-institute"
    },
    {
        "id": "47",
        "name": "Boston University High School Honors",
        "hyphenated": "boston-university-high-school-honors"
    },
    {
        "id": "48",
        "name": "Boston University Research in Science & Engineering Program (RISE)",
        "hyphenated": "boston-university-research-in-science-&-engineering-program-(rise)"
    },
    {
        "id": "49",
        "name": "Boston University Summer Journalism Academy On Campus",
        "hyphenated": "boston-university-summer-journalism-academy-on-campus"
    },
    {
        "id": "50",
        "name": "Boston University Summer Journalism Academy Online",
        "hyphenated": "boston-university-summer-journalism-academy-online"
    },
    {
        "id": "51",
        "name": "Boston University Tanglewood Institute",
        "hyphenated": "boston-university-tanglewood-institute"
    },
    {
        "id": "52",
        "name": "Boys State and Nation",
        "hyphenated": "boys-state-and-nation"
    },
    {
        "id": "53",
        "name": "Boyce Thompson Institute High School Summer Research Internship",
        "hyphenated": "boyce-thompson-institute-high-school-summer-research-internship"
    },
    {
        "id": "54",
        "name": "Boys and Girls Club",
        "hyphenated": "boys-and-girls-club"
    },
    {
        "id": "55",
        "name": "Girls State and Nation",
        "hyphenated": "girls-state-and-nation"
    },
    {
        "id": "56",
        "name": "Broad Summer Scholars Program (BSSP)",
        "hyphenated": "broad-summer-scholars-program-(bssp)"
    },
    {
        "id": "57",
        "name": "Brown Environmental Leadership Lab (BELL)",
        "hyphenated": "brown-environmental-leadership-lab-(bell)"
    },
    {
        "id": "58",
        "name": "Brown Experiential Education",
        "hyphenated": "brown-experiential-education"
    },
    {
        "id": "59",
        "name": "Brown Leadership Institute",
        "hyphenated": "brown-leadership-institute"
    },
    {
        "id": "60",
        "name": "Brown Pre-Baccalaureate",
        "hyphenated": "brown-pre-baccalaureate"
    },
    {
        "id": "61",
        "name": "Brown STEM for Rising 9th and 10th Graders",
        "hyphenated": "brown-stem-for-rising-9th-and-10th-graders"
    },
    {
        "id": "62",
        "name": "Business is Global: Summer Language Program",
        "hyphenated": "business-is-global:-summer-language-program"
    },
    {
        "id": "63",
        "name": "Business Opportunities Summer Session (BOSS)",
        "hyphenated": "business-opportunities-summer-session-(boss)"
    },
    {
        "id": "64",
        "name": "California State Summer School for Mathematics & Science (COSMOS)",
        "hyphenated": "california-state-summer-school-for-mathematics-&-science-(cosmos)"
    },
    {
        "id": "65",
        "name": "California State Summer School for the Arts Summer Program",
        "hyphenated": "california-state-summer-school-for-the-arts-summer-program"
    },
    {
        "id": "66",
        "name": "Caltech Da Vinci Camp",
        "hyphenated": "caltech-da-vinci-camp"
    },
    {
        "id": "67",
        "name": "Caltech Summer Research Connection",
        "hyphenated": "caltech-summer-research-connection"
    },
    {
        "id": "68",
        "name": "Cambridge 1-on-1 Research Mentorship Programme",
        "hyphenated": "cambridge-1-on-1-research-mentorship-programme"
    },
    {
        "id": "69",
        "name": "Cambridge Future Scholar Program",
        "hyphenated": "cambridge-future-scholar-program"
    },
    {
        "id": "70",
        "name": "Cameron Impact Scholarship",
        "hyphenated": "cameron-impact-scholarship"
    },
    {
        "id": "71",
        "name": "Caminos al Futuro",
        "hyphenated": "caminos-al-futuro"
    },
    {
        "id": "72",
        "name": "Camp Cronkite",
        "hyphenated": "camp-cronkite"
    },
    {
        "id": "73",
        "name": "Canada/USA Mathcamp",
        "hyphenated": "canada/usa-mathcamp"
    },
    {
        "id": "74",
        "name": "Capitol Debate Summer Programs",
        "hyphenated": "capitol-debate-summer-programs"
    },
    {
        "id": "75",
        "name": "Cardiothoracic Surgical Skills and Education Center's Stanford Summer Internship",
        "hyphenated": "cardiothoracic-surgical-skills-and-education-center's-stanford-summer-internship"
    },
    {
        "id": "76",
        "name": "Carl Sandburg Student Poetry Contest",
        "hyphenated": "carl-sandburg-student-poetry-contest"
    },
    {
        "id": "77",
        "name": "Carnegie Mellon AI Scholars",
        "hyphenated": "carnegie-mellon-ai-scholars"
    },
    {
        "id": "78",
        "name": "Carnegie Mellon CS Scholars",
        "hyphenated": "carnegie-mellon-cs-scholars"
    },
    {
        "id": "79",
        "name": "Carnegie Mellon Pre-College Architecture",
        "hyphenated": "carnegie-mellon-pre-college-architecture"
    },
    {
        "id": "80",
        "name": "Carnegie Mellon Pre-College Art",
        "hyphenated": "carnegie-mellon-pre-college-art"
    },
    {
        "id": "81",
        "name": "Carnegie Mellon Pre-College Computational Biology",
        "hyphenated": "carnegie-mellon-pre-college-computational-biology"
    },
    {
        "id": "82",
        "name": "Carnegie Mellon Pre-College Design",
        "hyphenated": "carnegie-mellon-pre-college-design"
    },
    {
        "id": "83",
        "name": "Carnegie Mellon Pre-College Drama",
        "hyphenated": "carnegie-mellon-pre-college-drama"
    },
    {
        "id": "84",
        "name": "Carnegie Mellon Pre-College Music",
        "hyphenated": "carnegie-mellon-pre-college-music"
    },
    {
        "id": "85",
        "name": "Carnegie Mellon Pre-College Writing & Culture",
        "hyphenated": "carnegie-mellon-pre-college-writing-&-culture"
    },
    {
        "id": "86",
        "name": "Carnegie Mellon Summer Session",
        "hyphenated": "carnegie-mellon-summer-session"
    },
    {
        "id": "87",
        "name": "Carnegie Museums Internships",
        "hyphenated": "carnegie-museums-internships"
    },
    {
        "id": "88",
        "name": "Caroline D. Bradley Scholarship",
        "hyphenated": "caroline-d.-bradley-scholarship"
    },
    {
        "id": "89",
        "name": "Civic Innovation Academy: Georgetown",
        "hyphenated": "civic-innovation-academy:-georgetown"
    },
    {
        "id": "90",
        "name": "Civic Innovation Academy: UCLA",
        "hyphenated": "civic-innovation-academy:-ucla"
    },
    {
        "id": "91",
        "name": "Civic Innovators Fellowship",
        "hyphenated": "civic-innovators-fellowship"
    },
    {
        "id": "92",
        "name": "Climate Science Olympiad",
        "hyphenated": "climate-science-olympiad"
    },
    {
        "id": "93",
        "name": "Clinical Anatomy Summer Program",
        "hyphenated": "clinical-anatomy-summer-program"
    },
    {
        "id": "94",
        "name": "Clinical Neuroscience Immersion Experience",
        "hyphenated": "clinical-neuroscience-immersion-experience"
    },
    {
        "id": "95",
        "name": "Columbia College Edge Summer Program",
        "hyphenated": "columbia-college-edge-summer-program"
    },
    {
        "id": "96",
        "name": "Columbia College Young Authors Writing Competition",
        "hyphenated": "columbia-college-young-authors-writing-competition"
    },
    {
        "id": "97",
        "name": "Columbia Engineering ENG (Engineering the Next Generation) Program",
        "hyphenated": "columbia-engineering-eng-(engineering-the-next-generation)-program"
    },
    {
        "id": "98",
        "name": "Columbia Engineering SHAPE Program",
        "hyphenated": "columbia-engineering-shape-program"
    },
    {
        "id": "99",
        "name": "Columbia Junior Science Research Journal",
        "hyphenated": "columbia-junior-science-research-journal"
    },
    {
        "id": "100",
        "name": "Columbia NYC Commuter Summer",
        "hyphenated": "columbia-nyc-commuter-summer"
    },
    {
        "id": "101",
        "name": "Columbia NYC Residential Summer",
        "hyphenated": "columbia-nyc-residential-summer"
    },
    {
        "id": "102",
        "name": "Columbia Online Summer",
        "hyphenated": "columbia-online-summer"
    },
    {
        "id": "103",
        "name": "Columbia Scholastic Press Awards - Gold Circle Awards",
        "hyphenated": "columbia-scholastic-press-awards-gold-circle-awards"
    },
    {
        "id": "104",
        "name": "Columbia Summer Journalism Workshop",
        "hyphenated": "columbia-summer-journalism-workshop"
    },
    {
        "id": "105",
        "name": "Columbia University Model United Nations Conference and Exposition",
        "hyphenated": "columbia-university-model-united-nations-conference-and-exposition"
    },
    {
        "id": "106",
        "name": "Columbia University Science Honors Program (SHP)",
        "hyphenated": "columbia-university-science-honors-program-(shp)"
    },
    {
        "id": "107",
        "name": "Community High School Program",
        "hyphenated": "community-high-school-program"
    },
    {
        "id": "108",
        "name": "Concord Review",
        "hyphenated": "concord-review"
    },
    {
        "id": "109",
        "name": "Concord Review - Emerson Prize",
        "hyphenated": "concord-review-emerson-prize"
    },
    {
        "id": "110",
        "name": "Concord Review In-Person Summer Program",
        "hyphenated": "concord-review-in-person-summer-program"
    },
    {
        "id": "111",
        "name": "Concord Review Summer Online Program",
        "hyphenated": "concord-review-summer-online-program"
    },
    {
        "id": "112",
        "name": "Congressional App Challenge",
        "hyphenated": "congressional-app-challenge"
    },
    {
        "id": "113",
        "name": "Congressional Award",
        "hyphenated": "congressional-award"
    },
    {
        "id": "114",
        "name": "Continuing Umbrella of Research Services",
        "hyphenated": "continuing-umbrella-of-research-services"
    },
    {
        "id": "115",
        "name": "Coolidge Scholarship",
        "hyphenated": "coolidge-scholarship"
    },
    {
        "id": "116",
        "name": "Coolidge Senators Program",
        "hyphenated": "coolidge-senators-program"
    },
    {
        "id": "117",
        "name": "Coriell Summer Experience for Students",
        "hyphenated": "coriell-summer-experience-for-students"
    },
    {
        "id": "118",
        "name": "Cornell Art as Experience Summer Program",
        "hyphenated": "cornell-art-as-experience-summer-program"
    },
    {
        "id": "119",
        "name": "Cornell CATALYST Academy",
        "hyphenated": "cornell-catalyst-academy"
    },
    {
        "id": "120",
        "name": "Cornell International Summer Debate Program",
        "hyphenated": "cornell-international-summer-debate-program"
    },
    {
        "id": "121",
        "name": "Cornell SCE Precollege Studies ",
        "hyphenated": "cornell-sce-precollege-studies"
    },
    {
        "id": "122",
        "name": "Critical Debates in Humanities, Science and Global Justice",
        "hyphenated": "critical-debates-in-humanities-science-and-global-justice"
    },
    {
        "id": "123",
        "name": "CrowdMath",
        "hyphenated": "crowdmath"
    },
    {
        "id": "124",
        "name": "Curieux Academic Journal",
        "hyphenated": "curieux-academic-journal"
    },
    {
        "id": "125",
        "name": "Dartmouth Health High School Summer Internship Program",
        "hyphenated": "dartmouth-health-high-school-summer-internship-program"
    },
    {
        "id": "126",
        "name": "Dartmouth Model UN",
        "hyphenated": "dartmouth-model-un"
    },
    {
        "id": "127",
        "name": "Dartmouth Summer Scholars Program",
        "hyphenated": "dartmouth-summer-scholars-program"
    },
    {
        "id": "128",
        "name": "DIYA Research AI Insights",
        "hyphenated": "diya-research-ai-insights"
    },
    {
        "id": "129",
        "name": "Data Science Young Analysts Exploration Program",
        "hyphenated": "data-science-young-analysts-exploration-program"
    },
    {
        "id": "130",
        "name": "DIYA High School Data Science Contest",
        "hyphenated": "diya-high-school-data-science-contest"
    },
    {
        "id": "131",
        "name": "Davidson Institute Awards - Davidson Fellow",
        "hyphenated": "davidson-institute-awards-davidson-fellow"
    },
    {
        "id": "132",
        "name": "Davidson THINK",
        "hyphenated": "davidson-think"
    },
    {
        "id": "133",
        "name": "DECA",
        "hyphenated": "deca"
    },
    {
        "id": "134",
        "name": "Department of Education Internships",
        "hyphenated": "department-of-education-internships"
    },
    {
        "id": "135",
        "name": "Diamond Challenge",
        "hyphenated": "diamond-challenge"
    },
    {
        "id": "136",
        "name": "Digital Citizen Fellowship",
        "hyphenated": "digital-citizen-fellowship"
    },
    {
        "id": "137",
        "name": "Diversity In Vision Research and Ophthalmology Summer Internship Program",
        "hyphenated": "diversity-in-vision-research-and-ophthalmology-summer-internship-program"
    },
    {
        "id": "138",
        "name": "Duke Pre-College Commuter Program",
        "hyphenated": "duke-pre-college-commuter-program"
    },
    {
        "id": "139",
        "name": "Duke Pre-College Online Program",
        "hyphenated": "duke-pre-college-online-program"
    },
    {
        "id": "140",
        "name": "Duke Pre-College On-Campus Courses",
        "hyphenated": "duke-pre-college-on-campus-courses"
    },
    {
        "id": "141",
        "name": "Duke Summer Session",
        "hyphenated": "duke-summer-session"
    },
    {
        "id": "142",
        "name": "Duke University Marine Lab",
        "hyphenated": "duke-university-marine-lab"
    },
    {
        "id": "143",
        "name": "Duke University Math Meet",
        "hyphenated": "duke-university-math-meet"
    },
    {
        "id": "144",
        "name": "Economics for Leaders (EFL)",
        "hyphenated": "economics-for-leaders-(efl)"
    },
    {
        "id": "145",
        "name": "Economics for Leaders Virtual",
        "hyphenated": "economics-for-leaders-virtual"
    },
    {
        "id": "146",
        "name": "Emory Debate Institutes",
        "hyphenated": "emory-debate-institutes"
    },
    {
        "id": "147",
        "name": "Emory Math Circle",
        "hyphenated": "emory-math-circle"
    },
    {
        "id": "148",
        "name": "Emory National Debate Institute Policy Debate Institute",
        "hyphenated": "emory-national-debate-institute-policy-debate-institute"
    },
    {
        "id": "149",
        "name": "Emory Summer Scholars Research Program",
        "hyphenated": "emory-summer-scholars-research-program"
    },
    {
        "id": "150",
        "name": "Emory Summer Science Academy",
        "hyphenated": "emory-summer-science-academy"
    },
    {
        "id": "151",
        "name": "Emory University\u2019s Youth Theological Initiative Summer Academy",
        "hyphenated": "emory-university\u2019s-youth-theological-initiative-summer-academy"
    },
    {
        "id": "152",
        "name": "Empire Mock Trial Competition",
        "hyphenated": "empire-mock-trial-competition"
    },
    {
        "id": "153",
        "name": "Engage In Research With A College Professor",
        "hyphenated": "engage-in-research-with-a-college-professor"
    },
    {
        "id": "154",
        "name": "Engineering Science and Technology to Energize and Expand Young Minds Research Mentoring Program",
        "hyphenated": "engineering-science-and-technology-to-energize-and-expand-young-minds-research-mentoring-program"
    },
    {
        "id": "155",
        "name": "Engineering Summer Academy at Penn",
        "hyphenated": "engineering-summer-academy-at-penn"
    },
    {
        "id": "156",
        "name": "Entrepreneurship in the Global Economy",
        "hyphenated": "entrepreneurship-in-the-global-economy"
    },
    {
        "id": "157",
        "name": "Environment & the Economy",
        "hyphenated": "environment-&-the-economy"
    },
    {
        "id": "158",
        "name": "Epsilon Camp",
        "hyphenated": "epsilon-camp"
    },
    {
        "id": "159",
        "name": "Equitable Excellence Scholarship",
        "hyphenated": "equitable-excellence-scholarship"
    },
    {
        "id": "160",
        "name": "Essentials of Leadership",
        "hyphenated": "essentials-of-leadership"
    },
    {
        "id": "161",
        "name": "Euler Circle",
        "hyphenated": "euler-circle"
    },
    {
        "id": "162",
        "name": "Expanding Horizons Internship",
        "hyphenated": "expanding-horizons-internship"
    },
    {
        "id": "163",
        "name": "Experiment In International Living",
        "hyphenated": "experiment-in-international-living"
    },
    {
        "id": "164",
        "name": "FBLA Awards",
        "hyphenated": "fbla-awards"
    },
    {
        "id": "165",
        "name": "FIRST Robotics Contests",
        "hyphenated": "first-robotics-contests"
    },
    {
        "id": "166",
        "name": "Florida State University Summer Intensive Dance Workshop",
        "hyphenated": "florida-state-university-summer-intensive-dance-workshop"
    },
    {
        "id": "167",
        "name": "Florida State University Summer Music Camps",
        "hyphenated": "florida-state-university-summer-music-camps"
    },
    {
        "id": "168",
        "name": "Florida State University Young Scholars Program",
        "hyphenated": "florida-state-university-young-scholars-program"
    },
    {
        "id": "169",
        "name": "For Future Lungs",
        "hyphenated": "for-future-lungs"
    },
    {
        "id": "170",
        "name": "Foresight Prep Pre College Programs",
        "hyphenated": "foresight-prep-pre-college-programs"
    },
    {
        "id": "171",
        "name": "Fred Hutch Summer High School Internship Program (SHIP)",
        "hyphenated": "fred-hutch-summer-high-school-internship-program-(ship)"
    },
    {
        "id": "172",
        "name": "FSU College of Medicine Summer Institute",
        "hyphenated": "fsu-college-of-medicine-summer-institute"
    },
    {
        "id": "173",
        "name": "FSU Jim Moran College of Entrepreneurship Summer Camp",
        "hyphenated": "fsu-jim-moran-college-of-entrepreneurship-summer-camp"
    },
    {
        "id": "174",
        "name": "Future Problem Solving International Competition",
        "hyphenated": "future-problem-solving-international-competition"
    },
    {
        "id": "175",
        "name": "Garcia Research Scholar Program for High School Students",
        "hyphenated": "garcia-research-scholar-program-for-high-school-students"
    },
    {
        "id": "176",
        "name": "Gateway to Business",
        "hyphenated": "gateway-to-business"
    },
    {
        "id": "177",
        "name": "Gator Computing Program",
        "hyphenated": "gator-computing-program"
    },
    {
        "id": "178",
        "name": "GenCyber Summer Program",
        "hyphenated": "gencyber-summer-program"
    },
    {
        "id": "179",
        "name": "Gene A. Burd Institute on Media & Politics",
        "hyphenated": "gene-a.-burd-institute-on-media-&-politics"
    },
    {
        "id": "180",
        "name": "GENIUS Olympiad",
        "hyphenated": "genius-olympiad"
    },
    {
        "id": "181",
        "name": "Genomics Research Internship Program at Stanford",
        "hyphenated": "genomics-research-internship-program-at-stanford"
    },
    {
        "id": "182",
        "name": "Georgetown College Prep Program",
        "hyphenated": "georgetown-college-prep-program"
    },
    {
        "id": "183",
        "name": "Georgetown Pre College Online Program",
        "hyphenated": "georgetown-pre-college-online-program"
    },
    {
        "id": "184",
        "name": "Georgetown Summer Academies",
        "hyphenated": "georgetown-summer-academies"
    },
    {
        "id": "185",
        "name": "Georgetown Summer College",
        "hyphenated": "georgetown-summer-college"
    },
    {
        "id": "186",
        "name": "Georgia Tech Pre-College Architecture Program",
        "hyphenated": "georgia-tech-pre-college-architecture-program"
    },
    {
        "id": "187",
        "name": "Georgia Tech Pre-College Industrial Design Program",
        "hyphenated": "georgia-tech-pre-college-industrial-design-program"
    },
    {
        "id": "188",
        "name": "Georgia Tech Pre-College Music Technology Program",
        "hyphenated": "georgia-tech-pre-college-music-technology-program"
    },
    {
        "id": "189",
        "name": "Geosciences Bridge Program",
        "hyphenated": "geosciences-bridge-program"
    },
    {
        "id": "190",
        "name": "Get Published In An Industry Publication",
        "hyphenated": "get-published-in-an-industry-publication"
    },
    {
        "id": "191",
        "name": "Global Youth Entrepreneurship Challenge",
        "hyphenated": "global-youth-entrepreneurship-challenge"
    },
    {
        "id": "192",
        "name": "Gloria Barron Prize for Young Heroes",
        "hyphenated": "gloria-barron-prize-for-young-heroes"
    },
    {
        "id": "193",
        "name": "Governor\u2019s School of New Jersey Program in the Sciences",
        "hyphenated": "governor\u2019s-school-of-new-jersey-program-in-the-sciences"
    },
    {
        "id": "194",
        "name": "Group Summer Scholars Research Program (GSSRP)",
        "hyphenated": "group-summer-scholars-research-program-(gssrp)"
    },
    {
        "id": "195",
        "name": "Hampshire College Summer Studies in Math (HCSSiM)",
        "hyphenated": "hampshire-college-summer-studies-in-math-(hcssim)"
    },
    {
        "id": "196",
        "name": "Harvard 4-Week Secondary School Program Residential",
        "hyphenated": "harvard-4-week-secondary-school-program-residential"
    },
    {
        "id": "197",
        "name": "Harvard 7-Week Secondary School Program Commuting",
        "hyphenated": "harvard-7-week-secondary-school-program-commuting"
    },
    {
        "id": "198",
        "name": "Harvard 7-Week Secondary School Program Online",
        "hyphenated": "harvard-7-week-secondary-school-program-online"
    },
    {
        "id": "199",
        "name": "Harvard 7-Week Secondary School Program Residential",
        "hyphenated": "harvard-7-week-secondary-school-program-residential"
    },
    {
        "id": "200",
        "name": "Harvard Debate Council Summer Workshops: Congressional Debate",
        "hyphenated": "harvard-debate-council-summer-workshops:-congressional-debate"
    },
    {
        "id": "201",
        "name": "Harvard Debate Council Summer Workshops: Lincoln Douglas",
        "hyphenated": "harvard-debate-council-summer-workshops:-lincoln-douglas"
    },
    {
        "id": "202",
        "name": "Harvard Debate Council Summer Workshops: Policy Debate",
        "hyphenated": "harvard-debate-council-summer-workshops:-policy-debate"
    },
    {
        "id": "203",
        "name": "Harvard Debate Council Summer Workshops: Public Forum",
        "hyphenated": "harvard-debate-council-summer-workshops:-public-forum"
    },
    {
        "id": "204",
        "name": "Harvard Debate Council Summer Workshops: Public Speaking",
        "hyphenated": "harvard-debate-council-summer-workshops:-public-speaking"
    },
    {
        "id": "205",
        "name": "Harvard Debate Council Summer Workshops: World Schools",
        "hyphenated": "harvard-debate-council-summer-workshops:-world-schools"
    },
    {
        "id": "206",
        "name": "Harvard National High School Invitational Forensics Tournament",
        "hyphenated": "harvard-national-high-school-invitational-forensics-tournament"
    },
    {
        "id": "207",
        "name": "Harvard Pre-College Program",
        "hyphenated": "harvard-pre-college-program"
    },
    {
        "id": "208",
        "name": "Harvard Pre-Collegiate Economics Challenge",
        "hyphenated": "harvard-pre-collegiate-economics-challenge"
    },
    {
        "id": "209",
        "name": "Harvard Student Agencies On-Campus Academies",
        "hyphenated": "harvard-student-agencies-on-campus-academies"
    },
    {
        "id": "210",
        "name": "Harvard Student Agencies Virtual Academies",
        "hyphenated": "harvard-student-agencies-virtual-academies"
    },
    {
        "id": "211",
        "name": "Headquarters Student Internship Program at the Department of Treasury",
        "hyphenated": "headquarters-student-internship-program-at-the-department-of-treasury"
    },
    {
        "id": "212",
        "name": "High School Honors Science, Math, and Engineering Program (HSHSP)",
        "hyphenated": "high-school-honors-science-math-and-engineering-program-(hshsp)"
    },
    {
        "id": "213",
        "name": "High School Scientific Training and Enrichment Program (HiSTEP)",
        "hyphenated": "high-school-scientific-training-and-enrichment-program-(histep)"
    },
    {
        "id": "214",
        "name": "High Tech Bound",
        "hyphenated": "high-tech-bound"
    },
    {
        "id": "215",
        "name": "HK Maker Lab",
        "hyphenated": "hk-maker-lab"
    },
    {
        "id": "216",
        "name": "HMMT",
        "hyphenated": "hmmt"
    },
    {
        "id": "217",
        "name": "HOPP Summer Student Program",
        "hyphenated": "hopp-summer-student-program"
    },
    {
        "id": "218",
        "name": "Horizon Academic",
        "hyphenated": "horizon-academic"
    },
    {
        "id": "219",
        "name": "Hutton Junior Fisheries Biology Program",
        "hyphenated": "hutton-junior-fisheries-biology-program"
    },
    {
        "id": "220",
        "name": "Imagine Cup Junior",
        "hyphenated": "imagine-cup-junior"
    },
    {
        "id": "221",
        "name": "Innovation and Startup Culture",
        "hyphenated": "innovation-and-startup-culture"
    },
    {
        "id": "222",
        "name": "Innovation Research Fellowship",
        "hyphenated": "innovation-research-fellowship"
    },
    {
        "id": "223",
        "name": "Innovator Competition",
        "hyphenated": "innovator-competition"
    },
    {
        "id": "224",
        "name": "Inspiring Future Scientists through Shadowing",
        "hyphenated": "inspiring-future-scientists-through-shadowing"
    },
    {
        "id": "225",
        "name": "Institute for Speech and Debate",
        "hyphenated": "institute-for-speech-and-debate"
    },
    {
        "id": "226",
        "name": "Intensive Law & Trial",
        "hyphenated": "intensive-law-&-trial"
    },
    {
        "id": "227",
        "name": "International Biology Olympiad",
        "hyphenated": "international-biology-olympiad"
    },
    {
        "id": "228",
        "name": "International Brain Bee",
        "hyphenated": "international-brain-bee"
    },
    {
        "id": "229",
        "name": "International Chemistry Olympiad",
        "hyphenated": "international-chemistry-olympiad"
    },
    {
        "id": "230",
        "name": "International Economics Olympiad",
        "hyphenated": "international-economics-olympiad"
    },
    {
        "id": "231",
        "name": "International History Olympiad",
        "hyphenated": "international-history-olympiad"
    },
    {
        "id": "232",
        "name": "International Journal of High School Research",
        "hyphenated": "international-journal-of-high-school-research"
    },
    {
        "id": "233",
        "name": "International Math Olympiad",
        "hyphenated": "international-math-olympiad"
    },
    {
        "id": "234",
        "name": "International Olympiad in Informatics",
        "hyphenated": "international-olympiad-in-informatics"
    },
    {
        "id": "235",
        "name": "International Photography Awards",
        "hyphenated": "international-photography-awards"
    },
    {
        "id": "236",
        "name": "International Physics Olympiad",
        "hyphenated": "international-physics-olympiad"
    },
    {
        "id": "237",
        "name": "International Psychology Olympiad",
        "hyphenated": "international-psychology-olympiad"
    },
    {
        "id": "238",
        "name": "International Public Policy Forum",
        "hyphenated": "international-public-policy-forum"
    },
    {
        "id": "239",
        "name": "International Young Physicists\u2019 Tournament",
        "hyphenated": "international-young-physicists\u2019-tournament"
    },
    {
        "id": "240",
        "name": "International Youth Neuroscience Association Journal",
        "hyphenated": "international-youth-neuroscience-association-journal"
    },
    {
        "id": "241",
        "name": "Intersect",
        "hyphenated": "intersect"
    },
    {
        "id": "242",
        "name": "Introduction To Logic Summer Camp",
        "hyphenated": "introduction-to-logic-summer-camp"
    },
    {
        "id": "243",
        "name": "Iowa Young Writers Studio Online Courses",
        "hyphenated": "iowa-young-writers-studio-online-courses"
    },
    {
        "id": "244",
        "name": "Iowa Young Writers Studio Summer Program",
        "hyphenated": "iowa-young-writers-studio-summer-program"
    },
    {
        "id": "245",
        "name": "ISSOS International Summer Schools",
        "hyphenated": "issos-international-summer-schools"
    },
    {
        "id": "246",
        "name": "J. Craig Venter Institute Internship Program",
        "hyphenated": "j.-craig-venter-institute-internship-program"
    },
    {
        "id": "247",
        "name": "Jackson Laboratory Summer Student Program",
        "hyphenated": "jackson-laboratory-summer-student-program"
    },
    {
        "id": "248",
        "name": "Cooke College Scholarship Program",
        "hyphenated": "cooke-college-scholarship-program"
    },
    {
        "id": "249",
        "name": "Jane Austen Society of North America Essay Contest",
        "hyphenated": "jane-austen-society-of-north-america-essay-contest"
    },
    {
        "id": "250",
        "name": "Jefferson Lab/VIMS Governor's School",
        "hyphenated": "jefferson-lab/vims-governor's-school"
    },
    {
        "id": "251",
        "name": "John F. Kennedy Profile in Courage Essay Contest",
        "hyphenated": "john-f.-kennedy-profile-in-courage-essay-contest"
    },
    {
        "id": "252",
        "name": "John Locke Institute - Global Affairs Forum",
        "hyphenated": "john-locke-institute-global-affairs-forum"
    },
    {
        "id": "253",
        "name": "John Locke Institute - Public Policy Symposium",
        "hyphenated": "john-locke-institute-public-policy-symposium"
    },
    {
        "id": "254",
        "name": "Johns Hopkins Applied Physics Lab Internship Program",
        "hyphenated": "johns-hopkins-applied-physics-lab-internship-program"
    },
    {
        "id": "255",
        "name": "Johns Hopkins Center for Talented Youth (CTY)",
        "hyphenated": "johns-hopkins-center-for-talented-youth-(cty)"
    },
    {
        "id": "256",
        "name": "Johns Hopkins Engineering Innovation Pre-College Programs",
        "hyphenated": "johns-hopkins-engineering-innovation-pre-college-programs"
    },
    {
        "id": "257",
        "name": "Johns Hopkins Pre-College: Summer at Hopkins",
        "hyphenated": "johns-hopkins-pre-college:-summer-at-hopkins"
    },
    {
        "id": "258",
        "name": "Journal of Emerging Investigators",
        "hyphenated": "journal-of-emerging-investigators"
    },
    {
        "id": "259",
        "name": "Journal of High School Science",
        "hyphenated": "journal-of-high-school-science"
    },
    {
        "id": "260",
        "name": "Journal of Research High School",
        "hyphenated": "journal-of-research-high-school"
    },
    {
        "id": "261",
        "name": "Journal of Student Research",
        "hyphenated": "journal-of-student-research"
    },
    {
        "id": "262",
        "name": "Juniper Institute for Young Writers",
        "hyphenated": "juniper-institute-for-young-writers"
    },
    {
        "id": "263",
        "name": "Juniper Young Writers Online",
        "hyphenated": "juniper-young-writers-online"
    },
    {
        "id": "264",
        "name": "Kaiser Permanente Launch Internship",
        "hyphenated": "kaiser-permanente-launch-internship"
    },
    {
        "id": "265",
        "name": "Kelley Women's Leadership Institute",
        "hyphenated": "kelley-women's-leadership-institute"
    },
    {
        "id": "266",
        "name": "Kenyon Review Young Writers Summer Online Workshop",
        "hyphenated": "kenyon-review-young-writers-summer-online-workshop"
    },
    {
        "id": "267",
        "name": "Kenyon Review Young Writers Summer Residential Workshop",
        "hyphenated": "kenyon-review-young-writers-summer-residential-workshop"
    },
    {
        "id": "268",
        "name": "Khan Academy",
        "hyphenated": "khan-academy"
    },
    {
        "id": "269",
        "name": "LaunchX Entrepreneurship Summer Programs",
        "hyphenated": "launchx-entrepreneurship-summer-programs"
    },
    {
        "id": "270",
        "name": "Le Grand Concours",
        "hyphenated": "le-grand-concours"
    },
    {
        "id": "271",
        "name": "Lehigh Iacocca Global Entrepreneurship Intensive",
        "hyphenated": "lehigh-iacocca-global-entrepreneurship-intensive"
    },
    {
        "id": "272",
        "name": "Lehigh University Summer Engineering Institute (SEI)",
        "hyphenated": "lehigh-university-summer-engineering-institute-(sei)"
    },
    {
        "id": "273",
        "name": "Leonard L. Milberg \u201953 High School Poetry Prize",
        "hyphenated": "leonard-l.-milberg-\u201953-high-school-poetry-prize"
    },
    {
        "id": "274",
        "name": "Library of Congress Virtual Volunteer",
        "hyphenated": "library-of-congress-virtual-volunteer"
    },
    {
        "id": "275",
        "name": "Lillehei Heart Institute Summer Research Program",
        "hyphenated": "lillehei-heart-institute-summer-research-program"
    },
    {
        "id": "276",
        "name": "Lincoln Laboratory Cipher Program",
        "hyphenated": "lincoln-laboratory-cipher-program"
    },
    {
        "id": "277",
        "name": "Lincoln Laboratory Radar Introduction for Student Engineers (LLRISE)",
        "hyphenated": "lincoln-laboratory-radar-introduction-for-student-engineers-(llrise)"
    },
    {
        "id": "278",
        "name": "Louisville Review",
        "hyphenated": "louisville-review"
    },
    {
        "id": "279",
        "name": "Management and Technology Summer Institute (M&TSI)",
        "hyphenated": "management-and-technology-summer-institute-(m&tsi)"
    },
    {
        "id": "280",
        "name": "MathROOTS",
        "hyphenated": "mathroots"
    },
    {
        "id": "281",
        "name": "Mathworks Honors Summer Math Camp (HSMC)",
        "hyphenated": "mathworks-honors-summer-math-camp-(hsmc)"
    },
    {
        "id": "282",
        "name": "MathWorks Math Modeling Challenge",
        "hyphenated": "mathworks-math-modeling-challenge"
    },
    {
        "id": "283",
        "name": "MDI Biological Lab",
        "hyphenated": "mdi-biological-lab"
    },
    {
        "id": "284",
        "name": "Medill-Northwestern Journalism Institute",
        "hyphenated": "medill-northwestern-journalism-institute"
    },
    {
        "id": "285",
        "name": "Medusa Mythology Exam Contest",
        "hyphenated": "medusa-mythology-exam-contest"
    },
    {
        "id": "286",
        "name": "Menezes Challenge PRIMES Circle",
        "hyphenated": "menezes-challenge-primes-circle"
    },
    {
        "id": "287",
        "name": "Meta Hacker Cup",
        "hyphenated": "meta-hacker-cup"
    },
    {
        "id": "288",
        "name": "Michigan Math and Science Scholars",
        "hyphenated": "michigan-math-and-science-scholars"
    },
    {
        "id": "289",
        "name": "Microsoft Discovery Program",
        "hyphenated": "microsoft-discovery-program"
    },
    {
        "id": "290",
        "name": "Middle School National Speech & Debate Tournament",
        "hyphenated": "middle-school-national-speech-&-debate-tournament"
    },
    {
        "id": "291",
        "name": "Milton Fisher Scholarship for Innovation & Creativity",
        "hyphenated": "milton-fisher-scholarship-for-innovation-&-creativity"
    },
    {
        "id": "292",
        "name": "Minority Introduction to Engineering & Science (MITES) Saturdays",
        "hyphenated": "minority-introduction-to-engineering-&-science-(mites)-saturdays"
    },
    {
        "id": "293",
        "name": "Minority Introduction to Engineering & Science (MITES) Semester",
        "hyphenated": "minority-introduction-to-engineering-&-science-(mites)-semester"
    },
    {
        "id": "294",
        "name": "Minority Introduction to Engineering & Science (MITES) Summer",
        "hyphenated": "minority-introduction-to-engineering-&-science-(mites)-summer"
    },
    {
        "id": "295",
        "name": "MirCore \u2013 High School Research Conference",
        "hyphenated": "mircore-\u2013-high-school-research-conference"
    },
    {
        "id": "296",
        "name": "MIT PRIMES",
        "hyphenated": "mit-primes"
    },
    {
        "id": "297",
        "name": "MIT Think",
        "hyphenated": "mit-think"
    },
    {
        "id": "298",
        "name": "Model UN Institute Summer Camp - High School",
        "hyphenated": "model-un-institute-summer-camp-high-school"
    },
    {
        "id": "299",
        "name": "Model UN Institute Summer Camp - High School Advanced",
        "hyphenated": "model-un-institute-summer-camp-high-school-advanced"
    },
    {
        "id": "300",
        "name": "Model UN Institute Summer Camp - Middle School",
        "hyphenated": "model-un-institute-summer-camp-middle-school"
    },
    {
        "id": "301",
        "name": "Monell Science Apprenticeship",
        "hyphenated": "monell-science-apprenticeship"
    },
    {
        "id": "302",
        "name": "Moneyball Academy: Training Camp FLEX",
        "hyphenated": "moneyball-academy:-training-camp-flex"
    },
    {
        "id": "303",
        "name": "NAF Future Ready Scholars\nSummer Camp",
        "hyphenated": "naf-future-ready-scholars-summer-camp"
    },
    {
        "id": "304",
        "name": "Nancy Thorp Poetry Contest",
        "hyphenated": "nancy-thorp-poetry-contest"
    },
    {
        "id": "305",
        "name": "NASA Texas High School Aerospace Scholars",
        "hyphenated": "nasa-texas-high-school-aerospace-scholars"
    },
    {
        "id": "306",
        "name": "National Academic Bee",
        "hyphenated": "national-academic-bee"
    },
    {
        "id": "307",
        "name": "National Academic Bowl",
        "hyphenated": "national-academic-bowl"
    },
    {
        "id": "308",
        "name": "National Art Honor Society",
        "hyphenated": "national-art-honor-society"
    },
    {
        "id": "309",
        "name": "National Chinese Honor Society",
        "hyphenated": "national-chinese-honor-society"
    },
    {
        "id": "310",
        "name": "National Constitution Bee",
        "hyphenated": "national-constitution-bee"
    },
    {
        "id": "311",
        "name": "National Economics Challenge",
        "hyphenated": "national-economics-challenge"
    },
    {
        "id": "312",
        "name": "National English Honor Society",
        "hyphenated": "national-english-honor-society"
    },
    {
        "id": "313",
        "name": "National Gallery of Art\u2019s Teen Volunteer Program",
        "hyphenated": "national-gallery-of-art\u2019s-teen-volunteer-program"
    },
    {
        "id": "314",
        "name": "National Geographic Student Travel",
        "hyphenated": "national-geographic-student-travel"
    },
    {
        "id": "315",
        "name": "National Geography Bee",
        "hyphenated": "national-geography-bee"
    },
    {
        "id": "316",
        "name": "National Geography Bowl",
        "hyphenated": "national-geography-bowl"
    },
    {
        "id": "317",
        "name": "National German Exam",
        "hyphenated": "national-german-exam"
    },
    {
        "id": "318",
        "name": "National Greek Exam",
        "hyphenated": "national-greek-exam"
    },
    {
        "id": "319",
        "name": "National Heart Lung and Blood Institute Summer Internships",
        "hyphenated": "national-heart-lung-and-blood-institute-summer-internships"
    },
    {
        "id": "320",
        "name": "National High School Ethics Bowl",
        "hyphenated": "national-high-school-ethics-bowl"
    },
    {
        "id": "321",
        "name": "National High School Game Academy",
        "hyphenated": "national-high-school-game-academy"
    },
    {
        "id": "322",
        "name": "National High School Journal of Science",
        "hyphenated": "national-high-school-journal-of-science"
    },
    {
        "id": "323",
        "name": "National High School Mock Trial Contest",
        "hyphenated": "national-high-school-mock-trial-contest"
    },
    {
        "id": "324",
        "name": "National History Bee",
        "hyphenated": "national-history-bee"
    },
    {
        "id": "325",
        "name": "National History Bowl",
        "hyphenated": "national-history-bowl"
    },
    {
        "id": "326",
        "name": "National History Day (NHD)",
        "hyphenated": "national-history-day-(nhd)"
    },
    {
        "id": "327",
        "name": "National Honor Society",
        "hyphenated": "national-honor-society"
    },
    {
        "id": "328",
        "name": "National Italian Exam",
        "hyphenated": "national-italian-exam"
    },
    {
        "id": "329",
        "name": "National Jefferson Award",
        "hyphenated": "national-jefferson-award"
    },
    {
        "id": "330",
        "name": "National Junior Science and Humanities Symposium (JSHS)",
        "hyphenated": "national-junior-science-and-humanities-symposium-(jshs)"
    },
    {
        "id": "331",
        "name": "National Latin Exam",
        "hyphenated": "national-latin-exam"
    },
    {
        "id": "332",
        "name": "National Math Honor Society (Mu Alpha Theta)",
        "hyphenated": "national-math-honor-society-(mu-alpha-theta)"
    },
    {
        "id": "333",
        "name": "National Ocean Sciences Bowl",
        "hyphenated": "national-ocean-sciences-bowl"
    },
    {
        "id": "334",
        "name": "National Parks Service Youth Conservation Corps",
        "hyphenated": "national-parks-service-youth-conservation-corps"
    },
    {
        "id": "335",
        "name": "National Peace Essay Contest",
        "hyphenated": "national-peace-essay-contest"
    },
    {
        "id": "336",
        "name": "National Political Science Bee",
        "hyphenated": "national-political-science-bee"
    },
    {
        "id": "337",
        "name": "National Robotics Challenge",
        "hyphenated": "national-robotics-challenge"
    },
    {
        "id": "338",
        "name": "National Science Bee",
        "hyphenated": "national-science-bee"
    },
    {
        "id": "339",
        "name": "National Science Bowl",
        "hyphenated": "national-science-bowl"
    },
    {
        "id": "340",
        "name": "National Science Honor Society",
        "hyphenated": "national-science-honor-society"
    },
    {
        "id": "341",
        "name": "National Security Language Initiative for Youth Summer Program (NSLI-Y)",
        "hyphenated": "national-security-language-initiative-for-youth-summer-program-(nsli-y)"
    },
    {
        "id": "342",
        "name": "National Social Studies Honor Society",
        "hyphenated": "national-social-studies-honor-society"
    },
    {
        "id": "343",
        "name": "National Speech & Debate Tournament",
        "hyphenated": "national-speech-&-debate-tournament"
    },
    {
        "id": "344",
        "name": "National Student Leadership Conference",
        "hyphenated": "national-student-leadership-conference"
    },
    {
        "id": "345",
        "name": "National YoungArts Competition",
        "hyphenated": "national-youngarts-competition"
    },
    {
        "id": "346",
        "name": "NCTE Achievement Awards in Writing",
        "hyphenated": "ncte-achievement-awards-in-writing"
    },
    {
        "id": "347",
        "name": "NCTE Promising Young Writers",
        "hyphenated": "ncte-promising-young-writers"
    },
    {
        "id": "348",
        "name": "Neuroscience Research Prize",
        "hyphenated": "neuroscience-research-prize"
    },
    {
        "id": "349",
        "name": "New York Film Academy",
        "hyphenated": "new-york-film-academy"
    },
    {
        "id": "350",
        "name": "New York Times Student Contests",
        "hyphenated": "new-york-times-student-contests"
    },
    {
        "id": "351",
        "name": "NINDS High School Summer Internship Program",
        "hyphenated": "ninds-high-school-summer-internship-program"
    },
    {
        "id": "352",
        "name": "Non Trivial Research Fellowship",
        "hyphenated": "non-trivial-research-fellowship"
    },
    {
        "id": "353",
        "name": "Norm Brodsky Business Concept Competition",
        "hyphenated": "norm-brodsky-business-concept-competition"
    },
    {
        "id": "354",
        "name": "North American Computational Linguistics Olympiad",
        "hyphenated": "north-american-computational-linguistics-olympiad"
    },
    {
        "id": "355",
        "name": "North Carolina Governor's School",
        "hyphenated": "north-carolina-governor's-school"
    },
    {
        "id": "356",
        "name": "Northeastern Young Scholars Program",
        "hyphenated": "northeastern-young-scholars-program"
    },
    {
        "id": "357",
        "name": "Northwestern College Prep Program",
        "hyphenated": "northwestern-college-prep-program"
    },
    {
        "id": "358",
        "name": "Northwestern e-FOCUS Seminars",
        "hyphenated": "northwestern-e-focus-seminars"
    },
    {
        "id": "359",
        "name": "Northwestern IN FOCUS Seminars",
        "hyphenated": "northwestern-in-focus-seminars"
    },
    {
        "id": "360",
        "name": "Notre Dame Leadership Seminars",
        "hyphenated": "notre-dame-leadership-seminars"
    },
    {
        "id": "361",
        "name": "Notre Dame Summer Scholars",
        "hyphenated": "notre-dame-summer-scholars"
    },
    {
        "id": "362",
        "name": "NYU Coding for Game Design",
        "hyphenated": "nyu-coding-for-game-design"
    },
    {
        "id": "363",
        "name": "NYU Tisch High School Summer Program",
        "hyphenated": "nyu-tisch-high-school-summer-program"
    },
    {
        "id": "364",
        "name": "Open Journal of Business and Management",
        "hyphenated": "open-journal-of-business-and-management"
    },
    {
        "id": "365",
        "name": "Oratory contest - American Legion",
        "hyphenated": "oratory-contest-american-legion"
    },
    {
        "id": "366",
        "name": "Otis College Summer of Art",
        "hyphenated": "otis-college-summer-of-art"
    },
    {
        "id": "367",
        "name": "Patricia Grodd Poetry Prize",
        "hyphenated": "patricia-grodd-poetry-prize"
    },
    {
        "id": "368",
        "name": "Pediatrics Internship Program at Stanford",
        "hyphenated": "pediatrics-internship-program-at-stanford"
    },
    {
        "id": "369",
        "name": "Penn Carey Law Pre-College Academy",
        "hyphenated": "penn-carey-law-pre-college-academy"
    },
    {
        "id": "370",
        "name": "UPenn EcoVenture Challenge",
        "hyphenated": "upenn-ecoventure-challenge"
    },
    {
        "id": "371",
        "name": "Penn Pre-College Programs",
        "hyphenated": "penn-pre-college-programs"
    },
    {
        "id": "372",
        "name": "Pennsylvania Governor\u2019s School for the Sciences",
        "hyphenated": "pennsylvania-governor\u2019s-school-for-the-sciences"
    },
    {
        "id": "373",
        "name": "PI Math Contest",
        "hyphenated": "pi-math-contest"
    },
    {
        "id": "374",
        "name": "Pioneer Research Institute",
        "hyphenated": "pioneer-research-institute"
    },
    {
        "id": "375",
        "name": "Politics for Teens",
        "hyphenated": "politics-for-teens"
    },
    {
        "id": "376",
        "name": "Polygence",
        "hyphenated": "polygence"
    },
    {
        "id": "377",
        "name": "Pomona College Academy for Youth Success (PAYS)",
        "hyphenated": "pomona-college-academy-for-youth-success-(pays)"
    },
    {
        "id": "378",
        "name": "President's Volunteer Service Award",
        "hyphenated": "president's-volunteer-service-award"
    },
    {
        "id": "379",
        "name": "PRIMES - USA",
        "hyphenated": "primes-usa"
    },
    {
        "id": "380",
        "name": "Princeton 10 Minute Play Contest",
        "hyphenated": "princeton-10-minute-play-contest"
    },
    {
        "id": "381",
        "name": "Princeton Laboratory Learning Program",
        "hyphenated": "princeton-laboratory-learning-program"
    },
    {
        "id": "382",
        "name": "Princeton Physics Plasma Lab Internship",
        "hyphenated": "princeton-physics-plasma-lab-internship"
    },
    {
        "id": "383",
        "name": "Princeton Prize in Race Relations",
        "hyphenated": "princeton-prize-in-race-relations"
    },
    {
        "id": "384",
        "name": "Princeton Program in Algorithmic and Combinatorial Thinking",
        "hyphenated": "princeton-program-in-algorithmic-and-combinatorial-thinking"
    },
    {
        "id": "385",
        "name": "Princeton Summer Journalism Program",
        "hyphenated": "princeton-summer-journalism-program"
    },
    {
        "id": "386",
        "name": "Princeton University Preparatory Program",
        "hyphenated": "princeton-university-preparatory-program"
    },
    {
        "id": "387",
        "name": "Program in Mathematics for Young Scientists (PROMYS)",
        "hyphenated": "program-in-mathematics-for-young-scientists-(promys)"
    },
    {
        "id": "388",
        "name": "Prudential Awards",
        "hyphenated": "prudential-awards"
    },
    {
        "id": "389",
        "name": "Pursue A Capstone Project",
        "hyphenated": "pursue-a-capstone-project"
    },
    {
        "id": "390",
        "name": "PZ Math Camp",
        "hyphenated": "pz-math-camp"
    },
    {
        "id": "391",
        "name": "Qualcomm Thinkabit Lab Tech for Good Camp",
        "hyphenated": "qualcomm-thinkabit-lab-tech-for-good-camp"
    },
    {
        "id": "392",
        "name": "Quill and Scroll Writing, Visual, and Multimedia Contest",
        "hyphenated": "quill-and-scroll-writing-visual-and-multimedia-contest"
    },
    {
        "id": "393",
        "name": "Random Math Competitive Math Programs",
        "hyphenated": "random-math-competitive-math-programs"
    },
    {
        "id": "394",
        "name": "Regeneron International Science And Engineering Fair (ISEF)",
        "hyphenated": "regeneron-international-science-and-engineering-fair-(isef)"
    },
    {
        "id": "395",
        "name": "Regeneron Science Talent Search (STS)",
        "hyphenated": "regeneron-science-talent-search-(sts)"
    },
    {
        "id": "396",
        "name": "Rensselaer Polytechnic Institute: Architecture Career Discovery Program",
        "hyphenated": "rensselaer-polytechnic-institute:-architecture-career-discovery-program"
    },
    {
        "id": "397",
        "name": "Research Archive of Rising Scholars",
        "hyphenated": "research-archive-of-rising-scholars"
    },
    {
        "id": "398",
        "name": "Research In The Biological Sciences (RIBS)",
        "hyphenated": "research-in-the-biological-sciences-(ribs)"
    },
    {
        "id": "399",
        "name": "Research Science Institute (RSI)",
        "hyphenated": "research-science-institute-(rsi)"
    },
    {
        "id": "400",
        "name": "Rice University Aerospace & Aviation Academy",
        "hyphenated": "rice-university-aerospace-&-aviation-academy"
    },
    {
        "id": "401",
        "name": "Rice University School Mathematics Project's Summer Math Camps and Programs",
        "hyphenated": "rice-university-school-mathematics-project's-summer-math-camps-and-programs"
    },
    {
        "id": "402",
        "name": "River of Words Competition",
        "hyphenated": "river-of-words-competition"
    },
    {
        "id": "403",
        "name": "Rose Hulman Institute of Technology - Operation Catapult",
        "hyphenated": "rose-hulman-institute-of-technology-operation-catapult"
    },
    {
        "id": "404",
        "name": "Ross Mathematics Program",
        "hyphenated": "ross-mathematics-program"
    },
    {
        "id": "405",
        "name": "Roswell Park High School Summer Research Experience",
        "hyphenated": "roswell-park-high-school-summer-research-experience"
    },
    {
        "id": "406",
        "name": "Rubincam Youth Writing Competition",
        "hyphenated": "rubincam-youth-writing-competition"
    },
    {
        "id": "407",
        "name": "Russian School of Mathematics",
        "hyphenated": "russian-school-of-mathematics"
    },
    {
        "id": "408",
        "name": "Santa Clara University: Summer Engineering Seminar (SES)",
        "hyphenated": "santa-clara-university:-summer-engineering-seminar-(ses)"
    },
    {
        "id": "409",
        "name": "Santa Clara University: Young Scholars Program\n",
        "hyphenated": "santa-clara-university:-young-scholars-program"
    },
    {
        "id": "410",
        "name": "SASI Clinical Skills Immersion",
        "hyphenated": "sasi-clinical-skills-immersion"
    },
    {
        "id": "411",
        "name": "Scholastic Art and Writing Awards",
        "hyphenated": "scholastic-art-and-writing-awards"
    },
    {
        "id": "412",
        "name": "Science Accelerating Girls' Engagement",
        "hyphenated": "science-accelerating-girls'-engagement"
    },
    {
        "id": "413",
        "name": "Science and Engineering Apprenticeship Program (SEAP)",
        "hyphenated": "science-and-engineering-apprenticeship-program-(seap)"
    },
    {
        "id": "414",
        "name": "Science Internship Program (SIP)",
        "hyphenated": "science-internship-program-(sip)"
    },
    {
        "id": "415",
        "name": "Science Olympiad",
        "hyphenated": "science-olympiad"
    },
    {
        "id": "416",
        "name": "Science Research Mentoring Program (SRMP)",
        "hyphenated": "science-research-mentoring-program-(srmp)"
    },
    {
        "id": "417",
        "name": "Scripps Research Translational Institute",
        "hyphenated": "scripps-research-translational-institute"
    },
    {
        "id": "418",
        "name": "Secondary Student Training Program (SSTP)",
        "hyphenated": "secondary-student-training-program-(sstp)"
    },
    {
        "id": "419",
        "name": "Self-Publish Original Content",
        "hyphenated": "self-publish-original-content"
    },
    {
        "id": "420",
        "name": "Sewanee Young Writers Workshop",
        "hyphenated": "sewanee-young-writers-workshop"
    },
    {
        "id": "421",
        "name": "Shell Eco-Marathon",
        "hyphenated": "shell-eco-marathon"
    },
    {
        "id": "422",
        "name": "Simons Summer Research Program",
        "hyphenated": "simons-summer-research-program"
    },
    {
        "id": "423",
        "name": "Smithsonian Digital Volunteers",
        "hyphenated": "smithsonian-digital-volunteers"
    },
    {
        "id": "424",
        "name": "Smithsonian Explainers Program",
        "hyphenated": "smithsonian-explainers-program"
    },
    {
        "id": "425",
        "name": "South Carolina Governor's School for Science and Mathematics Summer Camps",
        "hyphenated": "south-carolina-governor's-school-for-science-and-mathematics-summer-camps"
    },
    {
        "id": "426",
        "name": "SPARK Summer Internship Program",
        "hyphenated": "spark-summer-internship-program"
    },
    {
        "id": "427",
        "name": "Sports Business Academy",
        "hyphenated": "sports-business-academy"
    },
    {
        "id": "428",
        "name": "Stanford AI4ALL",
        "hyphenated": "stanford-ai4all"
    },
    {
        "id": "429",
        "name": "Stanford AIMI Center Summer Health AI Bootcamp",
        "hyphenated": "stanford-aimi-center-summer-health-ai-bootcamp"
    },
    {
        "id": "430",
        "name": "Stanford AIMI Center Summer Research Internship",
        "hyphenated": "stanford-aimi-center-summer-research-internship"
    },
    {
        "id": "431",
        "name": "Stanford Anesthesia Summer Institute Foundations",
        "hyphenated": "stanford-anesthesia-summer-institute-foundations"
    },
    {
        "id": "432",
        "name": "Stanford Anesthesia Summer Institute Virtual",
        "hyphenated": "stanford-anesthesia-summer-institute-virtual"
    },
    {
        "id": "433",
        "name": "Stanford Clinical Summer Internship",
        "hyphenated": "stanford-clinical-summer-internship"
    },
    {
        "id": "434",
        "name": "Stanford Clinical Summer Internship Fall Intensive",
        "hyphenated": "stanford-clinical-summer-internship-fall-intensive"
    },
    {
        "id": "435",
        "name": "Stanford EXPLORE Lecture Series",
        "hyphenated": "stanford-explore-lecture-series"
    },
    {
        "id": "436",
        "name": "Stanford Healthcare Tri-Valley Clinical Academy",
        "hyphenated": "stanford-healthcare-tri-valley-clinical-academy"
    },
    {
        "id": "437",
        "name": "Stanford Institutes of Medicine Summer Research Program (SIMR)",
        "hyphenated": "stanford-institutes-of-medicine-summer-research-program-(simr)"
    },
    {
        "id": "438",
        "name": "Stanford Math Circles",
        "hyphenated": "stanford-math-circles"
    },
    {
        "id": "439",
        "name": "Stanford Medical Youth Science Program",
        "hyphenated": "stanford-medical-youth-science-program"
    },
    {
        "id": "440",
        "name": "Stanford Medicine Art and Anatomy Summer Program",
        "hyphenated": "stanford-medicine-art-and-anatomy-summer-program"
    },
    {
        "id": "441",
        "name": "Stanford Middle School Scholars Program",
        "hyphenated": "stanford-middle-school-scholars-program"
    },
    {
        "id": "442",
        "name": "Stanford National Forensic Institute",
        "hyphenated": "stanford-national-forensic-institute"
    },
    {
        "id": "443",
        "name": "Stanford Pre-Collegiate Summer Institutes",
        "hyphenated": "stanford-pre-collegiate-summer-institutes"
    },
    {
        "id": "444",
        "name": "Stanford Pre-Collegiate University-Level Online Immersive",
        "hyphenated": "stanford-pre-collegiate-university-level-online-immersive"
    },
    {
        "id": "445",
        "name": "Stanford Pre-Collegiate University-Level Online Math & Physics",
        "hyphenated": "stanford-pre-collegiate-university-level-online-math-&-physics"
    },
    {
        "id": "446",
        "name": "Stanford Program for Inspiring the Next Generation of Women in Physics",
        "hyphenated": "stanford-program-for-inspiring-the-next-generation-of-women-in-physics"
    },
    {
        "id": "447",
        "name": "Stanford Science Technology and Reconstructive Surgery (STaRS) Internship Program",
        "hyphenated": "stanford-science-technology-and-reconstructive-surgery-(stars)-internship-program"
    },
    {
        "id": "448",
        "name": "Stanford Summer Humanities Institute",
        "hyphenated": "stanford-summer-humanities-institute"
    },
    {
        "id": "449",
        "name": "Stanford Summer Research Internships",
        "hyphenated": "stanford-summer-research-internships"
    },
    {
        "id": "450",
        "name": "Stanford Summer Session",
        "hyphenated": "stanford-summer-session"
    },
    {
        "id": "451",
        "name": "Stanford University Mathematics Camp (SUMaC - Online)",
        "hyphenated": "stanford-university-mathematics-camp-(sumac-online)"
    },
    {
        "id": "452",
        "name": "Stanford University Mathematics Camp (SUMaC - Residential)",
        "hyphenated": "stanford-university-mathematics-camp-(sumac-residential)"
    },
    {
        "id": "453",
        "name": "Stanford Young Investigators",
        "hyphenated": "stanford-young-investigators"
    },
    {
        "id": "454",
        "name": "Star League",
        "hyphenated": "star-league"
    },
    {
        "id": "455",
        "name": "Start A Business",
        "hyphenated": "start-a-business"
    },
    {
        "id": "456",
        "name": "Start A Non-profit",
        "hyphenated": "start-a-non-profit"
    },
    {
        "id": "457",
        "name": "Start A School Club",
        "hyphenated": "start-a-school-club"
    },
    {
        "id": "458",
        "name": "StellarXplorers National Space Design Competition",
        "hyphenated": "stellarxplorers-national-space-design-competition"
    },
    {
        "id": "459",
        "name": "STEM Enhancement in Earth Science (SEES) Summer High School Intern Program",
        "hyphenated": "stem-enhancement-in-earth-science-(sees)-summer-high-school-intern-program"
    },
    {
        "id": "460",
        "name": "STEM Fellowship Journal",
        "hyphenated": "stem-fellowship-journal"
    },
    {
        "id": "461",
        "name": "Stones and Bones",
        "hyphenated": "stones-and-bones"
    },
    {
        "id": "462",
        "name": "Student Historian Internship Program",
        "hyphenated": "student-historian-internship-program"
    },
    {
        "id": "463",
        "name": "Student Journalist Impact Award",
        "hyphenated": "student-journalist-impact-award"
    },
    {
        "id": "464",
        "name": "Subiendo: The Academy for Rising Leaders",
        "hyphenated": "subiendo:-the-academy-for-rising-leaders"
    },
    {
        "id": "465",
        "name": "Summer Academy for Math and Science (SAMS)",
        "hyphenated": "summer-academy-for-math-and-science-(sams)"
    },
    {
        "id": "466",
        "name": "Boston University Summer Journalism Academy (On Campus)",
        "hyphenated": "boston-university-summer-journalism-academy-(on-campus)"
    },
    {
        "id": "467",
        "name": "Summer Journalism Academy (Online)",
        "hyphenated": "summer-journalism-academy-(online)"
    },
    {
        "id": "468",
        "name": "Summer Program for Applied Rationality and Cognition",
        "hyphenated": "summer-program-for-applied-rationality-and-cognition"
    },
    {
        "id": "469",
        "name": "Summer Science Program (SSP)",
        "hyphenated": "summer-science-program-(ssp)"
    },
    {
        "id": "470",
        "name": "Summer@Brown",
        "hyphenated": "summer@brown"
    },
    {
        "id": "471",
        "name": "Telluride Association Summer Seminar (TASS)",
        "hyphenated": "telluride-association-summer-seminar-(tass)"
    },
    {
        "id": "472",
        "name": "Tests of Engineering Aptitude, Mathematics, and Science",
        "hyphenated": "tests-of-engineering-aptitude-mathematics-and-science"
    },
    {
        "id": "473",
        "name": "Texas Tech University\u2019s Anson L. Clark Scholars Program",
        "hyphenated": "texas-tech-university\u2019s-anson-l.-clark-scholars-program"
    },
    {
        "id": "474",
        "name": "The Cherubs at Northwestern University",
        "hyphenated": "the-cherubs-at-northwestern-university"
    },
    {
        "id": "475",
        "name": "The Claudia Ann Seaman Awards For Young Writers",
        "hyphenated": "the-claudia-ann-seaman-awards-for-young-writers"
    },
    {
        "id": "476",
        "name": "The Coca-Cola Scholars Program Scholarship",
        "hyphenated": "the-coca-cola-scholars-program-scholarship"
    },
    {
        "id": "477",
        "name": "The Conrad Challenge",
        "hyphenated": "the-conrad-challenge"
    },
    {
        "id": "478",
        "name": "The Earth Prize",
        "hyphenated": "the-earth-prize"
    },
    {
        "id": "479",
        "name": "The Gates Scholarship",
        "hyphenated": "the-gates-scholarship"
    },
    {
        "id": "480",
        "name": "The Johns Hopkins Internship in Brain Sciences",
        "hyphenated": "the-johns-hopkins-internship-in-brain-sciences"
    },
    {
        "id": "481",
        "name": "The Met Museum School-Year High School Internship",
        "hyphenated": "the-met-museum-school-year-high-school-internship"
    },
    {
        "id": "482",
        "name": "The Michigan Ross Summer Business Academy",
        "hyphenated": "the-michigan-ross-summer-business-academy"
    },
    {
        "id": "483",
        "name": "The Schola",
        "hyphenated": "the-schola"
    },
    {
        "id": "484",
        "name": "The School for Science and Math at Vanderbilt",
        "hyphenated": "the-school-for-science-and-math-at-vanderbilt"
    },
    {
        "id": "485",
        "name": "The School of the New York Times NYC Summer Academy",
        "hyphenated": "the-school-of-the-new-york-times-nyc-summer-academy"
    },
    {
        "id": "486",
        "name": "The University of Iowa Belin-Blank Center Summer Writing Online",
        "hyphenated": "the-university-of-iowa-belin-blank-center-summer-writing-online"
    },
    {
        "id": "487",
        "name": "The Whitman Journal of Psychology",
        "hyphenated": "the-whitman-journal-of-psychology"
    },
    {
        "id": "488",
        "name": "The Young Researcher",
        "hyphenated": "the-young-researcher"
    },
    {
        "id": "489",
        "name": "Thomas Aquinas College's Great Books Summer Program",
        "hyphenated": "thomas-aquinas-college's-great-books-summer-program"
    },
    {
        "id": "490",
        "name": "TOPSS Competition for High School Psychology Students",
        "hyphenated": "topss-competition-for-high-school-psychology-students"
    },
    {
        "id": "491",
        "name": "Tournament of Champions",
        "hyphenated": "tournament-of-champions"
    },
    {
        "id": "492",
        "name": "TryEngineering Summer Institute",
        "hyphenated": "tryengineering-summer-institute"
    },
    {
        "id": "493",
        "name": "Tufts High School Pre College Programs",
        "hyphenated": "tufts-high-school-pre-college-programs"
    },
    {
        "id": "494",
        "name": "Tufts Summer Research Experience",
        "hyphenated": "tufts-summer-research-experience"
    },
    {
        "id": "495",
        "name": "Tufts Summit Program",
        "hyphenated": "tufts-summit-program"
    },
    {
        "id": "496",
        "name": "Tufts University Biomedical Engineering Research Scholars (TUBERS) Program",
        "hyphenated": "tufts-university-biomedical-engineering-research-scholars-(tubers)-program"
    },
    {
        "id": "497",
        "name": "U.S. National Chemistry Olympiad",
        "hyphenated": "u.s.-national-chemistry-olympiad"
    },
    {
        "id": "498",
        "name": "UC Berkeley Academic Talent Development Program Secondary Division",
        "hyphenated": "uc-berkeley-academic-talent-development-program-secondary-division"
    },
    {
        "id": "499",
        "name": "UC Berkeley Pre-College Scholars Summer Commuter Track",
        "hyphenated": "uc-berkeley-pre-college-scholars-summer-commuter-track"
    },
    {
        "id": "500",
        "name": "UC Berkeley Pre-College Scholars Summer Residential Track",
        "hyphenated": "uc-berkeley-pre-college-scholars-summer-residential-track"
    },
    {
        "id": "501",
        "name": "UC Berkeley Pre-College Scholars Summer Virtual Track",
        "hyphenated": "uc-berkeley-pre-college-scholars-summer-virtual-track"
    },
    {
        "id": "502",
        "name": "UC Berkeley Summer Computer Science Academy",
        "hyphenated": "uc-berkeley-summer-computer-science-academy"
    },
    {
        "id": "503",
        "name": "UCI Youth Science Fellowship Program",
        "hyphenated": "uci-youth-science-fellowship-program"
    },
    {
        "id": "504",
        "name": "UCLA Bruin Ascent Summer Experience (BASE)",
        "hyphenated": "ucla-bruin-ascent-summer-experience-(base)"
    },
    {
        "id": "505",
        "name": "UCLA Precollege Summer Institutes",
        "hyphenated": "ucla-precollege-summer-institutes"
    },
    {
        "id": "506",
        "name": "UCLA Summer College Immersion Program",
        "hyphenated": "ucla-summer-college-immersion-program"
    },
    {
        "id": "507",
        "name": "UCLA Summer College Immersion Program (Virtual)",
        "hyphenated": "ucla-summer-college-immersion-program-(virtual)"
    },
    {
        "id": "508",
        "name": "UCLA Summer College Immersion Program + Research",
        "hyphenated": "ucla-summer-college-immersion-program-+-research"
    },
    {
        "id": "509",
        "name": "UCLA Summer Courses",
        "hyphenated": "ucla-summer-courses"
    },
    {
        "id": "510",
        "name": "UCLA Summer Intensives",
        "hyphenated": "ucla-summer-intensives"
    },
    {
        "id": "511",
        "name": "UCLA Summer Online",
        "hyphenated": "ucla-summer-online"
    },
    {
        "id": "512",
        "name": "UCSB Young Scholars Program",
        "hyphenated": "ucsb-young-scholars-program"
    },
    {
        "id": "513",
        "name": "UGA Young Dawgs Program",
        "hyphenated": "uga-young-dawgs-program"
    },
    {
        "id": "514",
        "name": "University of Miami (UM) Academies",
        "hyphenated": "university-of-miami-(um)-academies"
    },
    {
        "id": "515",
        "name": "Understanding Your Money",
        "hyphenated": "understanding-your-money"
    },
    {
        "id": "516",
        "name": "United States Association for Young Physicists Tournament",
        "hyphenated": "united-states-association-for-young-physicists-tournament"
    },
    {
        "id": "517",
        "name": "United States Astronomy and Astrophysics Olympiad",
        "hyphenated": "united-states-astronomy-and-astrophysics-olympiad"
    },
    {
        "id": "518",
        "name": "United States of America Junior Mathematical Olympiad (USAJMO)",
        "hyphenated": "united-states-of-america-junior-mathematical-olympiad-(usajmo)"
    },
    {
        "id": "519",
        "name": "United States of America Mathematical Olympiad (USAMO)",
        "hyphenated": "united-states-of-america-mathematical-olympiad-(usamo)"
    },
    {
        "id": "520",
        "name": "United States Secret Service Student Volunteer Program",
        "hyphenated": "united-states-secret-service-student-volunteer-program"
    },
    {
        "id": "521",
        "name": "University of California Irvine X Gifted and Talented Institute Program",
        "hyphenated": "university-of-california-irvine-x-gifted-and-talented-institute-program"
    },
    {
        "id": "522",
        "name": "University of Chicago Enrichment Programs",
        "hyphenated": "university-of-chicago-enrichment-programs"
    },
    {
        "id": "523",
        "name": "University of Chicago Pre-College Connect",
        "hyphenated": "university-of-chicago-pre-college-connect"
    },
    {
        "id": "524",
        "name": "University of Chicago Summer College",
        "hyphenated": "university-of-chicago-summer-college"
    },
    {
        "id": "525",
        "name": "University of Chicago Summer Immersion",
        "hyphenated": "university-of-chicago-summer-immersion"
    },
    {
        "id": "526",
        "name": "University of Chicago Summer Language Institute",
        "hyphenated": "university-of-chicago-summer-language-institute"
    },
    {
        "id": "527",
        "name": "University of Chicago Summer Online",
        "hyphenated": "university-of-chicago-summer-online"
    },
    {
        "id": "528",
        "name": "University of Florida Student Science Training Program (UF SSTP)",
        "hyphenated": "university-of-florida-student-science-training-program-(uf-sstp)"
    },
    {
        "id": "529",
        "name": "University of Miami Summer Scholars Program",
        "hyphenated": "university-of-miami-summer-scholars-program"
    },
    {
        "id": "530",
        "name": "University of Pennsylvania Summer Academies, Global Culture and Media Academy",
        "hyphenated": "university-of-pennsylvania-summer-academies-global-culture-and-media-academy"
    },
    {
        "id": "531",
        "name": "University of Pennsylvania Summer Academy: American Sign Language and Deaf Culture",
        "hyphenated": "university-of-pennsylvania-summer-academy:-american-sign-language-and-deaf-culture"
    },
    {
        "id": "532",
        "name": "University of Pennsylvania Summer Academy: Biomedical Research",
        "hyphenated": "university-of-pennsylvania-summer-academy:-biomedical-research"
    },
    {
        "id": "533",
        "name": "University of Pennsylvania Summer Academy: Chemistry Research ",
        "hyphenated": "university-of-pennsylvania-summer-academy:-chemistry-research"
    },
    {
        "id": "534",
        "name": "University of Pennsylvania Summer Academy: Coding ",
        "hyphenated": "university-of-pennsylvania-summer-academy:-coding"
    },
    {
        "id": "535",
        "name": "University of Pennsylvania Summer Academy: Experimental Physics ",
        "hyphenated": "university-of-pennsylvania-summer-academy:-experimental-physics"
    },
    {
        "id": "536",
        "name": "University of Pennsylvania Summer Academy: Mathematics ",
        "hyphenated": "university-of-pennsylvania-summer-academy:-mathematics"
    },
    {
        "id": "537",
        "name": "University of Pennsylvania Summer Academy: Neuroscience Research ",
        "hyphenated": "university-of-pennsylvania-summer-academy:-neuroscience-research"
    },
    {
        "id": "538",
        "name": "University of Pennsylvania Summer Academy: Social Justice Research ",
        "hyphenated": "university-of-pennsylvania-summer-academy:-social-justice-research"
    },
    {
        "id": "539",
        "name": "University of Southern California Bovard Scholars Program",
        "hyphenated": "university-of-southern-california-bovard-scholars-program"
    },
    {
        "id": "540",
        "name": "University of Texas Dallas Top Trader Stock Market Competition",
        "hyphenated": "university-of-texas-dallas-top-trader-stock-market-competition"
    },
    {
        "id": "541",
        "name": "US Air Force Academy Summer Seminar",
        "hyphenated": "us-air-force-academy-summer-seminar"
    },
    {
        "id": "542",
        "name": "US Geography Championships",
        "hyphenated": "us-geography-championships"
    },
    {
        "id": "543",
        "name": "US History Bee",
        "hyphenated": "us-history-bee"
    },
    {
        "id": "544",
        "name": "US Military Academy Summer Leaders Experience (SLE)",
        "hyphenated": "us-military-academy-summer-leaders-experience-(sle)"
    },
    {
        "id": "545",
        "name": "US Presidential Scholars Program",
        "hyphenated": "us-presidential-scholars-program"
    },
    {
        "id": "546",
        "name": "USA Biolympiad",
        "hyphenated": "usa-biolympiad"
    },
    {
        "id": "547",
        "name": "USA Computing Olympiad",
        "hyphenated": "usa-computing-olympiad"
    },
    {
        "id": "548",
        "name": "USA Mathematical Talent Search (USAMTS)",
        "hyphenated": "usa-mathematical-talent-search-(usamts)"
    },
    {
        "id": "549",
        "name": "USC Pre-College Online Exploration",
        "hyphenated": "usc-pre-college-online-exploration"
    },
    {
        "id": "550",
        "name": "USC Summer Programs",
        "hyphenated": "usc-summer-programs"
    },
    {
        "id": "551",
        "name": "USC Viterbi's Summer High School Intensive in Next-Generation Engineering (SHINE)",
        "hyphenated": "usc-viterbi's-summer-high-school-intensive-in-next-generation-engineering-(shine)"
    },
    {
        "id": "552",
        "name": "UT Austin High School Research Academy",
        "hyphenated": "ut-austin-high-school-research-academy"
    },
    {
        "id": "553",
        "name": "Vanderbilt Blair Academy",
        "hyphenated": "vanderbilt-blair-academy"
    },
    {
        "id": "554",
        "name": "Vanderbilt Mentor Immersion: Academic Year Experience (Grades 10-12)",
        "hyphenated": "vanderbilt-mentor-immersion:-academic-year-experience-(grades-10-12)"
    },
    {
        "id": "555",
        "name": "VEX Robotics Competitions",
        "hyphenated": "vex-robotics-competitions"
    },
    {
        "id": "556",
        "name": "Video Games for Health",
        "hyphenated": "video-games-for-health"
    },
    {
        "id": "557",
        "name": "Virginia Tech Center for Health Behaviors Research Summer Research Program",
        "hyphenated": "virginia-tech-center-for-health-behaviors-research-summer-research-program"
    },
    {
        "id": "558",
        "name": "Wake Forest Bioscience Institute",
        "hyphenated": "wake-forest-bioscience-institute"
    },
    {
        "id": "559",
        "name": "Wake Forest Business Institute",
        "hyphenated": "wake-forest-business-institute"
    },
    {
        "id": "560",
        "name": "Wake Forest Debate and Public Advocacy Institute",
        "hyphenated": "wake-forest-debate-and-public-advocacy-institute"
    },
    {
        "id": "561",
        "name": "Wake Forest Engineering Institute",
        "hyphenated": "wake-forest-engineering-institute"
    },
    {
        "id": "562",
        "name": "Wake Forest Entrepreneurship Institute",
        "hyphenated": "wake-forest-entrepreneurship-institute"
    },
    {
        "id": "563",
        "name": "Wake Forest Filmmaking Institute",
        "hyphenated": "wake-forest-filmmaking-institute"
    },
    {
        "id": "564",
        "name": "Wake Forest Finance and Investing Institute",
        "hyphenated": "wake-forest-finance-and-investing-institute"
    },
    {
        "id": "565",
        "name": "Wake Forest Health and Exercise Science Institute",
        "hyphenated": "wake-forest-health-and-exercise-science-institute"
    },
    {
        "id": "566",
        "name": "Wake Forest Law Institute",
        "hyphenated": "wake-forest-law-institute"
    },
    {
        "id": "567",
        "name": "Wake Forest Leadership Institute",
        "hyphenated": "wake-forest-leadership-institute"
    },
    {
        "id": "568",
        "name": "Wake Forest Medicine Institute",
        "hyphenated": "wake-forest-medicine-institute"
    },
    {
        "id": "569",
        "name": "Wake Forest Neuroscience Institute",
        "hyphenated": "wake-forest-neuroscience-institute"
    },
    {
        "id": "570",
        "name": "Wake Forest Online Business Programs",
        "hyphenated": "wake-forest-online-business-programs"
    },
    {
        "id": "571",
        "name": "Wake Forest Online Law Programs",
        "hyphenated": "wake-forest-online-law-programs"
    },
    {
        "id": "572",
        "name": "Wake Forest Online Medicine Programs",
        "hyphenated": "wake-forest-online-medicine-programs"
    },
    {
        "id": "573",
        "name": "Wake Forest Policy and Politics Institute",
        "hyphenated": "wake-forest-policy-and-politics-institute"
    },
    {
        "id": "574",
        "name": "Wake Forest Psychology Institute",
        "hyphenated": "wake-forest-psychology-institute"
    },
    {
        "id": "575",
        "name": "Wake Forest Screenwriting Institute",
        "hyphenated": "wake-forest-screenwriting-institute"
    },
    {
        "id": "576",
        "name": "Wake Forest Sports Business Institute",
        "hyphenated": "wake-forest-sports-business-institute"
    },
    {
        "id": "577",
        "name": "Wake Forest Sports Medicine Institute",
        "hyphenated": "wake-forest-sports-medicine-institute"
    },
    {
        "id": "578",
        "name": "Wake Forest Technology Institute",
        "hyphenated": "wake-forest-technology-institute"
    },
    {
        "id": "579",
        "name": "Wake Forest Writing for Life Institute",
        "hyphenated": "wake-forest-writing-for-life-institute"
    },
    {
        "id": "580",
        "name": "Washington Performing Arts Internship",
        "hyphenated": "washington-performing-arts-internship"
    },
    {
        "id": "581",
        "name": "Washington University in St. Louis Architecture Discovery Program",
        "hyphenated": "washington-university-in-st.-louis-architecture-discovery-program"
    },
    {
        "id": "582",
        "name": "Washington University in St. Louis BOLD@Olin",
        "hyphenated": "washington-university-in-st.-louis-bold@olin"
    },
    {
        "id": "583",
        "name": "Washington University in St. Louis Early College Scholars Program",
        "hyphenated": "washington-university-in-st.-louis-early-college-scholars-program"
    },
    {
        "id": "584",
        "name": "Washington University in St. Louis Exploration Courses",
        "hyphenated": "washington-university-in-st.-louis-exploration-courses"
    },
    {
        "id": "585",
        "name": "Washington University in St. Louis Gateway to Business",
        "hyphenated": "washington-university-in-st.-louis-gateway-to-business"
    },
    {
        "id": "586",
        "name": "Washington University in St. Louis High School Summer Institutes",
        "hyphenated": "washington-university-in-st.-louis-high-school-summer-institutes"
    },
    {
        "id": "587",
        "name": "Washington University in St. Louis High School Summer Launch",
        "hyphenated": "washington-university-in-st.-louis-high-school-summer-launch"
    },
    {
        "id": "588",
        "name": "Washington University in St. Louis High School Summer Scholars Program",
        "hyphenated": "washington-university-in-st.-louis-high-school-summer-scholars-program"
    },
    {
        "id": "589",
        "name": "Washington University in St. Louis Middle School Summer Challenge",
        "hyphenated": "washington-university-in-st.-louis-middle-school-summer-challenge"
    },
    {
        "id": "590",
        "name": "Washington University in St. Louis Portfolio Plus Program",
        "hyphenated": "washington-university-in-st.-louis-portfolio-plus-program"
    },
    {
        "id": "591",
        "name": "Washington University in St. Louis Rural Scholars Academy",
        "hyphenated": "washington-university-in-st.-louis-rural-scholars-academy"
    },
    {
        "id": "592",
        "name": "Washington University in St. Louis Shaw Institute for Field Training (SIFT)",
        "hyphenated": "washington-university-in-st.-louis-shaw-institute-for-field-training-(sift)"
    },
    {
        "id": "593",
        "name": "Washington University in St. Louis Summer Focus",
        "hyphenated": "washington-university-in-st.-louis-summer-focus"
    },
    {
        "id": "594",
        "name": "Weekend at Vanderbilt University",
        "hyphenated": "weekend-at-vanderbilt-university"
    },
    {
        "id": "595",
        "name": "Wharton Data Science Academy",
        "hyphenated": "wharton-data-science-academy"
    },
    {
        "id": "596",
        "name": "Wharton Essentials of Entrepreneurship",
        "hyphenated": "wharton-essentials-of-entrepreneurship"
    },
    {
        "id": "597",
        "name": "Wharton Essentials of Finance",
        "hyphenated": "wharton-essentials-of-finance"
    },
    {
        "id": "598",
        "name": "Wharton Financial Decision Making",
        "hyphenated": "wharton-financial-decision-making"
    },
    {
        "id": "599",
        "name": "Wharton Future of the Business World",
        "hyphenated": "wharton-future-of-the-business-world"
    },
    {
        "id": "600",
        "name": "Wharton Global Youth Investment Competition",
        "hyphenated": "wharton-global-youth-investment-competition"
    },
    {
        "id": "601",
        "name": "Wharton Leadership In The Business World (LBW)",
        "hyphenated": "wharton-leadership-in-the-business-world-(lbw)"
    },
    {
        "id": "602",
        "name": "Wharton Moneyball Academy",
        "hyphenated": "wharton-moneyball-academy"
    },
    {
        "id": "603",
        "name": "Wharton Moneyball Academy: Training Camp",
        "hyphenated": "wharton-moneyball-academy:-training-camp"
    },
    {
        "id": "604",
        "name": "Wharton Product Design Academy",
        "hyphenated": "wharton-product-design-academy"
    },
    {
        "id": "605",
        "name": "Women's Technology Program",
        "hyphenated": "women's-technology-program"
    },
    {
        "id": "606",
        "name": "World Economics Cup",
        "hyphenated": "world-economics-cup"
    },
    {
        "id": "607",
        "name": "World Historian Student Essay Competition",
        "hyphenated": "world-historian-student-essay-competition"
    },
    {
        "id": "608",
        "name": "X Culture International Business Academy",
        "hyphenated": "x-culture-international-business-academy"
    },
    {
        "id": "609",
        "name": "Yale Bassett Award for Community Engagement",
        "hyphenated": "yale-bassett-award-for-community-engagement"
    },
    {
        "id": "610",
        "name": "Yale Center for Clinical Investigation Research Internship",
        "hyphenated": "yale-center-for-clinical-investigation-research-internship"
    },
    {
        "id": "611",
        "name": "Yale Citizens Thinkers Writers",
        "hyphenated": "yale-citizens-thinkers-writers"
    },
    {
        "id": "612",
        "name": "Yale Discovery to Cure",
        "hyphenated": "yale-discovery-to-cure"
    },
    {
        "id": "613",
        "name": "Yale Pathways Research Internships",
        "hyphenated": "yale-pathways-research-internships"
    },
    {
        "id": "614",
        "name": "Yale Summer Debate Program",
        "hyphenated": "yale-summer-debate-program"
    },
    {
        "id": "615",
        "name": "Yale Summer Journalism Program",
        "hyphenated": "yale-summer-journalism-program"
    },
    {
        "id": "616",
        "name": "Yale Summer Program in Astrophysics (YSPA)",
        "hyphenated": "yale-summer-program-in-astrophysics-(yspa)"
    },
    {
        "id": "617",
        "name": "Yale Summer Session ",
        "hyphenated": "yale-summer-session"
    },
    {
        "id": "618",
        "name": "Yale Summer Session Drama Program",
        "hyphenated": "yale-summer-session-drama-program"
    },
    {
        "id": "619",
        "name": "Yale Summer Session University Preparation for International High School Students",
        "hyphenated": "yale-summer-session-university-preparation-for-international-high-school-students"
    },
    {
        "id": "620",
        "name": "Yale Summer Session Young Writer's Workshop",
        "hyphenated": "yale-summer-session-young-writer's-workshop"
    },
    {
        "id": "621",
        "name": "Yale Women in Economics Fellowship",
        "hyphenated": "yale-women-in-economics-fellowship"
    },
    {
        "id": "622",
        "name": "Yale Young Global Scholars (YYGS)",
        "hyphenated": "yale-young-global-scholars-(yygs)"
    },
    {
        "id": "623",
        "name": "Young Authors Writing Competition",
        "hyphenated": "young-authors-writing-competition"
    },
    {
        "id": "624",
        "name": "Young Entrepreneurs Academy",
        "hyphenated": "young-entrepreneurs-academy"
    },
    {
        "id": "625",
        "name": "Young Investors Society Certified Young Investment Analyst",
        "hyphenated": "young-investors-society-certified-young-investment-analyst"
    },
    {
        "id": "626",
        "name": "Young Investors Society Global Stock Pitch Competition",
        "hyphenated": "young-investors-society-global-stock-pitch-competition"
    },
    {
        "id": "627",
        "name": "Young Scholars at UC Davis",
        "hyphenated": "young-scholars-at-uc-davis"
    },
    {
        "id": "628",
        "name": "Young Scholars at UPenn",
        "hyphenated": "young-scholars-at-upenn"
    },
    {
        "id": "629",
        "name": "Young Scientist Journal",
        "hyphenated": "young-scientist-journal"
    },
    {
        "id": "630",
        "name": "Youth Citizen Entrepreneurship Competition",
        "hyphenated": "youth-citizen-entrepreneurship-competition"
    },
    {
        "id": "631",
        "name": "Youth Entrepreneurship Challenge",
        "hyphenated": "youth-entrepreneurship-challenge"
    },
    {
        "id": "632",
        "name": "Youth Leadership Wake Forest",
        "hyphenated": "youth-leadership-wake-forest"
    },
    {
        "id": "633",
        "name": "Youth Medical Journal",
        "hyphenated": "youth-medical-journal"
    },
    {
        "id": "634",
        "name": "Zero Robotics Middle School Program",
        "hyphenated": "zero-robotics-middle-school-program"
    },
    {
        "id": "635",
        "name": "Zuckerman Institute\u2019s Brain Research Apprenticeships in New York at Columbia",
        "hyphenated": "zuckerman-institute\u2019s-brain-research-apprenticeships-in-new-york-at-columbia"
    },
    {
        "id": "636",
        "name": "Davidson Young Scholars",
        "hyphenated": "davidson-young-scholars"
    },
    {
        "id": "637",
        "name": "High School International Economics Essay",
        "hyphenated": "high-school-international-economics-essay"
    },
    {
        "id": "638",
        "name": "DIYA Research AI Exploration",
        "hyphenated": "diya-research-ai-exploration"
    },
    {
        "id": "639",
        "name": "Creating the Future Scholarship",
        "hyphenated": "creating-the-future-scholarship"
    },
    {
        "id": "640",
        "name": "Youth Neuroscience Summer Program",
        "hyphenated": "youth-neuroscience-summer-program"
    },
    {
        "id": "641",
        "name": "Genes and Health Contest",
        "hyphenated": "genes-and-health-contest"
    },
    {
        "id": "642",
        "name": "mirCore Research Camp - Computational Biology",
        "hyphenated": "mircore-research-camp-computational-biology"
    },
    {
        "id": "643",
        "name": "The Met Museum Summer High School Internship",
        "hyphenated": "the-met-museum-summer-high-school-internship"
    },
    {
        "id": "644",
        "name": "Concord Review History Camp Winter Vacation",
        "hyphenated": "concord-review-history-camp-winter-vacation"
    },
    {
        "id": "645",
        "name": "Concord Review History Camp Winter Weekend",
        "hyphenated": "concord-review-history-camp-winter-weekend"
    },
    {
        "id": "646",
        "name": "Berkeley Business Academy for Youth (BBAY) Middle School Entrepreneurship Program",
        "hyphenated": "berkeley-business-academy-for-youth-(bbay)-middle-school-entrepreneurship-program"
    },
    {
        "id": "647",
        "name": "Berkeley Business Academy for Youth (BBAY) Middle School After-School Program",
        "hyphenated": "berkeley-business-academy-for-youth-(bbay)-middle-school-after-school-program"
    },
    {
        "id": "648",
        "name": "Berkeley Business Academy for Youth (BBAY) High School Academic Year Program",
        "hyphenated": "berkeley-business-academy-for-youth-(bbay)-high-school-academic-year-program"
    },
    {
        "id": "649",
        "name": "Kenyon Review Young Writers Winter Online Workshop",
        "hyphenated": "kenyon-review-young-writers-winter-online-workshop"
    },
    {
        "id": "650",
        "name": "Pegasus Mythology Exam Contest",
        "hyphenated": "pegasus-mythology-exam-contest"
    },
    {
        "id": "651",
        "name": "National Roman Civilization Exam",
        "hyphenated": "national-roman-civilization-exam"
    },
    {
        "id": "652",
        "name": "National Classical Etymology Exam",
        "hyphenated": "national-classical-etymology-exam"
    },
    {
        "id": "653",
        "name": "UCSB Research Mentorship Program",
        "hyphenated": "ucsb-research-mentorship-program"
    },
    {
        "id": "654",
        "name": "US Senate Youth Program (USSYP)",
        "hyphenated": "us-senate-youth-program-(ussyp)"
    },
    {
        "id": "655",
        "name": "Robertson Scholars Leadership Program",
        "hyphenated": "robertson-scholars-leadership-program"
    },
    {
        "id": "656",
        "name": "Junior Scientists and Young Explorers Meta (JYEM) Research Competition",
        "hyphenated": "junior-scientists-and-young-explorers-meta-(jyem)-research-competition"
    },
    {
        "id": "657",
        "name": "UC Berkeley Academic Talent Development Program Elementary Division",
        "hyphenated": "uc-berkeley-academic-talent-development-program-elementary-division"
    },
    {
        "id": "658",
        "name": "University of Pennsylvania Summer Academy: Chemistry Research ",
        "hyphenated": "university-of-pennsylvania-summer-academy:-chemistry-research"
    },
    {
        "id": "659",
        "name": "University of Pennsylvania Summer Academy: Microbiology and Infectious Diseases",
        "hyphenated": "university-of-pennsylvania-summer-academy:-microbiology-and-infectious-diseases"
    },
    {
        "id": "660",
        "name": "University of Pennsylvania Summer Academy: Economics",
        "hyphenated": "university-of-pennsylvania-summer-academy:-economics"
    },
    {
        "id": "661",
        "name": "Dartmouth Leadership Institute",
        "hyphenated": "dartmouth-leadership-institute"
    },
    {
        "id": "662",
        "name": "Applied Research Innovations in Science and Engineering (ARISE)",
        "hyphenated": "applied-research-innovations-in-science-and-engineering-(arise)"
    },
    {
        "id": "663",
        "name": "NASA Office of STEM Engagement (OSTEM) Internships",
        "hyphenated": "nasa-office-of-stem-engagement-(ostem)-internships"
    },
    {
        "id": "664",
        "name": "Rockefeller Summer Science Research Program",
        "hyphenated": "rockefeller-summer-science-research-program"
    },
    {
        "id": "665",
        "name": "The Research Experience for High School Students Program",
        "hyphenated": "the-research-experience-for-high-school-students-program"
    },
    {
        "id": "666",
        "name": "UCSD Research Scholars",
        "hyphenated": "ucsd-research-scholars"
    },
    {
        "id": "667",
        "name": "University of Wisconsin-Madison Summer Engineering Program",
        "hyphenated": "university-of-wisconsin-madison-summer-engineering-program"
    },
    {
        "id": "668",
        "name": "UVA Summer Language Institute",
        "hyphenated": "uva-summer-language-institute"
    },
    {
        "id": "669",
        "name": "Army Educational Outreach Program (AEOP) High School Apprenticeship",
        "hyphenated": "army-educational-outreach-program-(aeop)-high-school-apprenticeship"
    },
    {
        "id": "670",
        "name": "JCamp",
        "hyphenated": "jcamp"
    },
    {
        "id": "671",
        "name": "MathILy",
        "hyphenated": "mathily"
    },
    {
        "id": "672",
        "name": "UMass Research Intensives",
        "hyphenated": "umass-research-intensives"
    },
    {
        "id": "673",
        "name": "NYU GSTEM",
        "hyphenated": "nyu-gstem"
    },
    {
        "id": "674",
        "name": "AMP Global Scholar",
        "hyphenated": "amp-global-scholar"
    },
    {
        "id": "675",
        "name": "JTB Summer Legal Institute",
        "hyphenated": "jtb-summer-legal-institute"
    }
];
