import { URL } from "../dictionary"
import axios from "axios"
import { getAuth, useAuth } from "../../modules/auth"
import { TestScoreStrategyReactGAHandlers } from "../ReactGAHandlers"
let apiToken: any = undefined

if (getAuth()?.api_token && getAuth()?.groups) {
  apiToken = getAuth()?.api_token
}

const editScoreModalFetchData = async (user, currentUser, setFormValues) => {

  try {

    let userID = user?.user_id || currentUser?.user_id

    const userDataAxios = axios.get(`${URL}/user_profiles/${userID}`)

    const [userDataRes] = await Promise.all([
      userDataAxios
    ])

    await Promise.all([
      setFormValues({
        satVerbal: userDataRes.data.satVerbal,
        satMath: userDataRes.data.satMath,
        actComposite: userDataRes.data.actComposite,
        actEnglish: userDataRes.data.actEnglish,
        actMath: userDataRes.data.actMath,
        actWriting: userDataRes.data.actWriting,
        actScience: userDataRes.data.actScience,
        actReading: userDataRes.data.actReading
      })
    ])

  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

export const EditScoreProfile = ({ user, setFormValues, mobile = false, pill = false }) => {

  const {
    handleGAEventEditScoreProfile
  } = TestScoreStrategyReactGAHandlers()

  const { currentUser } = useAuth()

  return pill ? (<button
    type="button"
    className='dca-test-score-modal-enter-scores-pill'
    data-bs-toggle="modal"
    data-bs-target="#editScoreProfile"
    onClick={() => {
      editScoreModalFetchData(user, currentUser, setFormValues);
      handleGAEventEditScoreProfile('card')
    }
    }
  >
    Enter Score(s) For Your Recommendation
  </button>) : (
    <div className={mobile ? 'd-flex justify-content-center' : ''}>
      <button
        type="button"
        className='dca-test-score-edit-your-score-profile-button'
        onClick={() => {
          editScoreModalFetchData(user, currentUser, setFormValues);
          handleGAEventEditScoreProfile('aside')
        }}
        data-bs-toggle="modal"
        data-bs-target="#editScoreProfile"
      >
        Edit Your Score Profile
      </button>
    </div>
  )
}
