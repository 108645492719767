import premiumIcon from '../../../custom_assets/dashboard/premium.png'
import insightIcon from '../../../custom_assets/dashboard/insight.png'
import ai_red from '../../../custom_assets/dashboard/ai_red.png'
import ai_yellow from '../../../custom_assets/dashboard/ai_yellow.png'
import ai_green from '../../../custom_assets/dashboard/ai_green.png'
import { useNavigate } from 'react-router'
import { currentYear } from '../dictionary'
import { useState, useEffect, useRef } from 'react';
import { DashboardReactGAHandlers } from '../ReactGAHandlers'

// const resources = [{ navigate: '', title: '', description: ''}]
// const insights = [{ navigate: '', text: ''}]

const ContentBlock = ({ block, wizardActive, setWizardActive, statusLabelMaxWidth }) => {

  let navigate = useNavigate()

  const {
    handleGAEventExpandBlock,
    handleGAEventCollapseBlock,
    handleGAEventTrackerLinkClick
  } = DashboardReactGAHandlers()

  // For mobile settings:
  const [adjustedMargins, setAdjustedMargins] = useState<number[]>([]);
  const rowRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  useEffect(() => {
    const updateScreenSize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', updateScreenSize);
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setAdjustedMargins([]);
      return;
    }

    const margins: number[] = [];
    const idealSpacing = 10;

    rowRefs.current.forEach((row, index) => {
      if (!row || index === 0) return;

      const textElement = row.querySelector<HTMLAnchorElement | HTMLSpanElement>(
        '.dca-dashboard-block-insights-link'
      );

      if (textElement) {
        const rowHeight = row.offsetHeight;
        const textHeight = textElement.offsetHeight;
        // when insight link doesnt wrap, grab the difference in height, px, between the text string and the top of the row div. divide by 2 since the text is vertically aligned center
        const emptySpaceAboveText = (rowHeight - textHeight) / 2;

        // aiming for 10px in #983
        const adjustedMargin = idealSpacing - emptySpaceAboveText;

        margins[index - 1] = Math.max(adjustedMargin, 0);
      }
    });

    setAdjustedMargins(margins);
  }, [block?.insights, isMobile]);

  const returnStatus = (block) => {

    const returnStatusColor = (status) => {

      // let grey = '#ECECEC'
      let grey = '#F2F2F2'
      // let red = '#FFE9EA'
      let red = 'rgb(255, 110, 112, 15%)'
      let yellow = '#FFF6A1'
      // let green = '#98F29B'
      let green = '#BBF7BD'

      switch (status) {
        case 'inactive':
          return grey
        case 'red':
          return red
        case 'yellow':
          return yellow
        case 'green':
          return green
        default:
          return grey
      }
    }

    let generalStatusLabel = (label: String) => {
      return <div
        className='dca-dashboard-status-label'
        style={{
          background: block.status ? returnStatusColor(block.status) : returnStatusColor(undefined),
          // width: statusLabelMaxWidth ? `${statusLabelMaxWidth}px` : 'fit-content', maxWidth: statusLabelMaxWidth ? `${statusLabelMaxWidth}px` : 'fit-content'
        }}>
        <span className='dca-dashboard-status-label-text'>{label}</span>
      </div>
    }

    if (block?.class === 'CollegeApplications') {
      let gradYr = block?.gradYr

      if (gradYr - currentYear <= 1) {
        return generalStatusLabel('MONITOR')
      }

      else if (gradYr - currentYear > 1) {
        return generalStatusLabel('FOCUS LATER')
      }

      else {
        return generalStatusLabel('MONITOR')
      }
    }

    switch (true) {
      case block?.status == 'inactive':
        return generalStatusLabel('FOCUS LATER')
      case block?.status == 'red':
        return generalStatusLabel('GET STARTED')
      case block?.status == 'yellow':
        return generalStatusLabel('CONTINUE')
      case block?.status == 'green' && block?.class === 'Introduction':
        return generalStatusLabel('COMPLETED')
      case block?.status == 'green':
        return generalStatusLabel('OPTIMIZE')
      default:
        return generalStatusLabel('FOCUS LATER')
    }
  }

  const returnProgressTrackerIcon = (color) => {
    switch (color) {
      case 'red':
        return ai_red
      case 'yellow':
        return ai_yellow
      case 'green':
        return ai_green
      default:
        return ai_red
    }
  }

  const progressTracker = <div className='dca-dashboard-progressTracker-wrapper'>

    <div className={`dca-dashboard-progressTracker ${block?.progressTracker?.color ? block?.progressTracker?.color : ''}`}>

      <img className='dca-dashboard-progressTracker-icon' src={returnProgressTrackerIcon(block?.progressTracker?.color)} alt='ai_icon'></img>

      <div className='d-flex flex-column'>
        <span className='dca-dashboard-progressTracker-text' style={{ marginBottom: block?.progressTracker?.subject ? '' : '18px' }}>{block?.progressTracker?.text ? block?.progressTracker?.text : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua'}</span>

        {block?.progressTracker?.subject && <div className='dca-dashboard-progressTracker-link-wrapper'>
          <img className='dca-dashboard-progressTracker-link-icon' id={`dca-dashboard-block-icon-sm-${block?.class}`} src={block?.icon} alt={`dca-dashboard-block-icon-sm-${block?.class}
  `}></img>
          &nbsp;&nbsp;&nbsp;
          <span onClick={() => {
            block?.progressTracker?.link && handleGAEventTrackerLinkClick(block?.title)

            block?.progressTracker?.link && block?.progressTracker?.link();
          }} className={block?.progressTracker?.link ? 'dca-dashboard-progressTracker-link' : 'dca-dashboard-progressTracker-link-inactive'}>{block?.progressTracker?.count} Selected {block?.progressTracker?.subject}</span>
        </div>}
      </div>

    </div>
  </div>

  const proprietaryCollegeAnalytics = <div className="dca-dashboard-block-resources-wrapper" style={{ marginBottom: '-10px' }}>
    <span className='dca-dashboard-block-resources-title'>PROPRIETARY COLLEGE ANALYTICS</span>
    {block?.pca?.map((res, index) => (
      <div
        key={index}
        onClick={() => navigate(res.navigate)}
        className="dca-dashboard-block-resources-item-wrapper"
        style={{ marginBottom: index != block?.resources?.length - 1 ? '10px' : '' }}
      >
        <div id='dca-dashboard-premium-icon-wrapper'>
          <img
            alt='premium_icon'
            src={res?.icon ? res.icon : premiumIcon}
            className='dca-dashboard-block-premium-icon'></img>
        </div>

        &nbsp;&nbsp;&nbsp;&nbsp;

        <div className='d-flex flex-column'>
          <span
            className='dca-dashboard-block-resource-title'>{res.title}</span>
          <span className='dca-dashboard-block-resource-description'>{res.description}</span>
        </div>
      </div>
    ))}
  </div>

  const resources = <div className="dca-dashboard-block-resources-wrapper">
    <span className='dca-dashboard-block-resources-title'>RELATED RESOURCES</span>
    {block?.resources?.map((resource, index) => (
      <div
        key={index}
        onClick={() => navigate(resource.navigate)}
        className="dca-dashboard-block-resources-item-wrapper"
        style={{ marginBottom: index != block?.resources?.length - 1 ? '10px' : '' }}
      >
        <div id='dca-dashboard-premium-icon-wrapper'>
          <img
            alt='premium_icon'
            src={resource?.icon ? resource.icon : premiumIcon}
            className='dca-dashboard-block-premium-icon'></img>
        </div>

        &nbsp;&nbsp;&nbsp;&nbsp;

        <div className='d-flex flex-column'>
          <span
            className='dca-dashboard-block-resource-title'>{resource.title}</span>
          <span className='dca-dashboard-block-resource-description'>{resource.description}</span>
        </div>
      </div>
    ))}
  </div>

  const insights = <div className="dca-dashboard-block-insights-wrapper">
    <span className='dca-dashboard-block-insights-title'>INSIGHTS</span>
    {block?.insights?.map((insight, index) => (
      <div
        className="dca-dashboard-mobile-insights-row"
        key={index}
        ref={(el) => (rowRefs.current[index] = el)}
        style={{
          marginBottom:
            index !== block.insights!.length - 1 ? isMobile ? `${adjustedMargins[index] ?? 10}px`
              : '10px' : ''
        }}
      >
        <div id='dca-dashboard-insight-icon-wrapper'>
          <img
            alt='insight_icon'
            src={insightIcon}
            className='dca-dashboard-block-insight-icon'></img>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        {insight?.external ? <a
          href={insight.navigate}
          target='_blank'
          className='dca-dashboard-block-insights-link'>{insight.text}</a> : <span
            className='dca-dashboard-block-insights-link'
            onClick={() => navigate(insight.navigate)}>{insight.text}</span>}
      </div>
    ))}
  </div>

  return (
    <div className="dca-dashboard-card" style={{ marginTop: block.class == 'Introduction' ? '0px' : '32px' }}>
      <div className="accordion" id={`accordion${block.class}`}>
        <div className="accordion-item">
          {/* Header -- accordion click */}
          <h2
            className="accordion-header dca-dashboard-content-block-accordion-header">
            <button
              className="accordion-button collapsed"
              onClick={(event) => {
                if (event.currentTarget.classList.contains('collapsed')) {
                  handleGAEventCollapseBlock(block.title)
                } else {
                  handleGAEventExpandBlock(block.title)
                }
              }}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#${block.class}`}
              aria-expanded="false"
              aria-controls={`${block.class}`}>
              {returnStatus(block)}

              <div id="dca-dashboard-icon-wrapper">
                <img
                  alt={`dca-dashboard-block-icon-${block.class}
                `}
                  src={block.icon}
                  className={block?.specialIconClass ? `${block?.specialIconClass}` : "dca-dashboard-block-icon"}></img>
              </div>

              <span className='dca-dashboard-block-title'>{block.title}</span>
            </button>
          </h2>

          {/* Accordion body -- wizard or default content */}
          <div
            id={`${block.class}`}
            className={`accordion-collapse collapse ${block.expand ? 'show' : ''}`}
            data-bs-parent={`#accordion${block.class}`}>
            {wizardActive && wizardActive[block?.class] ? <div className="accordion-body">

              {block.wizardContent && block.wizardContent}

            </div> : <div className="accordion-body">
              <div className='dca-dashboard-block-intro-text'>{block.body}</div>

              {block.progressTracker && block.status != 'inactive' && progressTracker}

              {block.button && <div className="d-flex justify-content-center">{block.button}</div>}

              {/* College Block Additional Resources list */}
              {block.pca && proprietaryCollegeAnalytics}

              {block.resources ? resources : <div className='dca-dashboard-block-resources-wrapper-empty'></div>}

              {insights}

            </div>}
          </div>
        </div>
      </div>
    </div>)
}

export { ContentBlock }