import { useState, useEffect } from "react";

type RadioInputProps = {
  selectedState: any;
  onClick: any;
  name: string;
  id: string;
  text: string;
  box?: boolean;
  mt?: string;
  mobileMT?: string;
  maxWidth?: string
};

export const RadioInput = ({ selectedState, onClick, name, id, text, box = false, mt = "", mobileMT = "", maxWidth = "" }: RadioInputProps) => {

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  useEffect(() => {
    const updateScreenSize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', updateScreenSize);
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return <div
    className={`dca-dashboard-intro-radio border ${selectedState ? "radio-selected" : ""} ${box ? 'box' : 'circle'}`}
    onClick={onClick}
    style={{
      marginTop: isMobile && mobileMT ? mobileMT : mt,
      maxWidth: maxWidth != "" ? maxWidth : ""
    }}>
    <input
      className='form-check-input'
      type="radio"
      readOnly
      style={{ cursor: 'pointer' }}
      name={name}
      checked={selectedState}
      id={id}></input>{text}
  </div>
}