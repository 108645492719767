import { selectedTabGreen } from "./dictionary";
import { useNavigate } from 'react-router-dom'
import { pageRoutes } from "./dictionary";

export const ConnectWithAdvisorButton = ({ cardID, searchPage, buttonTextParam = '', inLine = false, mobile = false }) => {

  const navigate = useNavigate()

  const returnButtonText = () => {
    if (buttonTextParam != '') {
      switch (true) {
        case searchPage == pageRoutes.collegeSearch:
          return `Connect with ${buttonTextParam} students`
        case searchPage == pageRoutes.majorSearch:
          if (buttonTextParam.length > 30) {
            return `Connect with students in this major`
          }
          return `Connect with ${buttonTextParam} students`
        // EC case is default
        default:
          return 'Connect with past participants'
      }

    } else {
      return 'Connect with past participants'
    }
  }

  return (
    <button
      type="button"
      className={mobile ? `btn btn-sm ${inLine ? 'dca-advisor-connect-button-inline-mobile' : 'dca-advisor-connect-button-card-mobile'}` : `btn btn-sm ${inLine ? 'dca-advisor-connect-button-inline' : 'dca-advisor-connect-button-card'}`}
      onClick={(e) => {
        e.stopPropagation();

        if (cardID) {
          console.log('cardID:', cardID)
          switch (true) {
            case searchPage == pageRoutes.collegeSearch:
              return navigate(`${pageRoutes.advisorSearch}?college=${cardID}`)
            case searchPage == pageRoutes.majorSearch:
              return navigate(`${pageRoutes.advisorSearch}?fieldOfStudy=${cardID}`)
            case searchPage == pageRoutes.ecSearch:
              return navigate(`${pageRoutes.advisorSearch}?extracurriculars=${cardID}`)
            default:
              return navigate(pageRoutes.advisorSearch)
          }
        } else {
          return navigate(pageRoutes.advisorSearch)
        }
      }}
    >
      {returnButtonText()}
    </button>
  )
}

