import { sessionStorageGet } from "../../dictionary"

// status_sentence

// familiarity == Very Familiar (3)
export const green_1a = `Given your existing knowledge of the college admissions process, you are probably already familiar with the information linked below. Feel free to review as a refresher or proceed to the next module.`

// familiarity != Very Familiar
export const green_1b = `You should now have a baseline understanding of the college process and ready to proceed to the next module.`

export const yellow_1 = `Please review the content linked below to develop a better understanding of the overall college admissions process.`

export const red_1 = `Please review the content linked below for an introduction to the college admissions process.`

export const returnProgressTrackerText = (status, user, introQuestions) => {
  if (status === 'green') {
    let overviewIntroductionKey = sessionStorageGet('overviewIntroduction')
    let overviewCollegeAppKey = sessionStorageGet('overviewCollegeApp')

    let userFamiliarity = user?.familiarityWithProcess === 3 || introQuestions?.c === 3 || (overviewIntroductionKey && overviewCollegeAppKey)

    return userFamiliarity ? green_1a : green_1b
  }

  else if (status === 'yellow') {
    return yellow_1
  }

  else if (status === 'red') {
    return red_1
  }

  return undefined
}