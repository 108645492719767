// sentence_score_colleges

export const sentence1_scoreN_collegesNull = `After you have taken the SAT or ACT, please add your score(s) to your profile to receive personalized recommendations.`

export const sentence1_scoreY_colleges0 = `Add colleges to your profile to unlock personalized test score guidance for each of the schools you're potentially interested in applying to.`

export const sentence1_scoreY_colleges1 = `Visit the test score guidance feature to review personalized recommendations at your selected colleges (along with all the top 100 schools.) `

export const sentence2_scoreN_colleges1 = `Keep in mind that some of the colleges on your interest list have a policy requiring a test score for application.`

export const sentence2_scoreY_colleges1 = (collegesBelow) => {
  return `You should strongly consider improving your score${collegesBelow?.length > 1 ? 's' : ''} as your current ${collegesBelow.test} below the median benchmark values at ${collegesBelow?.length > 1 ? 'colleges' : 'a college'} on your interest list ${collegesBelow?.length > 1 ? `(such as ${collegesBelow?.college1} and ${collegesBelow?.college2}) that have` : `(${collegesBelow.college1}) that has`} a test required policy.`
}

export const sentence3_scoreY_colleges1 = `Note that at your current score level, our guidance may be to not submit a test score when applying to some test-optional schools on your interest list.`

export const returnProgressTrackerText = (testScores, userCollegeInterest, collegeData, user) => {
  let capValue = 5
  let len = userCollegeInterest?.length ?? undefined
  const userCollegeIds = new Set(userCollegeInterest.map((college) => college.college_id));
  let userCollegesThatRequireTests = collegeData.filter(
    (res) => userCollegeIds.has(res.id) && res.testPolicy == 1
  );
  let userCollegesOptionalTests = collegeData.filter(
    (res) => userCollegeIds.has(res.id) && res.testPolicy == 2
  );
  let concat: any = []

  if (testScores) {

    // SENTENCE 1
    if (len == 0 || len == undefined) {
      concat.push(sentence1_scoreY_colleges0)
    }
    else if (len > 0) {
      concat.push(sentence1_scoreY_colleges1)
    }

    // SENTENCES 2-3
    if (len > 0) {
      // SENTENCE 2
      const checkTestScoresAgainstMedians = (userCollegesThatRequireTests) => {

        const returnUserSATScoreOutputLowerThan50 = (userSATMath, userSATVerbal, res: any) => {
          const returnOptionBSATComposite25 = (cds) => {
            if (cds.satEvidence25 && cds.satMath25) {
              return cds.satEvidence25 + cds.satMath25
            }

            else {
              return null
            }
          }

          const returnOptionBSATComposite75 = (cds) => {
            if (cds.satEvidence75 + cds.satMath75) {
              return cds.satEvidence75 + cds.satMath75
            }

            else {
              return null
            }
          }

          const returnOptionBSATComposite50 = (cds) => {

            if (cds.satEvidence50 && cds.satMath50) {
              return cds.satEvidence50 + cds.satMath50
            }

            else if (cds.satEvidence25 && cds.satEvidence75 && cds.satMath25 && cds.satMath75) {
              return ((cds.satEvidence25 + cds.satEvidence75) / 2) + ((cds.satMath25 + cds.satMath75) / 2)
            }

            else {
              return null
            }
          }

          if (res && res.college_cds) {

            const cds = res.college_cds[0]

            const caseA = (cds.satComposite25 == null || cds.satComposite25 == undefined) || (cds.satComposite75 == null || cds.satComposite75 == undefined)

            const caseB = [cds.satEvidence25, cds.satEvidence75, cds.satMath25, cds.satMath75].some((sat) => sat == null || sat == undefined)

            if (caseA && caseB) {
              return false
            }

            else if ((userSATMath == null || userSATMath == undefined) || (userSATVerbal == null || userSATVerbal == undefined)) {
              return false
            }

            else {

              let satSuperScore = userSATMath + userSATVerbal
              let satComposite50: any = cds.satComposite50 ? cds.satComposite50 : null
              let satComposite25: any = cds.satComposite25 ? cds.satComposite25 : null
              let satComposite75: any = cds.satComposite75 ? cds.satComposite75 : null

              let above25: boolean = false
              let above50: boolean = false
              let above75: boolean = false

              if (cds.satComposite25 !== null && cds.satComposite75 !== null) {
                satComposite25 = cds.satComposite25;
                satComposite75 = cds.satComposite75;
                cds.satComposite50 ? satComposite50 = cds.satComposite50 : satComposite50 = ((satComposite25 + satComposite75) / 2);
              }

              else if (cds.satComposite25 === null && cds.satComposite75 === null) {
                satComposite25 = returnOptionBSATComposite25(cds);
                satComposite50 = returnOptionBSATComposite50(cds);
                satComposite75 = returnOptionBSATComposite75(cds);
              }

              if (satSuperScore >= satComposite25) {
                above25 = true
              }

              if (satSuperScore >= satComposite75) {
                above75 = true
              }

              if (satSuperScore >= satComposite50) {
                above50 = true
              }

              switch (true) {
                case above25 == false:
                  return ((((satSuperScore / satComposite25)) * 25) < 50) ? true : false
                case above25 == true && above50 == false:
                  return ((((satSuperScore - satComposite25) / (satComposite50 - satComposite25)) * 25) + 25) < 50 ? true : false
                case above50 == true && above75 == false:
                  return ((((satSuperScore - satComposite50) / (satComposite75 - satComposite50)) * 25) + 50) < 50 ? true : false
                default:
                  return ((((satSuperScore - satComposite75) / (1600 - satComposite75)) * 25) + 75) < 50 ? true : false
              }
            }
          }

          else {
            return false
          }

        }

        const returnUserACTScoreOutputLowerThan50 = (userACTBestScore, res: any) => {

          let actReference50: any = 0
          let above25: any = false
          let above50: any = false
          let above75: any = false

          if (res && res.college_cds) {

            const cds = res.college_cds[0]

            // If either actComposite25 or actComposite75 are null, set ACT score output to -2
            if ((cds.actComposite25 == null || cds.actComposite25 == undefined) || (cds.actComposite75 == null || cds.actComposite75 == undefined)) {
              return false
            }

            else if (userACTBestScore == null || userACTBestScore == undefined) {
              return false
            }

            // Set act reference 50. If it exists set it to actComposite50 otherwise set it to the average of actComposite25 and actComposite75
            actReference50 = cds.actComposite50 ? cds.actComposite50 : ((cds.actComposite25 + cds.actComposite75) / 2)

            const setBooleans = () => {
              if (userACTBestScore >= cds.actComposite25) {
                above25 = true
              }

              if (userACTBestScore >= actReference50) {
                above50 = true
              }

              if (userACTBestScore >= cds.actComposite75) {
                above75 = true
              }
            }
            setBooleans();


            if (above25 === false) {
              return ((((userACTBestScore / cds.actComposite25)) * 25)) < 50 ? true : false
            }

            else if (above25 === true && above50 === false) {
              return ((((userACTBestScore - cds.actComposite25) / (actReference50 - cds.actComposite25)) * 25) + 25) < 50 ? true : false
            }

            else if (above50 === true && above75 === false) {

              if ((cds.actComposite25 == actReference50) && (userACTBestScore == cds.actComposite25)) {
                return ((50 + 25) / 2) < 50 ? true : false
              }

              return ((((userACTBestScore - actReference50) / (cds.actComposite75 - actReference50)) * 25) + 50) < 50 ? true : false
            }

            else {
              if (userACTBestScore == 36 && cds.actComposite75 == 36) {
                return false
              }

              if ((cds.actComposite75 == actReference50) && (userACTBestScore == cds.actComposite75)) {
                return false
              }

              return ((((userACTBestScore - cds.actComposite75) / (36 - cds.actComposite75)) * 25) + 75) < 50 ? true : false
            }

          }

          else {
            // fallback, if college cds is not available
            return false
          }


        }

        let belowArr: any = []
        let satTaken = (user?.satMath && user?.satVerbal);
        let actTaken = !!user?.actComposite;
        let test: any = []

        if (satTaken) {
          let satMath = user?.satMath
          let satVerbal = user?.satVerbal

          userCollegesThatRequireTests.map((college) => {
            if (returnUserSATScoreOutputLowerThan50(satMath, satVerbal, college)) {
              !belowArr.includes(college) && belowArr.push(college)
              !test.includes('SAT') && test.push('SAT')
            }
          })
        }

        if (actTaken) {
          let actBest = user?.actComposite

          userCollegesThatRequireTests.map((college) => {
            if (returnUserACTScoreOutputLowerThan50(actBest, college)) {
              !belowArr.includes(college) && belowArr.push(college)
              !test.includes('ACT') && test.push('ACT')
            }
          })

        }

        const returnTestValue = (test) => {
          if (test.includes('SAT') && test.includes('ACT')) {
            return `SAT and ACT scores are`
          }

          else if (test.includes('SAT') && !test.includes('ACT')) {
            return `SAT score is`
          }

          else if (!test.includes('SAT') && test.includes('ACT')) {
            return `ACT score is`
          }

          return `SAT and ACT scores are`
        }

        return {
          length: belowArr?.length > 0 ? belowArr?.length : undefined,
          test: returnTestValue(test),
          college1: belowArr[0]?.name ?? undefined,
          college2: belowArr[1]?.name ?? undefined
        }

      }

      let collegesBelow = checkTestScoresAgainstMedians(userCollegesThatRequireTests)

      if (collegesBelow && collegesBelow.length > 0) {
        concat.push(sentence2_scoreY_colleges1(collegesBelow))
      }

      // SENTENCE 3
      const checkDoNotSubmitCount = (userCollegesOptionalTests) => {
        let doNotSubmitArr: any = []
        let satMath = user?.satMath ?? undefined
        let satVerbal = user?.satVerbal ?? undefined
        let actBest = user?.actComposite ?? undefined

        const returnOptionBSATComposite25 = (cds) => {
          if (cds.satEvidence25 && cds.satMath25) {
            return cds.satEvidence25 + cds.satMath25
          }

          else {
            return null
          }
        }

        const returnOptionBSATComposite75 = (cds) => {
          if (cds.satEvidence75 + cds.satMath75) {
            return cds.satEvidence75 + cds.satMath75
          }

          else {
            return null
          }
        }

        const returnOptionBSATComposite50 = (cds) => {

          if (cds.satEvidence50 && cds.satMath50) {
            return cds.satEvidence50 + cds.satMath50
          }

          else if (cds.satEvidence25 && cds.satEvidence75 && cds.satMath25 && cds.satMath75) {
            return ((cds.satEvidence25 + cds.satEvidence75) / 2) + ((cds.satMath25 + cds.satMath75) / 2)
          }

          else {
            return null
          }
        }

        const returnUserSATScoreOutput = (userSATMath, userSATVerbal, res: any) => {

          if (res && res.college_cds) {

            const cds = res.college_cds[0]

            const caseA = (cds.satComposite25 == null || cds.satComposite25 == undefined) || (cds.satComposite75 == null || cds.satComposite75 == undefined)

            const caseB = [cds.satEvidence25, cds.satEvidence75, cds.satMath25, cds.satMath75].some((sat) => sat == null || sat == undefined)

            if (caseA && caseB) {
              return -2
            }

            else if ((userSATMath == null || userSATMath == undefined) || (userSATVerbal == null || userSATVerbal == undefined)) {
              return -1
            }

            else {

              let satSuperScore = userSATMath + userSATVerbal
              let satComposite50: any = cds.satComposite50 ? cds.satComposite50 : null
              let satComposite25: any = cds.satComposite25 ? cds.satComposite25 : null
              let satComposite75: any = cds.satComposite75 ? cds.satComposite75 : null

              let above25: boolean = false
              let above50: boolean = false
              let above75: boolean = false

              if (cds.satComposite25 !== null && cds.satComposite75 !== null) {
                satComposite25 = cds.satComposite25;
                satComposite75 = cds.satComposite75;
                cds.satComposite50 ? satComposite50 = cds.satComposite50 : satComposite50 = ((satComposite25 + satComposite75) / 2);
              }

              else if (cds.satComposite25 === null && cds.satComposite75 === null) {
                satComposite25 = returnOptionBSATComposite25(cds);
                satComposite50 = returnOptionBSATComposite50(cds);
                satComposite75 = returnOptionBSATComposite75(cds);
              }

              if (satSuperScore >= satComposite25) {
                above25 = true
              }

              if (satSuperScore >= satComposite75) {
                above75 = true
              }

              if (satSuperScore >= satComposite50) {
                above50 = true
              }

              switch (true) {
                case above25 == false:
                  return (((satSuperScore / satComposite25)) * 25)
                case above25 == true && above50 == false:
                  return (((satSuperScore - satComposite25) / (satComposite50 - satComposite25)) * 25) + 25
                case above50 == true && above75 == false:
                  return (((satSuperScore - satComposite50) / (satComposite75 - satComposite50)) * 25) + 50
                default:
                  return (((satSuperScore - satComposite75) / (1600 - satComposite75)) * 25) + 75
              }
            }
          }

          else {
            return -2
          }

        }

        const returnUserACTScoreOutput = (userACTBestScore, res: any) => {

          let actReference50: any = 0
          let above25: any = false
          let above50: any = false
          let above75: any = false

          if (res && res.college_cds) {

            const cds = res.college_cds[0]

            // If either actComposite25 or actComposite75 are null, set ACT score output to -2
            if ((cds.actComposite25 == null || cds.actComposite25 == undefined) || (cds.actComposite75 == null || cds.actComposite75 == undefined)) {
              return -2
            }

            else if (userACTBestScore == null || userACTBestScore == undefined) {
              return -1
            }

            // Set act reference 50. If it exists set it to actComposite50 otherwise set it to the average of actComposite25 and actComposite75
            actReference50 = cds.actComposite50 ? cds.actComposite50 : ((cds.actComposite25 + cds.actComposite75) / 2)

            const setBooleans = () => {
              if (userACTBestScore >= cds.actComposite25) {
                above25 = true
              }

              if (userACTBestScore >= actReference50) {
                above50 = true
              }

              if (userACTBestScore >= cds.actComposite75) {
                above75 = true
              }
            }
            setBooleans();


            if (above25 === false) {
              return ((((userACTBestScore / cds.actComposite25)) * 25))
            }

            else if (above25 === true && above50 === false) {
              return ((((userACTBestScore - cds.actComposite25) / (actReference50 - cds.actComposite25)) * 25) + 25)
            }

            else if (above50 === true && above75 === false) {

              if ((cds.actComposite25 == actReference50) && (userACTBestScore == cds.actComposite25)) {
                return ((50 + 25) / 2)
              }

              return ((((userACTBestScore - actReference50) / (cds.actComposite75 - actReference50)) * 25) + 50)
            }

            else {
              if (userACTBestScore == 36 && cds.actComposite75 == 36) {
                return 100
              }

              if ((cds.actComposite75 == actReference50) && (userACTBestScore == cds.actComposite75)) {
                return ((50 + 75) / 2)
              }

              return ((((userACTBestScore - cds.actComposite75) / (36 - cds.actComposite75)) * 25) + 75)
            }

          }

          else {
            // fallback, if college cds is not available
            return -2
          }


        }

        const recommendationLogic = (userSATScoreOutput, userACTScoreOutput, res) => {

          if (res.testPolicy == 3) {
            // Do not submit
            return true
          }

          // CTA to enter score data
          if (userSATScoreOutput === -1 && userACTScoreOutput === -1) {
            return false
          }

          // Insufficient college data
          if (userSATScoreOutput === -2 && userACTScoreOutput === -2) {
            return false
          }

          if (userSATScoreOutput === -2 && userACTScoreOutput === -1) {
            return false
          }

          if (userSATScoreOutput === -1 && userACTScoreOutput === -2) {
            return false
          }

          // Testpolicy 1 vs 2:
          switch (true) {
            case res.testPolicy == 1:
              if (userSATScoreOutput > userACTScoreOutput) {
                //Submit SAT
                return false
              }
              else if (userSATScoreOutput < userACTScoreOutput) {
                //Submit ACT
                return false
              }
              else {
                //Submit either
                return false
              }
            case res.testPolicy == 2:
              if (userSATScoreOutput > userACTScoreOutput) {
                if (userSATScoreOutput >= 60) {
                  //Submit SAT
                  return false
                }
                else if ((40 <= userSATScoreOutput) && (userSATScoreOutput < 60)) {
                  //Borderline
                  return false
                }
                else if (userSATScoreOutput < 40) {
                  // Do not submit
                  return true
                }
              }

              if (userSATScoreOutput < userACTScoreOutput) {
                if (userACTScoreOutput >= 60) {
                  // Submit ACT 
                  return false
                }
                else if ((40 <= userACTScoreOutput) && (userACTScoreOutput < 60)) {
                  // Borderline
                  return false
                }
                else if (userACTScoreOutput < 40) {
                  //Do not submit
                  return true
                }
              }

              if (userSATScoreOutput === userACTScoreOutput) {
                if (userSATScoreOutput >= 60) {
                  if (res.college_cds[0].submitSATPercent >= res.college_cds[0].submitACTPercent) {
                    // Submit SAT
                    return false
                  }
                  else if (res.college_cds[0].submitSATPercent < res.college_cds[0].submitACTPercent) {
                    // Submit ACT
                    return false
                  }
                }
                if (40 <= userSATScoreOutput && userSATScoreOutput < 60) {
                  //Borderline
                  return false
                }
                if (userSATScoreOutput < 40) {
                  // Do not submit
                  return true
                }
              }
          }
        }

        userCollegesOptionalTests.map((college) => {
          let satOutput = returnUserSATScoreOutput(satMath, satVerbal, college)
          let actOutput = returnUserACTScoreOutput(actBest, college)

          if (recommendationLogic(satOutput, actOutput, college)) {
            doNotSubmitArr.push(college)
          }
        })

        return doNotSubmitArr

      }

      let doNotSubmit = checkDoNotSubmitCount(userCollegesOptionalTests)

      if (doNotSubmit && doNotSubmit.length > 0) {
        concat.push(sentence3_scoreY_colleges1)
      }
    }

    // if multiple sentences, join with single space
    if (concat?.length > 0) {
      concat = concat.join(" ")
      return concat
    }

    return sentence1_scoreY_colleges0
  }

  else {
    // SENTENCE 1
    concat.push(sentence1_scoreN_collegesNull)

    // SENTENCE 2
    if (len > 0 && userCollegeInterest.some((college) => collegeData.find((res) => res.id == college.college_id && res.testPolicy == 1))) {
      concat.push(sentence2_scoreN_colleges1)
    }

    if (concat.length > 0) {
      concat = concat.join(" ")
      return concat
    }

    return concat[0]

  }

}