import axios from "axios"
import { URL, pageRoutes, updateTitleAndDescription } from "../components/dictionary"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router"
import { Card } from "../components/AdvisorSearch/Card"
import { Aside } from "../components/AdvisorSearch/Aside"
import { LoadingIndicator } from "../components/LoadingIndicator"
import { collegeMap } from "../components/collegeMap"
import { fullCollegeMap } from "../components/fullCollegeMap"

const AdvisorSearchPage = ({ user, cip, collegesId, ecActivities, setPrompt, setNavigateTo, setPurchaseID, setAdvisorID, advisorSearchLocation, setAdvisorSearchLocation }) => {

  const navigate = useNavigate()

  //////// Set states of variables ////////
  const [advisors, setAdvisors] = useState<any>([])
  const [majors, setMajors] = useState<any>([])
  const [results, setResults] = useState<any>([])
  const [filteredResults, setFilteredResults] = useState(false)
  const [sortKey, setSortKey] = useState<any>('joinDate')
  const [sortOrder, setSortOrder] = useState<any>('Ascending')

  //Filter states
  const [collegeFilter, setCollegeFilter] = useState<any>((item) => {
    return item => item
  })
  const [majorFilter, setMajorFilter] = useState<any>((item) => {
    return item => item
  })
  const [ecFilter, setECFilter] = useState<any>((item) => {
    return item => item
  })

  const [filtersActive, setFiltersActive] = useState(0)

  useEffect(() => {
    const sampleInput = {};
    const individualFilters = [
      collegeFilter,
      majorFilter,
      ecFilter
    ];

    const isFilterActive = (filter) => filter(sampleInput) !== sampleInput;

    const activeIndividualFiltersCount = individualFilters.filter(isFilterActive)?.length || 0;

    setFiltersActive(activeIndividualFiltersCount);
  }, [
    collegeFilter,
    majorFilter,
    ecFilter
  ])

  //////// Functions and event handlers ////////
  const getMajors = async () => {
    let res = await axios.get(`${URL}/majors/`)
    setMajors(res.data)
  }

  const getData = async () => {
    let res = await axios.get(`${URL}/advisor_profiles/`);
    res = res.data.filter((item) => item.displayState == 1)
    setAdvisors(res)
    setResults(res)
    setFilteredResults(true)
  }
  //////// useEffects ////////
  useEffect(() => {
    // Sets default
    updateTitleAndDescription(pageRoutes.advisorSearch)

    getMajors();
    getData();
  }, [])

  useEffect(() => {
    const sortAndFilter = (collegeFilter, majorFilter, ecFilter, sortKey, sortOrder) => {
      let res: any = advisors

      res = res.filter(collegeFilter).filter(majorFilter).filter(ecFilter)

      if (res.length === 0) {
        return setFilteredResults(false)
      }

      switch (sortKey) {
        case 'joinDate':
          res.sort((a, b) => {
            let dateA: any = new Date(a.joinDate)
            let dateB: any = new Date(b.joinDate)

            return sortOrder === 'Descending' ? (dateB - dateA) : (dateA - dateB)
          })
          break;
        case 'college':
          res.sort((a, b) => {
            // return sortOrder === 'Descending' ?
            //   (collegesId.filter((item) => item.id == b.college_id)[0].name.localeCompare(collegesId.filter((item) => item.id == a.college_id)[0].name)) :
            //   (collegesId.filter((item) => item.id == a.college_id)[0].name.localeCompare(collegesId.filter((item) => item.id == b.college_id)[0].name))
            return sortOrder === 'Descending' ?
              (fullCollegeMap.filter((item) => item.id == b.college_id)[0]?.name.localeCompare(fullCollegeMap.filter((item) => item.id == a.college_id)[0]?.name)) :
              (fullCollegeMap.filter((item) => item.id == a.college_id)[0]?.name.localeCompare(fullCollegeMap.filter((item) => item.id == b.college_id)[0]?.name))
          })
          break;
        default:
          res = res
      }

      setResults(res)
      if (filteredResults === false) {
        setFilteredResults(true)
      }
    }

    sortAndFilter(collegeFilter, majorFilter, ecFilter, sortKey, sortOrder);

  }, [collegeFilter, majorFilter, ecFilter, sortKey, sortOrder])

  const returnResults = (filteredResults, mobile = false) => {
    switch (filteredResults) {
      case true:
        return <Card
          results={results}
          sortKey={sortKey}
          setSortKey={setSortKey}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          collegesId={collegesId}
          collegeMap={fullCollegeMap}
          majors={majors}
          setPrompt={setPrompt}
          setNavigateTo={setNavigateTo}
          user={user}
          setPurchaseID={setPurchaseID}
          setAdvisorID={setAdvisorID}
          filtersActive={filtersActive}
          mobile={mobile}
        />
      case false:
        return <div className="d-flex flex-column align-items-center" style={{ width: '1129px', maxWidth: '1129px' }}>
          <div style={{ marginTop: '180px', marginBottom: '180px' }}>No results meet your criteria</div>
        </div>
    }
  }

  const advisorHeader = (
    <div className="dca-search-header-wrapper">
      <div className="dca-search-header-title-button-container">
        <span className='dca-search-title align-self-center'>Advisors Network</span>

        <div className="dca-search-header-bttn-container">
          <button
            type="button"
            aria-pressed="true"
            onClick={() => navigate(pageRoutes.advisorNetwork)}
            className='dca-search-header-bttn'
          >Advisors Network Overview</button>
        </div>
      </div>

      <span className="dca-search-description">Connect with recent high school graduates to benefit from their first-hand experience with your extracurricular activities, colleges, and majors of interest. Leverage advisors with specific expertise to help in your college planning and application process.</span>

    </div>
  )

  const advisorHeaderMobile = (
    <div className="dca-search-header-wrapper">
      <div className="dca-search-header-title-button-container">
        <span className='dca-search-title align-self-center'>Advisors Network</span>

        <span className="dca-search-description">Connect with recent high school graduates to benefit from their first-hand experience with your extracurricular activities, colleges, and majors of interest. Leverage advisors with specific expertise to help in your college planning and application process.</span>

        <Aside
          advisors={advisors}
          cip={cip}
          collegesId={collegesId}
          collegeMap={collegeMap}
          ecActivities={ecActivities}
          setCollegeFilter={setCollegeFilter}
          setMajorFilter={setMajorFilter}
          setECFilter={setECFilter}
          sortKey={sortKey}
          setSortKey={setSortKey}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          advisorSearchLocation={advisorSearchLocation}
          setAdvisorSearchLocation={setAdvisorSearchLocation}
          filtersActive={filtersActive}
          setFiltersActive={setFiltersActive}
          mobile={true}
        />
      </div>

      <div>
        <div className='dca-college-search-resources-links-header'>RESOURCES</div>
        <div
          className='dca-college-search-resources-links'
          onClick={() => navigate(pageRoutes.advisorNetwork)}
        >Advisors Network Overview</div>
      </div>


    </div>
  )

  return (
    <div id='dca-college-search-wrapper'>
      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex'>
        {advisorHeader}
      </div>

      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-flex d-md-none'>
        {advisorHeaderMobile}
      </div>

      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex mt-5 flex-column-fluid' style={{ marginLeft: '35px' }}>
        <Aside
          advisors={advisors}
          cip={cip}
          collegesId={collegesId}
          collegeMap={collegeMap}
          ecActivities={ecActivities}
          setCollegeFilter={setCollegeFilter}
          setMajorFilter={setMajorFilter}
          setECFilter={setECFilter}
          sortKey={sortKey}
          setSortKey={setSortKey}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          advisorSearchLocation={advisorSearchLocation}
          setAdvisorSearchLocation={setAdvisorSearchLocation}
          filtersActive={filtersActive}
          setFiltersActive={setFiltersActive}
        />
        {(results.length > 0) ? returnResults(filteredResults) : <div className="d-flex flex-column justify-content-start align-items-center">
          <LoadingIndicator text={"Getting Advisors ... "} />
        </div>}
      </div>

      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-flex d-md-none justify-content-center'>
        {(results.length > 0) ? returnResults(filteredResults, true) : <div className="d-flex flex-column justify-content-center align-items-center">
          <LoadingIndicator text={"Getting Advisors ... "} mt={'20px'} ml={'0px'} />
        </div>}
      </div>
    </div>
  )
}

export { AdvisorSearchPage }


























// For previous mobile search 

// search page::
// return results.length > 0 ? (
//   <div>
//     {advisorHeader}

//     <div className='mt-5 d-flex flex-column-fluid' style={{ marginLeft: '35px' }}>
//       <Aside
//         advisors={advisors}
//         cip={cip}
//         collegesId={collegesId}
//         collegeMap={collegeMap}
//         ecActivities={ecActivities}
//         setCollegeFilter={setCollegeFilter}
//         setMajorFilter={setMajorFilter}
//         setECFilter={setECFilter}
//         sortKey={sortKey}
//         setSortKey={setSortKey}
//         sortOrder={sortOrder}
//         setSortOrder={setSortOrder}
//         advisorSearchLocation={advisorSearchLocation}
//         setAdvisorSearchLocation={setAdvisorSearchLocation}
//         filtersActive={filtersActive}
//         setFiltersActive={setFiltersActive}
//       />
//       {returnResults(filteredResults)}
//     </div>
//   </div>
// ) : (
//   <div>
//     <div className='d-flex flex-column' style={{ marginTop: '30px' }}>
//       <p className='fw-bold' style={{ fontSize: '30px', marginLeft: '35px' }}>Advisors Network</p>
//       <p className="mt-3" style={{ fontSize: '21px', marginLeft: '35px', marginBottom: '250px' }}>Connect with recent high school graduates to benefit from their first-hand experience with your extracurricular activities, colleges, and majors of interest. Leverage advisors with specific expertise to help in your college planning and application process.</p>
//     </div>
//     <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginLeft: '100px' }}>
//       <LoadingIndicator text={"Getting Advisors ... "} />
//     </div>
//   </div>
// )

// card::
// return (
//   <div style={{ width: '1129px', maxWidth: '1129px', marginLeft: '40px', marginRight: '40px' }}>

//     <div className="d-flex flex-row justify-content-between align-items-end">
//       <div className="d-flex flex-row">
//         <div
//           className="me-3 align-bottom dca-card-items-found-text">Displaying {results.length} advisor{results?.length == 1 ? '' : 's'} {filtersActive > 0 && `matching active filter${filtersActive == 1 ? '' : 's'}`}</div>
//         <p
//           onClick={handleSortOrderChange}
//           style={{ cursor: 'pointer', fontSize: '15px', lineHeight: '18px', color: '#585555', marginBottom: '2px' }}
//           className='text-muted fs-7 fw-bolder align-self-end'
//         >by {sortOrder} {sortKeyReturn(sortKey)} {sortOrder === 'Ascending' && (<i className="bi bi-arrow-up"></i>)}
//           {sortOrder === 'Descending' && (<i className="bi bi-arrow-down"></i>)}
//         </p>
//       </div>

//       <div className="d-flex flex-row">
//         {/* {customSmallerCardIcon} */}
//         <div></div>

//         <select
//           className='form-select'
//           data-control="select2"
//           value={sortKey}
//           onChange={handleSortKeyChange}
//         >
//           {sortKeys.map((res, id) => (
//             <option key={id} value={Object.values(res)}>{Object.keys(res)}</option>
//           ))}
//         </select>
//       </div>
//     </div>
//     {/* END EC CARD HEADER */}

//     <div className="d-flex flex-wrap justify-content-between">{results.map((res) => (

//       <div
//         key={res.id}
//         className="dca-advisor-card hover-elevate-up"
//         onClick={() => routeChange(res.id)}
//       >

//         <div className="dca-advisor-search-card-image-wrapper">

//           <img
//             src={advisorJPGs[res.id] != null ? advisorJPGs[res.id] as string : avatar}
//             alt='avatar' className="dca-advisor-search-card-image" />

//         </div>

//         {apiToken != undefined && user?.emailAddress ? <button
//           type="button"
//           className="btn btn-sm dca-connect-button-card"
//           data-bs-toggle="modal"
//           data-bs-target="#purchase_modal"
//           onClick={(e) => {
//             e.stopPropagation();
//             setPurchaseID(advisorConnectAccessCode)
//             setAdvisorID(res.id)
//             handleGAEventPurchaseModalOpen(res.id);
//             handleAdvisorGAEventCardConnectClick(res.id)
//           }}
//         >
//           Connect
//         </button> : <ConnectButtonInCardNoAuth setPrompt={setPrompt} setNavigateTo={setNavigateTo} biLock={false} advisorID={res.id} />}

//         <div className="d-flex justify-content-center">
//           <div className="mt-5 dca-advisor-search-card-nameField">{res.firstName}</div>
//         </div>

//         {/* INNER CARD CONTENT */}
//         <div style={{ margin: '45px 0px 63px 63px' }}>
//           <div className="d-flex flex-row justify-content-evenly">
//             <div className="d-flex flex-column" style={{ width: '40%' }}>
//               <div className="dca-card-item-header">COLLEGE</div>
//               <div className="dca-card-item-text">{collegeReturn(res.college_id, collegeMap)}</div>
//             </div>

//             <div className="d-flex flex-column" style={{ width: '40%' }}>
//               <div className="dca-card-item-header">CLASS</div>
//               <div className="dca-card-item-text">{res.collegeGradYear}</div>
//             </div>
//           </div>

//           <div className="d-flex flex-row justify-content-evenly">
//             <div className="d-flex flex-column" style={{ width: '40%' }}>
//               <div className="dca-card-item-header" style={{ marginTop: '15px' }}>MAJOR(S)</div>
//               <div className="dca-card-item-text">{majorReturn(res.major_id, majors, res)}</div>
//             </div>

//             <div className="d-flex flex-column" style={{ width: '40%' }}>
//               <div className="dca-card-item-header align-text-start" style={{ marginTop: '15px' }}>JOINED</div>
//               <div className="dca-card-item-text">{dateReturn(res.joinDate)}</div>
//             </div>
//           </div>
//         </div>

//         {/* <div className="d-flex flex-row" style={{ margin: '45px 0px 63px 63px' }}>

//             <div className="d-flex flex-column" style={{ width: '45%', height: 'fit-content' }}>
//               <div className="d-flex flex-column" style={{ height: '50%' }}>
//                 <div className="dca-card-item-header">COLLEGE</div>
//                 <div className="dca-card-item-text">{collegeReturn(res.college_id, collegeMap)}</div>
//               </div>

//               <div className="d-flex flex-column" style={{ height: '50%' }}>
//                 <div className="dca-card-item-header">MAJOR(S)</div>
//                 <div className="dca-card-item-text">{majorReturn(res.major_id, majors, res)}</div>
//               </div>
//             </div>

//             <div className="d-flex flex-column" style={{ width: '45%', height: 'fit-content', marginLeft: '30px' }}>

//               <div className="d-flex flex-column" style={{ height: '75px' }}>
//                 <div className="dca-card-item-header">CLASS</div>
//                 <div className="dca-card-item-text">{res.collegeGradYear}</div>
//               </div>

//               <div className="d-flex flex-column">
//                 <div className="dca-card-item-header">JOINED</div>
//                 <div className="dca-card-item-text">{dateReturn(res.joinDate)}</div>
//               </div>

//             </div>
//           </div> */}


//         {/* END INNER CARD CONTENT */}

//       </div>
//     ))}

//     </div>
//   </div>
// )