import { useState, useEffect } from 'react'
import { useLocation } from 'react-router'

const ECTypeSearch = ({ filteredEC, setTypeSelect, resetStateToggle, filteredResultsFull }) => {

  const location = useLocation()

  let summerProgramCount = 0
  let preCollegeStudiesCount = 0
  let researchCount = 0
  let competitionsCount = 0
  let awardsCount = 0
  let internshipCount = 0
  let academicCount = 0
  let independentCount = 0

  const [summerProgram, setSummerProgram] = useState<any>(false)
  const [preCollegeStudies, setPreCollegeStudies] = useState<any>(false)
  const [research, setResearch] = useState<any>(false)
  const [competitions, setCompetitions] = useState<any>(false)
  const [awards, setAwards] = useState<any>(false)
  const [internship, setInternship] = useState<any>(false)
  const [academic, setAcademic] = useState<any>(false)
  const [independent, setIndependent] = useState<any>(false)

  const handleCheckChange = (event) => {
    let checkStatus = event.target.checked
    return checkStatus
  }

  const checkCheckCase = (filterVal) => {
    return filterVal ? true : false
  }

  useEffect(() => {
    setSummerProgram(false);
    setPreCollegeStudies(false);
    setResearch(false);
    setCompetitions(false);
    setAwards(false);
    setInternship(false);
    setAcademic(false);
    setIndependent(false);
  }, [resetStateToggle])

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let typeParam = params.get('type');

    if (typeParam) {
      const typeParamSplit = typeParam.split(',').map(res => res)

      // if (summerProgram) {
      //   res.push(1)
      // }

      // if (preCollegeStudies) {
      //   res.push(2)
      // }

      // if (research) {
      //   res.push(3)
      // }

      // if (competitions) {
      //   res.push(4)
      // }

      // if (awards) {
      //   res.push(5)
      // }

      // if (internship) {
      //   res.push(6)
      // }

      // if (academic) {
      //   res.push(7)
      // }

      // if (independent) {
      //   res.push(8)
      // }

      typeParamSplit.map((res) => {
        let intRes = parseInt(res)
        switch (true) {
          case intRes === 1:
            setSummerProgram(true)
            break;
          case intRes === 2:
            setPreCollegeStudies(true)
            break;
          case intRes === 3:
            setResearch(true)
            break;
          case intRes === 4:
            setCompetitions(true)
            break
          case intRes === 5:
            setAwards(true)
            break
          case intRes === 6:
            setInternship(true)
            break
          case intRes === 7:
            setAcademic(true)
            break
          case intRes === 8:
            setIndependent(true)
            break
        }
      })
    }

  }, [location.search]);

  // if (filteredEC) {
  //   summerProgramCount = filteredEC.filter(item => item.activityType === 1).length
  //   preCollegeStudiesCount = filteredEC.filter(item => item.activityType === 2).length
  //   researchCount = filteredEC.filter(item => item.activityType === 3).length
  //   competitionsCount = filteredEC.filter(item => item.activityType === 4).length
  //   awardsCount = filteredEC.filter(item => item.activityType === 5).length
  //   internshipCount = filteredEC.filter(item => item.activityType === 6).length
  //   academicCount = filteredEC.filter(item => item.activityType === 7).length
  //   independentCount = filteredEC.filter(item => item.activityType === 8).length
  // }

  if (filteredResultsFull) {
    summerProgramCount = filteredResultsFull.filter(item => item.activityType === 1).length
    preCollegeStudiesCount = filteredResultsFull.filter(item => item.activityType === 2).length
    researchCount = filteredResultsFull.filter(item => item.activityType === 3).length
    competitionsCount = filteredResultsFull.filter(item => item.activityType === 4).length
    awardsCount = filteredResultsFull.filter(item => item.activityType === 5).length
    internshipCount = filteredResultsFull.filter(item => item.activityType === 6).length
    academicCount = filteredResultsFull.filter(item => item.activityType === 7).length
    independentCount = filteredResultsFull.filter(item => item.activityType === 8).length
  }

  useEffect(() => {
    let res: any = []

    if (summerProgram) {
      res.push(1)
    }

    if (preCollegeStudies) {
      res.push(2)
    }

    if (research) {
      res.push(3)
    }

    if (competitions) {
      res.push(4)
    }

    if (awards) {
      res.push(5)
    }

    if (internship) {
      res.push(6)
    }

    if (academic) {
      res.push(7)
    }

    if (independent) {
      res.push(8)
    }

    setTypeSelect(res)

  }, [summerProgram, preCollegeStudies, research, competitions, awards, internship, academic, independent])

  const typeArray = [
    { name: 'Summer Program', onchange: setSummerProgram, count: summerProgramCount, check: summerProgram },
    { name: 'Pre-College Studies', onchange: setPreCollegeStudies, count: preCollegeStudiesCount, check: preCollegeStudies },
    { name: 'Research', onchange: setResearch, count: researchCount, check: research },
    { name: 'Competition', onchange: setCompetitions, count: competitionsCount, check: competitions },
    { name: 'Scholarship/Award', onchange: setAwards, count: awardsCount, check: awards },
    { name: 'Internship', onchange: setInternship, count: internshipCount, check: internship },
    { name: 'Academic Enrichment', onchange: setAcademic, count: academicCount, check: academic },
    { name: 'Independent', onchange: setIndependent, count: independentCount, check: independent },
  ]

  return (

    <div className='mt-4 d-flex flex-column' style={{ minWidth: 'fit-content', whiteSpace: 'nowrap' }}>
      <div className='dca-aside-header'>TYPE</div>
      <div className="checkbox-list d-flex flex-column" style={{ marginTop: '5px' }}>

        {typeArray.map((res, index) => (
          <label key={index} className="d-flex justify-content-between" style={{ cursor: 'pointer', marginTop: index !== 0 ? '4px' : '' }}>
            <div>
              <input
                type="checkbox"
                className='dca-checkbox'
                checked={checkCheckCase(res.check)}
                name={res.name}
                onChange={(event) => (res.onchange(handleCheckChange(event)))}
                style={{ cursor: 'pointer' }}
              />
              <span className='ms-3 me-3'>{res.name}</span>
            </div>

            <span className='text-muted fs-7 fw-bolder'>{res.count}</span>
          </label>
        ))}

      </div>
    </div>
  )
}

export { ECTypeSearch }