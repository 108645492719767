import Select from 'react-select';
import arrowUpArrowDown from '../../../../custom_assets/arrow-up-arrow-down.png'
import { returnMobileSortLabel } from '../../dictionary';

const MobileSortSelect = (props) => {
  const options = Object.keys(props.sortKeyOptions).map((key) => ({
    value: props.sortKeyOptions[key],
    label: <span>{returnMobileSortLabel(key)}</span>
  }));

  const handleChange = (selectedOption) => {
    props.setSortKey(selectedOption.value);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '38px',
      borderColor: '#EDEDED',
      fontFamily: 'ColfaxWebMedium',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      flexDirection: 'row',
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: '18px',
      paddingRight: '0px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      flexDirection: 'row',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      zIndex: 999,
      cursor: 'pointer'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'lightgrey' : state.isFocused ? 'grey' : 'white',
      cursor: 'pointer',
      color: 'black'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black',
      fontFamily: 'ColfaxWebMedium',
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',

      // Update from #771
      // whiteSpace: 'normal',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      marginRight: '8px',
      paddingLeft: '0px',
      color: 'black !important'
    }),
    input: (provided) => ({
      ...provided,
      display: 'none'
    })
  };

  const selectedOption = options.find(opt => opt.value === props.sortKey)
  const selectedLabel = (
    <div
      className='dca-search-select-sort-mobile-label-wrapper'>
      <img
        alt="arrow-up-arrow-down"
        src={arrowUpArrowDown}
        style={{ marginRight: '8px', width: '16px', height: '15px' }}
      />
      <span style={{ paddingTop: '2px' }}>{selectedOption?.label}</span>
    </div>
  )

  return (
    <Select
      options={options}
      onChange={handleChange}
      value={{ label: selectedLabel, value: props.sortKey }}
      className="dca-search-select-sort-mobile"
      styles={customStyles}
    />
  );
};

export { MobileSortSelect };
