import { ContentBlock } from "../ContentBlock"
import ExtracurricularsIcon from '../../../../custom_assets/dashboard/ec.png'
import Select from 'react-select'
import { pageRoutes, relatedInterestAreas, handleLocalStorageData, URL } from "../../dictionary"
import { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router"
import axios from "axios"
import wizardHome from '../../../../custom_assets/dashboard/wizardHome.png'
import { returnProgressTrackerText } from "../ProgressTrackerText/Extracurriculars"
import { MultiSelect } from "../../MultiSelect"
import { RadioInput } from "../../RadioInputs"
import { scrollToWizard } from "../../../pages/Dashboard"
import { DashboardReactGAHandlers } from "../../ReactGAHandlers"

const Extracurriculars = ({ user, permissionGroups, wizardActive, setWizardActive, advisors, status, expand, setUserprofileSelectedTab, majorData, ecData, statusLabelMaxWidth }) => {

  const {
    handleGAEventCTAClick,
    handleGAEventWizardSkipClick,
    handleGAEventECWizardNextClick } = DashboardReactGAHandlers()

  let navigate = useNavigate()
  let blockClass = 'Extracurriculars'
  // let title = 'Extracurriculars'

  const [title, setTitle] = useState(window.innerWidth <= 500 ? 'Extra-curriculars' : 'Extracurriculars');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setTitle('Extra-curriculars');
      } else {
        setTitle('Extracurriculars');
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const extracurricularsPositionRef = useRef<HTMLDivElement>(null);

  const body = `Extracurricular activities provide a way to develop and showcase your individual strengths and passions. As your extracurricluar profile is an increasingly important aspect of the college admissions process, we recommend identifying and pursuing extracurricular activities that are aligned with your interests and will be impactful in strengthing your applications.`

  let userECs = user?.activitiesInterest ?? undefined
  let gradYr = user?.hsGradYr ?? undefined

  const [wizard, setWizard] = useState<any>({
    page: 1,
    response: false,
    params1: undefined,
    params2: undefined
  })

  let sortParams = `&sort=name%2CAscending`
  const [ecSearchURL, setECSearchURL] = useState<any>(`${pageRoutes.ecSearch}?${sortParams}`)
  const [ecPremiumInsights, setECPremiumInsights] = useState([])
  const [interestOptions, setInterestOptions] = useState<any>([]);
  const [selectedInterests, setSelectedInterests] = useState<any>([]);
  const [typeSelects, setTypeSelects] = useState<any>({
    summerProgram: false,
    preCollegeStudies: false,
    research: false,
    competitions: false,
    awards: false,
    internship: false,
    academic: false,
    independent: false,
    all: false
  })
  const typeMap = {
    summerProgram: 1,
    preCollegeStudies: 2,
    research: 3,
    competitions: 4,
    awards: 5,
    internship: 6,
    academic: 7,
    independent: 8
  };

  const checkCheckCase = (filterVal) => {
    return filterVal ? true : false
  }

  let typeArray = [
    { name: 'Summer Program', onchange: 'summerProgram', check: typeSelects.summerProgram },
    { name: 'Pre-College Studies', onchange: 'preCollegeStudies', check: typeSelects.preCollegeStudies },
    { name: 'Research', onchange: 'research', check: typeSelects.research },
    { name: 'Competition', onchange: 'competitions', check: typeSelects.competitions },
    { name: 'Scholarship/Award', onchange: 'awards', check: typeSelects.awards },
    { name: 'Internship', onchange: 'internship', check: typeSelects.internship },
    { name: 'Academic Enrichment', onchange: 'academic', check: typeSelects.academic },
    { name: 'Independent', onchange: 'independent', check: typeSelects.independent },
    { name: 'All of the above', onchange: 'all', check: typeSelects.all },
  ]

  useEffect(() => {
    const getInsights = async () => {
      let res = await axios.get(`${URL}/ec_insights/`)
      setECPremiumInsights(res.data)
    }

    getInsights()
  }, [])

  useEffect(() => {
    const getInterestOptions = (ecData) => {
      let res: any = []

      relatedInterestAreas.forEach((interest) => {
        res.push({ value: Object.keys(interest), label: Object.values(interest) })
        setInterestOptions(res)
      });
    }
    getInterestOptions(ecData)
  }, [ecData])

  useEffect(() => {

    if (typeSelects.all === true) {
      // pass array of all 8 types to params
      return setWizard(prevState => ({
        ...prevState, params2: [1, 2, 3, 4, 5, 6, 7, 8]
      }))
    }

    else {
      let converted = Object.keys(typeSelects)
        .filter((key) => typeSelects[key])
        ?.map((key) => typeMap[key]);

      return setWizard(prevState => ({
        ...prevState, params2: converted
      }))
    }
  }, [typeSelects])

  const handleInterestSelectChange = (selectedInterests) => {
    console.log(selectedInterests)
    setSelectedInterests(selectedInterests);
    setWizard(prevState => ({
      ...prevState, params1: selectedInterests
    }))
  }

  useEffect(() => {
    setWizard(prevState => ({
      ...prevState, response: false
    }));
  }, [wizard.page])

  useEffect(() => {

    let interestAreas = ``
    let types = ``

    if (wizard.params1?.length > 0) {
      const interestIDs = wizard.params1?.map(obj => obj.value).join(',');
      interestAreas = `interestAreas=${interestIDs}`
      // setECSearchURL(`${pageRoutes.ecSearch}?interestAreas=${interestIDs}${sortParams}`)
    }

    if (wizard.params2 != undefined) {
      const typeIDs = wizard.params2?.map(obj => obj).join(',');
      types = `type=${typeIDs}`
      // setECSearchURL(`${pageRoutes.ecSearch}?type=${typeIDs}${sortParams}`)
    }

    setECSearchURL(`${pageRoutes.ecSearch}?${interestAreas}${types != `` ? `&${types}` : `${types}`}${sortParams}`)

  }, [wizard.params1, wizard.params2])

  useEffect(() => {
    if (wizardActive?.Extracurriculars === false) {
      setWizard({
        page: 1,
        response: false,
        params1: undefined,
        params2: undefined
      })

      setTypeSelects({
        summerProgram: false,
        preCollegeStudies: false,
        research: false,
        competitions: false,
        awards: false,
        internship: false,
        academic: false,
        independent: false,
        all: false
      })
    }

    else if (wizardActive?.Extracurriculars && extracurricularsPositionRef.current) {
      scrollToWizard(extracurricularsPositionRef)
    }
  }, [wizardActive?.Extracurriculars])

  useEffect(() => {
    if (user?.majorsInterest?.length > 0 && interestOptions?.length > 0 && wizardActive?.Extracurriculars === true) {
      handleMajorMapForSelectedInterestPreset()
    }
  }, [user])

  const handleMajorMapForSelectedInterestPreset = () => {
    // 1 (Computer & Information Sciences) --> 5 (Computer Science)

    // 1 (Computer & Information Sciences) --> 28 (Technology)

    // 2 (Physical & Natural Sciences) --> 24 (Physical and Natural Sciences)

    // 3 (Arts) --> 19 (Performing Arts)

    // 3 (Arts) --> 29 (Visual Arts)

    // 4 (Humanities) --> 12 (Humanities)

    // 4 (Humanities) --> 13 (Writing & Journalism)

    // 5 (Engineering) --> 7 (Engineering)

    // 5 (Engineering) --> 28 (Technology)

    // 6 (Mathematics & Statistics) --> 17 (Math)

    // 7 (Social Sciences) --> 26 (Social Sciences)

    // 8 (Health & Medicine) --> 18 (Medicine)

    // 9 (Business) --> 2 (Business & Economics)

    let userMajorIDs = user?.majorsInterest
    let primarySecondaryGroups: number[] = []
    let mappedPrimarySecondaryGroups: number[] = []
    let interestAreaArr: number[] = []

    const validPrimaryOrSecondaryFound = (num) => {
      if ([1, 2, 3, 4, 5, 6, 7, 8, 9].includes(num)) {
        return true
      }

      return false
    }

    const mapInterestID = (groupNum) => {
      if (!mappedPrimarySecondaryGroups.includes(groupNum)) {
        mappedPrimarySecondaryGroups.push(groupNum)

        switch (groupNum) {
          case 1:
            interestAreaArr.push(5);
            interestAreaArr.push(28);
            break
          case 2:
            interestAreaArr.push(24);
            break
          case 3:
            interestAreaArr.push(19);
            interestAreaArr.push(29);
            break
          case 4:
            interestAreaArr.push(12);
            interestAreaArr.push(13);
            break
          case 5:
            interestAreaArr.push(7);
            interestAreaArr.push(28);
            break
          case 6:
            interestAreaArr.push(17);
            break
          case 7:
            interestAreaArr.push(26);
            break
          case 8:
            interestAreaArr.push(18);
            break
          case 9:
            interestAreaArr.push(2);
            break
        }
      }
    }

    userMajorIDs.map((majorID) => {
      let majorObj = majorData.find((majorObj) => majorObj.id === majorID)

      if (majorObj?.primaryGroup != null && !primarySecondaryGroups.includes(majorObj?.primaryGroup) && validPrimaryOrSecondaryFound(majorObj?.primaryGroup)) {
        primarySecondaryGroups.push(majorObj?.primaryGroup)
      }

      if (majorObj?.secondaryGroup != null && !primarySecondaryGroups.includes(majorObj?.secondaryGroup) && validPrimaryOrSecondaryFound(majorObj?.secondaryGroup)) {
        primarySecondaryGroups.push(majorObj?.secondaryGroup)
      }

    })

    if (primarySecondaryGroups?.length > 0) {
      primarySecondaryGroups.map(groupNum => {
        mapInterestID(groupNum)
      })
    }

    if (interestAreaArr?.length > 0) {
      let cleaned = [...new Set(interestAreaArr)]

      if (cleaned?.length > 0) {
        let presetInterestOptions = [...interestOptions]
        presetInterestOptions = presetInterestOptions.filter(interestObj => cleaned.includes(parseInt(interestObj.value)))
        handleInterestSelectChange(presetInterestOptions)
      }
    }


  }

  const button = <button
    onClick={() => {
      const handleOpenWizard = () => {
        if (user?.majorsInterest?.length > 0 && interestOptions?.length > 0) {
          handleMajorMapForSelectedInterestPreset()
        }

        setWizardActive(prevState => ({
          ...prevState, Extracurriculars: true
        }))
      }

      handleGAEventCTAClick(title);

      userECs?.length > 0 ? navigate(pageRoutes.ecSearch) : handleOpenWizard()
    }}
    className="dca-dashboard-button" style={{ marginTop: status === 'inactive' ? '34px' : '' }}>Explore Extracurriculars</button>

  const resources = [
    {
      navigate: pageRoutes.ecPremiumInsightsBase,
      title: 'EC Insights',
      description: 'Leverage exclusive data-driven insights to maximize the impact of your extracurriculars'
    },
    {
      navigate: pageRoutes.advisorNetwork,
      title: 'Advisors Network',
      description: `Hear insider perspectives on the specific EC activities you're considering`
    },
    {
      navigate: pageRoutes.personalizedExpertHelp,
      title: 'Expert Q&A',
      description: 'Get personalized help with identifying impactful and aligned ECs'
    }
  ]

  const insights = [
    {
      navigate: pageRoutes.overviewAdmissionsAdviceWithHash('crafting-an-extracurriculars-profile'),
      text: 'Crafting An Extracurriculars Profile'
    },
    {
      external: true,
      navigate: `https://blog.nextfour.ai/metasearch-for-extracurricular-activities/`,
      text: 'Metasearch For Extracurricular Activities'
    }
  ]

  const returnWizardQuestion = () => {
    switch (wizard.page) {
      case 1:
        return `What are your interest areas?`
      case 2:
        return `What types of activities are most interesting to you?`
    }
  }

  const returnWizardResponseForm = () => {
    let page1 = <div className='dca-dashboard-wizard-form-wrapper' style={{ width: '100%' }}>
      <div className='mt-4 d-flex flex-column' style={{ width: '100%' }}>
        <div className='dca-aside-header dca-dashboard-select-label'>INTEREST AREAS</div>
        <MultiSelect options={interestOptions} value={selectedInterests} onChange={handleInterestSelectChange} />
      </div>

      <button
        style={{ marginBottom: '0px' }}
        onClick={() => {
          handleGAEventECWizardNextClick('Interests');

          setWizard(prevState => ({ ...prevState, page: wizard.page + 1 }))
        }}
        className="dca-dashboard-wizard-button"
        disabled={wizard?.params1?.length > 0 ? false : true}>Next</button>

      <button
        style={{ background: '#fafafa', marginTop: '22px' }}
        className="dca-dashboard-wizard-button"
        onClick={() => {
          setWizard(prevState => ({
            ...prevState, page: prevState.page + 1, params1: undefined
          }))
        }}>I'm not sure</button>
    </div>

    let page2 = <div className='dca-dashboard-wizard-form-wrapper'>
      {typeArray.map((res, index) => (

        <RadioInput
          selectedState={checkCheckCase(res.check)}
          onClick={() => {
            if (res.onchange === 'all') {
              setTypeSelects({
                summerProgram: false,
                preCollegeStudies: false,
                research: false,
                competitions: false,
                awards: false,
                internship: false,
                academic: false,
                independent: false,
                all: !typeSelects.all
              })
            }

            else {
              setTypeSelects(prevState => ({
                ...prevState, [res.onchange]: !prevState[res.onchange], all: false
              }))
            }
          }}
          mt={index != 0 ? '10px' : ''}
          mobileMT={index != 0 ? '18px' : ''}
          name={res.name}
          id={res.name}
          text={res.name}
          box={true} />
      ))}

      <button
        onClick={() => {
          handleGAEventECWizardNextClick('Types');
          navigate(ecSearchURL)
        }}
        className="dca-dashboard-wizard-button"
        disabled={wizard?.params2?.length > 0 ? false : true}>Next</button>
    </div>

    switch (wizard.page) {
      case 1:
        return page1
      case 2:
        return page2
    }
  }

  const sharedWizardContent = <div className='dca-dashboard-wizard-header'>
    <div
      className="dca-intro-back-link"
      onClick={() => wizard.page > 1 ? setWizard(prevState => ({
        ...prevState, page: prevState.page - 1
      })) : setWizardActive(prevState => ({
        ...prevState, [blockClass]: false
      }))}><i className="fa-solid fa-arrow-left dca-intro-backArrow"></i>&nbsp;&nbsp;Back</div>

    <img className='dca-dashboard-wizard-home-icon' src={wizardHome} alt='home_icon' onClick={() => {
      setWizardActive(prevState => ({
        ...prevState,
        [blockClass]: false,
      }));
    }}></img>

    <div
      className="dca-intro-back-link"
      style={{ visibility: 'hidden' }}
    ><i className="fa-solid fa-arrow-left dca-intro-backArrow"></i>&nbsp;&nbsp;Back</div>
  </div>

  const returnNoteText = () => {

    let len = user?.majorsInterest?.length ?? 0
    // let selectedVals = selectedInterests?.map((obj) => parseInt(obj.value))

    if (wizard.page == 1 && len > 0 && interestOptions?.length > 0) {
      return len == 1 ? `We recommended one area based on your saved major, you can add/modify and we suggest selecting multiple interests if applicable` : `We recommended some areas based on your saved major(s), you can add/modify to indicate all applicable interests`
    }

    return `You can select multiple`
  }

  const wizardContent = (<div ref={extracurricularsPositionRef} className="d-flex flex-column justify-content-center">

    {sharedWizardContent}

    <h4 className="dca-introflow-question-text" id="dca-introflow-question-text-wizard-margin">{returnWizardQuestion()}</h4>
    <span className="dca-dashboard-wizard-select-multiple-note">{returnNoteText()}</span>

    {returnWizardResponseForm()}

    <span
      className="dca-dashboard-wizard-skip-wizard-link"
      onClick={() => {
        handleGAEventWizardSkipClick(title);
        navigate(pageRoutes.ecSearch)
      }}>Go straight to Extracurriculars Explorer</span>
  </div>
  );

  const handleProgressTrackerLink = () => {
    setUserprofileSelectedTab('EC Activities of Interest');
    navigate(pageRoutes.userProfileWithId(user?.user_id))
  }

  const progressTracker = {
    color: status,
    text: returnProgressTrackerText(userECs, permissionGroups, ecData, ecPremiumInsights, advisors, gradYr),
    count: userECs?.length ?? 0,
    subject: userECs?.length === 1 ? `Extracurricular` : `Extracurriculars`,
    link: userECs?.length > 0 ? handleProgressTrackerLink : undefined
  }

  const block = {
    class: blockClass,
    title: title,
    body: body,
    icon: ExtracurricularsIcon,
    specialIconClass: 'dca-dashboard-ecIcon',
    button: button,
    resources: resources,
    insights: insights,
    wizardContent: wizardContent,
    status: status,
    expand: expand,
    progressTracker: status != 'inactive' ? progressTracker : undefined
  }

  return <ContentBlock block={block} wizardActive={wizardActive} setWizardActive={setWizardActive} statusLabelMaxWidth={statusLabelMaxWidth} />
}

export { Extracurriculars }