import { useState } from "react"
import axios from "axios"
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { handleUpdateUserData, handleUpdateUserProfile } from "../../../pages/UserProfile"

const GradesForm = ({ user, setUser, apiToken, userData, setUserData }) => {

  const [loading, setLoading] = useState(false)

  const formSchema = Yup.object().shape({
    gpaWeighted: Yup.number()
      .min(0, 'Score cannot be lower than 0')
      .nullable(),
    gpaUnweighted: Yup.number()
      .min(0, 'Score cannot be lower than 0')
      .nullable(),
    classRankNumber: Yup.number()
      .nullable(),
    classRankSize: Yup.number()
      .nullable(),
    classRankTier: Yup.number()
      .nullable()
  })

  let initialValues = {
    gpaWeighted: userData.gpaWeighted, gpaUnweighted: userData.gpaUnweighted, classRankNumber: userData.classRankNumber, classRankSize: userData.classRankSize, classRankTier: userData.classRankTier
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        handleUpdateUserData(user, values, setUserData, apiToken);
        handleUpdateUserProfile(setUser, apiToken)
        console.log('Submitted these values:', values)
        setLoading(false)
      } catch (error) {
        console.error(error)
        alert('Error encountered')
        setStatus('The grades details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    }
  })

  const gpaWeightedField = (
    <div className="dca-userProfile-form-field">
      <input
        placeholder='Weighted GPA'
        {...formik.getFieldProps('gpaWeighted')}
        className={clsx(
          'form-control form-control-solid',
          { 'is-invalid': formik.touched.gpaWeighted && formik.errors.gpaWeighted },
          {
            'is-valid': formik.touched.gpaWeighted && !formik.errors.gpaWeighted,
          }
        )}
        type='string'
        name='gpaWeighted'
        autoComplete='off'
      />
      {formik.touched.gpaWeighted && formik.errors.gpaWeighted && (
        <div className='fv-plugins-message-container'>
          <span role='alert'>GPA (weighted) value must be a valid decimal number</span>
        </div>
      )}
    </div>
  )

  const gpaUnweightedField = (
    <div className="dca-userProfile-form-field">
      <input
        placeholder='Unweighted GPA'
        {...formik.getFieldProps('gpaUnweighted')}
        className={clsx(
          'form-control form-control-solid',
          { 'is-invalid': formik.touched.gpaUnweighted && formik.errors.gpaUnweighted },
          {
            'is-valid': formik.touched.gpaUnweighted && !formik.errors.gpaUnweighted,
          }
        )}
        type='string'
        name='gpaUnweighted'
        autoComplete='off'
      />
      {formik.touched.gpaUnweighted && formik.errors.gpaUnweighted && (
        <div className='fv-plugins-message-container'>
          <span role='alert'>GPA (unweighted) value must be a valid decimal number</span>
        </div>
      )}
    </div>
  )

  const classRankNumberField = (
    <div className="dca-userProfile-form-field" style={{ width: '100%' }}>
      <input
        placeholder='Class Rank'
        {...formik.getFieldProps('classRankNumber')}
        className={clsx(
          'form-control form-control-solid',
          { 'is-invalid': formik.touched.classRankNumber && formik.errors.classRankNumber },
          {
            'is-valid': formik.touched.classRankNumber && !formik.errors.classRankNumber,
          }
        )}
        type='string'
        name='classRankNumber'
        autoComplete='off'
      />
      {formik.touched.classRankNumber && formik.errors.classRankNumber && (
        <div className='fv-plugins-message-container'>
          <span role='alert'>Class Rank value must be a number</span>
        </div>
      )}
    </div>
  )

  const classSizeField = (
    <div className="dca-userProfile-form-field" style={{ width: '100%' }}>
      <input
        placeholder='Class Size'
        {...formik.getFieldProps('classRankSize')}
        className={clsx(
          'form-control form-control-solid',
          { 'is-invalid': formik.touched.classRankSize && formik.errors.classRankSize },
          {
            'is-valid': formik.touched.classRankSize && !formik.errors.classRankSize,
          }
        )}
        type='string'
        name='classRankSize'
        autoComplete='off'
      />
      {formik.touched.classRankSize && formik.errors.classRankSize && (
        <div className='fv-plugins-message-container'>
          <span role='alert'>Class Size value must be a number</span>
        </div>
      )}
    </div>
  )

  const inputStyle = { width: '100%', borderRadius: '5px', border: 'transparent', background: '#F9F9F9' }

  const classRankTierField = (
    <div className="dca-userProfile-form-field">
      <select
        className='form-select'
        data-control="select2"
        {...formik.getFieldProps('classRankTier')}
        name="classRankTier"
        style={inputStyle}
      >
        <option value={undefined} hidden>Select Class Rank Tier</option>
        <option value={1}>Top 1%</option>
        <option value={2}>Top 5%</option>
        <option value={3}>Top 10%</option>
        <option value={4}>Top 25%</option>
        <option value={5}>Top 50%</option>
        <option value={6}>Bottom 50%</option>
      </select>
    </div>
  )

  return (

    <div>
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        id="gradesForm">

        <div className='dca-userProfile-form-field-wrapper'>
          <div className='dca-userProfile-form-field-column'>
            <div className='dca-userProfile-form-field'>GPA (weighted)</div>
            <div className='dca-userProfile-form-field'>GPA (unweighted)</div>
            <div className='dca-userProfile-form-field'>Class Rank / Class Size</div>
            <div className='dca-userProfile-form-field'>Class Rank Tier</div>
          </div>

          <div className='d-flex flex-column' style={{ width: '100%' }}>
            {gpaWeightedField}
            {gpaUnweightedField}
            <div className='d-flex flex-row justify-content-evenly'>
              {classRankNumberField}
              {classSizeField}
            </div>
            {classRankTierField}
          </div>
        </div>

        <div className='separator'></div>

        <div className='d-flex flex-row justify-content-end align-items-end'>
          <button
            className='dca-userProfile-discard-changes-button text-muted'
            onClick={(e) => {
              e.preventDefault();
              formik.setValues({
                gpaWeighted: userData.gpaWeighted, gpaUnweighted: userData.gpaUnweighted, classRankNumber: userData.classRankNumber, classRankSize: userData.classRankSize, classRankTier: userData.classRankTier
              })
            }}>Discard</button>

          <button
            type='submit'
            id='gradesForm_submit'
            className='dca-userProfile-save-changes-button'
            disabled={formik.isSubmitting || !formik.isValid || !formik.values}
          >
            {!loading && <span className='indicator-label'>Save Changes</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form >
    </div >
  )

}

export { GradesForm }