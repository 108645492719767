import noUiSlider from 'nouislider'
import { numberConverter } from '../../dictionary';
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router';


const ECCostSearch = ({ costSelect, setCostSelect, resetStateToggle }) => {

  const location = useLocation()
  const costSliderRef: any = useRef(null);
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (costSliderRef.current && costSliderRef.current.noUiSlider) {
      costSliderRef.current.noUiSlider.set([costSelect]);
    }
  }, [costSelect]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setCostSelect(25000);

    if (costSliderRef.current && costSliderRef.current.noUiSlider) {
      costSliderRef.current.noUiSlider.set(25000);
    }
  }, [resetStateToggle]);

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let maxCostParam = params.get('maxCost');

    if (maxCostParam) {
      setCostSelect(parseInt(maxCostParam))
    }

    else {
      setCostSelect(25000)
    }

  }, [location.search]);

  useEffect(() => {
    const slider: any = document.querySelector("#kt_slider_cost");

    if (!costSliderRef.current) {

      let params = new URLSearchParams(location.search);
      let maxCostParam = params.get('maxCost');

      noUiSlider.create(slider, {
        start: [maxCostParam ? parseInt(maxCostParam) : 25000],
        connect: true,
        step: 100,
        range: {
          "min": 0,
          "max": 25000
        },
        tooltips: {
          to: function (value) {
            return `$${numberConverter(Math.round(value))}`;
          }
        },
      });

      slider.noUiSlider.on("set", function (values) {
        setCostSelect(Math.round(parseFloat(values[0])))
      });

      costSliderRef.current = slider;

    }

    return () => {
      if (slider && slider.noUiSlider) {
        slider.noUiSlider.destroy();
      }
    };


  }, [])

  return (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '10px' }}>
      <div className='dca-aside-header'>MAXIMUM COST</div>
      <div className="mb-0" style={{ marginTop: '50px' }}>
        <div id="kt_slider_cost"></div>
      </div>
    </div>
  )
}

export { ECCostSearch }

const ECCostSearchMobile = ({ costSelect, setCostSelect, resetStateToggle }) => {

  const location = useLocation()
  const costSliderRef: any = useRef(null);
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (costSliderRef.current && costSliderRef.current.noUiSlider) {
      costSliderRef.current.noUiSlider.set([costSelect]);
    }
  }, [costSelect]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setCostSelect(25000);

    if (costSliderRef.current && costSliderRef.current.noUiSlider) {
      costSliderRef.current.noUiSlider.set(25000);
    }
  }, [resetStateToggle]);

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let maxCostParam = params.get('maxCost');

    if (maxCostParam) {
      setCostSelect(parseInt(maxCostParam))
    }

    else {
      setCostSelect(25000)
    }

  }, [location.search]);

  useEffect(() => {
    const slider: any = document.querySelector("#kt_slider_cost_mobile");

    if (!costSliderRef.current) {

      let params = new URLSearchParams(location.search);
      let maxCostParam = params.get('maxCost');

      noUiSlider.create(slider, {
        start: [maxCostParam ? parseInt(maxCostParam) : 25000],
        connect: true,
        step: 100,
        range: {
          "min": 0,
          "max": 25000
        },
        tooltips: {
          to: function (value) {
            return `$${numberConverter(Math.round(value))}`;
          }
        },
      });

      slider.noUiSlider.on("set", function (values) {
        setCostSelect(Math.round(parseFloat(values[0])))
      });

      costSliderRef.current = slider;

    }

    return () => {
      if (slider && slider.noUiSlider) {
        slider.noUiSlider.destroy();
      }
    };


  }, [])

  return (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '10px' }}>
      <div className='dca-aside-header'>MAXIMUM COST</div>
      <div className="mb-0" style={{ marginTop: '50px' }}>
        <div id="kt_slider_cost_mobile"></div>
      </div>
    </div>
  )
}

export { ECCostSearchMobile }