import axios from 'axios'
import * as dictionary from '../components/dictionary'
import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Cards } from '../components/MajorDetail/Cards'
import { Aside } from '../components/MajorDetail/Aside'
import { getAuth } from '../modules/auth'
import { collegeMap } from '../components/collegeMap'
import { LoadingIndicator } from '../components/LoadingIndicator'
import { ConnectWithAdvisorButton } from '../components/ConnectWithAdvisorButton'

const MajorDetailPage = ({ user, setUser, collegeListLength, prompt, setPrompt, navigateTo, setNavigateTo, majorSearchLocation, idToSend }) => {

  let apiToken: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
  }

  let navigate = useNavigate()

  // const sortKeys = [{ 'Alphabetical': 'name' }, { 'College Metarank': 'metarank' }, { 'Popularity': 'sizeIndex' }, { 'Earnings': 'mdn4yrEarnings' }]
  const sortKeys = [{ 'Alphabetical': 'name' }, { 'Popularity': 'sizeIndex' }, { 'Earnings': 'mdn4yrEarnings' }]
  const sortKeysMobile = { Alphabetical: 'name', Popularity: 'sizeIndex', Earnings: 'mdn4yrEarnings' }
  const handleSortKeyChange = (event) => {
    const selectedKey = event.target.value
    setSortKey(selectedKey)
  }
  const sortKeyReturn = (sortKey) => {
    switch (sortKey) {
      case 'name':
        return 'Alphabetical'
      case 'metarank':
        return 'College Metarank'
      case 'sizeIndex':
        return 'Popularity'
      case 'mdn4yrEarnings':
        return 'Earnings'
      default:
        return 'Alphabetical'
    }
  }
  const handleSortOrderChange = () => {
    sortOrder === 'Ascending' ? setSortOrder('Descending') : setSortOrder('Ascending')
  }

  // const { majorId } = useParams()
  const majorId = idToSend
  const [major, setMajor] = useState<any>([])
  const [majors, setMajors] = useState<any>([])
  const [advisorConnectEligible, setAdvisorConnectEligible] = useState(false)
  const [results, setResults] = useState<any>([])
  const [dataLoaded, setDataLoaded] = useState(false)
  const [resultsNotNull, setResultsNotNull] = useState<any>(false)
  const [programData, setProgramData] = useState<any>([])
  // const [colleges, setColleges] = useState<any>([])
  const [collegesData, setCollegesData] = useState<any>([])
  const [collegesOptions, setCollegesOptions] = useState<any>([])
  const [collegeSet, setCollegeSet] = useState<any>([])
  const [showRelatedMajors, setShowRelatedMajors] = useState(false)
  const [sortKey, setSortKey] = useState<any>('name')
  const [sortOrder, setSortOrder] = useState<any>('Ascending')

  const [collegeSelect, setCollegeSelect] = useState<any>([])

  // Public and private select not fully in use at the moment, only useful when type field is accessible
  const [publicSelect, setPublicSelect] = useState<any>(false)
  const [privateSelect, setPrivateSelect] = useState<any>(false)
  //

  const [collegeSetSelect, setCollegeSetSelect] = useState<any>([])
  const [collegeFilter, setCollegeFilter] = useState<any>((item) => {
    return item => item
  })
  const [collegeSetFilter, setCollegeSetFilter] = useState<any>((item) => {
    return item => item
  })

  const [filtersActive, setFiltersActive] = useState(0)

  useEffect(() => {
    dictionary.updateTitleAndDescription(dictionary.pageRoutes.majorDetailGeneric, major?.name, major?.name)
  }, [major])

  useEffect(() => {
    const sampleInput = {};
    const individualFilters = [
      collegeFilter,
      collegeSetFilter,
    ];

    const isFilterActive = (filter) => filter(sampleInput) !== sampleInput;

    const activeIndividualFiltersCount = individualFilters.filter(isFilterActive)?.length || 0;
    let showRelatedMajorsActive = showRelatedMajors ? 1 : 0;
    const activeFilters = activeIndividualFiltersCount + showRelatedMajorsActive

    setFiltersActive(activeFilters);

  }, [
    collegeFilter,
    collegeSetFilter,
    showRelatedMajors
  ])

  // Show only interests states
  // State of checkbox
  const [onlyInterestsSelect, setOnlyInterestsSelect] = useState(false)
  // State to pass to sort and filter, if true override all card results with a message
  const [guestClickShowOnlyInterest, setGuestClickShowOnlyInterest] = useState(false)

  useEffect(() => {

    const abortController = new AbortController()
    const signal = abortController.signal

    const fetchData = async () => {
      try {
        const majorRes = axios.get(`${dictionary.URL}/majors/${majorId}`, { signal });
        const majorsRes = axios.get(`${dictionary.URL}/majors/`, { signal });
        const collegeSetRes = axios.get(`${dictionary.URL}/collegeset/`, { signal });
        // const collegeDataRes = axios.get(`${dictionary.URL}/colleges/0-to-${collegeListLength}`, { signal });
        const collegeDataRes = axios.get(`${dictionary.URL}/colleges-id-majorSize/`, { signal });
        const programDataRes = axios.get(`${dictionary.URL}/program_data/`, { signal });
        const advisorConnectRes = await axios.get(`${dictionary.URL}/advisor_profiles_connect_with/`);

        // const [major, majors, collegeSet, collegeData, programData] = await Promise.all([
        //   majorRes,
        //   majorsRes,
        //   collegeSetRes,
        //   collegeDataRes,
        //   programDataRes,
        // ]);

        const [major, majors, collegeSet, collegeData, programData, advisorConnect] = await Promise.all([
          majorRes,
          majorsRes,
          collegeSetRes,
          collegeDataRes,
          programDataRes,
          advisorConnectRes
        ]);


        let relatedMajors = programData.data.filter((item) => ((item.major_id !== null) && (item.major_id == majorId)))

        // console.log('relatedMajors set, programData retrieved via GET request:', relatedMajors)

        let collegesArr: any = [];
        relatedMajors.forEach((major) => {
          if (!collegesArr.some((college) => college.value === major.college_id)) {
            collegesArr.push({ value: major.college_id, label: collegeMap.find((item) => parseInt(item.id) === major.college_id)?.name });
          }
        });

        // console.log('colleges array to set:', collegesArr)

        if (collegesArr.length > 0) {
          collegesArr = collegesArr.sort((a, b) => a.label.localeCompare(b.label));
        }

        // console.log('college array after sort:', collegesArr);

        let advisorMajorids: any = []
        advisorConnectRes.data.map(advisor => {
          if (advisor.major_id) {
            advisor.major_id.forEach(id => advisorMajorids.push(id))
          }
        })

        if (advisorMajorids.includes(parseInt(idToSend))) {
          setAdvisorConnectEligible(true)
        }

        await Promise.all([
          setMajor(major.data),
          setMajors(majors.data),
          setCollegeSet(collegeSet.data.map((res) => ({ value: res.colleges, label: res.name }))),
          // setColleges(collegeData.data),
          setCollegesData(collegeData.data),
          setCollegesOptions(collegesArr),
          setProgramData(programData.data),
        ]);

        setDataLoaded(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    return () => {
      abortController.abort()
    }

  }, []);

  useEffect(() => {

    const returnCollegeFilter = (collegeSelect) => {
      if (collegeSelect.length > 0) {
        setCollegeFilter((item) => {
          return item => collegeSelect.includes(item.college_id)
        })
      }

      else {
        setCollegeFilter((item) => {
          return item => item
        })
      }
    }

    returnCollegeFilter(collegeSelect)

  }, [collegeSelect])

  useEffect(() => {
    const returnCollegeSetFilter = (collegeSetSelect) => {

      if (collegeSetSelect.length > 0) {
        setCollegeSetFilter((item) => {
          return item => collegeSetSelect.includes(item.college_id)
        })
      }

      else {
        setCollegeSetFilter((item) => {
          return item => item
        })
      }
    }

    returnCollegeSetFilter(collegeSetSelect)

  }, [collegeSetSelect])

  useEffect(() => {

    const sortAndFilter = (showRelatedMajors, collegeFilter, publicSelect, privateSelect, collegeSetFilter, sortOrder, sortKey) => {

      let res: any = []

      if (showRelatedMajors === false && programData.length > 0) {
        res = programData.filter((item) => ((item.major_id !== null) && (item.major_id == majorId)))

        let collegesOptionsRes: any = [];
        res.forEach((major) => {
          if (!collegesOptionsRes.some((college) => college.value === major.college_id)) {
            collegesOptionsRes.push({ value: major.college_id, label: collegeMap.find((item) => parseInt(item.id) === major.college_id)?.name });
          }
        });

        // collegesOptionsRes = collegesOptionsRes.sort((a, b) => a.label.localeCompare(b.label));
        collegesOptionsRes = collegesOptionsRes.sort((a, b) => a.label.localeCompare(b.label));

        // console.log('college options:', collegesOptionsRes);
        setCollegesOptions(collegesOptionsRes)

      }

      else if (showRelatedMajors === true && programData.length > 0 && majors.length > 0) {

        let ciptIds: any = []

        if (major.cipt_code !== null) {
          ciptIds.push(major.cipt_code.toString());
        }

        if (major.secondaryCIPs !== null) {
          major.secondaryCIPs.map((cip) => {
            ciptIds.push(cip.toString())
          })
        }

        // console.log('all ciptIds:', ciptIds)

        let relatedMajors: any = []
        relatedMajors = majors.filter((item) =>
          ciptIds.includes(item.cipt_code) ||
          (item.secondaryCIPs && item.secondaryCIPs.some((secondCIP) => ciptIds.includes(secondCIP)))
        );

        // console.log('related majors:', relatedMajors)

        let relatedMajorsRes: any = []
        relatedMajors.map((major) => {
          relatedMajorsRes.push(major.id)
        })

        // console.log('related major ids:', relatedMajorsRes)

        relatedMajors = programData.filter((item) => relatedMajorsRes.includes(item.major_id))

        // console.log('related majors data:', relatedMajors)

        res = programData.filter((item) => relatedMajorsRes.includes(item.major_id))

        // console.log('res1:', res)

        let collegesRes: any = []
        relatedMajors.map((major) => {
          collegesRes.push(major.college_id)
        })
        collegesRes = [...new Set(collegesRes)]


        let collegesOptionsRes: any = [];
        relatedMajors.forEach((major) => {
          if (!collegesOptionsRes.some((college) => college.value === major.college_id)) {
            collegesOptionsRes.push({ value: major.college_id, label: collegeMap.find((item) => parseInt(item.id) === major.college_id)?.name });
          }
        });

        collegesOptionsRes = collegesOptionsRes.sort((a, b) => a.label.localeCompare(b.label));
        // console.log('college options:', collegesOptionsRes);
        setCollegesOptions(collegesOptionsRes)

        //Return the length of this array of objects.
        // console.log('colleges with related majors length:', colleges.length)
      }

      res = res.filter(collegeFilter).filter(collegeSetFilter)

      // Hide public and private select while the type field is not accessed via the colleges request. Using collegeMap instead of the colleges get request
      // if (publicSelect === true || privateSelect === true) {

      //   let collegeIds: any = []
      //   res.map((re) => {
      //     collegeIds.push(re.college_id)
      //   })

      //   let collegeArr: any = []
      //   collegeIds.map((college) => {
      //     collegeArr.push(collegeMap.filter((item) => parseInt(item.id) == college)[0])
      //   })

      //   console.log('private public select collegeArr:', collegeArr)

      //   if (privateSelect === false) {
      //     let publicCollegeIds: any = []
      //     collegeArr.map((res) => {
      //       if (res.type === 1) {
      //         publicCollegeIds.push(res.id)
      //       }
      //     })
      //     res = res.filter((item) => publicCollegeIds.includes(item.college_id))

      //     console.log('publicCollegeIDs:', publicCollegeIds)
      //   }

      //   if (publicSelect === false) {
      //     let privateCollegeIds: any = []
      //     collegeArr.map((res) => {
      //       if (res.type === 2 || res.type === 3) {
      //         privateCollegeIds.push(res.id)
      //       }
      //     })

      //     res = res.filter((item) => privateCollegeIds.includes(item.college_id))
      //   }

      // }

      if (res.length === 0 || res == null) {
        return setResultsNotNull(false)
      }

      // console.log('sortKey', sortKey)

      if (sortKey === 'name') {
        // let collegeIds: any = []
        // res.map((re) => {
        //   collegeIds.push(re.college_id)
        // })

        // let collegeArr: any = []
        // collegeIds.map((college) => {
        //   collegeArr.push(colleges.filter((item) => item.id == college))
        // })

        // sortOrder === 'Descending' ?
        //   collegeArr = collegeArr.sort((a, b) => dictionary.sortLogic(b, a, sortKey, 'name')) :
        //   collegeArr = collegeArr.sort((a, b) => dictionary.sortLogic(a, b, sortKey, 'name'))

        // let resB: any = []
        // collegeArr.map((col) => {
        //   let obj: any = res.filter((item) => item.college_id == col.id)
        //   resB.push(obj[0])
        // })

        // console.log('res 2:', resB)

        sortOrder === 'Descending' ? res = res.sort((a, b) => {
          let aName = majors.find((item) => item.id === a.major_id)?.name || '';
          let bName = majors.find((item) => item.id === b.major_id)?.name || '';

          return bName.localeCompare(aName);
        }) : res = res.sort((a, b) => {
          let aName = majors.find((item) => item.id === a.major_id)?.name || '';
          let bName = majors.find((item) => item.id === b.major_id)?.name || '';

          return aName.localeCompare(bName);
        })

        setResults(res)
        if (resultsNotNull === false) {
          setResultsNotNull(true)
        }
      }

      // else if (sortKey === 'metarank') {

      //   sortOrder === 'Descending' ? res = res.sort((a, b) => {
      //     let aCollege = colleges.find((item) => item.id === a.college_id)?.metaRank || '';
      //     let bCollege = colleges.find((item) => item.id === b.college_id)?.metaRank || '';

      //     return bCollege - aCollege;
      //   }) : res = res.sort((a, b) => {
      //     let aCollege = colleges.find((item) => item.id === a.college_id)?.metaRank || '';
      //     let bCollege = colleges.find((item) => item.id === b.college_id)?.metaRank || '';

      //     return aCollege - bCollege;
      //   })

      //   setResults(res)
      //   if (resultsNotNull === false) {
      //     setResultsNotNull(true)
      //   }

      // }

      else {
        sortOrder === 'Descending' ?
          res = res.sort((a, b) => dictionary.sortLogic(b, a, sortKey, 'name')) :
          res = res.sort((a, b) => dictionary.sortLogic(a, b, sortKey, 'name'))

        setResults(res)
        // console.log('res 2:', res)
        if (resultsNotNull === false) {
          setResultsNotNull(true)
        }
      }

      // sortOrder === 'Descending' ?
      //   res = res.sort((a, b) => dictionary.sortLogic(b, a, sortKey, 'name')) :
      //   res = res.sort((a, b) => dictionary.sortLogic(a, b, sortKey, 'name'))

      // setResults(res)
      // console.log('res 2:', res)
      // console.log('majors', majors)
      // if (resultsNotNull === false) {
      //   setResultsNotNull(true)
      // }


    }

    sortAndFilter(showRelatedMajors, collegeFilter, publicSelect, privateSelect, collegeSetFilter, sortOrder, sortKey);

  }, [showRelatedMajors, collegeFilter, publicSelect, privateSelect, collegeSetFilter, sortOrder, sortKey, dataLoaded])

  const returnResults = (resultsNotNull) => {
    switch (resultsNotNull) {
      case true:
        return <Cards
          results={results}
          majors={majors}
          collegesData={collegesData}
          colleges={collegeMap}
          filtersActive={filtersActive}
          sortKey={sortKey}
          sortKeys={sortKeys}
          sortOrder={sortOrder}
          handleSortKeyChange={handleSortKeyChange}
          handleSortOrderChange={handleSortOrderChange}
          sortKeyReturn={sortKeyReturn}
          apiToken={apiToken}
          user={user}
          setUser={setUser}
          setPrompt={setPrompt}
          setNavigateTo={setNavigateTo}
          setSortKey={setSortKey}
          sortKeysMobile={sortKeysMobile}
        />
      case false:
        return <div className="d-flex flex-column align-items-center" style={{ width: '1129px', maxWidth: '1129px' }}>
          <div style={{ marginTop: '180px', marginBottom: '180px' }}>No results meet your criteria</div>
        </div>
    }
  }

  const returnResultsMobile = (resultsNotNull) => {
    switch (resultsNotNull) {
      case true:
        return <Cards
          results={results}
          majors={majors}
          collegesData={collegesData}
          filtersActive={filtersActive}
          colleges={collegeMap}
          sortKey={sortKey}
          sortKeys={sortKeys}
          sortOrder={sortOrder}
          handleSortKeyChange={handleSortKeyChange}
          handleSortOrderChange={handleSortOrderChange}
          sortKeyReturn={sortKeyReturn}
          apiToken={apiToken}
          user={user}
          setUser={setUser}
          setPrompt={setPrompt}
          setNavigateTo={setNavigateTo}
          setSortKey={setSortKey}
          sortKeysMobile={sortKeysMobile}
          mobile={true}
        />
      case false:
        return <div className="d-flex flex-column align-items-center" style={{ width: '1129px', maxWidth: '1129px' }}>
          <div style={{ marginTop: '180px', marginBottom: '180px' }}>No results meet your criteria</div>
        </div>
    }
  }

  const returnAdvisorConnectCardID = (resID, majors) => {
    let majorObj = majors.filter(major => major.id == resID)[0]
    if (majorObj) {
      return `${majorObj.cipt_code}`
    }
    return ""
  }

  const header = (
    <div className="dca-search-header-wrapper">

      <Link
        className="dca-font-M"
        to={majorSearchLocation}
        style={{ color: 'black', marginBottom: '25px' }}>
        <i className="me-3 fa-solid fa-arrow-left" style={{ color: 'black' }} ></i>
        Back to Exploring All Majors
      </Link>

      <div className="d-flex flex-row justify-content-between">
        <span className='dca-search-title align-self-center'>{major.name}</span>

        <div className='d-flex flex-row align-self-end'>
          {advisorConnectEligible && <ConnectWithAdvisorButton
            cardID={returnAdvisorConnectCardID(parseInt(idToSend), majors)}
            searchPage={dictionary.pageRoutes.majorSearch}
            buttonTextParam={major.name}
            inLine={true} />}

          <button
            type="button"
            aria-pressed="true"
            onClick={() => navigate(dictionary.pageRoutes.overviewAdmissionsAdviceWithHash('selecting-intended-major(s)'))}
            className='dca-search-header-bttn'
            style={{ marginRight: '70px' }}
          >Advice: Selecting Intended Major(s)</button>
        </div>
      </div>

      <span className="dca-search-description">{major.description ? major.description : 'Explore the colleges (among the 25 top-ranked universities analyzed) offering this major. Also learn about the relative popularity of each program and discover similar majors.'}</span>
    </div>

    //   </div>
    // </div>
  )

  const headerMobile = (
    <div className="dca-search-header-wrapper">

      <Link
        className="dca-font-M"
        to={majorSearchLocation}
        style={{ color: 'black', marginBottom: '25px' }}>
        <i className="me-3 fa-solid fa-arrow-left" style={{ color: 'black' }} ></i>
        Back to Exploring All Majors
      </Link>

      <div className="dca-search-header-title-button-container">
        <span className='dca-search-title align-self-center'>{major.name}</span>

        <span className="dca-search-description">{major.description ? major.description : 'Explore the colleges (among the 25 top-ranked universities analyzed) offering this major. Also learn about the relative popularity of each program and discover similar majors.'}</span>

        <Aside
          collegeSet={collegeSet}
          colleges={collegeMap}
          collegesOptions={collegesOptions}
          results={results}
          setCollegeSelect={setCollegeSelect}
          setCollegeSetSelect={setCollegeSetSelect}
          setPublicSelect={setPublicSelect}
          setPrivateSelect={setPrivateSelect}
          setShowRelatedMajors={setShowRelatedMajors}
          majorName={major.name}
          filtersActive={filtersActive}
          mobile={true} />

        <div>
          <div className='dca-college-search-resources-links-header'>RESOURCES</div>
          <div
            className='dca-college-search-resources-links'
            onClick={() => navigate(dictionary.pageRoutes.overviewAdmissionsAdviceWithHash('selecting-intended-major(s)'))}
          >Advice: Selecting Intended Major(s)</div>
        </div>
      </div>
    </div>
  )

  // return major && dataLoaded == true ? (
  //   <div>
  //     {header}

  //     <div className='d-flex flex-row' style={{ marginLeft: '40px', marginTop: '25px' }}>
  //       <Aside
  //         collegeSet={collegeSet}
  //         // colleges={colleges}
  //         colleges={collegeMap}
  //         collegesOptions={collegesOptions}
  //         results={results}
  //         setCollegeSelect={setCollegeSelect}
  //         setCollegeSetSelect={setCollegeSetSelect}
  //         setPublicSelect={setPublicSelect}
  //         setPrivateSelect={setPrivateSelect}
  //         setShowRelatedMajors={setShowRelatedMajors}
  //         majorName={major.name}
  //         filtersActive={filtersActive} />
  //       {returnResults(resultsNotNull)}

  //     </div>

  //   </div>

  // ) : (

  //   <div>
  //     {header}

  //     <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginLeft: '300px', marginTop: '20vh' }}>
  //       <LoadingIndicator text={"Loading Information On College Majors..."} />
  //     </div>

  //   </div>

  // )
  return (
    <div id='dca-college-search-wrapper'>
      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex'>
        {header}
      </div>

      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-flex d-md-none'>
        {headerMobile}
      </div>

      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex'>
        <div className='mt-5 d-flex flex-row' style={{ marginLeft: '35px', marginRight: '35px', width: '100%' }}>
          <Aside
            collegeSet={collegeSet}
            colleges={collegeMap}
            collegesOptions={collegesOptions}
            results={results}
            setCollegeSelect={setCollegeSelect}
            setCollegeSetSelect={setCollegeSetSelect}
            setPublicSelect={setPublicSelect}
            setPrivateSelect={setPrivateSelect}
            setShowRelatedMajors={setShowRelatedMajors}
            majorName={major.name}
            filtersActive={filtersActive} />
          {(major && dataLoaded == true) ? returnResults(resultsNotNull) : <div className="d-flex flex-column justify-content-start align-items-center">
            <LoadingIndicator text={"Loading Information On College Majors..."} />
          </div>}
        </div>
      </div>

      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-flex d-md-none justify-content-center'>
        {(major && dataLoaded == true) ? returnResultsMobile(resultsNotNull) : <div className='d-flex d-md-none justify-content-center align-items-center'>
          <LoadingIndicator text={"Loading Information On College Majors..."} mt={'0px'} ml={'0px'} />
        </div>}
      </div>
    </div>

  )
}

export { MajorDetailPage }
